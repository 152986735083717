import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { SCAN_API_URLS } from "../../shared/constants";

const fetchAssetPrices: any = createAsyncThunk<any>(
  "global/fetchAssetPrices",
  async () => {
    const res = await axios.get(
      `https://api.pancakeswap.info/api/tokens?_date=${Date.now() + ""}`,
    );
    return res.data;
  },
);

const fetchNativePrice: any = createAsyncThunk<any, any>(
  "global/fetchNativePrice",
  async ({ network }) => {
    let apikey = "";
    let action = "ethprice";

    switch (network) {
      case "ETH":
        apikey = process.env.REACT_APP_ETHSCAN_API_KEY || "";
        break;
      case "BSC":
        apikey = process.env.REACT_APP_BSCSCAN_API_KEY || "";
        action = "bnbprice";
        break;
      case "POLYGON":
        apikey = process.env.REACT_APP_POLYGONSCAN_API_KEY || "";
        action = "maticprice";
        break;
      default:
        apikey = "";
    }

    const res = await axios.get(
      `${SCAN_API_URLS[network]}?module=stats&action=${action}&apikey=${apikey}`,
    );
    return res.data;
  },
);

const fetchVenusPrices: any = createAsyncThunk<any>(
  "global/fetchVenusPrices",
  async () => {
    const res = await axios.get("https://api.venus.io/api/vtoken");
    return res.data;
  },
);

const fetchAllBeefyAssets: any = createAsyncThunk<any>(
  "global/fetchAllBeefyAssets",
  async () => {
    return [];
  },
);

export {
  fetchAssetPrices,
  fetchNativePrice,
  fetchVenusPrices,
  fetchAllBeefyAssets,
};
