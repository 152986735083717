import styled, { css } from "styled-components";
import { default as NotStyledUnlockWalletButton } from "components/UnlockWalletButton";

type WidgetBarTypes = {
  disabled?: boolean;
  off?: boolean;
};

const Wrapper = styled.aside<WidgetBarTypes>`
  max-width: 288px;
  min-width: 288px;
  padding: 36px 36px 0 0;
  height: 100vh;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 50;
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.off &&
    css`
      display: none;
    `}

  ${(props) =>
    props.disabled &&
    css`
      @media (max-width: 1350px) {
        display: none;
      }
    `}
  
  @media (max-width: 1350px) {
    position: initial;
    height: auto;
    max-width: 848px;
    padding: 0;
    margin: 40px auto;

    .swiper-container {
      width: 100%;
      margin: -30px 0;
    }
    .swiper-wrapper {
      padding: 30px 0;
    }
    .swiper {
      width: 100%;
      height: 100%;
    }
    .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .swiper-slide {
      width: 252px;
      height: 235px;
    }
  }
  @media (max-width: 600px) {
    padding: 0 16px;
    margin-bottom: 20px;
    margin-top: 20px;
    .swiper-slide {
      width: 180px;
      height: 180px;
    }
  }
`;

const Content = styled.div`
  width: 100%;
  @media (max-width: 1350px) {
    display: flex;
    align-items: center;
  }
`;

const UnlockWalletButton = styled(NotStyledUnlockWalletButton)`
  margin-bottom: 20px;
`;

export default {
  Wrapper,
  Content,
  UnlockWalletButton,
};
