import styled, { css } from "styled-components";
import { Link, NavLink } from "react-router-dom";
import { CustomScrollbar } from "shared/styles/Mixins";
import { default as NotStyledUnlockWalletButton } from "components/UnlockWalletButton";
import { default as NotStyledNetworkSelect } from "components/NetworkSelect";
import StyledNetworkSelect from "components/NetworkSelect/styled";
import { default as NotStyledLogo } from "./_resources/RoobeeIcon";

interface WrapperProps {
  collapsed: boolean;
}

interface NavListItemContentProps {
  active?: boolean;
}

const activeSidebarLink = css`
  color: ${(props) => props.theme.Colors.Black};
  position: relative;
  border-radius: 10px;
  border: solid 1.5px transparent;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(101deg, #b6e82f, #50e0ed);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #f8f8f8 inset;
  font-weight: 400;

  svg {
    fill: ${(props) => props.theme.Colors.White};
  }

  @media (max-width: 1350px) {
    box-shadow: 2px 1000px 1px #fff inset;
  }
`;

const Logo = styled(Link)`
  width: 100%;
  display: block;
  margin-bottom: 23px;
  @media (max-width: 1350px) {
    height: auto;
    margin-bottom: 0;
  }
  @media (max-width: 600px) {
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const LogoIcon = styled(NotStyledLogo)`
  @media (max-width: 1350px) {
  }
`;

const Nav = styled.nav`
  ${CustomScrollbar};
  overflow-x: hidden;
  overflow-y: auto;
  width: calc(100% + 40px);
  height: 100%;
  display: flex;
  min-height: 0;
  padding: 20px;
  margin: -20px -20px 0;
  @media (max-width: 1350px) {
    padding-top: 8px;
    width: 100%;
    margin: 0;
    padding-left: 36px;
  }
  @media (max-width: 600px) {
    justify-content: center;
    padding: 0;
  }
`;

const NavList = styled.ul`
  width: 100%;
  @media (max-width: 1350px) {
    max-width: 160px;
  }
  @media (max-width: 600px) {
    max-width: 300px;
  }
`;

const NavListItem = styled.li`
  position: relative;
  margin-bottom: 10px;
  z-index: 2;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: 600px) {
    margin-bottom: 0;
  }
`;

const NavListItemIcon = styled.img`
  width: 24px !important;
  height: 24px !important;
  margin-right: 10px;
`;

const NavListItemContentStyles = css`
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 300;
  width: 100%;
  min-height: 40px;
  font-size: 14px;
  line-height: 18px;
  color: ${(props) => props.theme.Colors.Black};
  border: 3px solid transparent;
  padding: 5px 0 5px 8px;
  transition:
    opacity,
    color,
    border,
    box-shadow,
    filter 0.3s ease;
  @media (hover: hover) {
    &:hover {
      ${activeSidebarLink};
      filter: drop-shadow(2px 2px 6px rgba(0, 0, 0, 0.35));
      border: 1px solid transparent;
      opacity: 0.8;
      border-image: linear-gradient(
        90deg,
        rgba(182, 232, 47, 0.25) 0%,
        rgba(80, 224, 237, 0.25) 100%
      );
    }
  }

  &.active {
    ${activeSidebarLink};
  }

  @media (max-width: 1350px) {
    padding: 8px;
  }

  @media (max-width: 600px) {
    min-height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const NavListItemContent = styled(NavLink)<NavListItemContentProps>`
  ${NavListItemContentStyles};
  ${(props) =>
    props.active &&
    css`
      ${activeSidebarLink};
    `};
`;

const NavListItemButton = styled.span`
  ${NavListItemContentStyles};
  display: none;
  @media (max-width: 1024px) {
    display: flex;
  }
`;

const NavListItemLabel = styled.span``;

const NavListItemUpdate = styled.span`
  text-transform: uppercase;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #ff8b02;
  display: none;
`;

const NavListItemDropdown = styled.ul`
  padding-left: 10px;
  margin-left: 15px;
  margin-top: 10px;
  border-left: 1px solid ${(props) => props.theme.Colors.Black};
`;

const Collapser = styled.button`
  margin: auto 10px 30px auto;
  width: 40px;
  height: 40px;
  display: none;
  justify-content: center;
  align-items: center;

  @media (max-width: 1350px) and (min-width: 601px) {
    display: flex;
  }
`;

const CollapserIcon = styled.div`
  transform: rotate(180deg);
  transition: transform 0.3s ease;

  path {
    fill: #000;
  }
`;

const Hamburger = styled.div`
  .hamburger-react {
    @media (min-width: 1350px) {
      display: none;
    }
  }

  .hamburger-react > div {
    background-color: black !important;
  }
`;

const Header = styled.div<WrapperProps>`
  @media (max-width: 1350px) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 94px;
    z-index: 10;
    background: ${(props) => props.theme.Colors.BackgroundWhite};
    box-shadow: 0 0 24px rgba(0, 111, 128, 0.12);
    padding: 0 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    overflow: visible;
    transform: translateX(232px);

    ${(props) =>
      props.collapsed &&
      css`
        justify-content: flex-end;
      `}
    ${Logo} {
      width: auto;
      height: auto;
      padding: 0;
    }
  }
  @media (max-width: 600px) {
    transform: translateX(100%);
    padding-left: 20px;
    padding-right: 20px;
    height: 60px;
  }
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 0;
  height: 100%;

  @media (max-width: 1350px) {
    margin-top: 30px;
    background: ${(props) => props.theme.Colors.BackgroundWhite};
    transition: transform 0.3s ease;
    min-height: calc(100vh - 40px);
    min-width: 284px;
    z-index: 20;
    position: relative;
    transform: translateX(calc(100vw - 88px));
    overflow: visible;
    &:after {
      content: "";
      top: 0;
      display: block;
      left: 100%;
      position: absolute;
      width: 100%;
      height: 100%;
      background: ${(props) => props.theme.Colors.BackgroundWhite};
    }
  }
  @media (max-width: 600px) {
    margin-top: 60px;
    transform: translateX(100%);
    padding-bottom: 78px;
  }
`;

const Wrapper = styled.aside<WrapperProps>`
  max-width: 232px;
  min-width: 232px;
  padding: 36px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  display: flex;
  flex-direction: column;

  @media (max-width: 1350px) {
    filter: unset;
    border-radius: unset;
    background: 0 0;
    transform: translateX(-100%);
    overflow: visible;
    &:after {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      pointer-events: none;
      width: 100vw;
      height: 100vh;
      background: ${(props) => props.theme.Colors.BackgroundGray6};
      opacity: 0.5;
      z-index: 10;
      transform: translateX(-52px);
      transition: opacity 0.3s ease;
    }
  }
  @media (max-width: 600px) {
    max-width: 100%;
    min-width: 100%;
    padding: 0;
    &:after {
      display: none;
    }
  }

  ${(props) =>
    props.collapsed &&
    css`
      @media (max-width: 1350px) {
        &:after {
          opacity: 0;
          pointer-events: none;
        }
      }

      @media (min-width: 1350px) {
        min-width: 60px;
        max-width: 60px;

        ${Logo} {
          padding: 25px 10px 10px;
          margin-bottom: 20px;
        }

        ${NavListItemLabel},
        ${NavListItemDropdown} {
          display: none;
        }

        ${CollapserIcon} {
          transform: rotate(0deg);
        }

        ${Nav} {
          padding-left: 10px;
          padding-right: 10px;
        }

        ${NavListItemContent} {
          padding: 10px;
        }

        ${NavListItemIcon} {
          margin-right: 0;
          max-height: 20px;
        }
      }

      @media (max-width: 1350px) {
        ${Body} {
          transform: translateX(calc(100vw + 100%));
        }
      }
    `}
`;

const UnlockWalletButton = styled(NotStyledUnlockWalletButton)`
  width: 252px;
  height: 46px;
  @media (max-width: 600px) {
    width: 161px;
    height: 40px;
  }
`;

const NetworkSelect = styled(NotStyledNetworkSelect)`
  width: 252px;
  height: 46px;
  margin-bottom: 0;
  @media (max-width: 600px) {
    width: 189px;
    height: 40px;
    margin-right: 10px;
    ${StyledNetworkSelect.SelectedOption} {
      padding: 6px 12px;
    }

    ${StyledNetworkSelect.SelectedOptionName} {
      font-size: 14px;
    }
  }
`;

export default {
  Wrapper,
  Header,
  Body,
  Hamburger,
  Logo,
  LogoIcon,
  Nav,
  NavList,
  NavListItem,
  NavListItemIcon,
  NavListItemContent,
  NavListItemButton,
  NavListItemLabel,
  NavListItemUpdate,
  NavListItemDropdown,
  NavListItemDropdownItem: NavListItem,
  UnlockWalletButton,
  NetworkSelect,
  Collapser,
  CollapserIcon,
};
