import styled from "styled-components";
import { default as NotSwapIcon } from "./_resources/SwapIcon";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 1px;
  background: #d8e4eb;
  margin-top: 50px;
  margin-bottom: 23px;
`;

const Button = styled.button`
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 10px;
  // width: 40px;
  // height: 40px;
  background: #ffffff;
  border: 1px solid #d8e4eb;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  transition: border-color 0.2s ease;
`;

const SwapIcon = styled(NotSwapIcon)`
  width: 18px;
  height: 18px;
`;

export default {
  Wrapper,
  Button,
  SwapIcon,
};
