import { BigNumber, BigNumberish } from "ethers";
import { useWeb3React } from "@web3-react/core";
import { useCallback, useEffect, useState } from "react";
import { ERC20 } from "shared/constants/abis/types";
import { getContract } from "utils/getContract";
import { ERC20Abi } from "shared/constants";

const useApproval = (address?: string, spender?: string) => {
  const { account, chainId, library } = useWeb3React();
  const [contract, setContract] = useState<ERC20>();
  const [allowance, setAllowance] = useState<BigNumber>(BigNumber.from(0));

  const updateAllowance = useCallback(async () => {
    if (!!contract && !!spender && !!account) {
      const allowance_ = await contract.allowance(account, spender);

      setAllowance(allowance_);
    }
  }, [account, spender, contract]);

  useEffect(() => {
    if (!!account && !!address && !!chainId && !!library) {
      const contract_ = getContract<ERC20>(
        ERC20Abi,
        address,
        library.getSigner(),
      );

      setContract(contract_);
    }
  }, [address, account, chainId, library]);

  useEffect(() => {
    if (!!spender && !!contract && !!account && !!chainId && !!library) {
      updateAllowance();
    }
  }, [spender, contract, account, chainId, library, updateAllowance]);

  const approve = useCallback(
    async (value: BigNumberish) => {
      if (!!contract && !!spender && !!account) {
        await (
          await contract.approve(spender, value)
        )
          .wait()
          .then(async () => {
            updateAllowance();
          })
          .catch(() => console.log("ERROR APPROVING TOKEN"));
      }
    },
    [spender, account, contract, updateAllowance],
  );

  return {
    approve,
    allowance,
    updateAllowance,
  };
};

export { useApproval };
