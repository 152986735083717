import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Rates, RatesHistory } from "shared/types/Rates";
import { ChainTokensEntry, ChainTokens } from "shared/types/ChainTokens";
import { RATES_BASE_TOKENS } from "./consts";
import axios from "axios";
import { GetRatesGenericProps, GetTokensGenericProps } from "./types";
import moment from "moment";
import { debounce } from "lodash";

const useRates = () => {
  const [chainTokens, setChainTokens] = useState<ChainTokens | null>(null);
  const [chainTokensHistory, setChainTokensHistory] =
    useState<ChainTokens | null>(null);
  const [rates, setRates] = useState<Rates | null>(null);
  const [ratesUsd, setRatesUsd] = useState<Rates | null>(null);
  const [ratesUsdHistory, setRatesUsdHistory] = useState<RatesHistory | null>(
    null,
  );
  const [loadingTokens, setLoadingTokens] = useState<boolean>(false);
  const [loadingTokensHistory, setLoadingTokensHistory] =
    useState<boolean>(false);
  const [loadingRates, setLoadingRates] = useState<boolean>(false);
  const [loadingRatesUsd, setLoadingRatesUsd] = useState<boolean>(false);
  const [loadingRatesUsdHistory, setLoadingRatesUsdHistory] =
    useState<boolean>(false);
  const mountedRef_ = useRef(true);

  useEffect(() => {
    return () => {
      mountedRef_.current = false;
    };
  }, []);

  const getTokensGeneric = async ({
    chainIds,
    setLoadingGeneric,
    setTokensGeneric,
    requestUrl,
    walletAddress,
    timestamp,
    mountedRef,
  }: GetTokensGenericProps) => {
    if (!mountedRef.current) return null;
    setLoadingGeneric(true);

    const cTokens: ChainTokens = {
      totalUsd: "0",
      updated: true,
      walletAddress,
      timestamp,
      chainIds,
    };

    await axios
      .get(requestUrl)
      .then((res) => {
        (Object.entries(res.data.balances) as any).forEach(
          ([chainId, tokens]: [string, ChainTokensEntry]) => {
            const chainIdNumber = parseInt(chainId);
            if (chainIds.includes(chainIdNumber)) {
              cTokens[chainIdNumber] = tokens;
            }
          },
        );
        cTokens.updated = res.data.updated;
        cTokens.totalUsd = res.data.totalUsd;
      })
      .catch((e) => {
        console.error(e);
      });

    setTokensGeneric(Object.keys(cTokens).length === 0 ? null : cTokens);

    setLoadingGeneric(false);
  };

  const getRatesGeneric = async ({
    setLoadingGeneric,
    setRatesGeneric,
    chainTokens: chainTokens_,
    chainIds,
    requestUrl,
    requestData,
    mountedRef,
  }: GetRatesGenericProps) => {
    if (!mountedRef.current) return null;
    if (chainTokens) {
      setLoadingGeneric(true);

      const rates_ = {};
      for (let i = 0; i < chainIds.length; i++) {
        const tokens = Object.values(chainTokens_?.[chainIds[i]] || {});
        if (tokens?.length && chainTokens_) {
          await axios
            .post(requestUrl, requestData(i, chainTokens_))
            .then((res) => {
              rates_[chainIds[i]] =
                res?.data?.rates || res?.data?.history || null;
            })
            .catch((e) => {
              console.error(e);
            });
        }
      }
      setRatesGeneric(Object.keys(rates_).length === 0 ? null : rates_);

      setLoadingGeneric(false);
    }
  };

  const getTokens = useCallback(
    async (walletAddress: string, chainIds: number[]) => {
      await getTokensGeneric({
        setLoadingGeneric: setLoadingTokens,
        setTokensGeneric: setChainTokens,
        requestUrl: `/address/${walletAddress}:getInfo`,
        chainIds,
        walletAddress,
        timestamp: "",
        mountedRef: mountedRef_,
      });
    },
    [setLoadingTokens, setChainTokens],
  );

  const getTokensHistory = useCallback(
    async (walletAddress: string, chainIds: number[], timestamp) => {
      await getTokensGeneric({
        setLoadingGeneric: setLoadingTokensHistory,
        setTokensGeneric: setChainTokensHistory,
        requestUrl: `/address/${walletAddress}:history?timestamp=${timestamp}`,
        chainIds,
        walletAddress,
        timestamp,
        mountedRef: mountedRef_,
      });
    },
    [setLoadingTokensHistory, setChainTokensHistory],
  );

  const getRates = async (chainIds: number[], chainTokens_: ChainTokens) => {
    await getRatesGeneric({
      setLoadingGeneric: setLoadingRates,
      setRatesGeneric: setRates,
      chainTokens: chainTokens_,
      chainIds,
      requestUrl: "/token/rates",
      requestData: (i, chainTokens__) => ({
        chainId: chainIds[i],
        baseTokens: RATES_BASE_TOKENS[chainIds[i]],
        quoteTokens: Object.keys(chainTokens__[chainIds[i]]),
      }),
      mountedRef: mountedRef_,
    });
  };

  const getRatesUsd = async (chainIds: number[], chainTokens_: ChainTokens) => {
    await getRatesGeneric({
      setLoadingGeneric: setLoadingRatesUsd,
      setRatesGeneric: setRatesUsd,
      chainTokens: chainTokens_,
      chainIds,
      requestUrl: "/token/rates/usd",
      requestData: (i, chainTokens__) => ({
        chainId: chainIds[i],
        tokens: Object.keys(chainTokens__[chainIds[i]]),
      }),
      mountedRef: mountedRef_,
    });
  };

  const getRatesUsdHistory = async (
    chainIds: number[],
    chainTokens_: ChainTokens,
    dateFrom: string = moment()
      .subtract(1, "day")
      .format("YYYY-MM-DD HH:mm:ss"),
    dateTo: string = moment().format("YYYY-MM-DD HH:mm:ss"),
  ) => {
    await getRatesGeneric({
      setLoadingGeneric: setLoadingRatesUsdHistory,
      setRatesGeneric: setRatesUsdHistory,
      chainTokens: chainTokens_,
      chainIds,
      requestUrl: "/token/rates/history/usd",
      requestData: (i, chainTokens__) => ({
        chainId: chainIds[i],
        tokens: Object.keys(chainTokens__[chainIds[i]]),
        interval: "day",
        dateFrom,
        dateTo,
      }),
      mountedRef: mountedRef_,
    });
  };

  const loading = useMemo<boolean>(() => {
    return (
      loadingTokens ||
      loadingTokensHistory ||
      loadingRates ||
      loadingRatesUsd ||
      loadingRatesUsdHistory
    );
  }, [
    loadingTokens,
    loadingTokensHistory,
    loadingRates,
    loadingRatesUsd,
    loadingRatesUsdHistory,
  ]);

  const debouncedGetTokens = useCallback(
    (chainTokens_) => {
      debounce(() => {
        getTokens(
          chainTokens_.walletAddress as string,
          chainTokens_.chainIds as number[],
        );
      }, 1000);
    },
    [getTokens],
  );

  useEffect(() => {
    if (chainTokens && !chainTokens.updated) {
      setChainTokens({ ...chainTokens, updated: true });
      setTimeout(() => debouncedGetTokens(chainTokens), 10 * 1000);
    }
  }, [chainTokens, debouncedGetTokens]);

  const debouncedGetTokensHistory = useCallback(
    (chainTokens_) => {
      debounce(() => {
        getTokensHistory(
          chainTokens_.walletAddress as string,
          chainTokens_.chainIds as number[],
          chainTokens_.timestamp,
        );
      }, 1000);
    },
    [getTokensHistory],
  );

  useEffect(() => {
    if (chainTokensHistory && !chainTokensHistory.updated) {
      setChainTokensHistory({ ...chainTokensHistory, updated: true });
      setTimeout(
        () => debouncedGetTokensHistory(chainTokensHistory),
        10 * 1000,
      );
    }
  }, [chainTokensHistory, debouncedGetTokensHistory]);

  return {
    chainTokens,
    chainTokensHistory,
    getTokens,
    getTokensHistory,
    getRates,
    getRatesUsd,
    getRatesUsdHistory,
    rates,
    ratesUsd,
    ratesUsdHistory,
    loading,
    loadingRates,
    loadingRatesUsd,
    loadingRatesUsdHistory,
  };
};

export default useRates;
