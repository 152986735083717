import React from "react";

const EditIcon = ({ ...props }) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 2.5H2.7C2.58954 2.5 2.5 2.58954 2.5 2.7V17.3C2.5 17.4105 2.58954 17.5 2.7 17.5H17.3C17.4105 17.5 17.5 17.4105 17.5 17.3V10"
      stroke="#A7DD12"
      strokeLinecap="round"
    />
    <path
      d="M13.4523 10.001L10.201 10.001C10.0905 10.001 10.001 9.91143 10.001 9.80098L10.001 6.54696C10.001 6.4947 10.0214 6.44452 10.058 6.40715L15.3644 0.978692C15.4422 0.899069 15.5701 0.898342 15.6488 0.977076L18.9015 4.22975C18.979 4.30723 18.9797 4.43262 18.9031 4.51098L13.5954 9.94078C13.5577 9.97928 13.5062 10.001 13.4523 10.001Z"
      stroke="#A7DD12"
    />
  </svg>
);

export default EditIcon;
