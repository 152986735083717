import { useWeb3React } from "@web3-react/core";
import { ROOBEE_MULTICALL, RoobeeMulticallAbi } from "../constants";
import { useContract } from "./useContract";
import { RoobeeMulticall } from "../constants/abis/types";
import { useCallback } from "react";

export const useMakeMulticall = () => {
  const { chainId } = useWeb3React();

  const multicall = useContract<RoobeeMulticall>(
    RoobeeMulticallAbi,
    ROOBEE_MULTICALL[chainId ?? 0],
  );

  const makeMulticall = useCallback(
    async (
      addresses: string[],
      datas: string[],
      values: string[],
      value: string,
    ) => {
      const gas = await multicall.estimateGas
        .makeCalls(addresses, datas, values, { value: value })
        .catch(() => {
          return "720000";
        });

      return multicall.makeCalls(addresses, datas, values, {
        value: value,
        gasLimit: gas,
      });
    },
    [multicall],
  );

  return { makeMulticall };
};
