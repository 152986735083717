import React from "react";

export const CartIcon = (props) => (
  <svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_7489_77737)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.37393 0.671875C2.88228 0.671864 3.37652 0.836948 3.78038 1.14166C4.18425 1.44637 4.07484 1.93481 4.2082 2.41898L4.46107 3.71946H14.5627C14.7674 3.71946 14.9638 3.79974 15.1085 3.94262C15.2533 4.0855 15.3346 4.27929 15.3346 4.48136V7.78038C15.3347 8.31567 15.1444 8.83402 14.797 9.24504C14.4495 9.65606 13.967 9.93368 13.4334 10.0295L6.20965 11.3262C6.0194 11.3604 5.82309 11.3228 5.65957 11.221C5.49604 11.1191 5.3772 10.9604 5.32657 10.7762L3.11885 2.75795C3.07444 2.59651 2.97744 2.45399 2.84281 2.35237C2.70819 2.25075 2.54342 2.19568 2.37393 2.19567H1.4399C1.23517 2.19567 1.03883 2.1154 0.894062 1.97252C0.749297 1.82963 0.667969 1.63584 0.667969 1.43377C0.667969 1.2317 0.749297 1.03791 0.894062 0.89503C1.03883 0.752146 1.23517 0.671875 1.4399 0.671875H2.37393ZM14.3231 4.66527H4.71395L6.39122 10.4058L13.3116 9.15784C13.4894 9.12592 13.9545 8.79551 14.0703 8.65866C14.1861 8.52181 14.323 7.95868 14.3231 7.78038V4.66527Z"
        fill="black"
      />
      <path
        d="M8.33529 14.0044C8.33529 14.5561 7.88775 15.0037 7.33529 15.0037C6.78282 15.0037 6.33529 14.5561 6.33529 14.0044C6.33529 13.4528 6.78282 13.0052 7.33529 13.0052C7.88775 13.0052 8.33529 13.4528 8.33529 14.0044Z"
        stroke="black"
        strokeWidth="0.666667"
      />
      <path
        d="M14.3368 14.0052C14.3368 14.5573 13.889 15.0052 13.3361 15.0052C12.7832 15.0052 12.3353 14.5573 12.3353 14.0052C12.3353 13.4531 12.7832 13.0052 13.3361 13.0052C13.889 13.0052 14.3368 13.4531 14.3368 14.0052Z"
        stroke="black"
        strokeWidth="0.666667"
      />
    </g>
    <defs>
      <clipPath id="clip0_7489_77737">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
