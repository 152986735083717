import styled, { css } from "styled-components";
import { default as NotStyledCoverIcon } from "./_resources/CoverIcon";
import { default as NotStyledUncoverIcon } from "./_resources/UncoverIcon";
import { default as NotStyledUserIcon } from "./_resources/UserIcon";
import { default as NotStyledHiddenIcon } from "./_resources/HiddenIcon";

type ApproximateBalanceTypes = {
  $active?: boolean;
};

const HideButton = styled.button`
  cursor: default;
  width: 16px;
  height: 16px;
`;

const Wrapper = styled.div<ApproximateBalanceTypes>`
  display: flex;
  align-items: center;
  ${(props) =>
    props.$active &&
    css`
      ${HideButton} {
        cursor: pointer;
      }
    `};
`;

const UserIcon = styled(NotStyledUserIcon)`
  height: 20px;
  width: 20px;
  margin-right: 10px;
  cursor: pointer;
`;

const Text = styled.div<ApproximateBalanceTypes>`
  font-weight: 500;
  font-size: 14px;
  line-height: 145%;
  transition: opacity 0.3s ease;
  cursor: default;
  ${(props) =>
    props.$active &&
    css`
      opacity: 0;
    `};
`;

const CoverIcon = styled(NotStyledCoverIcon)`
  width: 100%;
  height: 100%;
`;

const UncoverIcon = styled(NotStyledUncoverIcon)`
  width: 100%;
  height: 100%;
`;

const Balance = styled.div`
  font-weight: 300;
  font-size: 14px;
  line-height: 155%;
  margin-right: 5px;
`;

const HiddenIcon = styled(NotStyledHiddenIcon)<ApproximateBalanceTypes>`
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 0;
  transition: opacity 0.3s ease;
  transform: translateY(-50%);
  ${(props) =>
    props.$active &&
    css`
      opacity: 1;
    `}
`;

const Box = styled.div`
  position: relative;
  max-width: max-content;
  width: 100%;
  min-height: 25px;
`;

export default {
  Wrapper,
  UserIcon,
  Text,
  HideButton,
  HiddenIcon,
  UncoverIcon,
  CoverIcon,
  Balance,
  Box,
};
