import { deleteCartTokens, getCartTokens } from "../../store/cart/thunks";
import { useWeb3React } from "@web3-react/core";
import { cookies, COOKIES_KEYS } from "../constants";
import { useAppDispatch } from "../../store";

const useDeleteInvestToken = () => {
  const { account } = useWeb3React();
  const dispatch = useAppDispatch();
  const handleRemoveItem = async (itemId) => {
    const authToken = cookies.get(COOKIES_KEYS.authToken);

    await dispatch(
      deleteCartTokens({
        address: account!,
        authToken: authToken!,
        ids: [itemId],
      }),
    );
    await dispatch(getCartTokens(account!));
  };
  return { handleRemoveItem };
};

export { useDeleteInvestToken };
