import React from "react";

export const ScrollIcon = ({ ...props }) => (
  <svg
    {...props}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="20" cy="20" r="20" fill="white" />
    <circle cx="20" cy="20" r="19.5" stroke="#101010" />
    <path
      d="M29.1439 23.8123V11.2331C29.1271 10.1805 28.3024 9.33333 27.2673 9.33333H14.3576C11.5721 9.37612 9.3335 11.6952 9.3335 14.5362C9.3335 15.4946 9.58597 16.3161 9.97309 17.0349C10.3013 17.634 10.8146 18.1987 11.3196 18.6181C11.4627 18.7378 11.3953 18.6865 11.8329 18.9604C12.4389 19.3368 13.1289 19.5251 13.1289 19.5251L13.1205 27.047C13.1374 27.4064 13.171 27.7487 13.2636 28.0654C13.5497 29.1265 14.2735 29.9394 15.2581 30.333C15.6705 30.4957 16.1333 30.6069 16.6299 30.6155L26.939 30.6497C28.9924 30.6497 30.6587 28.9554 30.6587 26.8587C30.6671 25.618 30.0613 24.5055 29.1439 23.8123Z"
      fill="#FFEEDA"
    />
    <path
      d="M29.3964 26.9529C29.3544 28.2964 28.2687 29.3746 26.9391 29.3746L19.8447 29.349C20.4085 28.6815 20.7536 27.8172 20.7536 26.8759C20.7536 25.3955 19.8868 24.3772 19.8868 24.3772H26.9475C28.3024 24.3772 29.4048 25.4981 29.4048 26.8759L29.3964 26.9529Z"
      fill="#EBC28E"
    />
    <path
      d="M11.9928 17.5572C11.1765 16.7699 10.6043 15.7516 10.6043 14.545V14.4167C10.6716 12.3543 12.3379 10.6942 14.3661 10.6343H27.2757C27.6123 10.6514 27.8816 10.891 27.8816 11.2419V22.3493C28.1761 22.4007 28.3191 22.4435 28.6053 22.5462C28.8326 22.6318 29.1439 22.8114 29.1439 22.8114V11.2419C29.1271 10.1893 28.3024 9.34216 27.2673 9.34216H14.3576C11.5721 9.38495 9.3335 11.704 9.3335 14.545C9.3335 16.1966 10.0741 17.6086 11.2859 18.6012C11.3701 18.6697 11.4458 18.7553 11.6646 18.7553C12.0433 18.7553 12.3126 18.4472 12.2958 18.1134C12.2874 17.8311 12.1696 17.7284 11.9928 17.5572Z"
      fill="#101010"
    />
    <path
      d="M26.9389 23.0764H16.8149C16.1333 23.085 15.5862 23.6413 15.5862 24.3344V25.8148C15.6031 26.4994 16.1753 27.0813 16.857 27.0813H17.606V25.8148H16.857V24.3686C16.857 24.3686 17.0421 24.3686 17.2694 24.3686C18.5485 24.3686 19.4911 25.5752 19.4911 26.8674C19.4911 28.0141 18.4644 29.4773 16.7476 29.3576C15.2244 29.2549 14.3996 27.8771 14.3996 26.8674V14.3223C14.3996 13.7575 13.9452 13.2954 13.3898 13.2954H12.3799V14.579H13.1289V26.8759C13.0868 29.3747 14.8793 30.6326 16.7476 30.6326L26.9474 30.6668C29.0008 30.6668 30.6671 28.9725 30.6671 26.8759C30.6671 24.7794 28.9924 23.0764 26.9389 23.0764ZM29.3963 26.9529C29.3542 28.2964 28.2686 29.3747 26.9389 29.3747L19.8446 29.349C20.4084 28.6815 20.7535 27.8172 20.7535 26.8759C20.7535 25.3955 19.8866 24.3772 19.8866 24.3772H26.9474C28.3022 24.3772 29.4048 25.4982 29.4048 26.8759L29.3963 26.9529Z"
      fill="#101010"
    />
    <path
      d="M24.2116 14.8102H16.5786V13.5266H24.2116C24.5566 13.5266 24.8428 13.809 24.8428 14.1684C24.8428 14.5193 24.5651 14.8102 24.2116 14.8102Z"
      fill="#101010"
    />
    <path
      d="M24.2116 20.8515H16.5786V19.5678H24.2116C24.5566 19.5678 24.8428 19.8503 24.8428 20.2097C24.8428 20.5605 24.5651 20.8515 24.2116 20.8515Z"
      fill="#101010"
    />
    <path
      d="M25.5581 17.8308H16.5786V16.5472H25.5496C25.8948 16.5472 26.1809 16.8296 26.1809 17.189C26.1893 17.5399 25.9032 17.8308 25.5581 17.8308Z"
      fill="#101010"
    />
  </svg>
);

export default ScrollIcon;
