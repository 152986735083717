import React from "react";

const Calendar = ({ ...props }) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 5.11719H6C3.79086 5.11719 2 6.69736 2 8.6466V17.4701C2 19.4194 3.79086 20.9995 6 20.9995H18C20.2091 20.9995 22 19.4194 22 17.4701V8.6466C22 6.69736 20.2091 5.11719 18 5.11719Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 10.4118H22M8 3V6.70588V3ZM16 3V6.70588V3Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Calendar;
