import styled from "styled-components";
import { default as NotStyledSuccessIcon } from "../../_resources/SuccessIcon";

const Wrapper = styled.div`
  padding: 20px;
`;

const SuccessMessage = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const SuccessIcon = styled(NotStyledSuccessIcon)`
  width: 24px;
  height: 24px;
  margin-right: 12px;
`;

const Message = styled.span`
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  @media (max-width: 600px) {
    max-width: 200px;
  }
`;

const Recipient = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.span`
  font-size: 14px;
  line-height: 22px;
  color: #000000;
`;

const Address = styled.div`
  width: 100%;
  padding: 12px 16px;
  font-size: 14px;
  line-height: 22px;
  margin-top: 4px;
  text-align: center;
  color: #000000;
  border: 1px solid #c2c2c2;
  border-radius: 10px;
`;

const Button = styled.button`
  background: linear-gradient(90deg, #b6e82f 0%, #50e0ed 100%);
  border-radius: 10px;
  padding: 10px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 145%;
  text-align: center;
  color: #000000;
  min-width: 105px;
  height: 32px;
  margin: 24px auto 0;
`;

export default {
  Wrapper,
  SuccessMessage,
  SuccessIcon,
  Message,
  Recipient,
  Label,
  Address,
  Button,
};
