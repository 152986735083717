import React from "react";

const BuyIcon = (props) => (
  <svg
    {...props}
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.19573 5.5852e-10C2.70103 -1.08021e-05 3.1923 0.156683 3.59374 0.44591C3.99519 0.735137 3.88643 1.19875 4.01899 1.65832L4.27035 2.89271H14.3114C14.5149 2.89271 14.71 2.9689 14.8539 3.10452C14.9978 3.24014 15.0787 3.42408 15.0787 3.61588V6.74724C15.0788 7.25533 14.8896 7.74733 14.5443 8.13746C14.1989 8.52759 13.7192 8.7911 13.1888 8.88205L6.00844 10.1129C5.81933 10.1453 5.6242 10.1096 5.46165 10.013C5.29911 9.91627 5.18098 9.7656 5.13065 9.59077L2.93617 1.98006C2.89204 1.82682 2.79562 1.69154 2.6618 1.59509C2.52798 1.49863 2.3642 1.44636 2.19573 1.44635H1.2673C1.0638 1.44635 0.868633 1.37016 0.724737 1.23454C0.58084 1.09892 0.5 0.914975 0.5 0.723176C0.5 0.531378 0.58084 0.347435 0.724737 0.211813C0.868633 0.0761915 1.0638 5.5852e-10 1.2673 5.5852e-10H2.19573ZM14.0733 3.79044H4.5217L6.18892 9.2392L13.0678 8.05469C13.2445 8.0244 13.7068 7.71078 13.8219 7.58088C13.937 7.45099 14.0731 6.91648 14.0733 6.74724V3.79044Z"
      fill="black"
    />
    <path
      d="M9.18168 14.5471C9.18168 15.0475 8.74967 15.4926 8.16701 15.4926C7.58435 15.4926 7.15234 15.0475 7.15234 14.5471C7.15234 14.0466 7.58435 13.6016 8.16701 13.6016C8.74967 13.6016 9.18168 14.0466 9.18168 14.5471Z"
      stroke="black"
    />
    <path
      d="M15.9998 14.5479C15.9998 15.0488 15.5674 15.4943 14.9843 15.4943C14.4012 15.4943 13.9688 15.0488 13.9688 14.5479C13.9688 14.047 14.4012 13.6016 14.9843 13.6016C15.5674 13.6016 15.9998 14.047 15.9998 14.5479Z"
      stroke="black"
    />
  </svg>
);

export default BuyIcon;
