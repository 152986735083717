import React from "react";

const BaseIcon = ({ ...props }) => (
  <svg
    {...props}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="20" cy="20" r="20" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34 20C34 27.7382 27.7127 34 19.9745 34C12.6182 34 6.61091 28.3491 6 21.1709H24.5309V18.8291H6C6.61091 11.6509 12.6182 6 19.9745 6C27.7382 6 34 12.2618 34 20Z"
      fill="black"
    />
    <circle cx="20" cy="20" r="19.5" stroke="#101010" />
  </svg>
);

export default BaseIcon;
