import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { CartState } from "./types";
import { getCartTokens } from "./thunks";

const extraReducers = (builder: ActionReducerMapBuilder<CartState>) => {
  builder.addCase(getCartTokens.fulfilled, (state, { payload }) => {
    state.items = payload.data || [];
  });
};

export { extraReducers };
