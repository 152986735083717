import React, { FC, useEffect, useMemo, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { useLocation } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import SwiperCore, { Navigation, Pagination } from "swiper/core";

import Styled from "./styled";
import { WidgetBarTypes } from "./types";
import DisconnectModal from "../Modals/DisconnectModal";
import UserModal from "../Modals/UserModal";
import { checkUnlocked } from "shared/utils/isUnlocked";
import { useResize } from "shared/hooks";
import { ROUTE_PATHS } from "shared/constants";

import NetworkSelect from "components/NetworkSelect";
import Wallet from "./components/Wallet";
import Cart from "./components/Cart";
// import DailyPoints from "./components/DailyPoints";

SwiperCore.use([Navigation, Pagination]);

const WidgetBar: FC<WidgetBarTypes> = ({ user, setUser }) => {
  const { account, chainId } = useWeb3React();
  const isUnlocked = checkUnlocked();
  const { windowWidth } = useResize();
  const location = useLocation();

  const [showDisconnectModal, setShowDisconnectModal] =
    useState<boolean>(false);

  const tabletView = useMemo(() => {
    return windowWidth <= 1350;
  }, [windowWidth]);

  const mobileView = useMemo(() => {
    return windowWidth <= 600;
  }, [windowWidth]);

  useEffect(() => {
    if (!account) {
      setShowDisconnectModal(false);
    }
  }, [account]);

  const isIndexPage = location.pathname === ROUTE_PATHS.DASHBOARD;
  const isInvestPage = location.pathname === ROUTE_PATHS.INVEST;
  const investTokenPathPattern = /^\/invest\/.*/;
  const isInvestTokenPage = investTokenPathPattern.test(location.pathname);

  return (
    <Styled.Wrapper
      off={mobileView && (isIndexPage || mobileView)}
      disabled={!isIndexPage && !isInvestPage}
    >
      {!tabletView ? (
        <Styled.Content>
          {account ? (
            <NetworkSelect
              disabled={!account || !chainId}
              noChange={!isUnlocked}
            />
          ) : (
            <Styled.UnlockWalletButton />
          )}
          <Wallet userModalHandler={setUser} />
          {/* <DailyPoints /> */}
          <Cart disabled={!account || (!isInvestPage && !isInvestTokenPage)} />
        </Styled.Content>
      ) : (
        <>
          {!mobileView && (
            <Swiper slidesPerView={"auto"} spaceBetween={mobileView ? 20 : 40}>
              <SwiperSlide>
                <Wallet userModalHandler={setUser} />
              </SwiperSlide>
              <SwiperSlide>
                <Cart disabled={!account || !isInvestPage} />
              </SwiperSlide>
            </Swiper>
          )}
        </>
      )}
      {showDisconnectModal && (
        <DisconnectModal onClose={() => setShowDisconnectModal(false)} />
      )}
      {user && (
        <UserModal
          setShowDisconnectModal={setShowDisconnectModal}
          onClose={() => setUser(false)}
        />
      )}
    </Styled.Wrapper>
  );
};

export default WidgetBar;
