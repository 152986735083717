import React, { CSSProperties } from "react";
import { TextStyled } from "./styled";
import { Color, TypographySize } from "./types";

interface Props {
  style?: CSSProperties;
  children: React.ReactNode | string;
  size?: TypographySize;
  color?: Color;
  thin?: boolean;
  bold?: boolean;
  veryBold?: boolean;
  underline?: boolean;
  onClick?: () => void;
  regular?: boolean;
}

const Text = ({
  thin,
  bold,
  veryBold,
  regular,
  color,
  children,
  size,
  underline,
  onClick,
  style,
}: Props) => {
  return (
    <TextStyled
      size={size}
      color={color}
      thin={thin}
      bold={bold}
      veryBold={veryBold}
      underline={underline}
      onClick={onClick}
      style={style}
      regular={regular}
    >
      {children}
    </TextStyled>
  );
};

export default Text;
