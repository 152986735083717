import Cookies from "universal-cookie";

const cookies = new Cookies();

const COOKIES_KEYS = {
  authToken: "token",
  authAccount: "auth-account",
};

export { cookies, COOKIES_KEYS };
