import styled, { css } from "styled-components";
import type { Color, TypographySize } from "./types";

const TextStyled = styled.span<{
  size?: TypographySize;
  color?: Color;
  thin?: boolean;
  bold?: boolean;
  veryBold?: boolean;
  regular?: boolean;
  underline?: boolean;
}>`
  color: ${(props) => props.theme.Colors.Black};

  ${(props) => {
    switch (props.size) {
      case "ss":
        return css`
          font-size: 12px;
          line-height: 19px;
        `;
      case "s":
        return css`
          font-size: 14px;
          line-height: 22px;
        `;
      case "m":
        return css`
          font-size: 16px;
          line-height: 20px;
        `;
      case "l":
        return css`
          font-size: 18px;
          line-height: 18px;
        `;
      case "ll":
        return css`
          font-size: 20px;
          line-height: 20px;
        `;
      default:
        return css`
          font-size: 16px;
          line-height: 24px;
        `;
    }
  }}

  ${(props) =>
    props.color &&
    css`
      color: ${props.theme.Colors[props.color]};
    `};

  ${(props) =>
    props.thin &&
    css`
      font-weight: 300;
    `}

  ${(props) =>
    props.regular &&
    css`
      font-weight: 400;
    `}

    ${(props) =>
    props.bold &&
    css`
      font-weight: 500;
    `}

    ${(props) =>
    props.veryBold &&
    css`
      font-weight: 700;
    `}

    ${(props) =>
    props.underline &&
    css`
      text-decoration: underline;
    `}
`;

export { TextStyled };
