import React from "react";
import { useTranslation } from "react-i18next";
import TelegramIcon from "./_resources/TelegramIcon";
import TwitterIcon from "./_resources/TwitterIcon";
import MediumIcon from "./_resources/MediumIcon";
import DiscrodIcon from "./_resources/DiscordIcon";
import moment from "moment";

import Styled from "./styled";
import { FC, useMemo } from "react";
import { useResize } from "../../../shared/hooks";

const Footer: FC = () => {
  const { t } = useTranslation();
  const { windowWidth } = useResize();

  const mobileView = useMemo(() => windowWidth <= 650, [windowWidth]);

  return (
    <Styled.Wrapper>
      <Styled.Top>
        <Styled.TopContainer>
          {mobileView ? (
            <>
              <Styled.Column>
                <Styled.ColumnName>Community</Styled.ColumnName>
                <Styled.Box>
                  <Styled.Social
                    href="https://t.me/roobee_chat"
                    target="_blank"
                  >
                    <TelegramIcon />
                    <span>Telegram</span>
                  </Styled.Social>
                  <Styled.Social
                    href="https://twitter.com/roobee_invest"
                    target="_blank"
                  >
                    <TwitterIcon />
                    <span>Twitter</span>
                  </Styled.Social>
                  <Styled.Social
                    href="https://medium.com/roobee-invest"
                    target="_blank"
                  >
                    <MediumIcon />
                    <span>Medium</span>
                  </Styled.Social>
                  <Styled.Social
                    href="https://medium.com/roobee-invest"
                    target="_blank"
                  >
                    <DiscrodIcon />
                    <span>Discord</span>
                  </Styled.Social>
                </Styled.Box>
              </Styled.Column>
              <Styled.Column>
                <Styled.Box>
                  <Styled.ColumnName>About</Styled.ColumnName>
                  <Styled.Social
                    href="https://coinmarketcap.com/community/profile/Roobee"
                    target="_blank"
                  >
                    <span>Blog</span>
                  </Styled.Social>
                  <Styled.Social
                    href="https://roobee.gitbook.io/roobee-eng/the-roobee-token/key-information"
                    target="_blank"
                  >
                    <span>FAQ</span>
                  </Styled.Social>
                </Styled.Box>
                <Styled.Box>
                  <Styled.ColumnName>Documents</Styled.ColumnName>
                  <Styled.Social
                    href="/docs/privacy_policy.pdf"
                    target="_blank"
                  >
                    <span>{t("FOOTER_Privacy")}</span>
                  </Styled.Social>
                  <Styled.Social href="/docs/terms_of_use.pdf" target="_blank">
                    <span>{t("FOOTER_Service")}</span>
                  </Styled.Social>
                </Styled.Box>
                <Styled.Box>
                  <Styled.ColumnName>Contacts</Styled.ColumnName>
                  <Styled.Social
                    href="mailto:support@roobee.io"
                    target="_blank"
                  >
                    <span>support@roobee.io</span>
                  </Styled.Social>
                </Styled.Box>
              </Styled.Column>
            </>
          ) : (
            <>
              <Styled.Column>
                <Styled.ColumnName>Community</Styled.ColumnName>
                <Styled.Box>
                  <Styled.Social
                    href="https://t.me/roobee_chat"
                    target="_blank"
                  >
                    <TelegramIcon />
                    <span>Telegram</span>
                  </Styled.Social>
                  <Styled.Social
                    href="https://twitter.com/roobee_invest"
                    target="_blank"
                  >
                    <TwitterIcon />
                    <span>Twitter</span>
                  </Styled.Social>
                  <Styled.Social
                    href="https://medium.com/roobee-invest"
                    target="_blank"
                  >
                    <MediumIcon />
                    <span>Medium</span>
                  </Styled.Social>
                  <Styled.Social
                    href="https://discord.gg/HTU3BpFNc3"
                    target="_blank"
                  >
                    <DiscrodIcon />
                    <span>Discord</span>
                  </Styled.Social>
                </Styled.Box>
              </Styled.Column>
              <Styled.Column>
                <Styled.ColumnName>About</Styled.ColumnName>
                <Styled.Box>
                  <Styled.Social
                    href="https://coinmarketcap.com/community/profile/Roobee"
                    target="_blank"
                  >
                    <span>Blog</span>
                  </Styled.Social>
                  <Styled.Social
                    href="https://roobee.gitbook.io/roobee-eng/the-roobee-token/key-information"
                    target="_blank"
                  >
                    <span>FAQ</span>
                  </Styled.Social>
                </Styled.Box>
              </Styled.Column>
              <Styled.Column>
                <Styled.ColumnName>Documents</Styled.ColumnName>
                <Styled.Box>
                  <Styled.Social
                    href="/docs/privacy_policy.pdf"
                    target="_blank"
                  >
                    <span>{t("FOOTER_Privacy")}</span>
                  </Styled.Social>
                  <Styled.Social href="/docs/terms_of_use.pdf" target="_blank">
                    <span>{t("FOOTER_Service")}</span>
                  </Styled.Social>
                </Styled.Box>
              </Styled.Column>
              <Styled.Column>
                <Styled.ColumnName>Contacts</Styled.ColumnName>
                <Styled.Box>
                  <Styled.Social
                    href="mailto:support@roobee.io"
                    target="_blank"
                  >
                    <span>support@roobee.io</span>
                  </Styled.Social>
                </Styled.Box>
              </Styled.Column>
            </>
          )}
        </Styled.TopContainer>
        <Styled.Copyright>
          {t("FOOTER-copyright", { year: moment().format("YYYY") })}
        </Styled.Copyright>
      </Styled.Top>
    </Styled.Wrapper>
  );
};

export default Footer;
