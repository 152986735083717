import styled, { css } from "styled-components";

const BoxStyled = styled.div<{
  relative?: boolean;
  absolute?: boolean;
  top?: string;
  left?: string;
  right?: string;
  bottom?: string;
  margin?: string;
  mt?: string;
  mb?: string;
  ml?: string;
  mr?: string;
  padding?: string;
  pt?: string;
  pb?: string;
  pl?: string;
  pr?: string;
  row?: boolean;
  column?: boolean;
  flex?: string;
  spread?: boolean;
  startV?: boolean;
  startH?: boolean;
  centerV?: boolean;
  centerH?: boolean;
  endH?: boolean;
  endV?: boolean;
  grid?: boolean;
  gridCols?: number;
  gap?: string;
  border?: string;
  borderTop?: string;
  borderBot?: string;
  borderLeft?: string;
  borderRight?: string;
  borderR?: string;
  background?: string;
  w?: string;
  h?: string;
  minW?: string;
  minH?: string;
  maxW?: string;
  maxH?: string;
  zIndex?: number;
  clickable?: boolean;
  opacity?: string;
  ref?: any;
}>`
  ${({ relative }) =>
    relative &&
    css`
      position: relative;
    `}
  ${({ absolute }) =>
    absolute &&
    css`
      position: absolute;
    `}
    ${({ top }) =>
    top &&
    css`
      top: ${top};
    `}
    ${({ left }) =>
    left &&
    css`
      left: ${left};
    `}
    ${({ right }) =>
    right &&
    css`
      right: ${right};
    `}
    ${({ bottom }) =>
    bottom &&
    css`
      bottom: ${bottom};
    `}
    ${({ margin }) =>
    margin &&
    css`
      margin: ${margin};
    `}
    ${({ mt }) =>
    mt &&
    css`
      margin-top: ${mt}px;
    `}
    ${({ mb }) =>
    mb &&
    css`
      margin-bottom: ${mb}px;
    `}
    ${({ ml }) =>
    ml &&
    css`
      margin-left: ${ml}px;
    `}
    ${({ mr }) =>
    mr &&
    css`
      margin-right: ${mr}px;
    `}
    ${({ padding }) =>
    padding &&
    css`
      padding: ${padding};
    `}
    ${({ pt }) =>
    pt &&
    css`
      padding-top: ${pt}px;
    `}
    ${({ pb }) =>
    pb &&
    css`
      padding-bottom: ${pb}px;
    `}
    ${({ pl }) =>
    pl &&
    css`
      padding-left: ${pl}px;
    `}
    ${({ pr }) =>
    pr &&
    css`
      padding-right: ${pr}px;
    `}
    ${({ row }) =>
    row &&
    css`
      display: flex;
    `}
    ${({ column }) =>
    column &&
    css`
      display: flex;
      flex-direction: column;
    `}
    ${({ flex }) =>
    flex &&
    css`
      flex: ${flex};
    `}
    ${({ spread }) =>
    spread &&
    css`
      justify-content: space-between;
    `}
    ${({ startV }) =>
    startV &&
    css`
      align-items: flex-start;
    `}
    ${({ startH }) =>
    startH &&
    css`
      justify-content: start;
    `}
    ${({ centerV }) =>
    centerV &&
    css`
      align-items: center;
    `}
    ${({ centerH }) =>
    centerH &&
    css`
      justify-content: center;
    `}
    ${({ endH }) =>
    endH &&
    css`
      justify-content: end;
    `}
    ${({ endV }) =>
    endV &&
    css`
      align-items: flex-end;
    `}
    ${({ grid }) =>
    grid &&
    css`
      display: grid;
    `}
    ${({ gridCols }) =>
    gridCols &&
    css`
      grid-template-columns: repeat(${gridCols}, minmax(0, 1fr));
    `}
    ${({ gap }) =>
    gap &&
    css`
      gap: ${gap}px;
    `}
    ${({ border }) =>
    border &&
    css`
      border: ${border};
    `}
    ${({ borderTop }) =>
    borderTop &&
    css`
      border-top: ${borderTop};
    `}
    ${({ borderBot }) =>
    borderBot &&
    css`
      border-bottom: ${borderBot};
    `}
    ${({ borderLeft }) =>
    borderLeft &&
    css`
      border-left: ${borderLeft};
    `}
    ${({ borderRight }) =>
    borderRight &&
    css`
      border-right: ${borderRight};
    `}
    ${({ borderR }) =>
    borderR &&
    css`
      border-radius: ${borderR};
    `}
    ${({ background }) =>
    background &&
    css`
      background: ${background};
    `}
    ${({ w }) =>
    w &&
    css`
      width: ${w};
    `}
    ${({ h }) =>
    h &&
    css`
      height: ${h};
    `}
    ${({ minW }) =>
    minW &&
    css`
      min-width: ${minW};
    `}
    ${({ minH }) =>
    minH &&
    css`
      min-height: ${minH};
    `}
    ${({ maxW }) =>
    maxW &&
    css`
      max-width: ${maxW};
    `}
    ${({ maxH }) =>
    maxH &&
    css`
      max-height: ${maxH};
    `}
    ${({ zIndex }) =>
    zIndex &&
    css`
      zindex: ${zIndex};
    `}
    ${({ clickable }) =>
    clickable &&
    css`
      cursor: pointer;
    `}
    ${({ opacity }) =>
    opacity &&
    css`
      opacity: ${opacity};
    `}
`;

export { BoxStyled };
