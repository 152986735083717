import React, { useCallback, useContext, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { AuthorizeModal } from "./components";
import { useHistory } from "react-router";

type Props = {
  children: React.ReactNode;
};

const AuthorizeModalContext = React.createContext<{
  showModal: (rl?: string) => void;
  hideModal: () => void;
}>({
  showModal: () => {},
  hideModal: () => {},
});

const AuthorizeModalProvider = ({ children }: Props) => {
  const { account } = useWeb3React();
  const history = useHistory();
  const [show, setShow] = useState<boolean>(false);
  const [redirectLink, setRedirectLink] = useState<string | undefined>(
    undefined,
  );
  const showModal = useCallback(
    (rl: string | undefined) => {
      setShow(true);
      setRedirectLink(rl || undefined);
    },
    [setShow],
  );

  const hideModal = useCallback(() => {
    setShow(false);
    if (redirectLink) history.push(redirectLink);
    setRedirectLink(undefined);
  }, [redirectLink, history]);

  return (
    <AuthorizeModalContext.Provider
      value={{
        showModal,
        hideModal,
      }}
    >
      {show && !account && <AuthorizeModal />}
      {children}
    </AuthorizeModalContext.Provider>
  );
};

const useAuthorizeModal = () => useContext(AuthorizeModalContext);
export { useAuthorizeModal };
export default AuthorizeModalProvider;
