import { useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import axios from "axios";
import {
  AuthorizedCallCallParams,
  AuthorizedCallCallResult,
} from "shared/types";

async function makeAuthorizedCallParams<T>(
  params: AuthorizedCallCallParams,
): Promise<AuthorizedCallCallResult<T>> {
  return {
    value: await axios.post(params.url, params.dataString, {
      headers: params.headers,
    }),
    params: params,
  };
}

const useDcaAuthorizedCall = () => {
  const { account, chainId, library } = useWeb3React();

  const makeAuthorizedCall = useCallback(
    async function <T>(
      url: string,
      data: any = {},
    ): Promise<AuthorizedCallCallResult<T>> {
      if (!account || !chainId || !library) return {};

      const domainSeparator = {
        Name: "Roobee",
        Version: "1",
        ChainId: chainId.toString(),
        Salt: "d2c52395fc9996e89ed8e7eb22c20e6d", // just a randomhash
      };

      data.OwnerAddress = account.toLowerCase();

      const signer = library.getSigner();

      // keys are sorted alphabetically
      const dataString = JSON.stringify(data, Object.keys(data).sort());
      const signature = await signer.signMessage(
        dataString + JSON.stringify(domainSeparator),
      );

      const params: AuthorizedCallCallParams = {
        url: url,
        dataString: dataString,
        headers: {
          Signature: signature,
          ChainId: domainSeparator.ChainId,
          OwnerAddress: account,
        },
      };
      return makeAuthorizedCallParams(params);
    },
    [account, chainId, library],
  );

  return { makeAuthorizedCall };
};

export { useDcaAuthorizedCall };
