import { useWeb3React } from "@web3-react/core";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { COOKIES_KEYS, cookies } from "shared/constants";

const useAuth = () => {
  const { account, library } = useWeb3React();
  const [authToken, setAuthToken] = useState<string | null>(null);
  const [authorisedAccount, setAuthorisedAccount] = useState<string | null>(
    null,
  );

  const authorise = useCallback(async () => {
    if (account && library) {
      const loginInfo = await axios
        .get(`/login:info?address=${account}`)
        .then((res) => res.data)
        .catch((err) => {
          console.log("Error getting loginInfo", err);

          return null;
        });

      if (!loginInfo) {
        return;
      }

      const signer = library.getSigner();
      const signature = await signer
        .signMessage(JSON.stringify(loginInfo))
        .catch((err) => {
          console.log("Error creating signature:", err);

          return null;
        });

      if (!signature) {
        return;
      }

      const loginBody = {
        address: account,
        signature: signature,
        deadline: loginInfo.message.deadline,
      };

      const token = await axios
        .post("/login", loginBody)
        .then((res) => {
          return res.data.token;
        })
        .catch((err) => {
          console.log("Error getting auth token:", err);

          return null;
        });

      if (!token) {
        return;
      }

      const expiresAt = new Date(new Date().getTime() + 23 * 60 * 60 * 1000);

      cookies.set(COOKIES_KEYS.authToken, token, { expires: expiresAt });
      cookies.set(COOKIES_KEYS.authAccount, account.toLowerCase(), {
        expires: expiresAt,
      });
      setAuthToken(token);
    }
  }, [account, library]);

  useEffect(() => {
    if (account && authorisedAccount) {
      if (account.toLowerCase() !== authorisedAccount) {
        cookies.remove(COOKIES_KEYS.authToken);
        cookies.remove(COOKIES_KEYS.authAccount);

        setAuthToken(null);
        setAuthorisedAccount(null);
      }
    }
  }, [account, authorisedAccount]);

  useEffect(() => {
    const initialToken = cookies.get(COOKIES_KEYS.authToken);
    const initialAccount = cookies.get(COOKIES_KEYS.authAccount);

    if (initialToken) {
      setAuthToken(initialToken);
      setAuthorisedAccount(initialAccount);
    }
  }, []);

  return { authToken, authorise };
};

export { useAuth };
