import React, { useRef } from "react";
import { useClickOutside } from "shared/hooks";
import { Modal, Overlay } from "./styled";
import { useDialogModal } from "components/DialogModal/DialogModalProvider";
import { createPortal } from "react-dom";
import { Box, Text, Icon } from "components";
import Colors from "shared/styles/Colors";

interface Props {
  headerText?: string;
  content: JSX.Element;
}

const DialogModal = ({ headerText, content }: Props) => {
  const { hideModal } = useDialogModal();

  const ModalRef = useRef(null);
  useClickOutside(ModalRef, hideModal);

  return createPortal(
    <Overlay>
      <Modal>
        {headerText && (
          <Box
            row
            centerV
            spread
            padding="20px"
            borderBot={`1px solid ${Colors.MG24}`}
          >
            <Text size="l" color="Black" bold>
              {headerText}
            </Text>
            <Icon
              asset="util-close-cross"
              color="MG24"
              onClick={hideModal}
              clickable
            />
          </Box>
        )}
        {content}
      </Modal>
    </Overlay>,
    document.body,
  );
};

export default DialogModal;
