import styled, { css } from "styled-components";

type AuthCardTypes = {
  $active?: boolean;
  $disable?: boolean;
};

type TitleTextTypes = {
  mLeft?: boolean;
};

const Wrapper = styled.div`
  width: 100%;
  max-width: 100%;
  min-width: 100%;
`;

const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &:nth-child(2) {
    margin-top: 20px;
  }

  &:last-child {
    margin-top: 16px;
  }

  @media (max-width: 600px) {
    margin-bottom: 16px;
    &:nth-child(2) {
      margin-top: 0;
    }
    &:last-child {
      margin-top: 24px;
      margin-bottom: 0;
    }
  }
`;

const StepTitle = styled.div`
  display: flex;
  align-items: center;
`;

const TitleText = styled.div<TitleTextTypes>`
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  @media (max-width: 600px) {
    ${(props) =>
      props.mLeft &&
      css`
        margin-left: 10px;
      `}
  }
`;

const AuthCardGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  grid-column-gap: 3px;
  grid-row-gap: 6px;
  margin-top: 16px;
  max-width: 462px;

  @media (max-width: 600px) {
    grid-row-gap: 16px;
    margin-top: 12px;
  }
`;

const AuthCard = styled.div<AuthCardTypes>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 90px;
  height: 90px;
  align-items: center;
  cursor: pointer;
  border: 1px solid #f5f5f5;
  border-radius: 10px;
  padding: 15px 6px 0;
  transition:
    border,
    box-shadow,
    filter 0.3s ease;

  ${(props) =>
    props.$active &&
    css`
      border: solid 1px transparent;
      background-image: linear-gradient(
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0)
        ),
        linear-gradient(101deg, #b6e82f, #50e0ed);
      background-origin: border-box;
      background-clip: content-box, border-box;
      box-shadow: 2px 1000px 1px #ffffff inset;
    `};
  &:hover {
    border: solid 1px transparent;
    background-image: linear-gradient(
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0)
      ),
      linear-gradient(101deg, #b6e82f, #50e0ed);
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px #ffffff inset;
    filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.25));
  }

  ${(props) =>
    props.$disable &&
    css`
      filter: grayscale(1);
      opacity: 0.5;
      &:hover {
        filter: grayscale(1);
        background-image: none;
        box-shadow: none;
        background-clip: initial;
        background-origin: initial;
      }
      cursor: initial;
    `};
`;
const AuthCardImage = styled.img`
  width: 30px;
  height: 30px;
  margin-bottom: 6px;
`;
const AuthCardName = styled.div`
  font-size: 12px;
  line-height: 17px;
  text-align: center;
`;

export default {
  Wrapper,
  Step,
  StepTitle,
  TitleText,
  AuthCardGrid,
  AuthCard,
  AuthCardImage,
  AuthCardName,
};
