import React from "react";

const LineaIcon = (props) => (
  <svg
    {...props}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="20" cy="20" r="20" fill="white" />
    <mask
      id="mask0_8355_61458"
      style={{ maskType: "luminance" }}
      maskUnits="userSpaceOnUse"
      x="12"
      y="9"
      width="19"
      height="19"
    >
      <path d="M30.6667 9.33331H12V28H30.6667V9.33331Z" fill="white" />
    </mask>
    <g mask="url(#mask0_8355_61458)">
      <path
        d="M27.5027 27.9999H12V12.3622H15.547V24.9692H27.5027V27.9982V27.9999Z"
        fill="#61DFFF"
      />
      <path
        d="M27.5053 15.3913C29.2527 15.3913 30.6693 14.0352 30.6693 12.3623C30.6693 10.6894 29.2527 9.33331 27.5053 9.33331C25.7579 9.33331 24.3413 10.6894 24.3413 12.3623C24.3413 14.0352 25.7579 15.3913 27.5053 15.3913Z"
        fill="#61DFFF"
      />
    </g>
    <circle cx="20" cy="20" r="19.5" stroke="#61DFFF" />
  </svg>
);

export default LineaIcon;
