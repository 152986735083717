import React from "react";

const CheckedIcon = (props) => (
  <svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="16" height="16" rx="2" fill="#B6E82F" />
    <path
      d="M6.71676 10.7205L3.76923 7.74381C3.72795 7.70063 3.67858 7.66619 3.624 7.64249C3.56941 7.6188 3.5107 7.60633 3.4513 7.60581C3.39189 7.60528 3.33298 7.61672 3.27799 7.63946C3.22301 7.66219 3.17305 7.69576 3.13104 7.73821C3.08903 7.78066 3.05581 7.83114 3.03332 7.8867C3.01082 7.94226 2.9995 8.0018 3.00002 8.06183C3.00053 8.12186 3.01288 8.18119 3.03632 8.23635C3.05977 8.29151 3.09386 8.34139 3.13659 8.3831L6.71586 12L13.8744 4.7662C13.9559 4.68093 14.001 4.56673 14 4.44818C13.999 4.32964 13.9519 4.21625 13.869 4.13242C13.786 4.0486 13.6738 4.00105 13.5565 4.00002C13.4392 3.99899 13.3262 4.04456 13.2418 4.12691L6.71586 10.7205H6.71676Z"
      fill="#F8F8F8"
    />
  </svg>
);

export default CheckedIcon;
