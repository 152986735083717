import { CartState } from "./types";

const reducers = {
  setItemAmount: (state: CartState, action: { payload: any }) => {
    const { amount, itemId } = action.payload;
    if (amount && !isNaN(itemId)) {
      const item = state.items.find((val) => val.id === itemId);
      if (item) {
        item.amount = amount;
      }
      //   state.items = updatedItems;
    }
  },
};

export default reducers;
