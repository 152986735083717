import React, { FC, useEffect, useMemo, useState } from "react";
import Styled from "./styled";
import useRates from "shared/hooks/useRates";
import { Chain, TimeInterim } from "pages/MegaDashboard/types";
import { CHAIN_LIST_ARRAY, TIME_INTERIMS } from "pages/MegaDashboard/consts";
import { getTimeInterimTimestamp } from "pages/MegaDashboard/utils";
import { useWeb3React } from "@web3-react/core";
import { ApproximateBalanceTypes } from "./types";
import limitNumberString from "utils/limitNumberString";
import SUPPORTED_CHAIN_IDS from "shared/constants/supportedChainIds";

const ApproximateBalance: FC<ApproximateBalanceTypes> = ({
  className,
  balanceText,
  userModalHandler,
}) => {
  const [showBalance, setShowBalance] = useState<boolean>(false);
  const { getTokens, getTokensHistory, chainTokens } = useRates();
  const [chosenInterim] = useState<TimeInterim>(TIME_INTERIMS.current);
  const { account } = useWeb3React();
  const [selectedChains] = useState<Chain[]>(CHAIN_LIST_ARRAY);

  const approximateBalance = useMemo(() => {
    let total = 0;
    Object.values(SUPPORTED_CHAIN_IDS).forEach((chainId) => {
      const tokens = chainTokens?.[chainId.toString()];

      if (tokens) {
        Object.values(tokens).forEach((token) => {
          if (
            !(
              token.tokenName === "" ||
              token.tokenSymbol === "" ||
              token.tokenDecimals === 0
            )
          ) {
            console.log(
              "Whats the balance usd:",
              token.tokenSymbol,
              token.balanceUsd,
            );
            total += Number(token.balanceUsd);
          }
        });
      }
    });

    return total;

    // return chosenInterim.value === "current"
    //   ? parseFloat((chainTokens?.totalUsd as string) || "0")
    //   : parseFloat((chainTokensHistory?.totalUsd as string) || "0");
  }, [chainTokens]);
  useEffect(() => {
    const init = async () => {
      if (account) {
        if (chosenInterim.value !== "current") {
          await getTokensHistory(
            account,
            selectedChains.map((v) => v.chainId),
            getTimeInterimTimestamp(chosenInterim.value),
          );
        }
        await getTokens(
          account,
          selectedChains.map((v) => v.chainId),
        );
      }
    };

    init();
  }, [account, chosenInterim, getTokens, getTokensHistory, selectedChains]);

  return (
    <Styled.Wrapper className={className} $active={!!account}>
      <Styled.UserIcon onClick={userModalHandler} />
      {balanceText && <Styled.Balance>Balance</Styled.Balance>}
      <Styled.Box>
        <Styled.Text $active={showBalance}>
          {account
            ? `$${limitNumberString(approximateBalance) || "0.00"}`
            : "$0.00"}
        </Styled.Text>
        <Styled.HiddenIcon $active={showBalance} />
      </Styled.Box>
      <Styled.HideButton
        disabled={!account}
        onClick={() => setShowBalance(!showBalance)}
      >
        {showBalance ? <Styled.UncoverIcon /> : <Styled.CoverIcon />}
      </Styled.HideButton>
    </Styled.Wrapper>
  );
};

export default ApproximateBalance;
