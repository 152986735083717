export const tokenListsChained = {
  1: "https://extendedtokens.uniswap.org/",
  10: "https://static.optimism.io/optimism.tokenlist.json",
  42: "https://static.optimism.io/optimism.tokenlist.json",
  56: "https://tokens.pancakeswap.finance/pancakeswap-extended.json",
  137: "https://tokens.uniswap.org/",
  80001: "https://tokens.uniswap.org/",
  42161: "https://bridge.arbitrum.io/token-list-42161.json",
  421611: "https://bridge.arbitrum.io/token-list-42161.json",
};
