import Network, { AvailableNetwork } from "../types/network";
import SUPPORTED_CHAIN_IDS from "shared/constants/supportedChainIds";
import { RPC_URLS } from "./rpcUrls";

const NETWORKS: Record<AvailableNetwork, Network> = {
  ETH: {
    chainIdHex: "0x1",
    chainId: SUPPORTED_CHAIN_IDS.ETH,
    name: "NETWORKS-eth",
    slug: "ETH",
    color: "#627EEA",
    nativeCurrency: {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
      scanAddress: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    },
    // icon: <NetworkIcon chainId={SUPPORTED_CHAIN_IDS.ETH} />,
    rpcUrls: [RPC_URLS.ETH_MAINNET],
    blockExplorerUrls: ["https://etherscan.io/"],
    blockExplorerName: "EtherScan",
  },
  OPTIMISM: {
    chainIdHex: "0xA",
    chainId: SUPPORTED_CHAIN_IDS.OPTIMISM,
    name: "NETWORKS-optimistic",
    slug: "OPTIMISM",
    color: "#FF0420",
    nativeCurrency: {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
      scanAddress: "0x4200000000000000000000000000000000000006",
    },
    // icon: <NetworkIcon chainId={SUPPORTED_CHAIN_IDS.OPTIMISM} />,
    rpcUrls: ["https://mainnet.optimism.io/"],
    blockExplorerUrls: ["https://optimistic.etherscan.io/"],
    blockExplorerName: "OptiScan",
  },
  BSC: {
    chainIdHex: "0x38",
    chainId: SUPPORTED_CHAIN_IDS.BSC,
    name: "NETWORKS-bsc",
    slug: "BSC",
    color: "#EBB50B",
    nativeCurrency: {
      name: "Binance Smart Chain Native Token",
      symbol: "BNB",
      decimals: 18,
      scanAddress: "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c",
    },
    // icon: <NetworkIcon chainId={SUPPORTED_CHAIN_IDS.BSC} />,
    rpcUrls: [RPC_URLS.BSC_MAINNET],
    blockExplorerUrls: ["https://bscscan.com/"],
    blockExplorerName: "BscScan",
  },
  POLYGON: {
    chainIdHex: "0x89",
    chainId: SUPPORTED_CHAIN_IDS.POLYGON,
    name: "NETWORKS-polygon",
    slug: "POLYGON",
    color: "#8247E4",
    nativeCurrency: {
      name: "Matic",
      symbol: "MATIC",
      decimals: 18,
      scanAddress: "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270",
    },
    // icon: <NetworkIcon chainId={SUPPORTED_CHAIN_IDS.POLYGON} />,
    rpcUrls: ["https://rpc-mainnet.maticvigil.com/"],
    blockExplorerUrls: ["https://polygonscan.com/"],
    blockExplorerName: "PolygonScan",
  },
  ARBITRUM: {
    chainIdHex: "0xA4B1",
    chainId: SUPPORTED_CHAIN_IDS.ARBITRUM,
    name: "NETWORKS-arbitrum",
    slug: "ARBITRUM",
    color: "#96BEDC",
    nativeCurrency: {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
      scanAddress: "0x82af49447d8a07e3bd95bd0d56f35241523fbab1",
    },
    // icon: <NetworkIcon chainId={SUPPORTED_CHAIN_IDS.ARBITRUM} />,
    rpcUrls: ["https://arb1.arbitrum.io/rpc"],
    blockExplorerUrls: ["https://arbiscan.io/"],
    noIconColorChange: true,
    blockExplorerName: "ArbiScan",
  },
  AVALANCHE: {
    chainIdHex: "0xA86A",
    chainId: SUPPORTED_CHAIN_IDS.AVALANCHE,
    name: "NETWORKS-avalanche",
    slug: "AVALANCHE",
    color: "#E84142",
    nativeCurrency: {
      name: "Avalanche",
      symbol: "AVAX",
      decimals: 18,
      scanAddress: "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7",
    },
    // icon: <NetworkIcon chainId={SUPPORTED_CHAIN_IDS.AVALANCHE} />,
    rpcUrls: ["https://api.avax.network/ext/bc/C/rpc"],
    blockExplorerUrls: ["https://snowtrace.io/"],
    blockExplorerName: "Snowtrace",
  },
  AURORA: {
    chainIdHex: "0x4e454152",
    chainId: SUPPORTED_CHAIN_IDS.AURORA,
    name: "NETWORKS-aurora",
    slug: "AURORA",
    color: "#E84142",
    nativeCurrency: {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
      scanAddress: "0xC9BdeEd33CD01541e1eeD10f90519d2C06Fe3feB",
    },
    // icon: <NetworkIcon chainId={SUPPORTED_CHAIN_IDS.AURORA} />,
    rpcUrls: ["https://mainnet.aurora.dev"],
    blockExplorerUrls: ["https://aurorascan.dev/"],
    blockExplorerName: "AuroraScan",
  },
  BITGERT: {
    chainIdHex: "0x7F08",
    chainId: SUPPORTED_CHAIN_IDS.BITGERT,
    name: "NETWORKS-bitgert",
    slug: "BITGERT",
    color: "#8109EA",
    nativeCurrency: {
      name: "Brise",
      symbol: "BRISE",
      decimals: 18,
      scanAddress: "0x5aea5775959fbc2557cc8789bc1bf90a239d9a91",
    },
    // icon: <NetworkIcon chainId={SUPPORTED_CHAIN_IDS.BITGERT} />,
    rpcUrls: ["https://rpc.icecreamswap.com"],
    blockExplorerUrls: ["https://brisescan.com/"],
    blockExplorerName: "BriseScan",
  },
  ZKSYNCERA: {
    chainIdHex: "0x144",
    chainId: SUPPORTED_CHAIN_IDS.ZKSYNCERA,
    name: "NETWORKS-zksyncera",
    slug: "ZKSYNCERA",
    color: "#1E69FF",
    nativeCurrency: {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
      scanAddress: "0xC9BdeEd33CD01541e1eeD10f90519d2C06Fe3feB",
    },
    // icon: <NetworkIcon chainId={SUPPORTED_CHAIN_IDS.BITGERT} />,
    rpcUrls: ["https://mainnet.era.zksync.io"],
    blockExplorerUrls: ["https://explorer.zksync.io"],
    blockExplorerName: "BriseScan",
  },
  GNOSIS: {
    chainIdHex: "0x64",
    chainId: SUPPORTED_CHAIN_IDS.GNOSIS,
    name: "NETWORKS-gnosis",
    slug: "GNOSIS",
    color: "#3E6957",
    nativeCurrency: {
      name: "xDai",
      symbol: "xDAI",
      decimals: 18,
      scanAddress: "0xe91D153E0b41518A2Ce8Dd3D7944Fa863463a97d",
    },
    // icon: <NetworkIcon chainId={SUPPORTED_CHAIN_IDS.BITGERT} />,
    rpcUrls: ["https://rpc.gnosis.gateway.fm"],
    blockExplorerUrls: ["https://gnosisscan.io/"],
    blockExplorerName: "GnosisScan",
  },
  LINEA: {
    chainIdHex: "0xe708",
    chainId: SUPPORTED_CHAIN_IDS.LINEA,
    name: "NETWORKS-linea",
    slug: "LINEA",
    color: "#61DFFF",
    nativeCurrency: {
      name: "Ether",
      symbol: "ETH",
      decimals: 18,
      scanAddress: "0xe5D7C2a44FfDDf6b295A15c148167daaAf5Cf34f",
    },
    // icon: <NetworkIcon chainId={SUPPORTED_CHAIN_IDS.BITGERT} />,
    rpcUrls: ["https://rpc.linea.build"],
    blockExplorerUrls: ["https://lineascan.build/"],
    blockExplorerName: "LineaScan",
  },
  OP_BNB: {
    chainIdHex: "0xCC",
    chainId: SUPPORTED_CHAIN_IDS.OP_BNB,
    name: "NETWORKS-opbnb",
    slug: "OP_BNB",
    color: "#EBB50B",
    nativeCurrency: {
      name: "Bnb",
      symbol: "BNB",
      decimals: 18,
      scanAddress: "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c",
    },
    // icon: <NetworkIcon chainId={SUPPORTED_CHAIN_IDS.BITGERT} />,
    rpcUrls: ["https://opbnb-mainnet-rpc.bnbchain.org"],
    blockExplorerUrls: ["https://opbnbscan.com/"],
    blockExplorerName: "opBNBScan",
  },
  SCROLL: {
    chainIdHex: "0x82750",
    chainId: SUPPORTED_CHAIN_IDS.SCROLL,
    name: "NETWORKS-scroll",
    slug: "SCROLL",
    color: "#62E6D4",
    nativeCurrency: {
      name: "Eth",
      symbol: "ETH",
      decimals: 18,
      scanAddress: "0x5300000000000000000000000000000000000004",
    },
    // icon: <NetworkIcon chainId={SUPPORTED_CHAIN_IDS.BITGERT} />,
    rpcUrls: ["https://scroll.blockpi.network/v1/rpc/public"],
    blockExplorerUrls: ["https://scrollscan.com/"],
    blockExplorerName: "ScrollScan",
  },
  BASE: {
    chainIdHex: "0x2105",
    chainId: SUPPORTED_CHAIN_IDS.BASE,
    name: "NETWORKS-base",
    slug: "BASE",
    color: "#3773F5",
    nativeCurrency: {
      name: "Eth",
      symbol: "ETH",
      decimals: 18,
      scanAddress: "0x4200000000000000000000000000000000000006",
    },
    // icon: <NetworkIcon chainId={SUPPORTED_CHAIN_IDS.BITGERT} />,
    rpcUrls: ["https://mainnet.base.org"],
    blockExplorerUrls: ["https://basescan.org/"],
    blockExplorerName: "BaseScan",
  },
  BLAST: {
    chainIdHex: "0x13e31",
    chainId: SUPPORTED_CHAIN_IDS.BLAST,
    name: "NETWORKS-blast",
    slug: "BLAST",
    color: "#3773F5",
    nativeCurrency: {
      name: "Eth",
      symbol: "ETH",
      decimals: 18,
      scanAddress: "0x4300000000000000000000000000000000000004",
    },
    // icon: <NetworkIcon chainId={SUPPORTED_CHAIN_IDS.BITGERT} />,
    rpcUrls: ["https://rpc.blast.io"],
    blockExplorerUrls: ["https://blastscan.io"],
    blockExplorerName: "BlastScan",
  },
};

export const NETWORKS_BY_CHAIN_ID: Record<number, Network> = Object.values(
  NETWORKS,
).reduce(
  (networks, network) => ({
    ...networks,
    [network.chainId]: network,
  }),
  {},
);

console.log(NETWORKS_BY_CHAIN_ID);

export const NO_NETWORK: Network = {
  chainIdHex: "",
  chainId: -1,
  name: "NETWORKS-undefined",
  slug: "",
  nativeCurrency: {
    name: "-",
    symbol: "-",
    decimals: 18,
    scanAddress: "-",
  },
  // icon: null,
  color: "#627EEA",
  rpcUrls: [],
  blockExplorerUrls: [],
  blockExplorerName: "",
};

export default NETWORKS;
