import { useEffect, useState } from "react";

const useEndOfScroll = (): boolean => {
  const [isEndOfScroll, setIsEndOfScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.body.scrollHeight;
      const scrollTop = window.scrollY || window.pageYOffset;

      if (scrollTop + windowHeight >= documentHeight) {
        setIsEndOfScroll(true);
      } else {
        setIsEndOfScroll(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return isEndOfScroll;
};

export default useEndOfScroll;
