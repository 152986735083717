import React from "react";

const AirdropIcon = ({ ...props }) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_920_13385)">
      <path
        d="M6 18.1562C6 17.8801 6.22386 17.6562 6.5 17.6562H18.5C18.7761 17.6562 19 17.8801 19 18.1563V23.1563C19 23.4324 18.7761 23.6563 18.5 23.6563H6.5C6.22386 23.6563 6 23.4324 6 23.1563V18.1562Z"
        stroke="black"
        strokeLinejoin="round"
      />
      <path
        d="M5 17.1562V15.1562C5 14.8801 5.22386 14.6562 5.5 14.6562H19.5C19.7761 14.6562 20 14.8801 20 15.1563V17.1562C20 17.4324 19.7761 17.6562 19.5 17.6562H5.5C5.22386 17.6562 5 17.4324 5 17.1562Z"
        stroke="black"
        strokeLinejoin="round"
      />
      <path
        d="M12.7586 14.6562L20 9.35015M12.7586 14.6562L5 9.35015M12.7586 14.6562L10.431 9.0848M12.7586 14.6562L14.569 9.0848M20 9.35015C19.7414 6.87396 18.4483 1.65625 12.7586 1.65625C7.06897 1.65625 5.43103 6.69709 5 9.35015M20 9.35015C19.6552 8.90796 18.9655 8.02358 17.4138 8.02358C15.8621 8.02358 15 8.73106 14.569 9.0848M5 9.35015C5.43103 8.90796 6.03448 8.02358 7.58621 8.02358C8.87931 8.02358 9.91379 8.73106 10.431 9.0848M10.431 9.0848C10.6897 8.73106 11.2069 8.02358 12.5 8.02358C13.7931 8.02358 14.3103 8.73106 14.569 9.0848"
        stroke="black"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_920_13385">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0 0.65625)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default AirdropIcon;
