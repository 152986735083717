import styled, { css } from "styled-components";
import { MutableRefObject } from "react";
import { default as NotStyledNumberFormat } from "react-number-format";

type WrapperTypes = {
  white?: boolean;
  attrs?: any;
};

type InputTypes = {
  type?: string;
  readonly?: boolean;
  value?: string | number;
  ref?: MutableRefObject<HTMLInputElement | undefined>;
  disabled?: boolean;
  onChange?: (val: string) => void;
  onBlur?: () => void;
  suffix?: boolean;
};

const Wrapper = styled.div<WrapperTypes>`
  width: 100%;
  ${(props) =>
    props.white &&
    css`
      input {
        background: #fff;
        border-color: ${(props_) => props_.theme.Colors.Gray3};
      }
    `}
`;

const CardInput = styled.div`
  display: flex;
`;

const InputBox = styled.div`
  width: 100%;
  position: relative;
  border-radius: 8px;
`;

const Suffix = styled.div`
  position: absolute;
  top: 50%;
  right: 9px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;

  img {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }

  span {
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #000000;
  }
`;

const CardLable = styled.div``;

const Input = styled.input<InputTypes>`
  background: #f0f5f7;
  border-radius: 8px;
  font-size: 16px;
  line-height: 150%;
  padding: 12px 20px;
  width: 100%;
  transition:
    background 0.2s ease,
    border-color 0.2s ease,
    color 0.2s ease;
  border: 1px solid transparent;

  &:hover:not(:disabled) {
    border-color: #b8c4cc;
  }

  &:focus {
    background: #ffffff;
    border: 1px solid ${(props) => props.theme.Colors.TurquoiseDark};
  }

  &:disabled {
    opacity: 0.5;
  }

  ${(props) =>
    props.suffix &&
    css`
      padding-right: 86px;
    `}
`;

const NumberFormat = styled(NotStyledNumberFormat)`
  background: #f0f5f7;
  border-radius: 8px;
  font-size: 16px;
  line-height: 150%;
  padding: 12px 20px;
  width: 100%;
  transition:
    background 0.2s ease,
    border-color 0.2s ease,
    color 0.2s ease;
  border: 1px solid transparent;

  &:hover:not(:disabled) {
    border-color: #b8c4cc;
  }

  &:focus {
    background: #ffffff;
    border: 1px solid ${(props) => props.theme.Colors.TurquoiseDark};
  }

  &:disabled {
    opacity: 0.5;
  }

  ${(props) =>
    props.suffix &&
    css`
      padding-right: 86px;
    `}
`;

export default {
  Wrapper,
  CardInput,
  InputBox,
  Suffix,
  CardLable,
  Input,
  NumberFormat,
};
