import React from "react";
import { Button, Icon, useDialogModal } from "../../../../components";
import Styled from "./styled";
import { useHistory } from "react-router";
import { ROUTE_PATHS } from "../../../../shared/constants";
const MarketplaceDialogFailed = (): JSX.Element => {
  const { hideModal } = useDialogModal();

  const history = useHistory();

  const redirectToInvest = () => {
    hideModal();
    history.push(ROUTE_PATHS.INVEST);
  };

  return (
    <>
      <Styled.Top>
        <Styled.Box>
          <Icon asset="warning" color="Red" />
          <Styled.TopText>failed</Styled.TopText>
        </Styled.Box>
        <Icon
          asset="util-close-cross"
          color="MG24"
          clickable
          onClick={redirectToInvest}
        />
      </Styled.Top>
      <Styled.Content>
        <Styled.Text>Unfortunately the purchase went wrong</Styled.Text>
        <Styled.SubText>
          View transaction information in the explorer
        </Styled.SubText>
        <Button color="gradient" size="modal" onClick={redirectToInvest}>
          OK
        </Button>
      </Styled.Content>
    </>
  );
};

export default MarketplaceDialogFailed;
