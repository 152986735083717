import styled from "styled-components";
import { Link as NotStyledLink } from "react-router-dom";

const Link = styled(NotStyledLink)`
  color: ${(props) => props.theme.Colors.Black};
  border: solid 1px transparent;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(101deg, #b6e82f, #50e0ed);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #ffffff inset;
  border-radius: 10px;
  transition:
    color,
    border,
    box-shadow,
    filter 0.3s ease;

  &:hover {
    color: ${(props) => props.theme.Colors.Black};
    filter: drop-shadow(2px 2px 6px rgba(0, 0, 0, 0.35));
    border: 1px solid transparent;
    border-image: linear-gradient(
      90deg,
      rgba(182, 232, 47, 0.25) 0%,
      rgba(80, 224, 237, 0.25) 100%
    );
    position: relative;
    border-radius: 10px;
    background-image: linear-gradient(
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0)
      ),
      linear-gradient(101deg, #b6e82f, #50e0ed);
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px #f8f8f8 inset;
  }
`;

export default Link;
