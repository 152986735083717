import React from "react";

const ArrowRight = ({ ...props }) => (
  <svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 13L10.1464 8.85355C10.3417 8.65829 10.3417 8.34171 10.1464 8.14645L6 4"
      stroke="black"
      strokeLinecap="round"
    />
  </svg>
);

export default ArrowRight;
