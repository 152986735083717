import React from "react";

const ArrowBottom = ({ ...props }) => (
  <svg
    {...props}
    width="11"
    height="7"
    viewBox="0 0 11 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 1L5.5 5.5L10 1" stroke="#000000" strokeWidth="1.5" />
  </svg>
);

export default ArrowBottom;
