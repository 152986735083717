import { useWeb3React } from "@web3-react/core";
import axios from "axios";
import { useState } from "react";
import {
  Portfolio,
  PortfolioAvailableChains,
  PortfolioAvailableChainsT,
  PortfolioStatGroup,
  PortfolioStatGrouped,
} from "../types/Portfolio";
import { useInterval } from "./useInterval";

const GET_USER_PORTFOLIO_DELAY = 30_000;

const useUserPortfolio = () => {
  const { account } = useWeb3React();
  const [portfolioStatGroups, setPortfolioStatGroups] =
    useState<PortfolioStatGroup[]>();

  const getUserPortfolio = () => {
    const chainIds = new Set<PortfolioAvailableChainsT>();
    console.log("PORTFOLIO UPDATED");

    for (const chainId of PortfolioAvailableChains) {
      chainIds.add(chainId);
    }

    if (account) {
      axios
        .get(`/portfolio/${account}:info`)
        .then((res) => {
          const portfolio: Portfolio = res.data.chainIds;
          const result: PortfolioStatGroup[] = [];

          for (const chainId of PortfolioAvailableChains) {
            if (portfolio[chainId]) {
              const statsArr = portfolio[chainId];

              for (const stat of statsArr) {
                let group = result.find(
                  (group_) =>
                    group_.protocolName === stat.protocolName &&
                    group_.chainId === chainId,
                );

                const statToAdd = Object.keys(stat)
                  .filter((key) => key !== "protocolName")
                  .reduce((obj, key) => {
                    obj[key] = stat[key];
                    return obj;
                  }, {}) as PortfolioStatGrouped;

                if (!group) {
                  group = {
                    protocolName: stat.protocolName,
                    chainId: chainId,
                    stats: [],
                  } as PortfolioStatGroup;

                  group.stats.push(statToAdd);

                  result.push(group);
                } else {
                  group.stats.push(statToAdd);
                }
              }
            }
          }

          setPortfolioStatGroups(result);
        })
        .catch((err) => {
          console.error("Error fetching user portfolio", err);
        });
    }
  };

  useInterval(getUserPortfolio, GET_USER_PORTFOLIO_DELAY, true, [account]);

  return { portfolioStatGroups };
};

export { useUserPortfolio };
