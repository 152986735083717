import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { InvestState } from "./types";
import { fetchTokens } from "./thunks";

const extraReducers = (builder: ActionReducerMapBuilder<InvestState>) => {
  builder.addCase(fetchTokens.fulfilled, (state, { payload }) => {
    state.tokens = payload;
  });
};

export default extraReducers;
