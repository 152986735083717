import styled, { css } from "styled-components";
import GradientButton from "../../../shared/styles/elements/GradientButton";

type CookiesTypes = {
  active: boolean;
};

const Wrapper = styled.div<CookiesTypes>`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 16px 0;
  background: linear-gradient(
    180deg,
    #ffffff 100%,
    rgba(255, 255, 255, 0) 100%
  );
  z-index: 100001;
  transform: translateY(100%);
  transition: transform 0.3s ease;

  ${(props) =>
    props.active &&
    css`
      transform: translateY(0);
    `};

  @media (max-width: 600px) {
    padding: 20px;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-column-gap: 30px;
  max-width: 1240px;
  width: 100%;
  margin: 0 auto;
  padding: 0 10px;

  @media (max-width: 600px) {
    flex-direction: column;
    padding: 0;
  }
`;

const Text = styled.div`
  max-width: 780px;
  color: #5c5c5c;
  font-size: 16px;
  line-height: 35px;
  letter-spacing: 0.2px;

  a {
    text-decoration-line: underline;
    color: #5c5c5c;
    font-size: 16px;
    line-height: 35px;
    letter-spacing: 0.2px;
  }

  @media (max-width: 600px) {
    font-size: 12px;
    line-height: 19px;
    margin-bottom: 16px;

    a {
      font-size: 12px;
      line-height: 19px;
    }

    span {
      display: inline-block;
      margin-top: 14px;
    }
  }
`;

const Button = styled(GradientButton)`
  color: #000;
  text-align: center;
  font-size: 14px;
  line-height: 145%;
  text-transform: uppercase;
  width: 160px;
  min-width: 160px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 600px) {
    width: 158px;
    height: 32px;
  }
`;

export default {
  Wrapper,
  Container,
  Text,
  Button,
};
