import type { FC } from "react";
import React from "react";
import SUPPORTED_CHAIN_IDS from "shared/constants/supportedChainIds";

import ETHIcon from "components/NetworkSelect/_resources/ETHIcon";
import BSCIcon from "components/NetworkSelect/_resources/BSCIcon";
import PolygonIcon from "components/NetworkSelect/_resources/PolygonIcon";
import OptimisticIcon from "components/NetworkSelect/_resources/OptimisticIcon";
import ArbitrumIcon from "components/NetworkSelect/_resources/ArbitrumIcon";
import AvalancheIcon from "components/NetworkSelect/_resources/AvalancheIcon";
import AuroraIcon from "components/NetworkSelect/_resources/AuroraIcon";
import BitgertIcon from "components/NetworkSelect/_resources/BitgertIcon";
import ZkSyncEraIcon from "components/NetworkSelect/_resources/ZkSyncIconEra";
import GnosisIcon from "components/NetworkSelect/_resources/GnosisIcon";
import LineaIcon from "components/NetworkSelect/_resources/LineaIcon";
import OpBnbIcon from "components/NetworkSelect/_resources/opBNBIcon";
import BaseIcon from "components/NetworkSelect/_resources/BaseIcon";
import ScrollIcon from "components/NetworkSelect/_resources/ScrollIcon";
import BlastIcon from "components/NetworkSelect/_resources/BlastIcon";

type NetworkIconProps = {
  chainId: number;
  className?: string;
};

const NetworkIcon: FC<NetworkIconProps> = ({
  chainId,
  className,
}: NetworkIconProps) => {
  switch (chainId) {
    case SUPPORTED_CHAIN_IDS.ETH:
      return <ETHIcon className={className} />;
    case SUPPORTED_CHAIN_IDS.BSC:
      return <BSCIcon className={className} />;
    case SUPPORTED_CHAIN_IDS.POLYGON:
      return <PolygonIcon className={className} />;
    case SUPPORTED_CHAIN_IDS.OPTIMISM:
      return <OptimisticIcon className={className} />;
    case SUPPORTED_CHAIN_IDS.BITGERT:
      return <BitgertIcon className={className} />;
    case SUPPORTED_CHAIN_IDS.ARBITRUM:
      return <ArbitrumIcon className={className} />;
    case SUPPORTED_CHAIN_IDS.AVALANCHE:
      return <AvalancheIcon className={className} />;
    case SUPPORTED_CHAIN_IDS.AURORA:
      return <AuroraIcon className={className} />;
    case SUPPORTED_CHAIN_IDS.ZKSYNCERA:
      return <ZkSyncEraIcon className={className} />;
    case SUPPORTED_CHAIN_IDS.GNOSIS:
      return <GnosisIcon className={className} />;
    case SUPPORTED_CHAIN_IDS.LINEA:
      return <LineaIcon className={className} />;
    case SUPPORTED_CHAIN_IDS.BASE:
      return <BaseIcon className={className} />;
    case SUPPORTED_CHAIN_IDS.SCROLL:
      return <ScrollIcon className={className} />;
    case SUPPORTED_CHAIN_IDS.OP_BNB:
      return <OpBnbIcon className={className} />;
    case SUPPORTED_CHAIN_IDS.BLAST:
      return <BlastIcon className={className} />;
    default:
      return <div></div>;
  }
};

export default NetworkIcon;
