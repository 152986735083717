import React, { useCallback, useEffect, useState } from "react";
import { Box, Icon, Text, useDialogModal } from "components";
import { PurpleButton, RefreshButton } from "./styled";
import { useDcaWhitelistAuth } from "shared/hooks";
import { useHistory } from "react-router";
import { ROUTE_PATHS } from "shared/constants";

// TODO(Nikita): Remove if only used with dca whitelist
const DialogDcaJoinDiscord = () => {
  const [isRefreshButtonVisible, setIsRefreshButtonVisible] = useState(false);
  const { checkIfAccountInWhitelist, isWhitelisted } = useDcaWhitelistAuth();
  const { hideModal } = useDialogModal();

  const history = useHistory();

  const showRefreshButton = useCallback(() => {
    setIsRefreshButtonVisible(true);
  }, [setIsRefreshButtonVisible]);

  const openDiscordJoinLink = () => {
    window.open("https://discord.gg/NQHUyU8VVd");
    showRefreshButton();
  };

  const redirectToDashboard = () => {
    hideModal();
    history.push(ROUTE_PATHS.DASHBOARD);
  };

  useEffect(() => {
    if (isWhitelisted) {
      hideModal();
    }
  }, [hideModal, isWhitelisted]);

  return (
    <Box column centerV padding="32px 22px 36px 22px">
      <Box mb="10" style={{ alignSelf: "flex-end" }}>
        <Icon
          asset="util-close-cross"
          color="Gray6"
          clickable
          onClick={redirectToDashboard}
        />
      </Box>
      <Text size="l" color="Black" bold>
        Oh no!
      </Text>
      <Box mt="16">
        <Text
          size="m"
          color="Black"
          style={{ display: "flex", textAlign: "center" }}
        >
          Unfortunately you do not have access to test the DCA module. <br />
          You can leave a request to the whitelist for testing <br />
          in our Discord
        </Text>
      </Box>
      <Box row centerV mt="28">
        <PurpleButton onClick={openDiscordJoinLink}>
          <Icon asset="org-discord" color="White2" />
          <Box row centerV ml="12">
            <Text size="m" color="White" bold>
              Join the DCA Test Whitelist
            </Text>
          </Box>
        </PurpleButton>
        {isRefreshButtonVisible && (
          <Box ml="16">
            <RefreshButton onClick={checkIfAccountInWhitelist}>
              Refresh
            </RefreshButton>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default DialogDcaJoinDiscord;
