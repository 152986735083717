import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import {
  AddTokenRequestData,
  DeleteTokensRequestData,
  GetTokensResponseData,
  UpdateTokensRequestData,
} from "./requests";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const addCartToken = createAsyncThunk<AxiosResponse, AddTokenRequestData>(
  "cart/addToken",
  async (data) => {
    const { address, tokens, authToken } = data;
    return axios({
      method: "POST",
      url: `${BASE_URL}/invest/${address}:tokensAdd`,
      data: tokens,
      headers: { Authorization: `Bearer ${authToken}` },
    });
  },
);

const getCartTokens = createAsyncThunk<
  AxiosResponse<GetTokensResponseData>,
  string
>("cart/getTokens", async (address) => {
  return axios({
    method: "GET",
    url: `${BASE_URL}/invest/${address}:tokensGet`,
  });
});

const updateCartTokens = createAsyncThunk<
  AxiosResponse,
  UpdateTokensRequestData
>("cart/updateTokens", async (data) => {
  const { address, tokens, authToken } = data;
  return axios({
    method: "POST",
    url: `${BASE_URL}/invest/${address}:tokensUpdate`,
    data: { Tokens: tokens },
    headers: { Authorization: `Bearer ${authToken}` },
  });
});

const deleteCartTokens = createAsyncThunk<
  AxiosResponse,
  DeleteTokensRequestData
>("cart/deleteTokens", async (data) => {
  const { address, authToken, ...restData } = data;
  return axios({
    method: "POST",
    url: `${BASE_URL}/invest/${address}:tokensDelete`,
    data: restData,
    headers: { Authorization: `Bearer ${authToken}` },
  });
});

const saveCartTokens = createAsyncThunk<AxiosResponse, string>(
  "cart/saveTokens",
  async (address) => {
    return axios({
      method: "POST",
      url: `${BASE_URL}/invest/${address}:tokensSave`,
    });
  },
);

const getCartHistory = createAsyncThunk<
  AxiosResponse<GetTokensResponseData>,
  string
>("cart/getHistory", async (address) => {
  return axios({
    method: "GET",
    url: `${BASE_URL}/invest/${address}:history`,
  });
});

export {
  addCartToken,
  getCartTokens,
  updateCartTokens,
  deleteCartTokens,
  saveCartTokens,
  getCartHistory,
};
