import { useCallback, useEffect, useState } from "react";
import { TokensPricesMap } from "shared/types";
import axios from "axios";

const useTokensUsdPrices = (chainId: number, tokens: string[]) => {
  const [isLoading, setIsLoading] = useState(false);
  const [pricesMap, setPricesMap] = useState<TokensPricesMap>({});

  const fetchPrices = useCallback(async () => {
    if (chainId === 0) {
      return;
    }

    setIsLoading(true);

    await axios
      .post("/token/rates/usd", {
        chainId,
        tokens,
      })
      .then((res) => {
        const pricesWithSources = res.data.rates;
        setPricesMap(pricesWithSources);
      })
      .catch((err) => {
        console.error("Error fetching token prices:", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [chainId, setPricesMap, tokens, setIsLoading]);

  useEffect(() => {
    fetchPrices();
  }, [fetchPrices]);

  return {
    pricesMap,
    isLoadingPrices: isLoading,
  };
};

export { useTokensUsdPrices };
