import { setupNetwork } from "utils/setupNetwork";
import { useTranslation } from "react-i18next";
import { AvailableSetup } from "shared/types";
import React, { useCallback, useMemo } from "react";
import { GetNetworkReturnType, SwitchModalProps } from "./types";
import Styled from "./styled";
import { ROUTE_PATHS } from "shared/constants";
import { useHistory } from "react-router";
import SUPPORTED_CHAIN_IDS from "shared/constants/supportedChainIds";
import { useLocation } from "react-router-dom";

const SwitchModal = ({ toNetwork = "bsc" }: SwitchModalProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  const getNetwork = useCallback(
    (setup: AvailableSetup): GetNetworkReturnType => {
      switch (setup) {
        case "eth":
          return {
            label: "Ethereum",
            chainId: 1,
            setup,
          };
        case "bsc":
          return {
            label: t("NETWORKS-bsc"),
            chainId: 56,
            setup,
          };
        case "polygon":
          return {
            label: "Polygon",
            chainId: 137,
            setup,
          };
        case "avalanche":
          return {
            label: t("NETWORKS-avalanche"),
            chainId: 43114,
            setup,
          };
        case "optimism":
          return {
            label: t("NETWORKS-optimism"),
            chainId: 10,
            setup,
          };
        case "arbitrum":
          return {
            label: t("NETWORKS-arbitrum"),
            chainId: 42161,
            setup,
          };
        case "aurora":
          return {
            label: t("NETWORKS-aurora"),
            chainId: 1313161554,
            setup,
          };
        case "bitgert":
          return {
            label: t("NETWORKS-bitgert"),
            chainId: 32520,
            setup,
          };
        case "zksyncera":
          return {
            label: t("NETWORKS-zksyncera"),
            chainId: 324,
            setup,
          };
        case "gnosis":
          return {
            label: t("NETWORKS-gnosis"),
            chainId: 100,
            setup,
          };
        case "linea":
          return {
            label: t("NETWORKS-linea"),
            chainId: SUPPORTED_CHAIN_IDS.LINEA,
            setup,
          };
        case "base":
          return {
            label: t("NETWORKS-base"),
            chainId: SUPPORTED_CHAIN_IDS.BASE,
            setup,
          };
        case "opbnb":
          return {
            label: t("NETWORKS-opbnb"),
            chainId: SUPPORTED_CHAIN_IDS.OP_BNB,
            setup,
          };
        case "scroll":
          return {
            label: t("NETWORKS-scroll"),
            chainId: SUPPORTED_CHAIN_IDS.SCROLL,
            setup,
          };
        case "blast":
          return {
            label: t("NETWORKS-blast"),
            chainId: SUPPORTED_CHAIN_IDS.BLAST,
            setup,
          };
      }
    },
    [t],
  );

  const networks = useMemo(() => {
    if (typeof toNetwork === "string") {
      return [getNetwork(toNetwork)];
    } else if (toNetwork?.length) {
      return toNetwork.map((setup) => getNetwork(setup));
    }
  }, [getNetwork, toNetwork]);

  const redirectBack = () => history.push(ROUTE_PATHS.DASHBOARD);

  const PageNames: { [key: string]: string } = {
    "/dca": "DCA",
    "/invest": "Marketplace",
    "/": "Dashboard",
    "/exchange": "Exchange",
    "/bridges": "Bridges",
    "/transactions": "History",
    "/nft": "NFT",
  };

  const location = useLocation();
  const currentPage = PageNames[location.pathname] || "";

  return (
    <Styled.Wrapper onClose={redirectBack}>
      <Styled.Content>
        <Styled.Subtitle>
          «{currentPage}» does not work with the current network!
        </Styled.Subtitle>
        <Styled.Text>Please switch to one of the networks below:</Styled.Text>
        <Styled.Box>
          {networks?.map((newNetwork) => (
            <Styled.Network
              key={newNetwork.chainId}
              onClick={() => {
                setupNetwork(newNetwork.setup);
              }}
            >
              <Styled.NetworkIcon chainId={newNetwork.chainId} />
              <Styled.NetworkName>{newNetwork.label}</Styled.NetworkName>
            </Styled.Network>
          ))}
        </Styled.Box>
      </Styled.Content>
    </Styled.Wrapper>
  );
};

export default SwitchModal;
