import { GlobalState } from "./types";

const reducers = {
  setMobileMenuOpen(state: GlobalState, action: { payload: boolean }) {
    state.mobileMenuOpen = action.payload;
  },

  setSidebarShrunk(state: GlobalState, action: { payload: boolean }) {
    state.sidebarShrunk = action.payload;
  },
};

export default reducers;
