export enum ROUTE_PATHS {
  DASHBOARD = "/",
  SAVE = "/save",
  SAVE_VENUS = "/save/venus",
  SAVE_BEEFY = "/save/beefy",
  SAVE_ANALYSIS = "/save/analysis",
  SAVE_BEEFY_STAKE = "/save/beefy/stake",
  SAVE_BEEFY_STAKE_POOL = "/save/beefy/stake/pool/:id",
  SAVE_BEEFY_VAULT = "/save/beefy/vault/:id",
  EXCHANGE = "/exchange",
  ROOBEE_BRIDGE = "/bridges/roobee-bridge",
  BROOBEE_ROOBEE_BSC_BRIDGE = "/bridges/broobee-roobee-bsc-bridge",
  BINANCE_BRIDGE = "/binance-bridge",
  FARM = "/farm",
  TRANSACTIONS = "/transactions",
  TRANSACTIONS_DETAILS = "/transactions/details/:id",
  INVEST = "/invest",
  INVEST_TOKEN = "/invest/:symbol",
  INVEST_BUY = "/invest/buy/:symbol",
  LIQUIDITY = "/liquidity",
  LIQUIDITY_ACTION = "/liquidity/action",
  LIQUIDITY_ABOUT = "/liquidity/:id",
  LIQUIDITY_INCREASE = "/liquidity/increase/:id",
  LIQUIDITY_DELETE = "/liquidity/delete/:id",
  REWARDS = "/rewards",
  DCA_TEST = "/dca_test",
  QUESTS = "/quests",
  NOTIFICATIONS = "/notifications",
  DCA = "/dca",
  DCA_ACTION = "/dca/action",
  CART = "/cart",
  BRIDGES = "/bridges",
  LEADERBOARD = "/leaderboard",
  PAGENOTFOUND = "*",
  NFT = "/nft",
}
