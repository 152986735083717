import styled from "styled-components";
import GradientButton from "shared/styles/elements/GradientButton";
import { default as NotStyledWalletIcon } from "./_resources/WalletIcon";

const Wrapper = styled(GradientButton)`
  padding: 11px 15px;
  width: 100%;
  max-width: 252px;
  @media (max-width: 600px) {
    max-width: 161px;
    padding: 8px 16px;
  }
`;

const Icon = styled(NotStyledWalletIcon)`
  margin-right: 10px;
  width: 24px;
  height: 24px;
  z-index: 2;
  @media (max-width: 600px) {
  }
`;

const Text = styled.span`
  z-index: 2;
  font-weight: 400;
  font-size: 14px;
  line-height: 145%;
  @media (max-width: 600px) {
    font-size: 12px;
  }
`;

export default {
  Wrapper,
  Icon,
  Text,
};
