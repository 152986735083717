import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Parser from "html-react-parser";
import Styled from "./Styled";

const CookiesPrivacyPolicy = () => {
  const [cookiesPrivacyPolicyAccepted, setCookiesPrivacyAccepted] = useState(
    localStorage.getItem("cookies_accepted") === "true",
  );

  const onAccept = () => {
    setCookiesPrivacyAccepted(true);
    localStorage.setItem("cookies_accepted", "true");
  };

  const { t } = useTranslation();

  return (
    <Styled.Wrapper active={!cookiesPrivacyPolicyAccepted}>
      <Styled.Container>
        <Styled.Text>{Parser(t("COOKIES_PRIVACY_POLICY-text"))}</Styled.Text>
        <Styled.Button onClick={onAccept}>{t("BUTTONS-accept")}</Styled.Button>
      </Styled.Container>
    </Styled.Wrapper>
  );
};

export default CookiesPrivacyPolicy;
