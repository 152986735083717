import { AirdropState } from "./types";

const reducers = {
  setRoobeeBalance: (state: AirdropState, action: { payload: any }) => {
    state.roobeeBalance = action.payload;
  },
  setStakedRoobee: (state: AirdropState, action: { payload: any }) => {
    state.stakedRoobee = action.payload;
  },
  setStakingApy: (state: AirdropState, action: { payload: any }) => {
    state.stakingApy = action.payload;
  },
  setRewards: (state: AirdropState, action: { payload: any }) => {
    state.rewards = action.payload;
  },
  setLastClaimedRewardAmount: (
    state: AirdropState,
    action: { payload: any },
  ) => {
    state.lastClaimedRewardAmount = action.payload;
  },
  setShowConfetti: (state: AirdropState, action: { payload: any }) => {
    state.showConfetti = action.payload;
  },
};

export default reducers;
