import React from "react";

const TransactionIcon = ({ ...props }) => (
  <svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.66406 5.32812V8.46146C8.66406 8.57192 8.75361 8.66146 8.86406 8.66146H11.3307"
      stroke="black"
      strokeLinecap="round"
    />
    <path
      d="M6.39519 13.9068C7.78543 14.4409 9.31944 14.4681 10.7277 13.9834C12.136 13.4987 13.3284 12.5333 14.0954 11.2567C14.8625 9.98005 15.1551 8.47396 14.9218 7.00301C14.6886 5.53206 13.9445 4.19035 12.8202 3.21362C11.6958 2.2369 10.2633 1.68765 8.77415 1.66237C7.28505 1.63709 5.83466 2.13741 4.67784 3.07541C3.52102 4.01341 2.73176 5.32909 2.44875 6.79128C2.16573 8.25347 2.40707 9.76863 3.13035 11.0705L3.4102 11.5679"
      stroke="black"
      strokeLinecap="round"
    />
    <path
      d="M0.53125 10.6328L3.53676 11.9837C3.64648 12.033 3.77487 11.9731 3.80959 11.8564L4.76069 8.66059"
      stroke="black"
      strokeLinecap="round"
    />
  </svg>
);

export default TransactionIcon;
