import { VenusState } from "./types";
import { createSlice } from "@reduxjs/toolkit";
import reducers from "./reducers";
import extraReducers from "./extraReducers";

const initialState: VenusState = {
  // these are Venus assets (vBNB, vUSDT, etc.)
  assets: [
    {
      symbol: "XVS",
      addressMainnet: "0xcf6bb5389c92bdda8a3747ddb454cb7a64626c63",
      venusContract: "0x151b1e2635a717bcdc836ecd6fbb62b674fe3e1d",
    },
    {
      symbol: "BNB",
      addressMainnet: "0xa07c5b74c9b40447a954e1466938b865b6bbea36", // 0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c
      venusContract: "0xa07c5b74c9b40447a954e1466938b865b6bbea36",
    },
    {
      symbol: "DOGE",
      isSvg: true,
      addressMainnet: "0xba2ae424d960c26247dd6c32edc70b295c744c43",
      venusContract: "0xec3422ef92b2fb59e84c8b02ba73f1fe84ed8d71",
    },
    {
      symbol: "MATIC",
      isSvg: true,
      addressMainnet: "0xcc42724c6683b7e57334c4e856f4c9965ed682bd",
      venusContract: "0x5c9476fcd6a4f9a3654139721c949c2233bbbbc8",
    },
    {
      symbol: "CAKE",
      addressMainnet: "0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82",
      venusContract: "0x86ac3974e2bd0d60825230fa6f355ff11409df5c",
    },
    {
      symbol: "AAVE",
      addressMainnet: "0xfb6115445bff7b52feb98650c87f44907e58f802",
      venusContract: "0x26da28954763b92139ed49283625cecaf52c6f94",
    },
    {
      symbol: "BUSD",
      addressMainnet: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
      venusContract: "0x95c78222b3d6e262426483d42cfa53685a67ab9d",
    },
    {
      symbol: "USDT",
      addressMainnet: "0x55d398326f99059fF775485246999027B3197955",
      venusContract: "0xfd5840cd36d94d7229439859c0112a4185bc0255",
    },
    {
      symbol: "USDC",
      addressMainnet: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
      venusContract: "0xeca88125a5adbe82614ffc12d0db554e2e2867c8",
    },
    {
      symbol: "BTCB",
      addressMainnet: "0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c",
      venusContract: "0x882c173bc7ff3b7786ca16dfed3dfffb9ee7847b",
    },
    {
      symbol: "ETH",
      addressMainnet: "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
      venusContract: "0xf508fcd89b8bd15579dc79a6827cb4686a3592c8",
    },
    {
      symbol: "LTC",
      addressMainnet: "0x4338665cbb7b2485a8855a139b75d5e34ab0db94",
      venusContract: "0x57a5297f2cb2c0aac9d554660acd6d385ab50c6b",
    },
    {
      symbol: "SXP",
      addressMainnet: "0x47bead2563dcbf3bf2c9407fea4dc236faba485a",
      venusContract: "0x2ff3d0f6990a40261c66e1ff2017acbc282eb6d0",
    },
    {
      symbol: "XRP",
      addressMainnet: "0x1d2f0da169ceb9fc7b3144628db156f3f6c60dbe",
      venusContract: "0xb248a295732e0225acd3337607cc01068e3b9c10",
    },
    {
      symbol: "BCH",
      addressMainnet: "0x8ff795a6f4d97e7887c79bea79aba5cc76444adf",
      venusContract: "0x5f0388ebc2b94fa8e123f404b79ccf5f40b29176",
    },
    {
      symbol: "DOT",
      addressMainnet: "0x7083609fce4d1d8dc0c979aab8c869ea2c873402",
      venusContract: "0x1610bc33319e9398de5f57b33a5b184c806ad217",
    },
    {
      symbol: "LINK",
      addressMainnet: "0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd",
      venusContract: "0x650b940a1033b8a1b1873f78730fcfc73ec11f1f",
    },
    {
      symbol: "DAI",
      addressMainnet: "0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3",
      venusContract: "0x334b3ecb4dca3593bccc3c7ebd1a1c1d1780fbf1",
    },
    {
      symbol: "FIL",
      addressMainnet: "0x0D8Ce2A99Bb6e3B7Db580eD848240e4a0F9aE153",
      venusContract: "0xf91d58b5ae142dacc749f58a49fcbac340cb0343",
    },
    {
      symbol: "BETH",
      addressMainnet: "0x250632378e573c6be1ac2f97fcdf00515d0aa91b",
      venusContract: "0x972207a639cc1b374b893cc33fa251b55ceb7c07",
    },
    {
      symbol: "ADA",
      addressMainnet: "0x3ee2200efb3400fabb9aacf31297cbdd1d435d47",
      venusContract: "0x9a0af7fdb2065ce470d72664de73cae409da28ec",
    },
  ],
};

export const venusSlice = createSlice({
  name: "venus",
  initialState,
  reducers,
  extraReducers,
});

export default venusSlice.reducer;
export const { setAssets, setApproving } = venusSlice.actions;
