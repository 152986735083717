import styled from "styled-components";

export const WidgetBox = styled.div`
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  opacity: 0.5;
  background: ${(props) => props.theme.Colors.BackgroundGray10};
  transition: opacity 0.8s ease;
  @media (max-width: 1350px) {
    width: 100%;
    height: 100%;
    margin-bottom: 0;
  }
`;
