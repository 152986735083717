import styled from "styled-components";
import Modal from "components/Modal";
import ModalStyled from "components/Modal/styled";
import GradientButton from "shared/styles/elements/GradientButton";
import { default as NotStyledButton } from "shared/styles/elements/Button";
import { default as NotStyledWarningIcon } from "./_resources/WarningIcon";

const Wrapper = styled(Modal)`
  ${ModalStyled.Card} {
    width: 500px;
    max-height: 247px;
    height: 100%;
    @media (max-width: 600px) {
      width: 300px;
      max-height: 343px;
      min-height: 343px;
    }
  }
  ${ModalStyled.Close} {
    svg {
      width: 12px;
      height: 12px;
    }
  }
`;

const Top = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #c2c2c2;
`;

const WarningIcon = styled(NotStyledWarningIcon)``;

const Title = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 155%;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  margin-left: 16px;
  color: ${(props) => props.theme.Colors.Red};
`;

const Content = styled.div`
  padding: 20px 20px 30px;
  @media (max-width: 600px) {
    padding-bottom: 40px;
  }
`;

const ContentTitle = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  text-align: center;
  margin-bottom: 14px;
`;

const ContentText = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: ${(props) => props.theme.Colors.MG48};
`;

const Buttons = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 600px) {
    flex-direction: column-reverse;
  }
`;

const Button = styled(GradientButton)`
  padding: 12px 20px;
  font-size: 16px;
  line-height: 100%;
  min-width: 149px;
`;

const CancelButton = styled(NotStyledButton)`
  padding: 12px 20px;
  font-size: 16px;
  line-height: 100%;
  min-width: 149px;
  margin-right: 20px;

  @media (max-width: 600px) {
    margin-right: 0;
    margin-top: 10px;
  }
`;

export default {
  Wrapper,
  Top,
  WarningIcon,
  Title,
  Content,
  ContentTitle,
  ContentText,
  Buttons,
  Button,
  CancelButton,
};
