import type { NetworkSetupConfig } from "../types";

export const networkSetupConfigs: {
  eth: NetworkSetupConfig;
  bsc: NetworkSetupConfig;
  polygon: NetworkSetupConfig;
  optimism: NetworkSetupConfig;
  arbitrum: NetworkSetupConfig;
  avalanche: NetworkSetupConfig;
  aurora: NetworkSetupConfig;
  bitgert: NetworkSetupConfig;
  zksyncera: NetworkSetupConfig;
  gnosis: NetworkSetupConfig;
  linea: NetworkSetupConfig;
  base: NetworkSetupConfig;
  scroll: NetworkSetupConfig;
  opbnb: NetworkSetupConfig;
  blast: NetworkSetupConfig;
} = {
  eth: {
    chainId: "0x1",
    // chainIdNumber: 1,
    chainName: "Ethereum Mainnet",
    nativeCurrency: {
      name: "Ether",
      symbol: "ETH",
      decimals: 18,
      scanAddress: "-",
    },
    // TODO(Nikita): Change it to our node's rpcUrl
    rpcUrls: ["http://10.2.0.19:8545"],
    blockExplorerUrls: ["https://etherscan.io/"],
  },
  bsc: {
    chainId: "0x38",
    // chainIdNumber: 56,
    chainName: "Binance Smart Chain Mainnet",
    nativeCurrency: {
      name: "Binance Smart Chain Native Token",
      symbol: "bnb",
      decimals: 18,
      scanAddress: "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c",
    },
    rpcUrls: ["https://bsc-dataseed.binance.org/"],
    blockExplorerUrls: ["https://bscscan.com/"],
  },
  polygon: {
    chainId: "0x89",
    // chainIdNumber: 137,
    chainName: "Polygon Mainnet",
    nativeCurrency: {
      name: "Matic",
      symbol: "MATIC",
      decimals: 18,
      scanAddress: "-",
    },
    rpcUrls: ["https://rpc-mainnet.maticvigil.com/"],
    blockExplorerUrls: ["https://polygonscan.com/"],
  },
  optimism: {
    chainId: "0xA",
    // chainIdNumber: 10,
    chainName: "Optimistic Mainnet",
    nativeCurrency: {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
      scanAddress: "0x4200000000000000000000000000000000000006",
    },
    rpcUrls: ["https://mainnet.optimism.io/"],
    blockExplorerUrls: ["https://optimistic.etherscan.io/"],
  },
  arbitrum: {
    chainId: "0xA4B1",
    // chainIdNumber: 42161,
    chainName: "Arbitrum Mainnet",
    nativeCurrency: {
      name: "Arbitrum Ethereum",
      symbol: "ETH",
      decimals: 18,
      scanAddress: "-",
    },
    rpcUrls: ["https://arb1.arbitrum.io/rpc"],
    blockExplorerUrls: ["https://arbiscan.io/"],
  },
  avalanche: {
    chainId: "0xA86A",
    // chainIdNumber: 43114,
    chainName: "Avalanche Mainnet",
    nativeCurrency: {
      name: "Avalanche",
      symbol: "AVAX",
      decimals: 18,
      scanAddress: "-",
    },
    rpcUrls: ["https://api.avax.network/ext/bc/C/rpc"],
    blockExplorerUrls: ["https://snowtrace.io/"],
  },
  aurora: {
    chainId: "0x4e454152",
    // chainIdNumber: 1313161554,
    chainName: "Aurora Mainnet",
    nativeCurrency: {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
      scanAddress: "-",
    },
    rpcUrls: ["https://mainnet.aurora.dev"],
    blockExplorerUrls: ["https://aurorascan.dev/"],
  },
  bitgert: {
    chainId: "0x7F08",
    // chainIdNumber: 32520,
    chainName: "Bitgert Mainnet",
    nativeCurrency: {
      name: "Brise",
      symbol: "BRISE",
      decimals: 18,
      scanAddress: "-",
    },
    rpcUrls: ["https://rpc.icecreamswap.com"],
    blockExplorerUrls: ["https://brisescan.com/"],
  },
  zksyncera: {
    chainId: "0x144",
    chainName: "zkSync Era",
    nativeCurrency: {
      name: "Ether",
      symbol: "ETH",
      decimals: 18,
      scanAddress: "-",
    },
    rpcUrls: ["https://mainnet.era.zksync.io"],
    blockExplorerUrls: ["https://explorer.zksync.io"],
  },
  gnosis: {
    chainId: "0x64",
    chainName: "Gnosis",
    nativeCurrency: {
      name: "xDai",
      symbol: "xDAI",
      decimals: 18,
      scanAddress: "-",
    },
    rpcUrls: ["https://rpc.gnosis.gateway.fm"],
    blockExplorerUrls: ["https://gnosisscan.io/"],
  },
  linea: {
    chainId: "0xe708",
    chainName: "Linea",
    nativeCurrency: {
      name: "Ether",
      symbol: "ETH",
      decimals: 18,
      scanAddress: "-",
    },
    rpcUrls: ["https://rpc.linea.build"],
    blockExplorerUrls: ["https://lineascan.build/"],
  },
  opbnb: {
    chainId: "0xCC",
    chainName: "opBNB",
    nativeCurrency: {
      name: "Bnb",
      symbol: "BNB",
      decimals: 18,
      scanAddress: "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c",
    },
    rpcUrls: ["https://opbnb-mainnet-rpc.bnbchain.org"],
    blockExplorerUrls: ["https://opbnbscan.com/"],
  },
  scroll: {
    chainId: "0x82750",
    chainName: "Scroll",
    nativeCurrency: {
      name: "Eth",
      symbol: "ETH",
      decimals: 18,
      scanAddress: "0x5300000000000000000000000000000000000004",
    },
    // icon: <NetworkIcon chainId={SUPPORTED_CHAIN_IDS.BITGERT} />,
    rpcUrls: ["https://scroll.blockpi.network/v1/rpc/public"],
    blockExplorerUrls: ["https://scrollscan.com/"],
  },
  base: {
    chainId: "0x2105",
    chainName: "Base",
    nativeCurrency: {
      name: "Eth",
      symbol: "ETH",
      decimals: 18,
      scanAddress: "0x4200000000000000000000000000000000000006",
    },
    // icon: <NetworkIcon chainId={SUPPORTED_CHAIN_IDS.BITGERT} />,
    rpcUrls: ["https://mainnet.base.org"],
    blockExplorerUrls: ["https://basescan.org/"],
  },
  blast: {
    chainId: "0x13e31",
    chainName: "Blast",
    nativeCurrency: {
      name: "Eth",
      symbol: "ETH",
      decimals: 18,
      scanAddress: "0x4300000000000000000000000000000000000004",
    },
    // icon: <NetworkIcon chainId={SUPPORTED_CHAIN_IDS.BITGERT} />,
    rpcUrls: ["https://rpc.blast.io"],
    blockExplorerUrls: ["https://blastscan.io"],
  },
};
