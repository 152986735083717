import styled, { css } from "styled-components";

const GradientButton = styled.button`
  color: ${(props) => props.theme.Colors.Black};
  border-radius: 10px;
  z-index: 1;
  position: relative;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  &:before {
    position: absolute;
    content: "";
    top: 0;
    border-radius: 10px;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #b6e82f 0%, #50e0ed 100%);
    transition: opacity 0.3s ease;
    opacity: 1;
  }

  &:hover {
    &:before {
      opacity: 0.5;
    }
  }
  ${(props) =>
    props.disabled &&
    css`
      background-image: none !important;
      box-shadow: none !important;
      filter: none !important;
      cursor: default;
      border: 1px solid ${props.theme.Colors.MG24} !important;
      color: ${props.theme.Colors.MG24} !important;
      &:before {
        display: none;
      }
    `};
`;

export default GradientButton;
