import React, { FC } from "react";
import { useConnect } from "shared/hooks";
import Styled from "./styled";
import { useTranslation } from "react-i18next";

type DisconnectModalTypes = {
  onClose: () => void;
};

const DisconnectModal: FC<DisconnectModalTypes> = ({ onClose }) => {
  const { disconnect } = useConnect();

  const { t } = useTranslation();

  return (
    <Styled.Wrapper
      onClose={onClose}
      pretitleContent={
        <Styled.Top>
          <Styled.WarningIcon />
          <Styled.Title>{t("COMMON-warning")}</Styled.Title>
        </Styled.Top>
      }
    >
      <Styled.Content>
        <Styled.ContentTitle>
          {t("DISCONNECT-MODAL_are_you_sure")}
        </Styled.ContentTitle>
        <Styled.ContentText>{t("DISCONNECT-MODAL_text")}</Styled.ContentText>
        <Styled.Buttons>
          <Styled.CancelButton onClick={onClose}>
            {t("BUTTONS-cancel")}
          </Styled.CancelButton>
          <Styled.Button onClick={disconnect}>
            {t("BUTTONS-continue")}
          </Styled.Button>
        </Styled.Buttons>
      </Styled.Content>
    </Styled.Wrapper>
  );
};

export default DisconnectModal;
