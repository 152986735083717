import styled, { css } from "styled-components";
import { default as NotStyledCopyIcon } from "./_resources/CopyIcon";

type WalletAddressTypes = {
  $active?: boolean;
};

const CopyIcon = styled(NotStyledCopyIcon)``;

const Wrapper = styled.div<WalletAddressTypes>`
  display: flex;
  align-items: center;
  background: transparent;
  border: 1px solid ${(props) => props.theme.Colors.MG24};
  border-radius: 10px;
  padding: 5px 12px 5px 7px;
  @media (max-width: 600px) {
    padding: 8px 6px 8px 12px;
    justify-content: space-between;
  }
  ${(props) =>
    props.$active &&
    css`
      ${CopyIcon} {
        cursor: pointer;
      }
    `}
`;

const WalletIcon = styled.img`
  width: 30px;
  height: 30px;
  cursor: pointer;
`;

const WalletAddress = styled.div`
  max-width: 116px;
  width: 100%;
  margin: 0 auto 0 8px;
  cursor: default;
  font-weight: 400;
  font-size: 12px;
  line-height: 145%;
  color: ${(props) => props.theme.Colors.Black};
`;

export default {
  Wrapper,
  WalletIcon,
  WalletAddress,
  CopyIcon,
};
