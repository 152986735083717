import styled, { css } from "styled-components";
import { default as NotStyledCloseIcon } from "./_resources/CloseIcon";
import { default as NotStyledArrowIcon } from "./_resources/ArrowIcon";
import { Link } from "react-router-dom";
import { default as NotStyledBuyIcon } from "./_resources/BuyIcon";
import { CustomNetworkScrollbar, CustomScrollbar } from "shared/styles/Mixins";
import Button from "shared/styles/elements/Button";

type ArrowIconTypes = {
  rotate?: boolean;
};

const Wrapper = styled.div<{ $disabled?: boolean }>`
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  min-width: 0;
  transition:
    color,
    border,
    box-shadow,
    filter 0.3s ease;
  padding: 20px;

  @media (max-width: 1350px) {
    overflow-y: auto;
    height: 100%;
    width: 100%;
    ${CustomScrollbar};
  }

  @media (max-width: 600px) {
    position: fixed;
    height: unset;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 40px);
    z-index: 100001;
    border-radius: 0;
    border: unset;
    cursor: pointer;
  }

  ${(props) =>
    props.$disabled &&
    css`
      display: none;
    `}
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding-bottom: 11px;
  border-bottom: 1px solid #d8e4eb;

  @media (max-width: 600px) {
    display: none;
  }
`;

const Tag = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #000000;
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  @media (max-width: 600px) {
    grid-column-gap: 17px;
  }
`;

const TokenBox = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 600px) {
    grid-column-gap: 11px;
  }
`;

const CloseIcon = styled(NotStyledCloseIcon)`
  margin-right: 10px;
  cursor: pointer;
  min-height: 12px;
  min-width: 12px;
  @media (max-width: 600px) {
    margin-right: 0;
  }
`;

const TokenName = styled.div`
  font-weight: 400;
  font-size: 14px;
  display: flex;
  align-items: flex-end;
  color: #000000;
`;

const TokenIcon = styled.img`
  max-width: 30px;
  max-height: 30px;
  margin-left: 10px;
  @media (max-width: 600px) {
    margin-left: 0;
  }
`;

const InfoLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 120px;
  margin: 30px auto 0;
  padding: 10px 16px;
  font-size: 14px;
  line-height: 145%;
  color: ${(props) => props.theme.Colors.Black};
  border: solid 1px transparent;
  //background-image: #a7dd12;
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #a7dd12 inset;
  border-radius: 10px;
  transition:
    color,
    border,
    box-shadow,
    filter 0.3s ease;

  @media (max-width: 1350px) {
    margin-top: auto;
  }

  &:hover {
    color: ${(props) => props.theme.Colors.Black};
    filter: drop-shadow(2px 2px 6px rgba(0, 0, 0, 0.35));
    border: 1px solid transparent;
    //border-image: #a7dd12;
    position: relative;
    border-radius: 10px;
    //background-image: #a7dd12;
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px #a7dd12 inset;
  }

  @media (max-width: 600px) {
    margin: 0;
    max-height: 32px;
    width: 50%;
    max-width: 100%;
    padding: 4px 11px;
  }
`;

const InfoButton = styled.button`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 85%;
  text-align: center;
  color: #000000;
  display: flex;
  align-items: center;
  @media (max-width: 600px) {
    font-size: 14px;
  }
`;

const BuyIcon = styled(NotStyledBuyIcon)`
  margin-right: 5px;
`;

const CartButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column-gap: 22px;
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  @media (max-width: 600px) {
    max-width: 100%;
    width: 100%;
    margin-top: 20px;
  }
`;

const HideBtn = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  max-width: fit-content;
  font-size: 14px;
  cursor: pointer;
  line-height: 145%;
  width: 50%;

  @media (max-width: 600px) {
    width: 50%;
    padding: 4px 11px;
    max-width: 100%;
  }
`;

const TokensAmountWrapper = styled.div`
  border-radius: 30px;
  border: 1px solid #fff500;
  padding: 5px;
  display: flex;
  max-width: 241px;
  width: 100%;
  margin: 15px auto 0;
  justify-content: center;
  align-items: center;
  grid-column-gap: 10px;
`;

const CartIcon = styled.div``;

const TokensAmount = styled.div``;

const CartAmountNumber = styled.span`
  font-weight: 400;
`;

const CartAmount = styled.div`
  color: #000;
  font-size: 14px;
  font-weight: 300;
  line-height: 145%;
  border-bottom: 1px solid #c2c2c2;
  padding-bottom: 7px;
  margin-bottom: 16px;
`;

const ArrowIcon = styled(NotStyledArrowIcon)<ArrowIconTypes>`
  display: inline-block;
  margin-left: auto;
  transition: all 0.3s ease;

  ${(props) =>
    props.rotate &&
    css`
      transform: rotate(180deg);
    `}
`;

const Box = styled.div`
  max-height: 160px;
  padding-right: 17px;
  overflow-y: auto;
  ${CustomNetworkScrollbar};
`;

export default {
  Wrapper,
  Top,
  Tag,
  Content,
  TokenBox,
  CloseIcon,
  TokenName,
  TokenIcon,
  InfoButton,
  InfoLink,
  BuyIcon,
  CartButtons,
  HideBtn,
  TokensAmountWrapper,
  CartIcon,
  TokensAmount,
  ArrowIcon,
  CartAmount,
  CartAmountNumber,
  Box,
};
