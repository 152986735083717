import React, { FC } from "react";
import Styled from "./styled";
import { useAuthorizeModal } from "../AuthorizeModal";
import { useTranslation } from "react-i18next";
import { UnlockWalletButtonTypes } from "./types";

const UnlockWalletButton: FC<UnlockWalletButtonTypes> = ({ className }) => {
  const { showModal } = useAuthorizeModal();
  const { t } = useTranslation();

  return (
    <Styled.Wrapper className={className} onClick={() => showModal()}>
      <Styled.Icon />
      <Styled.Text>{t("BUTTONS-unlock_wallet")}</Styled.Text>
    </Styled.Wrapper>
  );
};

export default UnlockWalletButton;
