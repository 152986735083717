import {
  BNB,
  ETH_ARBITRUM,
  ETH_MAINNET,
  ETH_OPTIMISTIC,
  MATIC,
  USDT_BSC,
  USDT_MAINNET,
  USDT_OPTIMISM,
  USDT_POLYGON,
} from "shared/constants";
import SUPPORTED_CHAIN_IDS from "shared/constants/supportedChainIds";

export const UNISWAP_V3_FEE_AMOUNTS = [500, 3000, 10000];

export const PAY_TOKENS = {
  [SUPPORTED_CHAIN_IDS.ETH]: [ETH_MAINNET, USDT_MAINNET],
  [SUPPORTED_CHAIN_IDS.BSC]: [USDT_BSC, BNB],
  [SUPPORTED_CHAIN_IDS.OPTIMISM]: [USDT_OPTIMISM, ETH_OPTIMISTIC],
  [SUPPORTED_CHAIN_IDS.POLYGON]: [USDT_POLYGON, MATIC],
  [SUPPORTED_CHAIN_IDS.ARBITRUM]: [USDT_POLYGON, ETH_ARBITRUM],
};
