import { ROUTE_PATHS } from "shared/constants/routePaths";
import { SidebarNavItemType } from "./types";

const SIDEBAR_NAV: SidebarNavItemType[] = [
  {
    iconName: "comments",
    title: "Dashboard",
    path: `${ROUTE_PATHS.DASHBOARD}`,
  },
  {
    iconName: "comments",
    title: "DCA",
    path: `${ROUTE_PATHS.DCA}`,
    update: "new!",
  },
  {
    iconName: "comments",
    title: "Marketplace",
    path: `${ROUTE_PATHS.INVEST}`,
  },
  // {
  //   iconName: 'comments',
  //   title: 'Farm',
  //   path: `${ROUTE_PATHS.FARM}`,
  // },
  // {
  //   iconName: "clock",
  //   title: "History",
  //   path: `${ROUTE_PATHS.TRANSACTIONS}`,
  // },
  {
    iconName: "comments",
    title: "Exchange",
    path: `${ROUTE_PATHS.EXCHANGE}`,
  },
  {
    iconName: "dice",
    title: "Bridges",
    path: `${ROUTE_PATHS.BRIDGES}`,
  },
  // {
  //   iconName: "dice",
  //   title: "Liquidity",
  //   path: `${ROUTE_PATHS.LIQUIDITY}`,
  // },
  // {
  //   iconName: "comments",
  //   title: "Save",
  //   path: `${ROUTE_PATHS.SAVE}`,
  // },
  {
    iconName: "comments",
    title: "Claim NFT",
    path: `${ROUTE_PATHS.NFT}`,
  },
  {
    iconName: "comments",
    title: "DAO",
    path: "https://dao.roobee.finance/voting",
  },
  {
    iconName: "comments",
    title: "FAQ",
    path: "https://roobee.gitbook.io/roobee-eng/the-roobee-token/key-information",
  },
];

export { SIDEBAR_NAV };
