import React, { useCallback, useContext, useState } from "react";
import AirdropModal from "./AirdropModal";
import { useAppDispatch } from "store";
import { setShowConfetti } from "store/airdrop";

export type StageId = "primary" | "details" | "success";

type Props = {
  children: React.ReactNode;
};

const AirdropModalContext = React.createContext<{
  showAirdrop: (stage: StageId, showConfetti?: boolean) => void;
  hideAirdrop: () => void;
  isModalVisible: boolean;
}>({
  // eslint-disable-next-line
  showAirdrop: (stage: StageId) => {},
  hideAirdrop: () => {},
  isModalVisible: false,
});

const AirdropModalProvider = ({ children }: Props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [activeStage, setActiveStage] = useState<StageId>("primary");
  const dispatch = useAppDispatch();

  const showAirdrop = useCallback(
    (stage: StageId, showConfetti?: boolean) => {
      setActiveStage(stage);
      dispatch(setShowConfetti(showConfetti || false));
      setIsModalVisible(true);
    },
    [dispatch],
  );

  const hideAirdrop = useCallback(() => {
    setIsModalVisible(false);
    dispatch(setShowConfetti(false));
  }, [dispatch]);

  return (
    <AirdropModalContext.Provider
      value={{
        showAirdrop,
        hideAirdrop,
        isModalVisible,
      }}
    >
      {isModalVisible && <AirdropModal stageId={activeStage} />}
      {children}
    </AirdropModalContext.Provider>
  );
};

const useAirdrop = () => {
  const helpers = useContext(AirdropModalContext);

  return helpers;
};

export { useAirdrop };
export default AirdropModalProvider;
