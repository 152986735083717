import React, { useCallback, useState, useMemo } from "react";
import { State } from "store";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store";
import { setLastClaimedRewardAmount } from "store/airdrop";
import { fetchGeneralBalanceInfo, fetchRewards } from "store/airdrop/thunks";
import { useWeb3React } from "@web3-react/core";
import { AIRDROP, AirdropAbi, DEFAULT_DECIMALS } from "shared/constants";
import { Loading } from "components";
import BigNumber from "bignumber.js";
import { useContract } from "shared/hooks";
import { Airdrop } from "shared/constants/abis/types";
import { useAirdrop } from "../../AirdropModalProvider";
import { displayBigNumber } from "../../displayBigNumber";
import { useTranslation } from "react-i18next";
import { ethersToBN } from "utils/ethersToBN";
import eventBus from "utils/eventBus";

import Styled from "./styled";

const DetailsContent = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { rewards } = useSelector((state: State) => state.airdrop);
  const { account, chainId, library } = useWeb3React();
  const { showAirdrop } = useAirdrop();
  const dispatch = useAppDispatch();
  const AirdropContract = useContract<Airdrop>(AirdropAbi, AIRDROP);

  const onClaim = useCallback(async () => {
    if (!library || !account) return;
    setIsLoading(true);

    const firstAvailableReward = rewards.find((reward) => !reward.isClaimed);
    if (!firstAvailableReward) return;

    // const firstUnclaimedRound = firstAvailableReward.id;

    // const { roundId, amount, signature } = await axios.get(`/airdrop/${account}/${firstUnclaimedRound}:get`).then(res => res.data);
    const { roundId, amount, signature } = {
      roundId: 0,
      amount: 0,
      signature: "0x0",
    };

    if (!roundId || !amount || !signature) return;

    const rewardAmountStr = new BigNumber(amount).toFixed();

    const gas = ethersToBN(
      await AirdropContract.estimateGas.withdraw(
        roundId,
        "0xe64f5cb844946c1f102bd25bbd87a5ab4ae89fbe".toLowerCase(),
        account,
        rewardAmountStr,
        `0x${signature}`,
      ),
    ).toFixed();

    AirdropContract.withdraw(
      roundId,
      "0xe64f5cb844946c1f102bd25bbd87a5ab4ae89fbe".toLowerCase(),
      account,
      rewardAmountStr,
      `0x${signature}`,
      {
        gasLimit: gas,
      },
    )
      .then(async () => {
        setIsLoading(false);
        dispatch(setLastClaimedRewardAmount(new BigNumber(rewardAmountStr)));
        showAirdrop("success");
        await dispatch(fetchRewards({ account, chainId, library }));
        await dispatch(fetchGeneralBalanceInfo({ account, library }));
      })
      .finally(() => {
        setIsLoading(false);
        eventBus.dispatch("update-roobee-balance");
      });
  }, [
    library,
    account,
    rewards,
    AirdropContract,
    dispatch,
    showAirdrop,
    chainId,
  ]);

  const isClaimAvailable = useMemo(() => {
    const rewardSum = rewards.reduce(
      (acc, reward) => (reward.isClaimed ? acc : acc.plus(reward.amount)),
      new BigNumber(0),
    );

    return rewardSum.isGreaterThan(0);
  }, [rewards]);

  const { t } = useTranslation();

  return (
    <Styled.Wrapper>
      {isLoading && <Loading />}
      <Styled.Description>{t("AIRDROP_MODAL-details_text")}</Styled.Description>
      {rewards.map((reward, id) => (
        <Styled.RoundWrapper key={id}>
          {reward.isClaimed && (
            <Styled.ClaimedLabel>
              {t("AIRDROP_MODAL-claimed")}
            </Styled.ClaimedLabel>
          )}
          <Styled.RewardContainer $disabled={reward.disabled}>
            <Styled.Round isClaimed={reward.isClaimed}>
              {reward.id} {t("AIRDROP_MODAL-round")}
            </Styled.Round>
            <Styled.Reward>
              <Styled.RewardAmount isClaimed={reward.isClaimed}>
                {displayBigNumber(reward.amount, DEFAULT_DECIMALS)}
              </Styled.RewardAmount>
              <Styled.RewardToken isClaimed={reward.isClaimed}>
                bROOBEE
              </Styled.RewardToken>
            </Styled.Reward>
          </Styled.RewardContainer>
        </Styled.RoundWrapper>
      ))}
      <Styled.Button onClick={onClaim} disabled={!isClaimAvailable}>
        {t("BUTTONS-claim_now")}
      </Styled.Button>
    </Styled.Wrapper>
  );
};

export default DetailsContent;
