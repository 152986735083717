import NETWORKS from "../constants/networks_new";
import Network from "../types/network";
import { useState } from "react";

type UseNetworks = {
  networksByChainId: Record<number, Network>;
  networks: Network[];
};

const useNetworks = (): UseNetworks => {
  const getNetworksByChainId = (
    networks: Network[],
  ): Record<number, Network> => {
    const networksByChainId = {};

    networks.forEach((network) => {
      networksByChainId[network.chainId] = network;
    });

    return networksByChainId;
  };

  const [networks] = useState<Network[]>(Object.values(NETWORKS));
  const [networksByChainId] = useState<Record<number, Network>>(
    getNetworksByChainId(networks),
  );

  return { networksByChainId, networks };
};

export default useNetworks;
