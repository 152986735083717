import styled, { css } from "styled-components";
import { WidgetBox } from "shared/styles/elements/Widget";
import { default as NotStyledApproximateBalance } from "app/components/ApproximateBalance";
import ApproximateBalanceStyled from "app/components/ApproximateBalance/styled";
import { default as NotStyledWalletAddress } from "app/components/WalletAddress";
import WalletAddressStyled from "app/components/WalletAddress/styled";
import { default as NotStyledAccountIcon } from "../../_resources/AccountIcon";
import { default as NotStyledNotificationIcon } from "../../_resources/NotificationIcon";
import { default as NotStyledSettingsIcon } from "../../_resources/SettingsIcon";
import { default as NotStyledAirdropIcon } from "../../_resources/Airdrop";
import { default as NotStyledLogoutIcon } from "../../_resources/LogoutIcon";

type WalletActiveTypes = {
  $active?: boolean;
};

const AccountButton = styled.button`
  width: 18px;
  height: 18px;
  cursor: default;
`;

const AccountText = styled.div`
  color: ${(props) => props.theme.Colors.Black};
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 145%;
  cursor: default;
  color: ${(props) => props.theme.Colors.Black};
`;

const ControlIcon = styled.img``;

const Wrapper = styled(WidgetBox)<WalletActiveTypes>`
  padding: 22px 20px 23px;
  margin-bottom: 20px;

  ${(props) =>
    props.$active &&
    css`
      opacity: 1;
      background: ${(props_) => props_.theme.Colors.BackgroundWhite};

      ${AccountButton} {
        cursor: pointer;
      }

      ${AccountText} {
        color: ${(props_) => props_.theme.Colors.Gray6};
      }

      ${ControlIcon} {
        cursor: pointer;
      }
    `};
  @media (max-width: 1350px) {
    margin-bottom: 0;
  }
  @media (max-width: 600px) {
    padding: 9px 12px 13px 11px;
  }
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
`;

const AccountIcon = styled(NotStyledAccountIcon)`
  width: 16px;
  height: 16px;
`;

const NotificationIcon = styled(NotStyledNotificationIcon)`
  display: block;
`;

const SettingsIcon = styled(NotStyledSettingsIcon)`
  display: block;
  cursor: pointer;
`;

const AirdropIcon = styled(NotStyledAirdropIcon)`
  display: block;
  cursor: pointer;
`;

const LogoutIconIcon = styled(NotStyledLogoutIcon)`
  display: block;
  cursor: pointer;
`;

const Balance = styled.div``;

const Account = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 8px;
  border-bottom: 1px solid #c2c2c2;
  margin-bottom: 20px;
  @media (max-width: 600px) {
    padding-bottom: 12px;
    margin-bottom: 8px;
    max-height: 30px;
  }
`;

const Middle = styled.div`
  margin-top: 20px;
  @media (max-width: 600px) {
    margin-top: 16px;
  }
`;

const Bottom = styled.div`
  margin-top: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 600px) {
    margin-top: 15px;
  }
`;

const ControlIconWrapper = styled.div<{ active?: boolean }>`
  margin-right: 36px;
  position: relative;

  &:first-child {
    opacity: 0.2;
    cursor: unset;
  }

  &:last-child {
    margin-right: 0;
  }

  &:last-child {
    cursor: pointer;
    opacity: 1;
  }

  &:hover {
    &:after {
      content: "";
      position: absolute;
      bottom: -4px;
      left: 0;
      width: 100%;
      height: 1px;
      background: linear-gradient(#b6e82f, #50e0ed);
    }
  }

  ${(props) =>
    props.active &&
    css`
      &:after {
        content: "";
        position: absolute;
        bottom: -4px;
        left: 0;
        width: 100%;
        height: 1px;
        background: linear-gradient(#b6e82f, #50e0ed);
      }
    `};

  @media (max-width: 600px) {
    margin-right: 41px;
    &:first-child {
      display: none;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`;

const ApproximateBalance = styled(NotStyledApproximateBalance)`
  ${ApproximateBalanceStyled.UserIcon} {
    width: 37px;
    height: 37px;
    margin-right: 16px;
    @media (max-width: 600px) {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }
  }

  ${ApproximateBalanceStyled.Text} {
    font-weight: 500;
    font-size: 16px;
    line-height: 145%;
  }

  ${ApproximateBalanceStyled.Box} {
    margin-right: 12px;
  }
`;

const WalletAddress = styled(NotStyledWalletAddress)`
  @media (max-width: 600px) {
    ${WalletAddressStyled.Wrapper} {
      padding: 11px 7px 11px 11px;
    }

    ${WalletAddressStyled.WalletIcon} {
      display: none;
    }

    ${WalletAddressStyled.WalletAddress} {
      margin: 0;
      font-size: 11px;
    }
  }
`;

export default {
  Wrapper,
  Top,
  AccountIcon,
  NotificationIcon,
  SettingsIcon,
  AirdropIcon,
  LogoutIconIcon,
  AccountText,
  AccountButton,
  ControlIcon,
  ControlIconWrapper,
  Balance,
  Account,
  Middle,
  Bottom,
  ApproximateBalance,
  WalletAddress,
};
