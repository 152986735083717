import { InvestState, Token } from "./types";

export const reducers = {
  setTokens(state: InvestState, action: { payload: Token[] }) {
    state.tokens = action.payload;
  },
  setPrices(state: InvestState, action: { payload: any }) {
    state.prices = action.payload;
  },
  setPreviousPrices(state: InvestState, action: { payload: any }) {
    state.previousPrices = action.payload;
  },
};
