import React, { CSSProperties, MutableRefObject } from "react";
import Styled from "./styled";

import "./styles.scss";

type Props = {
  value: string | number;
  label?: string;
  type?: string;
  disabled?: boolean;
  right_label?: JSX.Element | null | undefined | boolean;
  suffix?: JSX.Element;
  input_right?: JSX.Element | boolean;
  onChange: (value: any) => void;
  className?: string;
  onBlur?: () => void;
  white?: boolean;
  attrs?: any;
  readonly?: boolean;
  positiveOnly?: boolean;
  inputRef?: MutableRefObject<HTMLInputElement | undefined>;
  labelStyle?: CSSProperties;
};

const Input = ({
  value = "",
  label,
  type = "number",
  disabled = false,
  right_label,
  suffix,
  input_right,
  onChange,
  className,
  onBlur,
  white,
  attrs,
  readonly = false,
  positiveOnly,
  inputRef,
  labelStyle,
}: Props) => {
  return (
    <Styled.Wrapper white={white} className={className} attrs={attrs}>
      {label && (
        <Styled.CardLable style={labelStyle}>
          <span>{label}</span>
          {right_label}
        </Styled.CardLable>
      )}
      <Styled.CardInput>
        <Styled.InputBox>
          {type === "number" ? (
            // @ts-ignore
            <Styled.NumberFormat
              thousandSeparator={" "}
              // @ts-ignore
              readOnly={readonly}
              value={value}
              type={"text"}
              getInputRef={(ref) =>
                inputRef ? (inputRef.current = ref) : undefined
              }
              disabled={disabled}
              onValueChange={(values) => {
                const { value: value_ } = values;
                onChange(value_);
              }}
              allowNegative={!positiveOnly}
              className={`${
                suffix !== null && suffix !== undefined ? "input--suffix" : ""
              }`}
              onBlur={onBlur}
            />
          ) : (
            <Styled.Input
              type={type}
              readOnly={readonly}
              value={value}
              // @ts-ignore
              ref={inputRef}
              disabled={disabled}
              onChange={(e: any) => onChange(e.target.value)}
              suffix={suffix !== null && suffix !== undefined}
              onBlur={onBlur}
            />
          )}
          {suffix !== null && suffix !== undefined && (
            <Styled.Suffix>{suffix}</Styled.Suffix>
          )}
        </Styled.InputBox>
        {input_right}
      </Styled.CardInput>
    </Styled.Wrapper>
  );
};

export default Input;
