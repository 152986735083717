import React from "react";

const Note = ({ ...props }) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="19" height="19" rx="0.5" stroke="black" />
    <path
      d="M4 16H10.8571M4 4H16M4 8H16M4 12H16"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Note;
