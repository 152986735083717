import styled from "styled-components";

const Wrapper = styled.div`
  padding: 20px;
`;

const BalanceInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const LabeledAmount = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  &:last-child {
    margin-left: 92px;
  }
  @media (max-width: 600px) {
    &:last-child {
      margin-left: 0;
    }
  }
`;

const Label = styled.span`
  font-size: 12px;
  line-height: 17px;
  color: #5c5c5c;
  margin-bottom: 5px;
`;

const Amount = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  color: #000000;
`;

const AttractiveLabel = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  margin-top: 20px;
  @media (max-width: 600px) {
    max-width: 224px;
  }
`;

const AddressContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  padding: 12px;
  border: 1px solid #c2c2c2;
  border-radius: 10px;
  @media (max-width: 600px) {
    justify-content: center;
  }
`;

const AddressText = styled.span`
  font-size: 14px;
  line-height: 22px;
  color: #5c5c5c;
  @media (max-width: 600px) {
    text-align: center;
  }
`;

const Button = styled.button`
  background: linear-gradient(90deg, #b6e82f 0%, #50e0ed 100%);
  border-radius: 10px;
  padding: 10px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;
  width: 91px;
  height: 32px;
  transition: all 0.1s ease;
  &:hover {
    color: ${(props) => props.theme.Colors.Black};
    filter: drop-shadow(2px 2px 6px rgba(0, 0, 0, 0.35));
    border: 1px solid transparent;
    border-image: linear-gradient(
      90deg,
      rgba(182, 232, 47, 0.25) 0%,
      rgba(80, 224, 237, 0.25) 100%
    );
    position: relative;
    border-radius: 10px;
    background-image: linear-gradient(
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0)
      ),
      linear-gradient(101deg, #b6e82f, #50e0ed);
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px #f8f8f8 inset;
    transition: all 0.1s ease;
  }
  @media (max-width: 600px) {
    margin-top: 12px;
  }
`;

const DaoText = styled.div`
  margin-top: 20px;
  font-weight: 300;
  font-size: 12px;
  line-height: 19px;

  a {
    text-decoration: underline;
  }
  @media (max-width: 600px) {
    a {
      text-decoration: none;
      color: #2eadb9;
    }
  }
`;

const Box = styled.div`
  display: flex;
  align-items: center;
  margin-left: 92px;
  @media (max-width: 600px) {
    margin-left: 0;
    margin-top: 20px;
    width: 100%;
    padding-right: 24px;
    justify-content: space-between;
  }
`;

export default {
  Wrapper,
  BalanceInfo,
  LabeledAmount,
  Label,
  Amount,
  AttractiveLabel,
  AddressContainer,
  AddressText,
  Button,
  DaoText,
  Box,
};
