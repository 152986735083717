const Colors = {
  Gray0: "#697B8C",
  Gray1: "#8596A6",
  Gray2: "#B8C4CC",
  Gray3: "#D8E4EB",
  Gray4: "#F0F5F7",
  Gray6: "#5C5C5C",
  Gray10: "#F5F5F5",
  MG24: "#C2C2C2",
  MG32: "#ADADAD",
  MG48: "#858585",
  GreenDark2: "#00B259",
  TurquoiseDark2: "#00A8C2",
  TurquoiseDark: "#0DC4E0",
  Turquoise: "#50E0ED",
  Blue: "#545DFF",
  Red: "#FF513D",
  Green: "#B6E82F",
  Orange: "#FFBB34",
  Black: "#000000",
  White: "#FFFFFF",
  White2: "#FDFDFD",
  PrimaryBlue: "#2EADB9",
  PrimaryOrange: "#FF8B02",
  PrimaryGreen: "#A7DD12",
  BackgroundWhite: "#FFFFFF",
  BackgroundGreen: "#B6E82F",
  BackgroundOrange: "#FFBB34",
  BackgroundGray2: "#B8C4CC",
  BackgroundGray6: "#5C5C5C",
  BackgroundGray10: "#F5F5F5",
  BackgroundGray24: "#C2C2C2",
};

export default Colors;
