import React from "react";

const SwapIcon = ({ ...props }) => (
  <svg
    {...props}
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.17969 0.820312H7.82031V10.8203L12.4297 6.25L13.6016 7.42188L7 14.0234L0.398438 7.42188L1.57031 6.25L6.17969 10.8203V0.820312Z"
      fill="#0DC4E0"
    />
  </svg>
);

export default SwapIcon;
