import React, { FC, useEffect, useMemo, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Styled from "./styled";
import eventBus from "utils/eventBus";
import { connectorKey, WALLETS } from "shared/constants";
import { useWeb3React } from "@web3-react/core";
import { WalletAddressTypes } from "./types";
import { useResize } from "../../../shared/hooks";

const WalletAddress: FC<WalletAddressTypes> = ({
  className,
  userModalHandler,
  showFullAddress,
}) => {
  const { account } = useWeb3React();
  const [connectedWalletName, setConnectedWalletName] = useState(
    localStorage.getItem(connectorKey),
  );
  const { windowWidth } = useResize();
  const mobileView = useMemo(() => windowWidth <= 600, [windowWidth]);

  useEffect(() => {
    const onConnect = (v) => {
      setConnectedWalletName(v);
    };
    const onDisconnect = () => {
      setConnectedWalletName(null);
    };

    eventBus.on("connected-to-wallet", onConnect);
    eventBus.on("disconnected-from-wallet", onDisconnect);

    return () => {
      eventBus.remove("connected-to-wallet", onConnect);
      eventBus.remove("disconnected-from-wallet", onDisconnect);
    };
  }, []);

  const currentWallet = useMemo(() => {
    const wallet = WALLETS.find(
      (wallet_) => wallet_.name === connectedWalletName,
    );

    if (wallet) {
      return wallet;
    } else {
      return {
        name: "ACCOUNT_MODAL-no_provided",
        image: "",
      };
    }
  }, [connectedWalletName]);

  return (
    <Styled.Wrapper className={className} $active={!!account}>
      <Styled.WalletIcon
        onClick={userModalHandler}
        key={currentWallet.image}
        src={
          currentWallet.image
            ? `/svg/wallets/${currentWallet.image}`
            : "/images/temp/no-wallet.svg"
        }
      />
      <Styled.WalletAddress>
        {/*{(showFullAddress && !mobileView) && account?.toString()}*/}
        {account
          ? showFullAddress && !mobileView
            ? account.toString()
            : `${account?.substring(0, 7)}...${account?.substring(40 - 7)}`
          : "..."}
      </Styled.WalletAddress>
      <CopyToClipboard disabled={!account} text={account || ""}>
        <Styled.CopyIcon $active={!!account} />
      </CopyToClipboard>
    </Styled.Wrapper>
  );
};

export default WalletAddress;
