import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { Token } from "./types";
import { getTokensListUrl } from "./api";
import { investTokens } from "shared/constants";

const fetchTokens = createAsyncThunk<Token[], boolean>(
  "invest/fetchTokens",
  async (shouldUpdate) => {
    if (shouldUpdate) {
      return axios.get(getTokensListUrl).then(async (res) => {
        const tokens = res.data.tokens;
        localStorage.setItem(investTokens, JSON.stringify(tokens));
        return tokens;
      });
    } else {
      const tokensSerialized = localStorage.getItem(investTokens);
      if (tokensSerialized) {
        return JSON.parse(tokensSerialized);
      } else {
        return [];
      }
    }
  },
);

export { fetchTokens };
