import React from "react";

const TwitterIcon = (props?: any): JSX.Element => {
  return (
    <svg
      {...props}
      width="22"
      height="17"
      viewBox="0 0 22 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 2.49571C21.1763 2.82229 20.3025 3.03655 19.4078 3.13135C20.3502 2.62672 21.0554 1.83266 21.392 0.897141C20.5062 1.36775 19.5369 1.69914 18.5261 1.87694C18.1038 1.47383 17.5935 1.15263 17.0268 0.933243C16.4601 0.71386 15.8491 0.600968 15.2317 0.601565C12.7392 0.601565 10.7182 2.4098 10.7182 4.64094C10.7182 4.95723 10.7582 5.26584 10.8353 5.56167C7.08401 5.39318 3.75843 3.78489 1.53223 1.34076C1.13083 1.95724 0.919915 2.65819 0.921107 3.3717C0.920515 4.03675 1.10349 4.69163 1.45379 5.27816C1.80408 5.86469 2.31084 6.36469 2.92903 6.73375C2.2124 6.71338 1.51157 6.54016 0.884823 6.22851C0.884251 6.24539 0.884251 6.26201 0.884251 6.27939C0.884251 8.23593 2.43991 9.8685 4.5044 10.24C3.83964 10.4011 3.14265 10.4247 2.46619 10.3091C3.04045 11.9135 4.70725 13.0817 6.68231 13.1145C5.08349 14.2374 3.10917 14.8464 1.07682 14.8437C0.712544 14.8437 0.353415 14.8248 0 14.7872C2.06373 15.9739 4.46558 16.6038 6.91859 16.6016C15.2206 16.6016 19.7604 10.4469 19.7604 5.10962C19.7604 4.93472 19.7558 4.76009 19.7472 4.58648C20.6313 4.01524 21.3943 3.30719 22 2.49571Z"
        fill="#50E0ED"
      />
    </svg>
  );
};

export default TwitterIcon;
