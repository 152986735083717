import { css, FlattenSimpleInterpolation } from "styled-components";

export const TextClamped = (
  maxLines: number | string,
): FlattenSimpleInterpolation => css`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: ${maxLines};
  word-break: break-word;
`;

export const CustomScrollbar = (): FlattenSimpleInterpolation => css`
  scrollbar-width: thin;
  scrollbar-height: thin;
  scrollbar-color: #dfdfdf #dcf8fb;

  &::-webkit-scrollbar {
    background: #dcf8fb;
    width: 7px;
    height: 7px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    -webkit-border-radius: 5px;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #dfdfdf;
    -webkit-border-radius: 5px;
    border-radius: 5px;
  }
`;

export const CustomNetworkScrollbar = (): FlattenSimpleInterpolation => css`
  scrollbar-width: thin;
  scrollbar-height: thin;
  scrollbar-color: #f5f5f5 #f5f5f5;

  &::-webkit-scrollbar {
    background: #f5f5f5;
    width: 4px;
    height: 4px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-track {
    -webkit-border-radius: 2px;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background: #c2c2c2;
    -webkit-border-radius: 2px;
    border-radius: 2px;
  }
`;
