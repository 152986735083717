import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { GlobalState } from "./types";
import {
  fetchAllBeefyAssets,
  fetchAssetPrices,
  fetchNativePrice,
  fetchVenusPrices,
} from "./thunks";

const extraReducers = (builder: ActionReducerMapBuilder<GlobalState>) => {
  builder.addCase(fetchAssetPrices.fulfilled, (state, action) => {
    state.assetPrices = action.payload;
  });

  builder.addCase(fetchNativePrice.fulfilled, (state, action) => {
    state.nativePrice = action.payload;
  });

  builder.addCase(fetchVenusPrices.fulfilled, (state, action) => {
    state.venusPrices = action.payload;
  });

  builder.addCase(fetchAllBeefyAssets.fulfilled, (state, action) => {
    state.beefyAssets = action.payload;
  });
};

export default extraReducers;
