import styled, { css } from "styled-components";

type CardSelectTypes = {
  active?: boolean;
  inactive?: boolean;
  white?: boolean;
};

type CardBoxLeftTypes = {
  withRight?: boolean;
};

const Wrapper = styled.div``;

const CardLabel = styled.div``;

const CardSelectDropdown = styled.div`
  position: absolute;
  left: 0;
  bottom: 8px;
  width: 100%;
  transform: translateY(100%) scale(0);
  transform-origin: top;
  padding: 18px 0 0;
  background: ${(props) => props.theme.Colors.White};
  border-radius: 0 0 8px 8px;
  box-shadow:
    0 20px 20px rgba(105, 123, 140, 0.1),
    0 10px 10px rgba(105, 123, 140, 0.05);
  overflow: hidden;
`;

const CardSelectTop = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 1;
  background: ${(props) => props.theme.Colors.Gray4};
  border-radius: 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: #000000;
  padding: 16px 60px 16px 20px;
  cursor: pointer;
  transition:
    background 0.2s ease,
    border-color 0.2s ease;
  border: 1px solid transparent;

  &:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 20px;
    width: 10px;
    height: 10px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("/assets/svg/card-select-dropdown.svg");
    transform: translateY(-50%);
  }

  &:hover {
    border-color: ${(props) => props.theme.Colors.BackgroundGray2};
  }

  img {
    width: 16px;
    height: 16px;
    object-fit: contain;
    margin-right: 10px;
  }
`;

const CardSelect = styled.div<CardSelectTypes>`
  position: relative;
  ${(props) =>
    props.active &&
    css`
      z-index: 10;
      ${CardSelectDropdown} {
        transform: translateY(100%) scale(1);
      }
      ${CardSelectTop} {
        background: ${(props_) => props_.theme.Colors.White};
        border-color: ${(props_) => props_.theme.Colors.TurquoiseDark};

        &:after {
          background-image: url("/assets/svg/card-select-dropdown-turquoise.svg");
        }
      }
    `}
  ${(props) =>
    props.inactive &&
    css`
      ${CardSelectTop} {
        cursor: default;
        padding-right: 20px;

        &:after {
          display: none;
        }

        &:hover {
          border-color: ${(props_) => props_.theme.Colors.Gray3} !important;
        }
      }
    `}
  ${(props) =>
    props.white &&
    css`
      transition:
        box-shadow 0.2s ease,
        border-color 0.2s ease;
      border: 1px solid transparent;
      border-radius: 8px 8px 0 0;
      ${CardSelectTop} {
        box-shadow: 0 2px 6px rgba(0, 111, 128, 0.08);
        background: ${(props_) => props_.theme.Colors.white};
        border: 1px solid ${(props_) => props_.theme.Colors.Gray3};
        transition:
          border-color 0.2s ease,
          box-shadow,
          0.2s ease;

        &:hover {
          border-color: #b8c4cc;
        }

        img {
          width: 40px;
          height: 40px;
          margin-right: 20px;
        }
      }
    `}
`;

const CardSelectTopBox = styled.div``;

const CardSelectSearch = styled.div`
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: ${(props) => props.theme.Colors.Black};
  position: relative;
  margin: 5px 20px 10px;
  height: 48px;

  &::placeholder {
    color: ${(props) => props.theme.Colors.Gray1};
  }

  input {
    width: 100%;
    height: 100%;
    background: ${(props) => props.theme.Colors.Gray4};
    padding: 0 60px 0 20px;
    border-radius: 8px;
    border: 1px solid transparent;
    transition: border-color 0.2s ease;

    &:hover {
      border-color: #b8c4cc;
    }
  }

  svg {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    pointer-events: none;
  }
`;

const CardList = styled.ul`
  max-height: 300px;
  overflow-y: auto;
  margin-right: 4px;
  margin-bottom: 4px;
  scrollbar-width: thin;
  scrollbar-color: #dfdfdf #dcf8fb;
  li {
    padding: 10px 20px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #000000;
    cursor: pointer;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover:not(.selected) {
      background: #dde2e4;
    }

    .left {
      display: flex;
      align-items: center;
    }

    img {
      width: 16px;
      height: 16px;
      object-fit: contain;
      margin-right: 10px;
    }

    &.disabled {
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.5;
    }

    &.selected {
      color: ${(props) => props.theme.Colors.TurquoiseDark};
      cursor: default;
    }
  }
`;

const CardBoxLeft = styled.div<CardBoxLeftTypes>`
  display: flex;
  align-items: center;
  ${(props) =>
    props.withRight &&
    css`
      margin-right: 10px;
    `}
`;

const CardBoxRight = styled.div`
  color: ${(props) => props.theme.Colors.Green};
  text-align: right;
  padding: 1px 8px;
  border-radius: 50px;
  font-size: 12px;
  font-weight: 500;
  background: rgba(2, 192, 118, 0.1);
  margin: 5px 0;
`;

export default {
  Wrapper,
  CardLabel,
  CardSelect,
  CardSelectTop,
  CardSelectTopBox,
  CardSelectDropdown,
  CardSelectSearch,
  CardList,
  CardBoxLeft,
  CardBoxRight,
};
