import { useSelector } from "react-redux";
import { State, useAppDispatch } from "../../store";
import useRates from "./useRates";
import { useEffect, useMemo, useState } from "react";
import { fetchTokens } from "../../store/invest/thunks";
import { fetchNativePrice } from "../../store/global/thunks";
import { useWalletNetwork } from "./useWalletNetwork";
import { ChainTokens } from "../types/ChainTokens";
import { setPreviousPrices, setPrices } from "../../store/invest";

const useRatesInvest = () => {
  const tokens = useSelector((state: State) => state.invest.tokens);
  const {
    getRatesUsdHistory,
    ratesUsdHistory,
    loading: loadingRates,
  } = useRates();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { walletNetwork: network } = useWalletNetwork();

  useEffect(() => {
    const update = async () => {
      if (tokens.length === 0) {
        setIsLoading(true);
        await dispatch(fetchTokens(true));
        if (network) await dispatch(fetchNativePrice({ network }));
        setIsLoading(false);
      }
    };

    update().then();
  }, [dispatch, network, tokens.length]);

  useEffect(() => {
    if (tokens) {
      const update = async () => {
        if (network) await dispatch(fetchNativePrice({ network }));
      };
      update().then();
    }
  }, [network, dispatch, tokens]);

  const loading = useMemo<boolean>(() => {
    return isLoading || loadingRates;
  }, [isLoading, loadingRates]);

  const chainTokens = useMemo<ChainTokens | null>(() => {
    if (tokens) {
      const chainTokens_: ChainTokens = {};
      tokens.forEach((token) => {
        if (token.chains?.length) {
          for (const chain of token.chains) {
            if (!chainTokens_[chain.chainId]) {
              chainTokens_[chain.chainId] = {};
            }
            chainTokens_[chain.chainId][chain.contractAddress.toLowerCase()] = {
              tokenSymbol: token.symbol,
              tokenName: token.name,
              tokenDecimals: token.decimals,
              balance: "0",
              balanceUsd: "0",
            };
          }
        }
      });

      return chainTokens_;
    }
    return null;
  }, [tokens]);

  useEffect(() => {
    const load = async () => {
      if (chainTokens) {
        await getRatesUsdHistory([56, 137], chainTokens);
      }
    };

    load().then();
  }, [chainTokens, getRatesUsdHistory]);

  useEffect(() => {
    if (!ratesUsdHistory) {
      dispatch(setPrices({}));
    } else {
      const prices = {};
      const pricesHistory = {};

      Object.entries(ratesUsdHistory[56] ?? {}).forEach(
        ([tokenAddress, values]) => {
          if (values[0])
            prices[tokenAddress.toLowerCase()] = {
              usd: Object.values(values[0])[0],
            };
          if (values[1])
            pricesHistory[tokenAddress.toLowerCase()] = {
              usd: Object.values(values[1])[0],
            };
        },
      );
      dispatch(setPrices(prices));
      dispatch(setPreviousPrices(pricesHistory));
    }
  }, [ratesUsdHistory, dispatch]);

  return {
    tokens,
    chainTokens,
    loading,
  };
};

export { useRatesInvest };
