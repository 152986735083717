import { AvailableNetwork } from "../types";

const SUPPORTED_CHAIN_IDS: Record<AvailableNetwork, number> = {
  ETH: 1,
  OPTIMISM: 10,
  BSC: 56,
  GNOSIS: 100,
  POLYGON: 137,
  OP_BNB: 204,
  ZKSYNCERA: 324,
  BASE: 8453,
  BITGERT: 32520,
  ARBITRUM: 42161,
  AVALANCHE: 43114,
  LINEA: 59144,
  BLAST: 81457,
  SCROLL: 534352,
  AURORA: 1313161554,
  // BLAST_TESTNET: 168587773,
};

export default SUPPORTED_CHAIN_IDS;
