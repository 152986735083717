export const PortfolioAvailableChains = [1, 56, 137] as const;

export type PortfolioAvailableChainsT =
  (typeof PortfolioAvailableChains)[number];

export type PortfolioStat = {
  currencyAddress: string;
  currencySymbol: string;
  internalValue1: number;
  internalValue2: number;
  value: number;
  usdValue: number;
  protocolName: string;
};

export type PortfolioStatGrouped = Omit<PortfolioStat, "protocolName">;

export type PortfolioStatGroup = {
  protocolName: string;
  chainId: number;
  stats: PortfolioStatGrouped[];
};

export type Portfolio = Record<PortfolioAvailableChainsT, PortfolioStat[]>;
