import type { AvailableSetup } from "shared/types";
import SUPPORTED_CHAIN_IDS from "./supportedChainIds";

export type Network = {
  chainId: number;
  name: string;
  icon: string;
  configName?: AvailableSetup;
  noIconColorChange?: boolean;
};

export const NETWORKS: Network[] = [
  {
    chainId: 56,
    name: "BNB Chain",
    icon: "binance",
    configName: "bsc",
  },
  {
    chainId: 1,
    name: "Ethereum",
    icon: "ethereum",
    configName: "eth",
  },
  {
    chainId: 10,
    name: "Optimism",
    icon: "optimistic",
    configName: "optimism",
  },
  {
    chainId: 42161,
    name: "Arbitrum",
    icon: "arbitrum",
    configName: "arbitrum",
    noIconColorChange: true,
  },
  {
    chainId: 137,
    name: "Polygon",
    icon: "polygon",
    configName: "polygon",
  },
  {
    chainId: 43114,
    name: "Avalanche",
    icon: "avalanche",
    configName: "avalanche",
  },
  {
    chainId: 1313161554,
    name: "Aurora",
    icon: "aurora",
    configName: "aurora",
  },
  {
    chainId: 32520,
    name: "Birgert",
    icon: "bitgert",
    configName: "bitgert",
  },
  {
    chainId: 324,
    name: "ZkSync Era",
    icon: "zksyncera",
    configName: "zksyncera",
  },
  {
    chainId: 100,
    name: "Gnosis",
    icon: "gnosis",
    configName: "gnosis",
  },
  {
    chainId: SUPPORTED_CHAIN_IDS.LINEA,
    name: "Linea",
    icon: "linea",
    configName: "linea",
  },
  {
    chainId: SUPPORTED_CHAIN_IDS.BASE,
    name: "Base",
    icon: "base",
    configName: "base",
  },
  {
    chainId: SUPPORTED_CHAIN_IDS.OP_BNB,
    name: "opBNB",
    icon: "opbnb",
    configName: "opbnb",
  },
  {
    chainId: SUPPORTED_CHAIN_IDS.SCROLL,
    name: "Scroll",
    icon: "scroll",
    configName: "scroll",
  },
  {
    chainId: SUPPORTED_CHAIN_IDS.BLAST,
    name: "Blast",
    icon: "blast",
    configName: "blast",
  },
  // {
  //   chainId: SUPPORTED_CHAIN_IDS.BLAST_TESTNET,
  //   name: "Blast testnet",
  //   icon: "blast_testnet",
  //   configName: "blast_testnet",
  // },
];
