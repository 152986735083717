import React, { FC, useMemo } from "react";
import Styled from "./styled";
import { useResize } from "shared/hooks/useResize";
import { useHistory } from "react-router";

type GoBackTypes = {
  className?: string;
  text?: string;
};

const GoBack: FC<GoBackTypes> = ({ className, text }) => {
  const { windowWidth } = useResize();
  const history = useHistory();

  const mobileView = useMemo(() => windowWidth <= 600, [windowWidth]);

  return (
    <Styled.Link className={className} onClick={() => history.go(-1)}>
      <Styled.Icon />
      <Styled.Text>{text && mobileView ? text : "Back"}</Styled.Text>
    </Styled.Link>
  );
};

export default GoBack;
