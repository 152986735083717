import React from "react";
import { useEffect, useRef } from "react";
import { useResize } from "shared/hooks";
import Styled from "./styled";

type AccordionTypes = {
  children: any;
  collapsed: any;
  className?: string;
};

const Accordion = ({ children, className, collapsed }: AccordionTypes) => {
  const accordionRef: any = useRef();
  const contentRef: any = useRef();
  const { windowWidth } = useResize();

  useEffect(() => {
    if (collapsed) {
      accordionRef.current.style.height = 0;
    } else {
      accordionRef.current.style.height = `${contentRef.current.scrollHeight}px`;
    }
  }, [collapsed, children, windowWidth]);

  return (
    <Styled.Wrapper className={className} ref={accordionRef}>
      <Styled.Content ref={contentRef}>{children}</Styled.Content>
    </Styled.Wrapper>
  );
};

export default Accordion;
