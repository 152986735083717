import React, { useCallback, useContext, useState } from "react";
import { DialogModal } from "./components";

type Props = {
  children: React.ReactNode;
};

const DialogModalContext = React.createContext<{
  showModal: (content: JSX.Element, header?: string) => void;
  hideModal: () => void;
}>({
  showModal: () => {},
  hideModal: () => {},
});

const DialogModalProvider = ({ children }: Props) => {
  const [show, setShow] = useState<boolean>(false);
  const [currentHeader, setCurrentHeader] = useState<string>();
  const [currentContent, setCurrentContent] = useState<JSX.Element>(<></>);

  const showModal = useCallback(
    (content, header) => {
      setCurrentContent(content);
      setCurrentHeader(header);
      setShow(true);
    },
    [setShow],
  );

  const hideModal = useCallback(() => {
    setShow(false);
  }, [setShow]);

  return (
    <DialogModalContext.Provider
      value={{
        showModal,
        hideModal,
      }}
    >
      {show && (
        <DialogModal headerText={currentHeader} content={currentContent} />
      )}
      {children}
    </DialogModalContext.Provider>
  );
};

const useDialogModal = () => {
  const helpers = useContext(DialogModalContext);

  return helpers;
};

export { useDialogModal };
export default DialogModalProvider;
