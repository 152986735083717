import styled from "styled-components";

const PageCardStyled = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 36px;
  max-width: 848px;
  margin: 0 auto;
  @media (max-width: 600px) {
    padding: 20px;
  }
`;

export { PageCardStyled };
