import { createSlice } from "@reduxjs/toolkit";
import { DashboardState } from "./types";
import reducers from "./reducers";
import extraReducers from "./extraReducers";

const initialState: DashboardState = {
  assets: null,
  activeAssets: null,
  tokenPercentages: null,
  limit: 200,
  offset: 0,
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers,
  extraReducers,
});

export default dashboardSlice.reducer;
export const { setAssets, setActiveAssets, setTokenPercentages } =
  dashboardSlice.actions;
