import { useMemo } from "react";
import { WALLETS_OBJ } from "../constants";

const useAvailableConnectors = () => {
  const availableConnectors = useMemo(() => {
    const _availableConnectors: string[] = []; // wallet slugs from WALLETS const

    // @ts-ignore
    if (typeof window.__CIPHER__ !== "undefined")
      _availableConnectors.push("cipher");

    // @ts-ignore
    if (window.BinanceChain)
      _availableConnectors.push(WALLETS_OBJ.binance.slug);

    // @ts-ignore
    if (window.bitkeep?.ethereum)
      _availableConnectors.push(WALLETS_OBJ.bitkeep.slug);

    // @ts-ignore
    if (window.ethereum?.isONTO)
      _availableConnectors.push(WALLETS_OBJ.onto.slug);

    // @ts-ignore
    if (window.ethereum?.isMetaMask)
      _availableConnectors.push(WALLETS_OBJ.metamask.slug);

    // @ts-ignore
    if (window.ethereum?.isSafePal)
      _availableConnectors.push(WALLETS_OBJ.safepal.slug);

    // @ts-ignore
    if (window.ethereum?.isTrust)
      _availableConnectors.push(WALLETS_OBJ.trustwallet.slug);

    // @ts-ignore
    if (window.solana?.isMathWallet)
      _availableConnectors.push(WALLETS_OBJ.mathwallet.slug);

    return _availableConnectors;
  }, []);

  return availableConnectors;
};

export { useAvailableConnectors };
