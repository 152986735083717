import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 0 30px 60px;

  @media (max-width: 1350px) {
    padding: 0;
  }
`;

export default Container;
