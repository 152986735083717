import React from "react";

const SearchIcon = (props) => {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="9.5" cy="9.5" r="7" stroke="#C2C2C2" />
      <path
        d="M14.5 15L14.7071 15.2071C14.8946 15.3946 15 15.649 15 15.9142V16.5858C15 16.851 15.1054 17.1054 15.2929 17.2929L19.2929 21.2929C19.6834 21.6834 20.3166 21.6834 20.7071 21.2929L21.2929 20.7071C21.6834 20.3166 21.6834 19.6834 21.2929 19.2929L17.2929 15.2929C17.1054 15.1054 16.851 15 16.5858 15H15.9142C15.649 15 15.3946 14.8946 15.2071 14.7071L15 14.5"
        stroke="#C2C2C2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default SearchIcon;
