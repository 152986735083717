import styled, { css } from "styled-components";
import { IconProps } from "./types";

import ArrowBottomNotStyled from "./_resources/ArrowBottom";
import ReminderIconNotStyled from "./_resources/ReminderIcon";
import CalendarNotStyled from "./_resources/Calendar";
import CloseCrossNotStyled from "./_resources/CloseCross";
import ArrowLeftNotStyled from "./_resources/ArrowLeft";
import ArrowRightNotStyled from "./_resources/ArrowRight";
import CircledEsclamationNotStyled from "./_resources/CircledEsclamation";
import NoteNotStyled from "./_resources/Note";
import OptionPlayNotstyled from "./_resources/OptionPlay";
import EditNotStyled from "./_resources/Edit";
import DiscordNotStyled from "./_resources/Discord";
import SuccessNotStyled from "./_resources/Success";
import WarningNotStyled from "./_resources/Warning";

const ArrowBottom = styled(ArrowBottomNotStyled)<IconProps>`
  ${(props) =>
    props.clickable &&
    css`
      cursor: pointer;
    `}

  path {
    stroke: ${(props) => props.theme.Colors[props.color]};
  }
`;

const ReminderIcon = styled(ReminderIconNotStyled)<{ IconProps }>`
  ${(props) =>
    props.clickable &&
    css`
      cursor: pointer;
    `}

  path {
    fill: ${(props) => props.theme.Colors[props.color]};
  }
`;

const Calendar = styled(CalendarNotStyled)<IconProps>`
  ${(props) =>
    props.clickable &&
    css`
      cursor: pointer;
    `}

  path {
    stroke: ${(props) => props.theme.Colors[props.color]};
  }
`;

const CloseCross = styled(CloseCrossNotStyled)<IconProps>`
  ${(props) =>
    props.clickable &&
    css`
      cursor: pointer;
    `}

  path {
    fill: ${(props) => props.theme.Colors[props.color]};
  }
`;

const ArrowLeft = styled(ArrowLeftNotStyled)<IconProps>`
  ${(props) =>
    props.clickable &&
    css`
      cursor: pointer;
    `}

  path {
    stroke: ${(props) => props.theme.Colors[props.color]};
  }
`;

const ArrowRight = styled(ArrowRightNotStyled)<IconProps>`
  ${(props) =>
    props.clickable &&
    css`
      cursor: pointer;
    `}

  path {
    stroke: ${(props) => props.theme.Colors[props.color]};
  }
`;

const CircledEsclamation = styled(CircledEsclamationNotStyled)<IconProps>`
  ${(props) =>
    props.clickable &&
    css`
      cursor: pointer;
    `}

  path {
    fill: ${(props) => props.theme.Colors[props.color]};
  }
`;

const Note = styled(NoteNotStyled)<IconProps>`
  ${(props) =>
    props.clickable &&
    css`
      cursor: pointer;
    `}

  rect {
    stroke: ${(props) => props.theme.Colors[props.color]};
  }

  path {
    stroke: ${(props) => props.theme.Colors[props.color]};
  }
`;

const OptionPlay = styled(OptionPlayNotstyled)<IconProps>`
  ${(props) =>
    props.clickable &&
    css`
      cursor: pointer;
    `}

  path {
    stroke: ${(props) => props.theme.Colors[props.color]};
  }

  path:last-child {
    stroke: none;
    fill: ${(props) => props.theme.Colors[props.color]};
  }
`;

const Edit = styled(EditNotStyled)<IconProps>`
  ${(props) =>
    props.clickable &&
    css`
      cursor: pointer;
    `}

  path {
    stroke: ${(props) => props.theme.Colors[props.color]};
  }
`;

const Discord = styled(DiscordNotStyled)<IconProps>`
  ${(props) =>
    props.clickable &&
    css`
      cursor: pointer;
    `}

  path {
    fill: ${(props) => props.theme.Colors[props.color]};
  }
`;

const Success = styled(SuccessNotStyled)<IconProps>`
  ${(props) =>
    props.clickable &&
    css`
      cursor: pointer;
    `}

  path {
    fill: ${(props) => props.theme.Colors[props.color]};
  }
`;

const Warning = styled(WarningNotStyled)<IconProps>`
  ${(props) =>
    props.clickable &&
    css`
      cursor: pointer;
    `}

  path {
    fill: ${(props) => props.theme.Colors[props.color]};
  }
`;

export {
  ArrowBottom,
  CloseCross,
  Calendar,
  CircledEsclamation,
  ReminderIcon,
  ArrowLeft,
  ArrowRight,
  Note,
  OptionPlay,
  Edit,
  Discord,
  Success,
  Warning,
};
