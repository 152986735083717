import React, { useState, useEffect, useRef, useMemo } from "react";
import { useClickOutside } from "shared/hooks";
import "./styles.scss";
import SearchIcon from "./_resources/SearchIcon";
import { useTranslation } from "react-i18next";
import Styled from "./styled";

interface Option {
  title: string;
  img: string;
  disabled: boolean;
  selected?: boolean;
  rightText?: string;
  selectedRightText?: string;
}

interface Props {
  label: string;
  options?: Option[];
  value?: Option;
  disabled?: boolean;
  type?: string;
  onChange?: (value: any) => void;
  className?: string;
  showSearch?: boolean;
  searchPlaceholder?: string;
}

const Select = ({
  label,
  options = [],
  value = { title: "", img: "", disabled: true, selected: false },
  disabled = false,
  type = "normal",
  onChange = () => {},
  className,
  showSearch = false,
  searchPlaceholder = "ACTIONS-search",
}: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenAvailable, setIsOpenAvailable] = useState<boolean>(true);
  const [searchInput, setSearchInput] = useState<string>("");
  const SelectRef = useRef(null);

  useEffect(() => {
    const isGreater = options.length > 1;
    setIsOpenAvailable(isGreater);
  }, [options]);

  useClickOutside(SelectRef, () => {
    setIsOpen(false);
  });

  const toggleSelect = () => {
    setIsOpen(!isOpen && isOpenAvailable && !disabled);
  };

  const setCurrentItem = (item: Option) => {
    if (item.title !== value.title && !item.selected) {
      if (searchInput !== "") setSearchInput("");
      onChange(item);
      setIsOpen(false);
    }
  };

  const filteredOptions = useMemo(() => {
    if (searchInput.trim() !== "") {
      return options.filter((item: Option) =>
        item.title.toLowerCase().includes(searchInput.trim().toLowerCase()),
      );
    }
    return options;
  }, [options, searchInput]);

  const { t } = useTranslation();

  return (
    <Styled.Wrapper className={`card-select__wrapper ${className}`}>
      <Styled.CardLabel className="card__label">
        <span>{label}</span>
      </Styled.CardLabel>
      <Styled.CardSelect
        className={`  card-select 
                    ${isOpen ? "active" : ""}
                    ${!isOpenAvailable || disabled ? "inactive" : ""}
                    ${type === "white" ? "white" : ""}`}
        ref={SelectRef}
      >
        <Styled.CardSelectTop onClick={toggleSelect}>
          <Styled.CardBoxLeft
            className={
              "left" + (value.selectedRightText ? " left--with-right" : "")
            }
          >
            <img
              src={value.img ? value.img : "/svg/global/question-coin.svg"}
              onError={(e) => {
                e.currentTarget.onerror = null;
                e.currentTarget.src = "/svg/global/question-coin.svg";
              }}
              alt={value.title}
            />
            <span>{value.title}</span>
          </Styled.CardBoxLeft>
          {!!value.selectedRightText && (
            <div className={"right"}>{value.selectedRightText}</div>
          )}
        </Styled.CardSelectTop>
        {!disabled && (
          <Styled.CardSelectDropdown className="card-select__dropdown">
            {showSearch && (
              <Styled.CardSelectSearch className="card-select__search">
                <input
                  type="text"
                  value={searchInput}
                  onInput={(event: any) => setSearchInput(event.target.value)}
                  placeholder={t(searchPlaceholder)}
                />
                <SearchIcon />
              </Styled.CardSelectSearch>
            )}
            <Styled.CardList>
              {filteredOptions.map((item, i) => (
                <li
                  key={i}
                  className={`${item.disabled ? "disabled" : ""}${
                    item.selected ? " selected" : ""
                  }`.trim()}
                  onClick={() => setCurrentItem(item)}
                >
                  <Styled.CardBoxLeft className="left">
                    <img
                      src={
                        item.img ? item.img : "/svg/global/question-coin.svg"
                      }
                      onError={(e: any) => {
                        e.currentTarget.onerror = null;
                        e.currentTarget.src = "/svg/global/question-coin.svg";
                      }}
                      alt={item.title}
                    />
                    <span>{item.title}</span>
                  </Styled.CardBoxLeft>
                  {!!item.rightText && (
                    <Styled.CardBoxRight className="right">
                      {item.rightText}
                    </Styled.CardBoxRight>
                  )}
                </li>
              ))}
            </Styled.CardList>
          </Styled.CardSelectDropdown>
        )}
      </Styled.CardSelect>
    </Styled.Wrapper>
  );
};

export default Select;
