import React from "react";
import { toast } from "react-toastify";
import styled, { css } from "styled-components";

type TypeLink = {
  text: string;
  href: string;
};

const PendingIcon = ({ ...props }) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="25"
    viewBox="0 0 26 25"
    fill="none"
  >
    <g filter="url(#filter0_d_1151_2712)">
      <path
        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
        stroke="#0277BD"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M12 7.5V12.75"
        stroke="#0277BD"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 17.25C12.6213 17.25 13.125 16.7463 13.125 16.125C13.125 15.5037 12.6213 15 12 15C11.3787 15 10.875 15.5037 10.875 16.125C10.875 16.7463 11.3787 17.25 12 17.25Z"
        fill="#0277BD"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_1151_2712"
        x="-4"
        y="0"
        width="32"
        height="32"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1151_2712"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1151_2712"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

const SuccessIcon = ({ ...props }) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.125 9.75L10.6219 15L7.875 12.375"
      stroke="#1D8C4B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
      stroke="#1D8C4B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ErrorIcon = ({ ...props }) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 9.75V13.5"
      stroke="#B71C1C"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.7058 3.75024L2.45585 18.0002C2.32442 18.2279 2.25512 18.4861 2.25488 18.7489C2.25465 19.0118 2.3235 19.2701 2.45452 19.498C2.58555 19.7258 2.77416 19.9153 3.00145 20.0473C3.22875 20.1793 3.48674 20.2493 3.7496 20.2502H20.2496C20.5125 20.2493 20.7705 20.1793 20.9977 20.0473C21.225 19.9153 21.4136 19.7258 21.5447 19.498C21.6757 19.2701 21.7445 19.0118 21.7443 18.7489C21.7441 18.4861 21.6748 18.2279 21.5434 18.0002L13.2934 3.75024C13.1629 3.5223 12.9746 3.33287 12.7474 3.20113C12.5202 3.06938 12.2622 3 11.9996 3C11.737 3 11.479 3.06938 11.2518 3.20113C11.0246 3.33287 10.8363 3.5223 10.7058 3.75024V3.75024Z"
      stroke="#B71C1C"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 18C12.6213 18 13.125 17.4963 13.125 16.875C13.125 16.2537 12.6213 15.75 12 15.75C11.3787 15.75 10.875 16.2537 10.875 16.875C10.875 17.4963 11.3787 18 12 18Z"
      fill="#B71C1C"
    />
  </svg>
);

const WarningIcon = ({ ...props }) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 7.5V12.75"
      stroke="#FFA000"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.4219 3H8.57813C8.48074 2.99966 8.38424 3.01853 8.29415 3.05553C8.20407 3.09253 8.12216 3.14694 8.05313 3.21563L3.21563 8.05313C3.14694 8.12216 3.09253 8.20407 3.05553 8.29415C3.01853 8.38424 2.99966 8.48074 3 8.57813V15.4219C2.99966 15.5193 3.01853 15.6158 3.05553 15.7059C3.09253 15.7959 3.14694 15.8778 3.21563 15.9469L8.05313 20.7844C8.12216 20.8531 8.20407 20.9075 8.29415 20.9445C8.38424 20.9815 8.48074 21.0003 8.57813 21H15.4219C15.5193 21.0003 15.6158 20.9815 15.7059 20.9445C15.7959 20.9075 15.8778 20.8531 15.9469 20.7844L20.7844 15.9469C20.8531 15.8778 20.9075 15.7959 20.9445 15.7059C20.9815 15.6158 21.0003 15.5193 21 15.4219V8.57813C21.0003 8.48074 20.9815 8.38424 20.9445 8.29415C20.9075 8.20407 20.8531 8.12216 20.7844 8.05313L15.9469 3.21563C15.8778 3.14694 15.7959 3.09253 15.7059 3.05553C15.6158 3.01853 15.5193 2.99966 15.4219 3V3Z"
      stroke="#FFA000"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M12 17.25C12.6213 17.25 13.125 16.7463 13.125 16.125C13.125 15.5037 12.6213 15 12 15C11.3787 15 10.875 15.5037 10.875 16.125C10.875 16.7463 11.3787 17.25 12 17.25Z"
      fill="#FFA000"
    />
  </svg>
);

const CloseIcon = ({ ...props }) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
      fill="#212121"
    />
  </svg>
);

const ToastContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div<{ spreaded?: boolean }>`
  display: flex;
  align-items: center;

  ${(props) =>
    props.spreaded &&
    css`
      justify-content: space-between;
    `}
`;

const TitleText = styled.div`
  margin-left: 12px;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  color: #212121;
`;

const ContentText = styled.div`
  margin-top: 12px;
  display: flex;
  font-size: 14px;
  line-height: 18px;
  color: #616161;
`;

const Link = styled.a`
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #2d9cdb;
  text-decoration: underline;
`;

const useToast = () => {
  const toastSuccess = (title: string, content?: string, link?: TypeLink) => {
    toast.success(
      <ToastContainer>
        <Row>
          <SuccessIcon />
          <TitleText>{title}</TitleText>
        </Row>
        {content && <ContentText>{content}</ContentText>}
        {link && <Link href={link.href}>{link.text}</Link>}
      </ToastContainer>,
      {
        autoClose: false,
        closeButton: CloseIcon,
      },
    );
  };

  const toastWarning = (title: string, content?: string, link?: TypeLink) => {
    toast.warning(
      <ToastContainer>
        <Row>
          <WarningIcon />
          <TitleText>{title}</TitleText>
        </Row>
        {content && <ContentText>{content}</ContentText>}
        {link && <Link href={link.href}>{link.text}</Link>}
      </ToastContainer>,
      {
        autoClose: 3000,
      },
    );
  };

  const toastError = (title: string, content?: string, link?: TypeLink) => {
    toast.error(
      <ToastContainer>
        <Row>
          <ErrorIcon />
          <TitleText>{title}</TitleText>
        </Row>
        {content && <ContentText>{content}</ContentText>}
        {link && <Link href={link.href}>{link.text}</Link>}
      </ToastContainer>,
      {
        autoClose: false,
        closeButton: CloseIcon,
      },
    );
  };

  const toastPromise = (promise: Promise<any>, { pending, success, error }) => {
    const {
      title: titlePending,
      content: contentPending,
      link: linkPending,
    } = pending;
    const {
      title: titleSuccess,
      content: contentSuccess,
      link: linkSuccess,
    } = success;
    const { title: titleError, content: contentError, link: linkError } = error;

    toast.promise(promise, {
      pending: {
        render: (
          <ToastContainer>
            <Row>
              <PendingIcon />
              <TitleText>{titlePending}</TitleText>
            </Row>
            {contentPending && <ContentText>{contentPending}</ContentText>}
            {linkPending && (
              <Link href={linkPending.href}>{linkPending.text}</Link>
            )}
          </ToastContainer>
        ),
        autoClose: 3000,
        className: "ToastPending",
      },
      success: {
        render: (
          <ToastContainer>
            <Row>
              <SuccessIcon />
              <TitleText>{titleSuccess}</TitleText>
            </Row>
            {contentSuccess && <ContentText>{contentSuccess}</ContentText>}
            {linkSuccess && (
              <Link href={linkSuccess.href}>{linkSuccess.text}</Link>
            )}
          </ToastContainer>
        ),
        autoClose: false,
        closeButton: <CloseIcon />,
      },
      error: {
        render: (
          <ToastContainer>
            <Row>
              <ErrorIcon />
              <TitleText>{titleError}</TitleText>
            </Row>
            {contentError && <ContentText>{contentError}</ContentText>}
            {linkError && <Link href={linkError.href}>{linkError.text}</Link>}
          </ToastContainer>
        ),
        autoClose: false,
        closeButton: <CloseIcon />,
      },
    });

    // toast.error(
    //     {
    //         autoClose: false,
    //         closeButton: CloseIcon
    //     }
    // )
  };

  return { toastSuccess, toastWarning, toastError, toastPromise };
};

export { useToast };
