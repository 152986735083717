import { useCallback, useEffect, useState } from "react";
import bn from "bignumber.js";
import axios from "axios";
import { UNISWAP_V3_SUBGRAPH_ENDPOINT } from "shared/constants";
import { useWeb3React } from "@web3-react/core";

const useUniswapTokenPriceInUSDMultiple = (addresses: string[] | undefined) => {
  const [prices, setPrices] = useState<bn[]>([]);
  const { chainId } = useWeb3React();

  const getPriceForToken = useCallback(
    async (address: string) => {
      const query = `{
            token(id: "${address.toLowerCase()}") {
                lastPriceUSD
            }
        }`;

      return axios
        .post(UNISWAP_V3_SUBGRAPH_ENDPOINT[chainId ?? 1], { query })
        .then((res) => {
          const price = res.data.data?.token?.lastPriceUSD;

          if (price) {
            return new bn(price);
          } else {
            return new bn(0);
          }
        })
        .catch((err) => {
          console.log("ERROR", err);
          return new bn(0);
        });
    },
    [chainId],
  );

  useEffect(() => {
    if (addresses) {
      const priceRequests: Promise<bn>[] = [];
      for (const address of addresses) {
        priceRequests.push(getPriceForToken(address));
      }

      Promise.all(priceRequests).then((values) => {
        setPrices(values);
      });
    }
  }, [addresses, chainId, getPriceForToken]);

  return prices;
};

export { useUniswapTokenPriceInUSDMultiple };
