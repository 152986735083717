import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { AirdropState } from "./types";
import { fetchGeneralBalanceInfo, fetchRewards } from "./thunks";

const extraReducers = (builder: ActionReducerMapBuilder<AirdropState>) => {
  builder.addCase(fetchGeneralBalanceInfo.fulfilled, (state, { payload }) => {
    state.roobeeBalance = payload.balance;
    state.stakedRoobee = payload.stakedBalance;
    state.stakingApy = payload.stakingApy;
  });

  builder.addCase(fetchRewards.fulfilled, (state, { payload }) => {
    state.rewards = payload;
  });
};

export default extraReducers;
