export const WBNB_BSC = "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c";
export const USDT_BSC = "0x55d398326f99059ff775485246999027b3197955";
export const USDC_BSC = "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d";
export const BUSD_BSC = "0xe9e7cea3dedca5984780bafc599bd69add087d56";

export const WETH_ETH = "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2";
export const WETH_OPTIMISM = "0x4200000000000000000000000000000000000006";
export const WETH_POLYGON = "0x7ceb23fd6bc0add59e62ac25578270cff1b9f619";
export const WETH_ARBITRUM = "0x82af49447d8a07e3bd95bd0d56f35241523fbab1";
export const WETH_ZKSYNCERA = "";

export const USDT_ETH = "0xdac17f958d2ee523a2206206994597c13d831ec7";
export const USDT_OPTIMISM = "0x94b008aa00579c1307b0ef2c499ad98a8ce58e58";
export const USDT_POLYGON = "0xc2132d05d31c914a87c6611c10748aeb04b58e8f";
export const USDT_ARBITRUM = "0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9";

export const USDC_ETH = "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48";
export const USDC_OPTIMISM = "0x7f5c764cbc14f9669b88837ca1490cca17c31607";
export const USDC_POLYGON = "0x2791bca1f2de4661ed88a30c99a7a9449aa84174";
export const USDC_ARBITRUM = "0xff970a61a04b1ca14834a43f5de4533ebddb5cc8";

export const WMATIC_POLYGON = "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270";

export const WAVAX_AVALANCHE = "0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7";

// export type Tokens = {
//   value: number,
//   usd?: number,
//   address: string,
//   name: string,
//   symbol: string
// }

// type GetBitQueryPricesProps = {
//   network?: string,
//   dateFrom: string,
//   dateTo?: string | null,
//   tokens?: any[] | null,
//   isNative?: boolean,
//   limit?: number | string,
//   timeInterval?: string,
//   additionalBaseCurrencies?: any[] | null,
//   getTrades?: boolean,
//   timeIntervalSort?: string,
//   useMinTradedSum?: boolean
// }

// // FOR ETH and BSC
// const getBitQueryPrices = async (
//   {
//     network,
//     dateFrom,
//     dateTo,
//     tokens = null,
//     isNative = false,
//     limit = 200,
//     timeInterval = "day",
//     additionalBaseCurrencies = null,
//     getTrades = false,
//     timeIntervalSort = "asc",
//     useMinTradedSum = true
//   }: GetBitQueryPricesProps) => {
//   return new Promise(resolve => {
//     if (dateFrom === moment().format("YYYY-MM-DD")) {
//       dateFrom = moment().subtract(1, "days").format("YYYY-MM-DD");
//       dateTo = moment().format("YYYY-MM-DD");
//     }

//     let baseCurrency, quoteCurrency;
//     switch (network) {
//     case "ETH":
//       baseCurrency = `["${WETH_ETH}"]`;
//       quoteCurrency = `"${USDT_ETH}", "${USDC_ETH}"`;
//       break;
//     default: // BSC
//       baseCurrency = `["${WBNB_BSC}","${USDT_BSC}","${BUSD_BSC}"${additionalBaseCurrencies?.map(bc => `"${bc.address}"`).join(",") || ""}]`;
//       quoteCurrency = "\"0xe9e7cea3dedca5984780bafc599bd69add087d56\",\"0x55d398326f99059ff775485246999027b3197955\", \"0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d\", ";
//       break;
//     }

//     const query = new window.widgets.query(`
//         query {
//           ethereum(network: ${network === "ETH" || network === "ARBITRUM" ? "ethereum" : "bsc"}) {
//             dexTrades(
//               options: {${timeInterval === "minute" ? `limitBy: {each: "quoteCurrency.address" limit: 1}, ${limit ? `limit: ${limit}` : ""}` : `limit: ${limit}`}, ${timeIntervalSort}: "timeInterval.${timeInterval}"}
//               date: {${dateTo ? `in: ["${dateFrom}", "${dateTo}"]` : `is: "${dateFrom}"`}}
//               baseCurrency: {in: ${baseCurrency}}
//               quoteCurrency: {in: [${quoteCurrency}${tokens
//   ? `, ${(Object.values(tokens as any) as Tokens[]).filter(t => t.address !== "-").map(t => `"${t.address}"`).join(", ")}`
//   : ""}]}
//               ${useMinTradedSum ? (!isNative ? "tradeAmountUsd: {gt: 10}" : "") : ""}
//             ) {
//               ${!isNative ? "tradeAmount(in: USD)" : ""}
//               timeInterval {
//                 ${timeInterval}
//               }
//               close: maximum(of: block, get: quote_price)
//               baseCurrency {
//                 name
//                 symbol
//                 address
//               }
//               quoteCurrency {
//                 name
//                 symbol
//                 address
//               }
//             }
//           }
//         }`);

//     query.request({}, (res: any) => {
//       const prices = {};

//       if (!isNative) {
//         if (res?.ethereum?.dexTrades?.length) {
//           if (getTrades) resolve(res.ethereum.dexTrades);

//           const trades = res.ethereum.dexTrades;

//           if (network === "ETH") {
//             const wrappedNativeToUsd =
//               parseFloat(
//                 trades?.find(t => t.quoteCurrency?.symbol?.toLowerCase() === "busd")?.close
//                 || trades?.find(t => t.quoteCurrency?.symbol?.toLowerCase() === "usdc")?.close
//                 || trades?.find(t => t.quoteCurrency?.symbol?.toLowerCase() === "usdt")?.close
//                 || "0"
//               );

//             trades.forEach(t => {
//               if (
//                 t.quoteCurrency?.address?.toLowerCase()
//                 && (
//                   (t.tradeAmount > 1000 && t.quoteCurrency.symbol !== "bROOBEE" && t.quoteCurrency.symbol !== "ROOBEE")
//                   || t.quoteCurrency.symbol === "bROOBEE" || t.quoteCurrency.symbol === "ROOBEE"
//                 )
//               ) {
//                 prices[t.quoteCurrency?.address?.toLowerCase()] = {
//                   ...t,
//                   usd: parseFloat(t.close) ? wrappedNativeToUsd / parseFloat(t.close) : 0,
//                   ...(tokens as any)[t.quoteCurrency?.address?.toLowerCase()]
//                 };
//               }
//             });
//           } else {
//             const wrappedNativeToUsd = parseFloat(
//               trades?.find(t => t.baseCurrency?.address === WBNB_BSC && t.quoteCurrency?.symbol?.toLowerCase() === "busd")?.close
//               || trades?.find(t => t.baseCurrency?.address === WBNB_BSC && t.quoteCurrency?.symbol?.toLowerCase() === "usdc")?.close
//               || trades?.find(t => t.baseCurrency?.address === WBNB_BSC && t.quoteCurrency?.symbol?.toLowerCase() === "usdt")?.close
//               || "0"
//             );

//             trades.forEach(t => {
//               if (
//                 t.quoteCurrency?.address?.toLowerCase()
//                 && (
//                   (t.tradeAmount > 1000 && t.quoteCurrency.symbol !== "bROOBEE" && t.quoteCurrency.symbol !== "ROOBEE")
//                   || t.quoteCurrency.symbol === "bROOBEE" || t.quoteCurrency.symbol === "ROOBEE"
//                 )
//               ) {
//                 const quoteToNative = parseFloat(trades.find(nt => nt.baseCurrency?.address === WBNB_BSC
//                   && nt.quoteCurrency?.address === t.quoteCurrency?.address)?.close || "0");
//                 const quoteToUsd = 1 / parseFloat(
//                   trades?.find(nt => nt.baseCurrency?.symbol?.toLowerCase() === "busd"
//                     && nt.quoteCurrency?.symbol?.toLowerCase() === t.quoteCurrency?.symbol?.toLowerCase())?.close
//                   || trades?.find(nt => nt.baseCurrency?.symbol?.toLowerCase() === "usdc"
//                     && nt.quoteCurrency?.symbol?.toLowerCase() === t.quoteCurrency?.symbol?.toLowerCase())?.close
//                   || trades?.find(nt => nt.baseCurrency?.symbol?.toLowerCase() === "usdt"
//                     && nt.quoteCurrency?.symbol?.toLowerCase() === t.quoteCurrency?.symbol?.toLowerCase())?.close
//                   || "0"
//                 );

//                 const usd = quoteToNative ? wrappedNativeToUsd / quoteToNative : quoteToUsd;

//                 prices[t.quoteCurrency?.address?.toLowerCase()] = {
//                   ...t,
//                   usd,
//                   ...(tokens as any)[t.quoteCurrency?.address?.toLowerCase()]
//                 };
//               }
//             });
//           }
//         }

//         resolve(prices);
//       } else {
//         if (res?.ethereum?.dexTrades) {
//           res.ethereum.dexTrades.forEach(t => {
//             if (t.quoteCurrency?.address?.toLowerCase()) {
//               prices[t.quoteCurrency?.address?.toLowerCase()] = {
//                 ...t,
//                 usd: t.close ? parseFloat(t.close) : 0
//               };
//             }
//           });
//         }

//         resolve(prices);
//       }
//     });
//   });
// };

// const TheGraphUrls = {
//   "ETH": "https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v3", // not used atm
//   "OPTIMISM": "https://api.thegraph.com/subgraphs/name/ianlapham/optimism-post-regenesis",
//   "POLYGON": "https://api.thegraph.com/subgraphs/name/ianlapham/uniswap-v3-polygon",
//   "ARBITRUM": "https://api.thegraph.com/subgraphs/name/ianlapham/arbitrum-minimal"
// };

// // FOR OPTIMISM, POLYGON, ARBITRUM
// const getTheGraphPrices = async ({dateTo, dateFrom, tokens = null, network}) => {
//   return new Promise((resolve, reject) => {
//     if (dateFrom === moment().format("YYYY-MM-DD")) {
//       dateFrom = moment().subtract(1, "days").format("YYYY-MM-DD");
//       dateTo = moment().format("YYYY-MM-DD");
//     }

//     let token0, usdt, usdc;
//     switch (network) {
//     case "ETH":
//       token0 = WETH_ETH;
//       usdt = USDT_ETH;
//       usdc = USDC_ETH;
//       break;
//     case "OPTIMISM":
//       token0 = WETH_OPTIMISM;
//       usdt = USDT_OPTIMISM;
//       usdc = USDC_OPTIMISM;
//       break;
//     case "POLYGON":
//       token0 = WETH_POLYGON;
//       usdt = USDT_POLYGON;
//       usdc = USDC_POLYGON;
//       break;
//     case "ARBITRUM":
//       token0 = WETH_ARBITRUM;
//       usdt = USDT_ARBITRUM;
//       usdc = USDC_ARBITRUM;
//       break;
//     default:
//       token0 = "";
//       usdt = "";
//       usdc = "";
//     }

//     axios.post(TheGraphUrls[network], {
//       query: `
//         {
//           pools (
//             first: ${2 + (tokens as any ? (Object.values(tokens as any)?.length || 0) : 0)},
//             orderBy: totalValueLockedUSD,
//             orderDirection:desc
//             where: {
//               token0: "${token0}"
//               token1_in: [
//                 "${usdt}",
//                 "${usdc}"
//                 ${tokens
//     ? `, ${(Object.values(tokens as any) as Tokens[])
//       .filter(t => t.address !== "-"
//             && t.address?.toLowerCase() !== usdt
//             && t.address?.toLowerCase() !== usdc)
//       .map(t => `"${t.address}"`)
//       .join(", ")}`
//     : ""}
//               ]
//             }
//           ) {
//             id
//             token0 {
//               symbol
//               id
//             }
//             token1 {
//               symbol
//               id
//             }
//             token0Price
//             token1Price
//           }
//         }
//       `,
//       variables: null
//     })
//       .then(res => {
//         const pools = res?.data?.data?.pools;

//         if (pools) {
//           const prices = {};

//           const wrappedNativeToUsd =
//             parseFloat(
//               pools.find(t => t.token0?.symbol?.toLowerCase() === "eth" && t.token1?.symbol?.toLowerCase() === "usdt")?.token1Price
//               || pools.find(t => t.token0?.symbol?.toLowerCase() === "eth" && t.token1?.symbol?.toLowerCase() === "usdc")?.token1Price
//               || "0"
//             );

//           pools.forEach(t => {
//             prices[t.token1?.id?.toLowerCase()] = {
//               ...t,
//               usd: parseFloat(t.token1Price) ? wrappedNativeToUsd / parseFloat(t.token1Price) : 0,
//               ...(tokens as any)?.[t.token1?.id?.toLowerCase()]
//             };
//           });

//           prices["native"] = {
//             usd: wrappedNativeToUsd,
//             ...(tokens as any)?.["-"]
//           };

//           resolve(prices);
//         }

//         resolve(null);
//       })
//       .catch(e => {
//         console.error(e);
//         reject(e);
//       });
//   });
// };

// export const useQueryPrices = () => {
//   const getQueryNativeTokenPrice = async (dateFrom = moment().format("YYYY-MM-DD"), dateTo: string | null = null, network?: string) => {
//     if (["OPTIMISM", "POLYGON", "ARBITRUM"].includes(network || "")) {
//       return await getTheGraphPrices({
//         dateFrom,
//         dateTo,
//         network
//       });
//       //    } else if (['ETH', 'BSC'].includes(network || '')) {
//     } else { // ETH and BSC
//       return await getBitQueryPrices({
//         network,
//         dateFrom,
//         dateTo,
//         isNative: true
//       });
//     }
//     return {};
//   };

//   const getQueryPrices = async (
//     tokens: any,
//     dateFrom = moment().format("YYYY-MM-DD"),
//     dateTo: string | null = null,
//     network?: string,
//     limit?: number | string,
//     timeInterval?: string,
//     additionalBaseCurrencies?: any[] | null,
//     getTrades?: boolean,
//     timeIntervalSort?: string,
//     useMinTradedSum?: boolean
//   ) => {
//     if (["OPTIMISM", "POLYGON", "ARBITRUM"].includes(network || "")) {
//       return await getTheGraphPrices({
//         dateFrom,
//         dateTo,
//         tokens,
//         network
//       });
//       //    } else if (['ETH', 'BSC'].includes(network || '')) {
//     } else { // ETH and BSC
//       return await getBitQueryPrices({
//         network,
//         dateFrom,
//         dateTo,
//         tokens,
//         limit,
//         timeInterval,
//         additionalBaseCurrencies,
//         getTrades,
//         timeIntervalSort,
//         useMinTradedSum
//       });
//     }
//     return {};
//   };

//   const getBitQueryPricesWithDefaults = async (
//     token0Address,
//     token1Address
//   ) => {
//     return new Promise((resolve) => {
//       const date = moment().subtract(1, "days").format("YYYY-MM-DD");
//       const query = new window.widgets.query(`
//         {
//           BNB: ethereum(network: bsc) {
//             dexTrades(
//               options: {limitBy: {each: "quoteCurrency.address", limit: 1}, desc: "timeInterval.minute"}
//               date: {after: "${date}"}
//               tradeAmountUsd: {gt: 10}
//               baseCurrency: {is: "${WBNB_BSC}"}
//               quoteCurrency: {in: [
//                   "${BUSD_BSC}",
//                   "${USDT_BSC}",
//                   "${USDC_BSC}",
//                   "${token0Address}",
//                   "${token1Address}"
//                 ]}
//             ) {
//               tradeAmount(in: USD)
//               timeInterval {minute}
//               baseCurrency {
//                 symbol
//                 address
//               }
//               quoteCurrency {
//                 symbol
//                 address
//               }
//               quotePrice
//             }
//           }

//           USDT: ethereum(network: bsc) {
//             dexTrades(
//               options: {limitBy: {each: "quoteCurrency.address", limit: 1}, desc: "timeInterval.minute"}
//               date: {after: "${date}"}
//               tradeAmountUsd: {gt: 10}
//               baseCurrency: {is: "${USDT_BSC}"}
//               quoteCurrency: {in: [
//                   "${BUSD_BSC}",
//                   "${token0Address}",
//                   "${token1Address}"
//                 ]}
//             ) {
//               tradeAmount(in: USD)
//               timeInterval {minute}
//               baseCurrency {
//                 symbol
//                 address
//               }
//               quoteCurrency {
//                 symbol
//                 address
//               }
//               quotePrice
//             }
//           }

//           USDC: ethereum(network: bsc) {
//             dexTrades(
//               options: {limitBy: {each: "quoteCurrency.address", limit: 1}, desc: "timeInterval.minute"}
//               date: {after: "${date}"}
//               tradeAmountUsd: {gt: 10}
//               baseCurrency: {is: "${USDC_BSC}"}
//               quoteCurrency: {in: [
//                   "${token0Address}",
//                   "${token1Address}"
//                 ]}
//             ) {
//               tradeAmount(in: USD)
//               timeInterval {minute}
//               baseCurrency {
//                 symbol
//                 address
//               }
//               quoteCurrency {
//                 symbol
//                 address
//               }
//               quotePrice
//             }
//           }

//           Pair: ethereum(network: bsc) {
//             dexTrades(
//               options: {limitBy: {each: "quoteCurrency.address", limit: 1}, desc: "timeInterval.minute"}
//               date: {after: "${date}"}
//               tradeAmountUsd: {gt: 10}
//               baseCurrency: {is: "${token0Address}"}
//               quoteCurrency: {is: "${token1Address}"}
//             ) {
//               tradeAmount(in: USD)
//               timeInterval {minute}
//               baseCurrency {
//                 symbol
//                 address
//               }
//               quoteCurrency {
//                 symbol
//                 address
//               }
//               quotePrice
//             }
//           }
//         }
//       `);

//       query.request({}, (res: any) => {
//         resolve(res);
//       });
//     });
//   };

//   return { getQueryPrices, getQueryNativeTokenPrice, getBitQueryPricesWithDefaults };
// };
