import styled from "styled-components";

const Top = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #c2c2c2;
`;

const Box = styled.div`
  display: flex;
  align-items: center;
`;

const TopText = styled.span`
  margin-left: 16px;
  color: #ff513d;
  font-size: 18px;
  font-weight: 500;
  line-height: 155%;
  text-transform: uppercase;
`;

const Content = styled.div`
  padding: 20px 20px 40px;
`;

const Text = styled.div`
  color: #000;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  margin-bottom: 5px;
`;

const SubText = styled.div`
  color: #858585;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-bottom: 30px;
`;

export default {
  Top,
  Box,
  TopText,
  Content,
  Text,
  SubText,
};
