import styled from "styled-components";

const Button = styled.button`
  padding: 12px 40px;
  color: ${(props) => props.theme.Colors.Black};
  background: linear-gradient(90deg, #b6e82f 0%, #50e0ed 100%);
  border-radius: 10px;
`;

export { Button };
