import React from "react";

const GnosisIcon = (props) => (
  <svg
    {...props}
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="15" cy="15" r="15" fill="white" />
    <g clipPath="url(#clip0_8588_11118)">
      <circle cx="15" cy="15" r="9" fill="white" />
      <path
        d="M22.729 10.3861C23.5359 11.7356 24 13.3137 24 15C24 19.9705 19.9705 24 15 24C10.0295 24 6 19.9705 6 15C6 13.2862 6.47854 11.6853 7.30988 10.3221L8.65481 11.744C7.76251 12.9632 7.74498 14.6723 8.72035 15.9228C9.87173 17.3995 11.9817 17.6899 13.4859 16.5994L15.0152 18.1394L16.6208 16.5697C18.1265 17.6586 20.2357 17.3668 21.3863 15.8885C22.3602 14.6373 22.3419 12.9281 21.4481 11.7097L22.7297 10.3861H22.729Z"
        fill="#3E6957"
      />
      <path
        d="M15 6C12.1074 6 9.5334 7.36475 7.88672 9.4854L15.0229 16.6558L22.159 9.54483C20.5146 7.38989 17.92 6 15 6ZM15.0229 15.0221L9.4694 9.44272C10.7237 8.00864 13.1948 7.13767 15.0061 7.13767C17.0399 7.13767 19.4288 8.0955 20.5756 9.48692L15.0229 15.0221Z"
        fill="#3E6957"
      />
      <path
        d="M20.6241 12.4428C21.2345 13.2711 21.2505 14.4346 20.5891 15.2888C19.808 16.297 18.3724 16.4997 17.3452 15.7605L20.6241 12.4435V12.4428Z"
        fill="#3E6957"
      />
      <path
        d="M9.38178 12.4428C8.77142 13.2711 8.75542 14.4346 9.41683 15.2888C10.1979 16.297 11.6335 16.4997 12.6607 15.7605L9.38178 12.4435V12.4428Z"
        fill="#3E6957"
      />
    </g>
    <circle cx="15" cy="15" r="14.5" stroke="#3E6957" />
    <defs>
      <clipPath id="clip0_8588_11118">
        <rect width="18" height="18" fill="white" transform="translate(6 6)" />
      </clipPath>
    </defs>
  </svg>
);

export default GnosisIcon;
