import styled, { css } from "styled-components";
import { default as NotStyledDropdownIcon } from "./_resources/DropdownIcon";

type DropdownTypes = {
  $active?: boolean;
};

const Wrapper = styled.div`
  position: relative;
`;

const Top = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 2;
  position: relative;
  &:disabled {
    cursor: default;
    opacity: 0.7;
  }
`;

const Label = styled.span`
  color: ${(props) => props.theme.Colors.Black};
  font-weight: 300;
  font-size: 14px;
  line-height: 145%;
`;

const DropdownIcon = styled(NotStyledDropdownIcon)<DropdownTypes>`
  transition: transform 0.2s ease;
  width: 16px;
  height: 16px;
  margin-left: 10px;
  ${(props) =>
    props.$active &&
    css`
      transform: rotate(180deg);
    `};
`;

const Dropdown = styled.ul<DropdownTypes>`
  position: absolute;
  top: 20px;
  left: -10px;
  min-width: calc(100% + 20px);
  background: #fff;
  box-shadow: 0 2px 6px rgba(0, 111, 128, 0.08);
  border-radius: 16px;
  z-index: 1;
  transform: scaleY(0);
  transition: transform 0.2s ease;
  padding: 10px 10px 10px;
  white-space: nowrap;
  ${(props) =>
    props.$active &&
    css`
      transform: scaleY(1);
    `};
`;
const Item = styled.li<DropdownTypes>`
  cursor: pointer;
  transition: color 0.2s ease;
  font-weight: 300;
  font-size: 14px;
  line-height: 145%;
  color: ${(props) => props.theme.Colors.BackgroundGray6};

  &:hover {
    font-weight: 400;
    color: ${(props) => props.theme.Colors.BackgroundGray6};
  }

  ${(props) =>
    props.$active &&
    css`
      font-weight: 700;
      color: ${(props_) => props_.theme.Colors.Black};
      &:hover {
        font-weight: 700;
        color: ${(props_) => props_.theme.Colors.Black};
      }
    `};
`;

export default {
  Wrapper,
  Top,
  Label,
  DropdownIcon,
  Dropdown,
  Item,
};
