import { useCallback, useEffect, useState } from "react";

const useResize = (callback?: () => void) => {
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState<number>(window.innerHeight);

  const onResize = useCallback(() => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);

    if (callback && typeof callback === "function") {
      callback();
    }
  }, [callback]);

  useEffect(() => {
    window.addEventListener("resize", onResize);
    onResize();

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [onResize]);

  return {
    windowWidth,
    windowHeight,
  };
};

export { useResize };
