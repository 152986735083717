import styled from "styled-components";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 65, 0.2);
  z-index: 1001;
`;

const Modal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  min-width: fit-content;
  height: fit-content;
  width: 100%;
  max-width: 500px;
  max-height: 100%;
  background: ${(props) => props.theme.Colors.White};
  border-radius: 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
  pointer-events: auto;
  padding: 0;
  z-index: 1002;
  overflow: auto;
`;

export { Overlay, Modal };
