import React from "react";

const opBNBIcon = ({ ...props }) => (
  <svg
    {...props}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="20" cy="20" r="19.5" fill="white" stroke="#F1B90C" />
    <g clipPath="url(#clip0_9039_18958)">
      <path
        d="M12.0674 9.60593L20.0946 5L27.8803 9.60593L25.0084 11.3376L20.0946 8.50699L15.0243 11.3376L12.0674 9.60593Z"
        fill="#F1B90C"
      />
      <path
        d="M17.0918 12.5003L19.9739 10.7887L22.8446 12.5003L20.0941 14.1794L17.0918 12.5003Z"
        fill="#F1B90C"
      />
      <path
        d="M25.094 26.2678L25.0181 22.9508L27.9489 21.3432L28.0634 24.5305L25.094 26.2678Z"
        fill="#F1B90C"
      />
      <path
        d="M14.9074 26.2678L14.9833 22.9508L12.0514 21.3432L11.938 24.5305L14.9074 26.2678Z"
        fill="#F1B90C"
      />
      <path
        d="M19.9744 29.2325L17.0923 27.5847V21.6898L12.0674 18.7742V15.3197L14.9835 13.6719L19.9687 16.5875L25.0186 13.6719L27.8803 15.4159L28.064 18.8066L22.8452 21.6585V27.5523L19.9744 29.2325Z"
        fill="#F1B90C"
      />
      <path
        d="M9.95692 14.1794L12.9138 12.4835L9.95692 10.7887L7 12.4835V15.8273L9.95692 17.6339V14.1794Z"
        fill="#F1B90C"
      />
      <path
        d="M30.0432 14.1794L27.0874 12.4835L30.0432 10.7887L33.0001 12.4835V15.8273L30.0432 17.6339V14.1794Z"
        fill="#F1B90C"
      />
      <path
        d="M7 18.2353V27.4897L14.983 32.0844V28.725L9.95692 25.7457V19.929L7 18.2353Z"
        fill="#F1B90C"
      />
      <path
        d="M32.9999 18.2353V27.4897L25.0181 32.0844V28.725L30.0441 25.7457V19.929L32.9999 18.2353Z"
        fill="#F1B90C"
      />
      <path
        d="M17.0918 29.929V33.3197L19.9739 35L22.8446 33.3197V29.929L19.9739 31.5142L17.0918 29.929Z"
        fill="#F1B90C"
      />
    </g>
    <defs>
      <clipPath id="clip0_9039_18958">
        <rect width="26" height="30" fill="white" transform="translate(7 5)" />
      </clipPath>
    </defs>
  </svg>
);

export default opBNBIcon;
