import { useRef, useCallback } from "react";

const useDebounce = (
  callback: (...args) => void,
  timeOutInMilliseconds: number,
) => {
  const timer = useRef<NodeJS.Timeout | null>(null);

  const debounce = useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }

    timer.current = setTimeout(() => {
      callback();
    }, timeOutInMilliseconds);
  }, [timeOutInMilliseconds, callback]);

  return debounce;
};

export { useDebounce };
