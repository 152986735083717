import { ITokenDisplayable } from "shared/types";
import { WETH9 } from "@uniswap/sdk-core";
import { WBNB } from "./addresses";

export const ROOBEE_TOKEN_KOVAN: ITokenDisplayable = {
  name: "Roobee",
  symbol: "ROOBEE",
  decimals: 18,
  address: "0x4354056BBF033Eb632C0d21F59965f2566B6609B",
  img: "/svg/global/select/ROOBEE.svg",
  disabled: false,
  isNative: false,
};

export const WETH_OPTIMISTIC: ITokenDisplayable = {
  name: "Wrapped Ether",
  symbol: "WETH",
  decimals: 18,
  address: "0x4200000000000000000000000000000000000006",
  img: "/svg/global/select/WETH.svg",
  disabled: false,
  isNative: false,
};

export const BNB: ITokenDisplayable = {
  name: "BNB",
  symbol: "BNB",
  decimals: 18,
  address: WBNB,
  img: "/images/invest/logos/BNB.png",
  disabled: false,
  isNative: true,
};

export const ETH_MAINNET: ITokenDisplayable = {
  name: "Ether",
  symbol: "ETH",
  decimals: 18,
  address: WETH9[1].address,
  img: "/svg/global/select/ETH.svg",
  disabled: true,
  isNative: true,
};

export const ETH_KOVAN: ITokenDisplayable = {
  name: "Ether",
  symbol: "ETH",
  decimals: 18,
  address: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
  img: "/svg/global/select/ETH.svg",
  disabled: false,
  isNative: true,
};

export const ETH_OPTIMISTIC: ITokenDisplayable = {
  name: "Ether",
  symbol: "ETH",
  decimals: 18,
  address: WETH_OPTIMISTIC.address,
  img: "/svg/global/select/ETH.svg",
  disabled: false,
  isNative: true,
};

export const ETH_ARBITRUM: ITokenDisplayable = {
  name: "Ether",
  symbol: "ETH",
  decimals: 18,
  address: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
  img: "/svg/global/select/ETH.svg",
  disabled: false,
  isNative: true,
};

export const MATIC: ITokenDisplayable = {
  name: "Matic",
  symbol: "MATIC",
  decimals: 18,
  address: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
  img: "/svg/global/select/MATIC.svg",
  disabled: false,
  isNative: true,
};

export const MATIC_TESTNET: ITokenDisplayable = {
  name: "Matic",
  symbol: "MATIC",
  decimals: 18,
  address: "0x9c3C9283D3e44854697Cd22D3Faa240Cfb032889",
  img: "/svg/global/select/MATIC.svg",
  disabled: false,
  isNative: true,
};

export const USDT_MAINNET: ITokenDisplayable = {
  name: "Tether USD",
  symbol: "USDT",
  decimals: 6,
  address: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
  img: "/svg/global/select/TUSDT.svg",
  disabled: false,
  isNative: false,
};

export const USDT_OPTIMISM: ITokenDisplayable = {
  name: "Tether USD",
  symbol: "USDT",
  decimals: 6,
  address: "0x94b008aA00579c1307B0EF2c499aD98a8ce58e58",
  img: "/svg/global/select/TUSDT.svg",
  disabled: false,
  isNative: false,
};

export const USDT_POLYGON: ITokenDisplayable = {
  name: "Tether USD",
  symbol: "USDT",
  decimals: 6,
  address: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
  img: "/svg/global/select/TUSDT.svg",
  disabled: false,
  isNative: false,
};

export const USDT_ARIBTRUM: ITokenDisplayable = {
  name: "Tether USD",
  symbol: "USDT",
  decimals: 6,
  address: "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
  img: "/svg/global/select/TUSDT.svg",
  disabled: false,
  isNative: false,
};

export const USDT_BSC: ITokenDisplayable = {
  name: "Tether USD",
  symbol: "USDT",
  decimals: 18,
  address: "0x55d398326f99059fF775485246999027B3197955",
  img: "/svg/global/select/TUSDT.svg",
  disabled: false,
  isNative: false,
};
