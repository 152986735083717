import React from "react";
import { Link } from "react-router-dom";
import { TabType } from "./types";
import "./styles.scss";

type Props = {
  active: TabType;
  setActive?: any;
  className?: string;
  options: TabType[];
};

const Tabs = ({ active, setActive, options, className = "" }: Props) => {
  const ListItem = ({ item }: { item: TabType }): JSX.Element => {
    return item?.link ? (
      <Link to={item.link} className={"card-tabs__item"}>
        {item.title}
      </Link>
    ) : (
      <span className={"card-tabs__item"}>{item.title}</span>
    );
  };

  return (
    <div className={`card-tabs ${className}`.trim()}>
      <ul>
        {options.map((item, index) => (
          <li
            key={index}
            onClick={() => (setActive ? setActive.bind(this, item) : {})}
            className={active.slug === item.slug ? "active" : ""}
          >
            <ListItem item={item} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Tabs;
