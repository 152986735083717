import { useState, useEffect, useCallback } from "react";
import { FeeAmount } from "@uniswap/v3-sdk";
import { useWeb3React } from "@web3-react/core";

import { UNISWAP_FACTORY_V3, ZERO_ADDRESS } from "shared/constants";
import { UniswapV3Pool, UniswapV3Factory } from "shared/constants/abis/types";
import { UniswapV3PoolAbi, UniswapV3FactoryAbi } from "shared/constants";
import { getContract } from "utils/getContract";

const useUniswapV3Pool = (
  token0: string | null | undefined,
  token1: string | null | undefined,
  fee: FeeAmount | undefined,
): {
  poolContract: UniswapV3Pool | null;
  poolDoesntExist: boolean;
  updatePool: () => void;
} => {
  const { account, chainId, library } = useWeb3React();
  const [poolContract, setPoolContract] = useState<UniswapV3Pool | null>(null);
  const [poolDoesntExist, setPoolDoesntExist] = useState<boolean>(false);
  const [forceStateUpdate, setForceStateUpdate] = useState<boolean>(false);

  const updatePool = useCallback(() => {
    setForceStateUpdate((prevState) => !prevState);
  }, [setForceStateUpdate]);

  useEffect(() => {
    if (!!library && !!chainId && !!token0 && !!token1 && !!fee) {
      const factoryAddress = UNISWAP_FACTORY_V3[chainId];

      if (factoryAddress) {
        const factoryContract = getContract<UniswapV3Factory>(
          UniswapV3FactoryAbi,
          factoryAddress,
          account ? library.getSigner() : library,
        );

        factoryContract.getPool(token0, token1, fee).then((poolAddress) => {
          if (poolAddress === ZERO_ADDRESS) {
            setPoolContract(null);
            setPoolDoesntExist(true);
          } else {
            const poolContract_ = getContract<UniswapV3Pool>(
              UniswapV3PoolAbi,
              poolAddress,
              account ? library.getSigner() : library,
            );
            setPoolContract(poolContract_);
            setPoolDoesntExist(false);
          }
        });
      }
    } else {
      setPoolContract(null);
      setPoolDoesntExist(false);
    }
  }, [forceStateUpdate, chainId, library, token0, token1, fee, account]);

  return { poolContract, poolDoesntExist, updatePool };
};

export { useUniswapV3Pool };
