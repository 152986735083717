import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Styled from "./styled";
import {
  FormattedNetwork,
  FormattedWallet,
} from "../AuthorizeModal/components/AuthorizeModal/types";
import { IWallet, Network, NETWORKS, WALLETS } from "shared/constants";
import { useAuthorizeModal, useClickOutside, useConnect } from "shared/hooks";
import { useWeb3React } from "@web3-react/core";
import { useAvailableConnectors } from "shared/hooks";

const WalletInfo: FC = () => {
  const [selectedNetwork, setSelectedNetwork] = useState<Network>(NETWORKS[0]);
  const [selectedWallet, setSelectedWallet] = useState<IWallet>(WALLETS[0]);
  const { hideModal } = useAuthorizeModal();
  const { chainId } = useWeb3React();
  const { connect } = useConnect();
  const ModalRef = useRef<HTMLDivElement>(null);
  const availableConnectors = useAvailableConnectors();
  useClickOutside(ModalRef, hideModal);

  const setNetwork = useCallback((network: FormattedNetwork) => {
    setSelectedNetwork(network);
  }, []);

  const setWallet = useCallback((wallet: FormattedWallet) => {
    setSelectedWallet(wallet);
  }, []);

  const connectWallet = useCallback(
    async (wallet: FormattedWallet) => {
      if (!wallet.disabled) {
        await connect(wallet, selectedNetwork);
        hideModal();
      }
    },
    [connect, hideModal, selectedNetwork],
  );

  useEffect(() => {
    if (chainId) {
      const newNetwork = NETWORKS.find(
        (network) => network.chainId === chainId,
      );

      if (newNetwork) {
        setSelectedNetwork(newNetwork);
      }
    }
  }, [chainId]);

  const onSelectedWallet = useCallback(
    (wallet) => {
      setWallet(wallet);
      connectWallet(wallet as FormattedWallet);
    },
    [connectWallet, setWallet],
  );

  const formattedNetworks = useMemo(() => {
    return NETWORKS.map((network) => ({
      ...network,
      disabled: false,
    }));
  }, []);

  const formattedWallets = useMemo(() => {
    const disabledWallets: any = [];
    const enabledWallets: any = [];

    for (let walletIndex = 0; walletIndex < WALLETS.length; walletIndex++) {
      const wallet = WALLETS[walletIndex];
      const disabled = !wallet.supportedNetworkIds.includes(
        selectedNetwork.chainId,
      );

      if (disabled) {
        disabledWallets.push({ ...wallet, disabled });
      } else {
        enabledWallets.push({ ...wallet, disabled });
      }
    }

    return [...enabledWallets, ...disabledWallets];
  }, [selectedNetwork]);

  return (
    <Styled.Wrapper>
      <Styled.Step>
        <Styled.StepTitle>
          <Styled.TitleText mLeft>Chain</Styled.TitleText>
        </Styled.StepTitle>
        <Styled.AuthCardGrid>
          {formattedNetworks.map((network) => (
            <Styled.AuthCard
              key={network.chainId}
              $active={network.name === selectedNetwork.name}
              onClick={() => setNetwork(network)}
            >
              <Styled.AuthCardImage
                src={`/svg/networks/${network.icon}.svg`}
                alt="network"
              />
              <Styled.AuthCardName>{network.name}</Styled.AuthCardName>
            </Styled.AuthCard>
          ))}
        </Styled.AuthCardGrid>
      </Styled.Step>
      <Styled.Step>
        <Styled.StepTitle>
          <Styled.TitleText mLeft>Wallet app</Styled.TitleText>
        </Styled.StepTitle>
        <Styled.AuthCardGrid>
          {formattedWallets.map((wallet) => (
            <Styled.AuthCard
              key={wallet.name}
              $disable={
                !wallet.supportedNetworkIds.includes(selectedNetwork.chainId)
              }
              onClick={() => onSelectedWallet(wallet)}
              $active={
                wallet.name === selectedWallet.name ||
                availableConnectors.includes(wallet.slug)
              }
            >
              <Styled.AuthCardImage
                src={`/svg/wallets/${wallet.image}`}
                alt={wallet.name}
              />
              <Styled.AuthCardName>{wallet.name}</Styled.AuthCardName>
            </Styled.AuthCard>
          ))}
        </Styled.AuthCardGrid>
      </Styled.Step>
    </Styled.Wrapper>
  );
};

export default WalletInfo;
