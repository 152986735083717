import React from "react";
import { SelectMinimalisticOption } from "./types";
import { useState } from "react";
import Styled from "./styled";
import { useWeb3React } from "@web3-react/core";

const SelectMinimalistic = ({
  selected,
  options,
  onSelect,
  showSelectedInList,
  className,
}: {
  selected: SelectMinimalisticOption;
  options: SelectMinimalisticOption[];
  onSelect: any;
  showSelectedInList: boolean;
  className?: string;
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { account } = useWeb3React();
  const onItemClick = (item) => {
    setDropdownOpen(false);
    onSelect(item);
  };

  return (
    <Styled.Wrapper className={className}>
      <Styled.Top
        disabled={!account}
        onClick={setDropdownOpen.bind(this, !dropdownOpen)}
      >
        <Styled.Label>{selected.label}</Styled.Label>
        <Styled.DropdownIcon $active={dropdownOpen} />
      </Styled.Top>
      <Styled.Dropdown $active={dropdownOpen}>
        {options
          .filter((o) =>
            !showSelectedInList ? o.value !== selected.value : true,
          )
          .map((item, index) => (
            <Styled.Item
              key={index}
              $active={selected.value === item.value}
              onClick={onItemClick.bind(this, item)}
            >
              {item.label}
            </Styled.Item>
          ))}
      </Styled.Dropdown>
    </Styled.Wrapper>
  );
};

export default SelectMinimalistic;
