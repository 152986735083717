import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { DashboardState } from "./types";
import { fetchAssets } from "./thunks";

const extraReducers = (builder: ActionReducerMapBuilder<DashboardState>) => {
  builder.addCase(fetchAssets.fulfilled, (state, { payload }) => {
    state.assets = payload;
  });
};

export default extraReducers;
