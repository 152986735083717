import { useState, useEffect } from "react";
import axios from "axios";
import { useWeb3React } from "@web3-react/core";
import { ITokenDisplayable } from "shared/types";
import {
  tokenListsChained,
  ETH_ARBITRUM,
  ETH_KOVAN,
  ETH_MAINNET,
  ETH_OPTIMISTIC,
  MATIC,
  MATIC_TESTNET,
  ROOBEE_TOKEN_KOVAN,
} from "shared/constants";

const nativeTokenSymbols = ["MATIC", "ETH", "BNB"];

const useTokenList = () => {
  const { chainId } = useWeb3React();

  const [tokens, setTokens] = useState<ITokenDisplayable[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (
      chainId === 1 ||
      chainId === 10 ||
      chainId === 42 ||
      chainId === 56 ||
      chainId === 137 ||
      chainId === 80001 ||
      chainId === 42161 ||
      chainId === 421611
    ) {
      setIsLoading(true);
      axios
        .get(tokenListsChained[chainId])
        .then((res) => {
          const rawTokens = res.data.tokens;

          const formattedTokens = rawTokens
            .filter((token) => token.chainId === chainId)
            .map((token) => {
              const formattedToken: ITokenDisplayable = {
                address: token.address,
                decimals: token.decimals,
                img: `https://storage.roobee.io/files/tokens/logos/${token.symbol}.png`,
                name: token.name,
                symbol: token.symbol,
                disabled: false,
                isNative: nativeTokenSymbols.includes(
                  token.symbol.toUpperCase(),
                ),
              };

              return formattedToken;
            });

          if (chainId === 1) {
            formattedTokens.unshift(ETH_MAINNET);
          }

          if (chainId === 10) {
            formattedTokens.unshift(ETH_OPTIMISTIC);
          }

          if (chainId === 42) {
            formattedTokens.unshift(ROOBEE_TOKEN_KOVAN);
            formattedTokens.unshift(ETH_KOVAN);
          }

          if (chainId === 137) {
            formattedTokens.unshift(MATIC);
          }

          if (chainId === 80001) {
            formattedTokens.unshift(MATIC_TESTNET);
          }

          if (chainId === 42161) {
            formattedTokens.unshift(ETH_ARBITRUM);
          }

          setTokens(formattedTokens);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [chainId]);

  return { tokenList: tokens, tokenListLoading: isLoading };
};

export { useTokenList };
