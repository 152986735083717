import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo,
} from "react";
import { createPortal } from "react-dom";
import { NETWORKS, WALLETS, termsAccepted } from "shared/constants";
import type { FormattedNetwork, FormattedWallet } from "./types";
import { useWeb3React } from "@web3-react/core";
import { Network } from "shared/constants";
import { useAuthorizeModal } from "../../AuthorizeModalProvider";
import { useClickOutside, useConnect } from "shared/hooks";
import { useTranslation } from "react-i18next";
import NoPluginModal from "app/components/Modals/NoPluginModal";
import Styled from "./styled";

const AuthorizeModal = () => {
  const { chainId } = useWeb3React();
  const { hideModal } = useAuthorizeModal();
  const { connect } = useConnect();
  const ModalRef = useRef<HTMLDivElement>(null);
  useClickOutside(ModalRef, hideModal);

  const [isTermsAccepted, setIsTermsAccepted] = useState<boolean>(false);
  const [selectedNetwork, setSelectedNetwork] = useState<Network>(NETWORKS[0]);
  const [showNoPluginModal, setShowNoPluginModal] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [collapsed, setCollapsed] = useState<boolean>(true);

  const setNetwork = useCallback(
    (network: FormattedNetwork) => {
      if (isTermsAccepted && !network.disabled) {
        setSelectedNetwork(network);
      }
    },
    [isTermsAccepted],
  );

  const connectWallet = useCallback(
    async (wallet: FormattedWallet) => {
      if (isTermsAccepted && !wallet.disabled) {
        await connect(wallet, selectedNetwork);
        hideModal();
      }
    },
    [isTermsAccepted, connect, selectedNetwork, hideModal],
  );

  useEffect(() => {
    if (chainId) {
      const newNetwork = NETWORKS.find(
        (network) => network.chainId === chainId,
      );

      if (newNetwork) {
        setSelectedNetwork(newNetwork);
      }
    }
  }, [chainId]);

  useEffect(() => {
    //@ts-ignore
    if (!window.web3 && !window.BinanceChain && !window.bitkeep) {
      setShowNoPluginModal(true);
      setShowModal(false); // Добавленная строка
    } else {
      setShowModal(true); // Добавленная строка
    }
  }, []);

  const formattedNetworks = useMemo(() => {
    return NETWORKS.map((network) => ({
      ...network,
      disabled: !isTermsAccepted,
    }));
  }, [isTermsAccepted]);

  const collapsedFormattedNetworks = collapsed
    ? formattedNetworks.slice(0, 9)
    : formattedNetworks;

  const formattedWallets = useMemo(() => {
    const disabledWallets: any = [];
    const enabledWallets: any = [];

    for (let walletIndex = 0; walletIndex < WALLETS.length; walletIndex++) {
      const wallet = WALLETS[walletIndex];
      const disabled =
        !wallet.supportedNetworkIds.includes(selectedNetwork.chainId) ||
        !isTermsAccepted;

      if (disabled) {
        disabledWallets.push({ ...wallet, disabled });
      } else {
        enabledWallets.push({ ...wallet, disabled });
      }
    }

    return [...enabledWallets, ...disabledWallets];
  }, [isTermsAccepted, selectedNetwork]);

  useEffect(() => {
    if (localStorage.getItem(termsAccepted)) {
      setIsTermsAccepted(true);
    }
  }, []);

  const { t } = useTranslation();

  return createPortal(
    <Styled.Overlay>
      {showNoPluginModal ? (
        <NoPluginModal onClose={() => hideModal()} />
      ) : showModal ? (
        <Styled.Modal ref={ModalRef}>
          <Styled.Header>
            <Styled.WalletIcon />
            <span>Your account</span>
            <Styled.CloseIcon onClick={hideModal} />
          </Styled.Header>
          <Styled.Content>
            <Styled.Step>
              <Styled.StepTitle>
                <Styled.TitleText>
                  <span>
                    {t("AUTHORIZE_MODAL-common-accept")}&nbsp;
                    <Styled.Br />
                    <Styled.Link
                      href={`${window.location.href}docs/terms_of_use.pdf`}
                      target="_blank"
                    >
                      {t("COMMON-terms_of_service")}
                    </Styled.Link>
                    &ensp;
                    {t("COMMON-and").toLowerCase()}
                    &ensp;
                    <Styled.Link
                      href={`${window.location.href}docs/privacy_policy.pdf`}
                      target="_blank"
                    >
                      {t("COMMON-privacy_policy")}
                    </Styled.Link>
                  </span>
                </Styled.TitleText>
              </Styled.StepTitle>
              <Styled.AcceptCheckbox>
                {isTermsAccepted ? (
                  <Styled.CheckedIcon
                    onClick={() =>
                      setIsTermsAccepted((prevState) => !prevState)
                    }
                  />
                ) : (
                  <Styled.UncheckedIcon
                    onClick={() =>
                      setIsTermsAccepted((prevState) => !prevState)
                    }
                  />
                )}
                <Styled.AcceptCheckboxText>
                  {t("AUTHORIZE_MODAL-accept")}
                </Styled.AcceptCheckboxText>
              </Styled.AcceptCheckbox>
            </Styled.Step>
            <Styled.ScrollContent>
              <Styled.Step>
                <Styled.StepTitle>
                  <Styled.TitleText mLeft>
                    {t("AUTHORIZE_MODAL-choose_chain")}
                  </Styled.TitleText>
                </Styled.StepTitle>
                <Styled.AuthCardGrid>
                  {collapsedFormattedNetworks.map((network) => (
                    <Styled.AuthCard
                      key={network.chainId}
                      $active={network.name === selectedNetwork.name}
                      $disable={network.disabled}
                      onClick={() => setNetwork(network)}
                    >
                      <Styled.AuthNetworkImage chainId={network.chainId} />
                      <Styled.AuthCardName>{network.name}</Styled.AuthCardName>
                    </Styled.AuthCard>
                  ))}
                  <Styled.Button onClick={() => setCollapsed(!collapsed)}>
                    <Styled.Dots>...</Styled.Dots>
                    {collapsed ? "Show more" : "Hide"}
                  </Styled.Button>
                </Styled.AuthCardGrid>
              </Styled.Step>
              <Styled.Step>
                <Styled.StepTitle>
                  <Styled.TitleText mLeft>
                    {t("AUTHORIZE_MODAL-choose_wallet")}
                  </Styled.TitleText>
                </Styled.StepTitle>
                <Styled.AuthCardGrid>
                  {formattedWallets.map((wallet) => (
                    <Styled.AuthCard
                      key={wallet.name}
                      $disable={
                        !wallet.supportedNetworkIds.includes(
                          selectedNetwork.chainId,
                        ) || !isTermsAccepted
                      }
                      onClick={() => connectWallet(wallet as FormattedWallet)}
                    >
                      <Styled.AuthCardImage
                        src={`/svg/wallets/${wallet.image}`}
                        alt={wallet.name}
                      />
                      <Styled.AuthCardName>{wallet.name}</Styled.AuthCardName>
                    </Styled.AuthCard>
                  ))}
                </Styled.AuthCardGrid>
              </Styled.Step>
            </Styled.ScrollContent>
          </Styled.Content>
        </Styled.Modal>
      ) : null}
    </Styled.Overlay>,
    document.body,
  );
};

export default AuthorizeModal;
