import React, { useCallback, useState } from "react";

import { TooltipIcon } from "./_resources/TooltipIcon";

import "./styles.scss";

type Side = "right" | "up" | "down" | "left";

type Props = {
  dir: Side;
  children: React.ReactNode;
  icon?: JSX.Element;
  className?: string;
};

const Tooltip = ({ dir, className, children, icon }: Props) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const show = useCallback(() => {
    setIsVisible(true);
  }, []);

  const hide = useCallback(() => {
    setIsVisible(false);
  }, []);

  return (
    <div
      onMouseEnter={show}
      onMouseLeave={hide}
      className={`tooltip tooltip--${dir} ${className}`}
    >
      {icon ? icon : <TooltipIcon className="tooltip-icon" />}
      {isVisible && <div className="tooltip-content">{children}</div>}
    </div>
  );
};

export default Tooltip;
