import React from "react";

const PreloaderIcon = (props) => {
  return (
    <svg
      {...props}
      width="76"
      height="76"
      viewBox="0 0 76 76"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3261_43024)">
        <path
          d="M70.3421 48.2134C72.4927 48.8925 73.7056 51.1984 72.7997 53.2637C70.0634 59.5024 65.6883 64.9156 60.1067 68.9079C53.4135 73.6951 45.3472 76.1803 37.1204 75.9898C28.8937 75.7994 20.951 72.9435 14.4866 67.8516C8.02219 62.7598 3.38533 55.7071 1.27312 47.7538C-0.839078 39.8005 -0.312471 31.3765 2.77378 23.7482C5.86003 16.1199 11.3391 9.69958 18.3874 5.45243C25.4356 1.20528 33.6721 -0.639162 41.8585 0.196403C48.6854 0.893204 55.1698 3.42238 60.641 7.48134C62.4523 8.82509 62.5433 11.4289 61.013 13.0856V13.0856C59.4827 14.7423 56.9125 14.8179 55.0624 13.5281C50.9215 10.6409 46.0955 8.83842 41.0292 8.32132C34.6022 7.66534 28.136 9.11336 22.6026 12.4477C17.0692 15.782 12.7677 20.8225 10.3448 26.8113C7.92182 32.8001 7.50839 39.4136 9.16663 45.6575C10.8249 51.9014 14.4652 57.4383 19.5402 61.4358C24.6153 65.4333 30.8509 67.6753 37.3095 67.8249C43.7681 67.9744 50.1008 66.0233 55.3554 62.265C59.4975 59.3024 62.7933 55.343 64.9561 50.7816C65.9224 48.7438 68.1915 47.5342 70.3421 48.2134V48.2134Z"
          fill="url(#paint0_linear_3261_43024)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_3261_43024"
          x1="38"
          y1="0"
          x2="38"
          y2="76"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF500" />
          <stop offset="0.523958" stopColor="#B6E82F" />
          <stop offset="1" stopColor="#50E0ED" />
        </linearGradient>
        <clipPath id="clip0_3261_43024">
          <rect width="76" height="76" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PreloaderIcon;
