import React from "react";

const DisconnectIcon = ({ ...props }) => (
  <svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1807_29388)">
      <path
        d="M13.3307 2.96418C12.7545 2.35357 12.0735 1.842 11.3137 1.45676C9.80998 0.694345 8.08951 0.473333 6.44194 0.830933C4.79436 1.18853 3.32032 2.1029 2.268 3.42009C1.21568 4.73728 0.649343 6.37683 0.664353 8.0627C0.679364 9.74856 1.2748 11.3778 2.35041 12.676C3.42603 13.9743 4.91611 14.8622 6.56979 15.1904C8.22347 15.5186 9.93973 15.267 11.4296 14.478C12.1434 14.0999 12.7843 13.6096 13.3307 13.0306"
        stroke="black"
        strokeLinecap="round"
      />
      <path
        d="M12.6719 5.66406L14.8875 7.87966C14.9656 7.95777 14.9656 8.0844 14.8875 8.16251L12.6719 10.3781"
        stroke="black"
        strokeLinecap="round"
      />
      <path
        d="M14.7165 8.02114L8.0026 8.02069"
        stroke="black"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1807_29388">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default DisconnectIcon;
