import React from "react";

export const LinkIcon = ({ ...props }) => (
  <svg
    {...props}
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.51172 0.5V2.01172H11.1836L3.83594 9.35938L4.89062 10.4141L12.2383 3.06641V5.73828H13.75V0.5H8.51172ZM12.2383 12.4883H1.76172V2.01172H7V0.5H1.76172C1.33984 0.5 0.976563 0.652344 0.671875 0.957031C0.390625 1.23828 0.25 1.58984 0.25 2.01172V12.4883C0.25 12.9102 0.390625 13.2734 0.671875 13.5781C0.976563 13.8594 1.33984 14 1.76172 14H12.2383C12.6602 14 13.0117 13.8594 13.293 13.5781C13.5977 13.2734 13.75 12.9102 13.75 12.4883V7.25H12.2383V12.4883Z"
      fill="#0DC4E0"
    />
  </svg>
);
