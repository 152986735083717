import React from "react";

const ReminderIcon = ({ ...props }) => (
  <svg
    {...props}
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.9 14.3H12.1V16.5H9.9V14.3ZM9.9 5.5H12.1V12.1H9.9V5.5ZM11 0C4.917 0 0 4.95 0 11C0 13.9174 1.15893 16.7153 3.22183 18.7782C4.24327 19.7996 5.4559 20.6099 6.79048 21.1627C8.12506 21.7155 9.55546 22 11 22C13.9174 22 16.7153 20.8411 18.7782 18.7782C20.8411 16.7153 22 13.9174 22 11C22 9.55546 21.7155 8.12506 21.1627 6.79048C20.6099 5.4559 19.7996 4.24327 18.7782 3.22183C17.7567 2.20038 16.5441 1.39013 15.2095 0.837325C13.8749 0.284523 12.4445 0 11 0ZM11 19.8C8.66609 19.8 6.42778 18.8729 4.77746 17.2225C3.12714 15.5722 2.2 13.3339 2.2 11C2.2 8.66609 3.12714 6.42778 4.77746 4.77746C6.42778 3.12714 8.66609 2.2 11 2.2C13.3339 2.2 15.5722 3.12714 17.2225 4.77746C18.8729 6.42778 19.8 8.66609 19.8 11C19.8 13.3339 18.8729 15.5722 17.2225 17.2225C15.5722 18.8729 13.3339 19.8 11 19.8Z"
      fill="#FF8B02"
    />
  </svg>
);

export default ReminderIcon;
