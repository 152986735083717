import React from "react";

const SuccessIcon = (props) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22 12C22 14.1331 21.3179 16.2103 20.0534 17.9281C18.7889 19.646 17.0082 20.9145 14.9715 21.5483C12.9348 22.1822 10.7487 22.1481 8.7327 21.4512C6.71669 20.7542 4.97636 19.4309 3.76594 17.6745C2.55552 15.9181 1.93844 13.8207 2.00485 11.6887C2.07126 9.55663 2.81767 7.50168 4.13505 5.82403C5.45243 4.14638 7.27175 2.93393 9.32722 2.3638C11.3827 1.79368 13.5666 1.89576 15.56 2.65512M7 11L11 15L20.5 5.5"
      stroke="#00B259"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SuccessIcon;
