import React from "react";

const BitgertIcon = (props) => {
  return (
    <svg
      {...props}
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="14" cy="14" r="14" fill="white" />
      <path
        d="M9.33594 21.5024V20.016C9.33594 19.9202 9.38623 19.8314 9.46841 19.7821L11.2147 18.7343C11.3966 18.6252 11.628 18.7562 11.628 18.9683V19.9518C11.628 20.036 11.7193 20.0885 11.7921 20.0461L17.3238 16.8232C17.396 16.7812 17.3961 16.6769 17.324 16.6347L16.5361 16.1739C16.3565 16.0688 16.3559 15.8094 16.535 15.7035L18.3831 14.6105C18.4568 14.5669 18.5491 14.5704 18.6193 14.6194L20.0367 15.6097C20.2413 15.7526 20.3632 15.9864 20.3632 16.236V17.3606C20.3632 17.632 20.2193 17.883 19.9851 18.02L11.8321 22.7919C11.7484 22.8409 11.6533 22.8667 11.5564 22.8667H10.7002C9.94676 22.8667 9.33594 22.2558 9.33594 21.5024Z"
        fill="url(#paint0_linear_7401_100673)"
      />
      <path
        d="M9.66331 8.75967L14.5075 11.6441C14.6928 11.7545 14.6828 12.026 14.49 12.1226L12.8424 12.9474C12.7212 13.008 12.5769 12.9999 12.4632 12.926L11.8007 12.4954C11.7273 12.4477 11.6304 12.5015 11.6321 12.5891L11.6656 14.2811C11.6673 14.3643 11.7578 14.4151 11.8297 14.3732L17.3677 11.1467C17.4393 11.105 17.4402 11.0018 17.3692 10.959L11.7936 7.59343C11.7209 7.54952 11.6281 7.6019 11.6281 7.68687V8.53956C11.6281 8.79526 11.3481 8.95229 11.1299 8.81895L9.4666 7.8025C9.38549 7.75293 9.33602 7.66473 9.33602 7.56967V6.19468C9.33602 5.35078 10.0201 4.66666 10.864 4.66666H11.4793C11.5767 4.66666 11.6723 4.69272 11.7562 4.74214L19.8257 9.49441C20.1588 9.69058 20.3633 10.0483 20.3633 10.4349V11.2729C20.3633 11.6608 20.1574 12.0196 19.8224 12.2153L9.66433 18.1506C9.51883 18.2357 9.33596 18.1307 9.33592 17.9622L9.33334 8.94728C9.33329 8.77791 9.51778 8.67301 9.66331 8.75967Z"
        fill="url(#paint1_linear_7401_100673)"
      />
      <circle cx="14" cy="14" r="13.5" stroke="#6200DC" />
      <defs>
        <linearGradient
          id="paint0_linear_7401_100673"
          x1="9.79987"
          y1="22.8666"
          x2="20.5332"
          y2="15.8667"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7718E5" />
          <stop offset="1" stopColor="#10D9B6" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_7401_100673"
          x1="9.33325"
          y1="12.1333"
          x2="20.5333"
          y2="12.1333"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8009E9" />
          <stop offset="1" stopColor="#3610DC" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default BitgertIcon;
