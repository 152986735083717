import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import globalReducer from "./global";
import dashboardReducer from "./dashboard";
import venusReducer from "./venus";
import airdropReducer from "./airdrop";
import investReducer from "./invest";
import cartReducer from "./cart";

import type { VenusState } from "./venus/types";
import type { GlobalState } from "./global/types";
import type { DashboardState } from "./dashboard/types";
import type { AirdropState } from "./airdrop/types";
import type { InvestState } from "./invest/types";
import type { CartState } from "./cart/types";

const store = configureStore({
  devTools: process.env.NODE_ENV !== "production",
  reducer: {
    airdrop: airdropReducer,
    dashboard: dashboardReducer,
    global: globalReducer,
    venus: venusReducer,
    invest: investReducer,
    cart: cartReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export type State = {
  dashboard: DashboardState;
  global: GlobalState;
  venus: VenusState;
  airdrop: AirdropState;
  invest: InvestState;
  cart: CartState;
};

export default store;
