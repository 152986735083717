import React from "react";

const CloseIcon = (props) => {
  return (
    <svg
      {...props}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9844 1.42188L12.5781 0.015625L7 5.59375L1.42188 0.015625L0.015625 1.42188L5.59375 7L0.015625 12.5781L1.42188 13.9844L7 8.40625L12.5781 13.9844L13.9844 12.5781L8.40625 7L13.9844 1.42188Z"
        fill="#B8C4CC"
      />
    </svg>
  );
};

export default CloseIcon;
