import SUPPORTED_CHAIN_IDS from "./supportedChainIds";

export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";
export const ROOBEE_BRIDGE_ETH = "0xBfB9Edb68a27d50E7cFD703fE1107E4Dd3004dc3";
export const ROOBEE_BRIDGE_BSC = "0xf57F2dA6EAabe3a15267d2eBCd8a99765eE5c801";
export const BROOBEE_ROOBEE_BSC_BRIDGE =
  "0x402240F85D2Db172aF78985e2A0aC68cE41C9BEf";
export const ROOBEE_ETH = "0xa31b1767e09f842ecfd4bc471fe44f830e3891aa";
export const ROOBEE_BSC = "0xF77351D8f4eE853135961A936BB8d2e4fFa75f9D";
export const BROOBEE_BSC = "0xe64f5cb844946c1f102bd25bbd87a5ab4ae89fbe";
export const PANCAKE_ROUTER = "0x10ED43C718714eb63d5aA57B78B54704E256024E";
export const PANCAKE_FACTORY = "0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73";
export const SMART_CHEF = "0x3490F8a48B421647E853DF8Ec82610Ac60bBD727";
export const BROOBEE_BNB = "0x9b35AeDD22E0Ad5e456557C17FE7B5DEa863F385";
export const ROOBEE_BNB_FARM = "0x5c8D727b265DBAfaba67E050f2f739cAeEB4A6F9";
export const WBNB = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c";
export const AIRDROP = "0x7787E3415E0d851280f810C482dbA47b58553FB8";
export const DAO_STAKING = "0xd86563DFaec51330796E2d219088DF23A637a012";
export const BAD_RECIPIENT_ADDRESSES = [
  "0xBCfCcbde45cE874adCB698cC183deBcF17952812", // v2 factory
  "0xf164fC0Ec4E93095b804a4795bBe1e041497b92a", // v2 router 01
  "0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F", // v2 router 02
];
export const GNANA = "0xdDb3Bd8645775F59496c821E4F55A7eA6A6dc299";
export const BANANA = "0x603c7f932ED1fc6575303D8Fb018fDCBb0f39a95";

export const GNANA_ROOBEE = "0x3490F8a48B421647E853DF8Ec82610Ac60bBD727";

export const APE_PRICE_GETTER = "0x5e545322b83626c745FE46144a15C00C94cBD803";
export const APE_ROUTER = "0xcF0feBd3f17CEf5b47b0cD257aCf6025c5BFf3b7";

export const ROOBEE_ADDRESSES = {
  ETH: ROOBEE_ETH,
  BSC: BROOBEE_BSC,
};

export const ROOBEE_ADDRESSES_MULTICHAIN = {
  [SUPPORTED_CHAIN_IDS.ETH]: "0xa31b1767e09f842ecfd4bc471fe44f830e3891aa",
  [SUPPORTED_CHAIN_IDS.BSC]: "0xF77351D8f4eE853135961A936BB8d2e4fFa75f9D",
  [SUPPORTED_CHAIN_IDS.OPTIMISM]: "0xb12c13e66AdE1F72f71834f2FC5082Db8C091358",
  [SUPPORTED_CHAIN_IDS.POLYGON]: "0xFaFA220145DfA5C3Ec85B6FA8a75aEE2451CDe5E",
  [SUPPORTED_CHAIN_IDS.AVALANCHE]: "0x4036f3d9c45a20f44f0b8B85dD6CA33005fF9654",
  [SUPPORTED_CHAIN_IDS.ARBITRUM]: "0x3BD2dFd03BC7c3011ed7fb8c4d0949B382726cee",
};

export const UNISWAP_FACTORY_V3 = {
  1: "0x1F98431c8aD98523631AE4a59f267346ea31F984",
  10: "0x1F98431c8aD98523631AE4a59f267346ea31F984",
  42: "0x1F98431c8aD98523631AE4a59f267346ea31F984",
  137: "0x1F98431c8aD98523631AE4a59f267346ea31F984",
  80001: "0x1F98431c8aD98523631AE4a59f267346ea31F984",
  42161: "0x1F98431c8aD98523631AE4a59f267346ea31F984",
};

export const UNISWAP_AUTO_ROUTER = {
  1: "0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45",
  10: "0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45",
  42: "0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45",
  56: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
  137: "0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45",
  800001: "0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45",
  42161: "0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45",
};

export const WETH = {
  1: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
  10: "0x4200000000000000000000000000000000000006",
  56: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
  42: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
  42161: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
};

export const UNISWAP_V3_NON_FUNGIBLE_POSITION_MANAGER = {
  1: "0xC36442b4a4522E871399CD717aBDD847Ab11FE88",
  10: "0xC36442b4a4522E871399CD717aBDD847Ab11FE88",
  42: "0xC36442b4a4522E871399CD717aBDD847Ab11FE88",
  137: "0xC36442b4a4522E871399CD717aBDD847Ab11FE88",
  80001: "0xC36442b4a4522E871399CD717aBDD847Ab11FE88",
  42161: "0xC36442b4a4522E871399CD717aBDD847Ab11FE88",
};

export const SIMPLE_LIQUIDITY_UNISWAP = {
  1: ZERO_ADDRESS,
  10: "0xca5DF8527602bcE0D99B564588f1dc46111fC56F",
  42: "0x66d024817c4AF1dC48b123Ca05E7AaEA2dD3DFA9",
  137: "0xca5DF8527602bcE0D99B564588f1dc46111fC56F",
  80001: ZERO_ADDRESS,
  42161: "0xca5DF8527602bcE0D99B564588f1dc46111fC56F",
};

export const DCA_FACTORY = {
  [SUPPORTED_CHAIN_IDS.ETH]: "0x7306289b2533506f894668d3e2f9a9af01b39074",
  [SUPPORTED_CHAIN_IDS.BSC]: "0xea5997c26204c099d1b3d6266244bf9d280e220f",
  [SUPPORTED_CHAIN_IDS.OPTIMISM]: "0xcA8Aa001ED813841bD55994e7e590867570696Fc",
  [SUPPORTED_CHAIN_IDS.ARBITRUM]: "0xeA5997C26204c099D1B3D6266244bF9d280E220f",
  [SUPPORTED_CHAIN_IDS.POLYGON]: "0xeA5997C26204c099D1B3D6266244bF9d280E220f",
  [SUPPORTED_CHAIN_IDS.BLAST]: "0xdECc2f4F0B9ff275c5C17Fc329aA06220d503127",
};

export const ROOBEE_MULTICALL = {
  [SUPPORTED_CHAIN_IDS.ETH]: "0x7B16062a9fb7Ba604a2735655269D7a645F0f900",
  [SUPPORTED_CHAIN_IDS.OPTIMISM]: "0x7B16062a9fb7Ba604a2735655269D7a645F0f900",
  [SUPPORTED_CHAIN_IDS.BSC]: "0x7B16062a9fb7Ba604a2735655269D7a645F0f900",
  [SUPPORTED_CHAIN_IDS.POLYGON]: "0x7B16062a9fb7Ba604a2735655269D7a645F0f900",
  [SUPPORTED_CHAIN_IDS.ARBITRUM]: "0x7B16062a9fb7Ba604a2735655269D7a645F0f900",
};

export const ROOBEE_CALL_FORWARDER = {
  [SUPPORTED_CHAIN_IDS.ETH]: "0x35265a611728c190aF223cA89375a8F12CD65a03",
  [SUPPORTED_CHAIN_IDS.OPTIMISM]: "0x35265a611728c190aF223cA89375a8F12CD65a03",
  [SUPPORTED_CHAIN_IDS.BSC]: "0x35265a611728c190aF223cA89375a8F12CD65a03",
  [SUPPORTED_CHAIN_IDS.POLYGON]: "0x35265a611728c190aF223cA89375a8F12CD65a03",
  [SUPPORTED_CHAIN_IDS.ARBITRUM]: "0x35265a611728c190aF223cA89375a8F12CD65a03",
};
