import { useState, useEffect, useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import { UniswapV3NonFungiblePositionManager } from "shared/constants/abis/types";
import {
  UniswapV3NonFungiblePositionManagerAbi,
  UNISWAP_V3_NON_FUNGIBLE_POSITION_MANAGER,
} from "shared/constants";
import { useContract } from "shared/hooks";
import { Position } from "pages/LiquidityAction/types";
import { BigNumber } from "ethers";

const useExistingPositionData = (
  tokenId: BigNumber | undefined,
): {
  existingPositionData: Position | undefined;
  updateExistingPositionData: () => void;
} => {
  const { account, chainId } = useWeb3React();
  const [positionData, setPositionData] = useState<Position>();
  const [forceStateUpdate, setForceStateUpdate] = useState<boolean>(false);
  const positionManagerContract =
    useContract<UniswapV3NonFungiblePositionManager>(
      UniswapV3NonFungiblePositionManagerAbi,
      UNISWAP_V3_NON_FUNGIBLE_POSITION_MANAGER[chainId ?? 1],
    );

  const updateExistingPositionData = useCallback(() => {
    setForceStateUpdate((prevState) => !prevState);
  }, []);

  useEffect(() => {
    if (!!chainId && !!account && !!tokenId && !!positionManagerContract) {
      positionManagerContract
        .positions(tokenId.toString())
        .then((position) => {
          const {
            nonce,
            operator,
            token0,
            token1,
            fee,
            tickLower,
            tickUpper,
            liquidity,
            feeGrowthInside0LastX128,
            feeGrowthInside1LastX128,
            tokensOwed0,
            tokensOwed1,
          } = position;

          setPositionData({
            tokenId,
            nonce,
            operator,
            token0,
            token1,
            fee,
            tickLower,
            tickUpper,
            liquidity,
            feeGrowthInside0LastX128,
            feeGrowthInside1LastX128,
            tokensOwed0,
            tokensOwed1,
          });
        })
        .catch((err) =>
          console.error(
            `ERROR FETCHING POSITION WITH ID: ${tokenId}, FROM MANAGER ON CHAIN: ${chainId}`,
            err,
          ),
        );
    }
  }, [forceStateUpdate, tokenId, account, chainId, positionManagerContract]);

  return { existingPositionData: positionData, updateExistingPositionData };
};

export { useExistingPositionData };
