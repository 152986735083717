import React from "react";
import { ComponentPropsWithoutRef } from "react";
import styled from "styled-components";

const PurpleButton = styled.button`
  display: flex;
  align-items: center;
  padding: 12px 21px;
  font-weight: 500;
  color: ${(props) => props.theme.Colors.White};
  background: #737bf0;
  border-radius: 10px;
`;

const GradientBackground = styled.div`
  padding: 1px;
  background: linear-gradient(90deg, #b6e82f 0%, #50e0ed 100%);
  border-radius: 10px;
`;

const ButtonInner = styled.div`
  padding: 12px 20px;
  border-radius: 10px;
  background: ${(props) => props.theme.Colors.White};
`;

const RefreshButton = (props: ComponentPropsWithoutRef<"button">) => {
  const { children } = props;

  return (
    <button {...props}>
      <GradientBackground>
        <ButtonInner>{children}</ButtonInner>
      </GradientBackground>
    </button>
  );
};

export { PurpleButton, RefreshButton };
