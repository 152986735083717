import { createSlice } from "@reduxjs/toolkit";
import reducers from "./reducers";
import extraReducers from "./extraReducers";
import { GlobalState } from "./types";

const initialState: GlobalState = {
  assetPrices: null,
  nativePrice: null,
  venusPrices: null,
  beefyAssets: null,
  mobileMenuOpen: false,
  sidebarShrunk: false,
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers,
  extraReducers,
});

export default globalSlice.reducer;

export const { setMobileMenuOpen, setSidebarShrunk } = globalSlice.actions;
