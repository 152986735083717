import { BoxStyled as Box } from "./styled";

// interface Props extends FC {
//     children:        React.ReactNode;
//     relative?:       boolean;
//     absolute?:       boolean;
//     top?:            string;
//     left?:           string;
//     right?:          string;
//     bottom?:         string;
//     margin?:         string;
//     mt?:             string;
//     mb?:             string;
//     ml?:             string;
//     mr?:             string;
//     padding?:        string;
//     pt?:             string;
//     pb?:             string;
//     pl?:             string;
//     pr?:             string;
//     row?:            boolean;
//     column?:         boolean;
//     flex?:           string;
//     spread?:         boolean;
//     startV?:         boolean;
//     startH?:         boolean;
//     centerV?:        boolean;
//     centerH?:        boolean;
//     endV?:           boolean;
//     endH?:           boolean;
//     grid?:           boolean;
//     gridCols?:       number;
//     gap?:            string;
//     border?:         string;
//     borderTop?:      string;
//     borderBot?:      string;
//     borderLeft?:     string;
//     borderRight?:    string;
//     borderR?:        string;
//     background?:     string;
//     w?:              string;
//     h?:              string;
//     minW?:           string;
//     minH?:           string;
//     zIndex?:         number;
//     clickable?:      boolean;
//     ref?:            React.MutableRefObject<HTMLDivElement|null> | undefined;
// }

// const Box: FC<Props> = ({
//     children,
//     relative,
//     absolute,
//     top,
//     left,
//     right,
//     bottom,
//     margin,
//     mt,
//     mb,
//     ml,
//     mr,
//     padding,
//     pt,
//     pb,
//     pl,
//     pr,
//     row,
//     column,
//     flex,
//     spread,
//     startV,
//     startH,
//     centerV,
//     centerH,
//     endV,
//     endH,
//     grid,
//     gridCols,
//     gap,
//     border,
//     borderTop,
//     borderBot,
//     borderLeft,
//     borderRight,
//     borderR,
//     background,
//     w,
//     h,
//     minW,
//     minH,
//     zIndex,
//     clickable,
//     ref,
//     onClick
// }: Props) => {

//     return (
//         <BoxStyled
//             relative={relative}
//             absolute={absolute}
//             top={top}
//             left={left}
//             right={right}
//             bottom={bottom}
//             margin={margin}
//             mt={mt}
//             mb={mb}
//             ml={ml}
//             mr={mr}
//             padding={padding}
//             pt={pt}
//             pb={pb}
//             pl={pl}
//             pr={pr}
//             row={row}
//             column={column}
//             flex={flex}
//             spread={spread}
//             startV={startV}
//             startH={startH}
//             centerV={centerV}
//             centerH={centerH}
//             endV={endV}
//             endH={endH}
//             grid={grid}
//             gridCols={gridCols}
//             gap={gap}
//             border={border}
//             borderTop={borderTop}
//             borderBot={borderBot}
//             borderLeft={borderLeft}
//             borderRight={borderRight}
//             borderR={borderR}
//             background={background}
//             w={w}
//             h={h}
//             minW={minW}
//             minH={minH}
//             zIndex={zIndex}
//             clickable={clickable}
//             ref={ref}
//             onClick={onClick}
//         >
//             {children}
//         </BoxStyled>
//     )
// }

export default Box;
