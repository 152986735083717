import React from "react";

const Success = ({ ...props }) => (
  <svg
    {...props}
    width="22"
    height="23"
    viewBox="0 0 22 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 0.5C4.917 0.5 0 5.45 0 11.5C0 14.4174 1.15893 17.2153 3.22183 19.2782C4.24327 20.2996 5.4559 21.1099 6.79048 21.6627C8.12506 22.2155 9.55546 22.5 11 22.5C13.9174 22.5 16.7153 21.3411 18.7782 19.2782C20.8411 17.2153 22 14.4174 22 11.5C22 10.0555 21.7155 8.62506 21.1627 7.29048C20.6099 5.9559 19.7996 4.74327 18.7782 3.72183C17.7567 2.70038 16.5441 1.89013 15.2095 1.33733C13.8749 0.784523 12.4445 0.5 11 0.5ZM11 20.3C8.66609 20.3 6.42778 19.3729 4.77746 17.7225C3.12714 16.0722 2.2 13.8339 2.2 11.5C2.2 9.16609 3.12714 6.92778 4.77746 5.27746C6.42778 3.62714 8.66609 2.7 11 2.7C13.3339 2.7 15.5722 3.62714 17.2225 5.27746C18.8729 6.92778 19.8 9.16609 19.8 11.5C19.8 13.8339 18.8729 16.0722 17.2225 17.7225C15.5722 19.3729 13.3339 20.3 11 20.3Z"
      fill="#A7DD12"
    />
    <path
      d="M7 11.5L9.21845 14.4579C9.61285 14.9838 10.3987 14.9926 10.8048 14.4758L15.5 8.5"
      stroke="#A7DD12"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export default Success;
