function isMobileDevice(): string | boolean {
  // @ts-ignore
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (
    // @ts-ignore
    (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) ||
    (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 0) ||
    navigator.platform === "iPad" ||
    (/mac os|Macintosh/i.test(userAgent) && navigator.maxTouchPoints > 0)
  ) {
    return "iOS";
  }

  return false;
}

export default isMobileDevice;
