import { ethers } from "ethers";

export function getContract<T = ethers.Contract>(
  abi: any,
  address: string,
  signer?: ethers.Signer | ethers.providers.Provider,
): T {
  const signerOrProvider = signer ?? ethers.providers.getDefaultProvider();

  // @ts-ignore
  return new ethers.Contract<T>(address, abi, signerOrProvider);
}
