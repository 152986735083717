import { useWeb3React } from "@web3-react/core";
import { useEffect, useState } from "react";

export const useWalletNetwork = () => {
  const [walletNetwork, setWalletNetwork] = useState("");
  const { account, chainId } = useWeb3React();

  useEffect(() => {
    switch (chainId) {
      case 1:
        setWalletNetwork("ETH");
        break;
      case 10:
        setWalletNetwork("OPTIMISM");
        break;
      case 56:
        setWalletNetwork("BSC");
        break;
      case 137:
      case 80001:
        setWalletNetwork("POLYGON");
        break;
      case 42161:
      case 421611:
        setWalletNetwork("ARBITRUM");
        break;
      case 43114:
      case 43113:
        setWalletNetwork("AVALANCHE");
        break;
      default:
        setWalletNetwork("");
    }
  }, [account, chainId]);

  return {
    walletNetwork,
  };
};
