import React from "react";
import "./index.scss";
import PreloaderIcon from "./_resources/preloader";

const Loading = () => {
  return (
    <div className="loading-component">
      {/* @ts-ignore */}
      {/*<ReactLoading type={'bars'} color={'#0DC4E0'} height={100} width={100} />*/}
      <PreloaderIcon className="preloader" />
    </div>
  );
};

export default Loading;
