import { useCallback, useEffect, useState } from "react";
import { ERC20Abi } from "shared/constants";
import { ERC20 } from "shared/constants/abis/types";
import { useWeb3React } from "@web3-react/core";
import { getContract } from "utils/getContract";

type TokenData = {
  tokenContract: ERC20 | null;
  decimals: number;
  symbol: string;
  name: string;
};

const useERC20Token = (
  address?: string,
): { token: TokenData; isLoading: boolean } => {
  const { chainId, library } = useWeb3React();
  const [token, setToken] = useState<TokenData>({
    tokenContract: null,
    decimals: 0,
    symbol: "",
    name: "",
  });
  const [tokenContract, setTokenContract] = useState<ERC20 | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!!library && !!chainId && !!address) {
      const contract = getContract<ERC20>(
        ERC20Abi,
        address,
        library.getSigner(),
      );

      setTokenContract(contract);
    }
  }, [library, chainId, address]);

  const getTokenData = useCallback(async (): Promise<TokenData> => {
    if (tokenContract) {
      const [decimals, symbol, name] = await Promise.all([
        tokenContract.decimals(),
        tokenContract.symbol(),
        tokenContract.name(),
      ]);

      return {
        tokenContract,
        decimals,
        symbol,
        name,
      };
    } else {
      return {
        // @ts-ignore
        tokenContract: null,
        decimals: 0,
        symbol: "",
        name: "",
      };
    }
  }, [tokenContract]);

  useEffect(() => {
    if (!!tokenContract && !!chainId) {
      setIsLoading(true);

      getTokenData()
        .then(({ decimals, symbol, name }) => {
          setToken({
            tokenContract,
            decimals,
            symbol,
            name,
          });
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  }, [tokenContract, chainId, getTokenData]);

  return { token, isLoading };
};

export { useERC20Token };
