import styled from "styled-components";
import { default as NotStyledCloseIcon } from "./_resources/CloseIcon";
import { default as NotStyledArrowBackIcon } from "./_resources/ArrowBack";

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1001;
`;

const Modal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  min-width: 500px;
  max-width: 500px;
  background: #ffffff;
  border-radius: 16px;
  box-shadow:
    0 40px 60px rgba(105, 123, 140, 0.3),
    0 10px 30px rgba(105, 123, 140, 0.4);
  z-index: 1002;

  @media (max-width: 800px) {
    max-width: 100%;
    min-width: 600px;
  }
  @media (max-width: 600px) {
    min-width: 300px;
    max-width: 300px;
    top: 50%;
    left: 50%;
    width: unset;
    transform: translate(-50%, -50%);
  }
`;

const Top = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid #c2c2c2;
`;

const TopInner = styled.div`
  display: flex;
  align-items: center;
`;

const BackLink = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 31px;
  @media (max-width: 600px) {
    margin-right: 8px;
  }
`;

const BackText = styled.span`
  font-size: 14px;
  line-height: 22px;
  color: #c2c2c2;
`;

const Title = styled.span`
  font-weight: 500;
  font-size: 18px;
  line-height: 155%;
  letter-spacing: -0.01em;
  color: #000000;
  @media (max-width: 600px) {
    font-size: 16px;
  }
`;

const CloseIcon = styled(NotStyledCloseIcon)`
  cursor: pointer;
  width: 12px;
  height: 12px;
`;

const ArrowBackIcon = styled(NotStyledArrowBackIcon)``;

export default {
  Wrapper,
  Modal,
  Top,
  TopInner,
  BackLink,
  BackText,
  Title,
  CloseIcon,
  ArrowBackIcon,
};
