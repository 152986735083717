import { useCallback, useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import axios from "axios";
import type { DcaPosition } from "shared/types";
// import { DCA_BASE_URL } from "shared/constants";

const useDcaPositionList = () => {
  const { account, chainId } = useWeb3React();
  const [positions, setPositions] = useState<DcaPosition[]>([]);

  const fetchPositions = useCallback(async () => {
    if (account) {
      const positionsList: DcaPosition[] | null = await axios
        .post("/dca/position/getPositionList", {
          ChainId: chainId,
          OwnerAddress: account.toLowerCase(),
        })
        .then((res) => res.data)
        .catch((err) => {
          console.log("ERROR:", err);

          return null;
        });

      console.log(positionsList);

      return positionsList;
    }

    return [];
  }, [account, chainId]);

  useEffect(() => {
    if (!!account && !!chainId) {
      fetchPositions().then((possiblePositions) => {
        if (possiblePositions !== null) {
          setPositions(possiblePositions);
        }
      });
    }
  }, [account, chainId, fetchPositions]);

  return { positions, updatePositions: fetchPositions };
};

export { useDcaPositionList };
