import React, { FC } from "react";
import Styled from "./styled";
import { ButtonProps } from "./types";

const Button: FC<ButtonProps> = ({
  children,
  color,
  size,
  disable,
  onClick,
}) => {
  return (
    <Styled.Wrapper
      disable={disable}
      onClick={onClick}
      size={size}
      color={color}
    >
      {children}
    </Styled.Wrapper>
  );
};

export default Button;
