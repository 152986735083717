import React from "react";

const CopyIcon = ({ ...props }) => (
  <svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="4.5"
      y="3.83594"
      width="9.66667"
      height="10.3333"
      rx="0.5"
      stroke="#858585"
    />
    <path
      d="M2 11.3359V2.33594C2 1.78365 2.44772 1.33594 3 1.33594H10.6667"
      stroke="#858585"
    />
  </svg>
);

export default CopyIcon;
