import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import { ROUTE_PATHS } from "shared/constants";
import { Loading } from "components";

// const Dashboard = React.lazy(() => import('pages/Dashboard'));
const MegaDashboard = React.lazy(() => import("pages/MegaDashboard"));
const Exchange = React.lazy(() => import("pages/Exchange"));
const SaveVenus = React.lazy(() => import("pages/Save/SaveVenus"));
// const SaveBeefy = React.lazy(() => import('pages/Save/SaveBeefy'));
const SaveAnalysis = React.lazy(() => import("pages/Save/SaveAnalysis"));
const RoobeeBridge = React.lazy(() => import("pages/RoobeeBridge"));
const BRoobeeRoobeeBscBridge = React.lazy(
  () => import("pages/BroobeeRoobeeBscBridge"),
);
const Cart = React.lazy(() => import("pages/Cart"));
const TransactionsDetails = React.lazy(
  () => import("pages/TransactionsDetails"),
);
const Farm = React.lazy(() => import("pages/Farm"));
const Invest = React.lazy(() => import("pages/Invest"));
const InvestToken = React.lazy(() => import("pages/InvestToken"));
const InvestBuy = React.lazy(() => import("pages/InvestBuy"));
const Liquidity = React.lazy(() => import("pages/Liquidity"));
const LiquidityAction = React.lazy(() => import("pages/LiquidityAction"));
const LiquidityAbout = React.lazy(() => import("pages/LiquidityAbout"));
const LiquidityIncrease = React.lazy(() => import("pages/LiquidityIncrease"));
const LiquidityDelete = React.lazy(() => import("pages/LiquidityDelete"));
const Rewards = React.lazy(() => import("pages/Rewards"));
const Transactions = React.lazy(() => import("pages/Transactions"));
const Quests = React.lazy(() => import("pages/Quests"));
const Notifications = React.lazy(() => import("pages/Notifications"));
const PageNotFound = React.lazy(() => import("pages/PageNotFound"));
const Dca = React.lazy(() => import("pages/DCA"));
const DcaAction = React.lazy(() => import("pages/DCAAction"));
const Bridges = React.lazy(() => import("pages/Bridges"));
const LeaderBoard = React.lazy(() => import("pages/LeaderBoard"));
const NFT = React.lazy(() => import("pages/NFT"));

const Routes = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        {/* <Route exact={true} path={ROUTE_PATHS.DASHBOARD} component={Dashboard}/> */}
        <Route
          exact={true}
          path={ROUTE_PATHS.DASHBOARD}
          component={MegaDashboard}
        />
        <Route
          exact={true}
          path={ROUTE_PATHS.TRANSACTIONS}
          component={Transactions}
        />
        <Route exact={true} path={ROUTE_PATHS.CART} component={Cart} />
        <Route
          exact={true}
          path={ROUTE_PATHS.TRANSACTIONS_DETAILS}
          component={TransactionsDetails}
        />
        <Route exact={true} path={"/mega"} component={MegaDashboard} />
        <Route exact={true} path={ROUTE_PATHS.SAVE} component={SaveVenus} />
        <Route
          exact={true}
          path={ROUTE_PATHS.SAVE_VENUS}
          component={SaveVenus}
        />
        <Route
          exact={true}
          path={ROUTE_PATHS.SAVE_ANALYSIS}
          component={SaveAnalysis}
        />
        <Route exact={true} path={ROUTE_PATHS.EXCHANGE} component={Exchange} />
        <Route
          exact={true}
          path={ROUTE_PATHS.ROOBEE_BRIDGE}
          component={RoobeeBridge}
        />
        <Route
          exact={true}
          path={ROUTE_PATHS.BROOBEE_ROOBEE_BSC_BRIDGE}
          component={BRoobeeRoobeeBscBridge}
        />
        <Route exact={true} path={ROUTE_PATHS.FARM} component={Farm} />
        <Route exact={true} path={ROUTE_PATHS.INVEST} component={Invest} />
        <Route
          exact={true}
          path={ROUTE_PATHS.INVEST_TOKEN}
          component={InvestToken}
        />
        <Route
          exact={true}
          path={ROUTE_PATHS.INVEST_BUY}
          component={InvestBuy}
        />
        <Route
          exact={true}
          path={ROUTE_PATHS.LIQUIDITY}
          component={Liquidity}
        />
        <Route
          exact={true}
          path={ROUTE_PATHS.LIQUIDITY_ACTION}
          component={LiquidityAction}
        />
        <Route
          exact={true}
          path={ROUTE_PATHS.LIQUIDITY_ABOUT}
          component={LiquidityAbout}
        />
        <Route
          exact={true}
          path={ROUTE_PATHS.LIQUIDITY_INCREASE}
          component={LiquidityIncrease}
        />
        <Route
          exact={true}
          path={ROUTE_PATHS.LIQUIDITY_DELETE}
          component={LiquidityDelete}
        />
        <Route exact={true} path={ROUTE_PATHS.REWARDS} component={Rewards} />
        <Route
          exact={true}
          path={ROUTE_PATHS.TRANSACTIONS}
          component={Transactions}
        />
        <Route exact={true} path={ROUTE_PATHS.QUESTS} component={Quests} />
        <Route
          exact={true}
          path={ROUTE_PATHS.NOTIFICATIONS}
          component={Notifications}
        />
        <Route exact={true} path={ROUTE_PATHS.DCA} component={Dca} />
        <Route
          exact={true}
          path={ROUTE_PATHS.DCA_ACTION}
          component={DcaAction}
        />
        <Route exact={true} path={ROUTE_PATHS.REWARDS} component={Rewards} />
        <Route exact={true} path={ROUTE_PATHS.BRIDGES} component={Bridges} />
        <Route
          exact={true}
          path={ROUTE_PATHS.LEADERBOARD}
          component={LeaderBoard}
        />
        <Route exact={true} path={ROUTE_PATHS.NFT} component={NFT} />
        <Route
          exact={true}
          path={ROUTE_PATHS.PAGENOTFOUND}
          component={PageNotFound}
        />
      </Switch>
    </Suspense>
  );
};

export default Routes;
