import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";

import { State, useAppDispatch } from "store";
import { setAssets } from "store/dashboard";
import { fetchGeneralBalanceInfo, fetchRewards } from "store/airdrop/thunks";
import {
  AirdropModalProvider,
  AuthorizeModalProvider,
  DialogModalProvider,
} from "components";
import {
  useAutoConnect,
  useBalanceOf,
  useQuery,
  useResize,
} from "shared/hooks";
import Routes from "./routes";
import Sidebar from "./components/Sidebar";
import Footer from "./components/Footer";
import CookiesPrivacyPolicy from "./components/CookiesPrivacyPolicy";
import { trackAddress } from "./utils/trackAddress";
import Widgetbar from "./components/Widgetbar";
import PageContainer from "shared/styles/PageContainer";
import Container from "shared/styles/elements/Container";
import UserModal from "./components/Modals/UserModal";
import DisconnectModal from "./components/Modals/DisconnectModal";

function App() {
  const { account, chainId, library } = useWeb3React();
  const { balance, balanceOf } = useBalanceOf();
  const dispatch = useAppDispatch();
  const [openSidebar, setOpenSidebar] = useState<boolean>(true);
  const sidebarShrunk = useSelector(
    (state: State) => state.global.sidebarShrunk,
  );
  const query = useQuery();
  const [, setWithLangSwitch] = useState<boolean>(false);
  const [user, setUser] = useState<boolean>(false);
  const { windowWidth } = useResize();
  const tabletView = useMemo(() => windowWidth <= 1024, [windowWidth]);
  const [showDisconnectModal, setShowDisconnectModal] =
    useState<boolean>(false);
  useAutoConnect();

  useEffect(() => {
    const updateAirdrop = async () => {
      await dispatch(fetchGeneralBalanceInfo({ account, library }));
      await dispatch(fetchRewards({ account, chainId, library }));
    };
    updateAirdrop();

    if (!account) {
      dispatch(setAssets(null));
    } else {
      (async () => {
        await balanceOf();
        trackAddress(account, balance, chainId);
      })();
    }
  }, [account, balance, balanceOf, chainId, dispatch, library]);

  useEffect(() => {
    if (account) {
      const updateAirdrop = async () => {
        await dispatch(fetchGeneralBalanceInfo({ account, library }));
        await dispatch(fetchRewards({ account, chainId, library }));
      };
      updateAirdrop();
    }
  }, [account, chainId, dispatch, library]);

  useEffect(() => {
    if (query.get("lang_switch") === "") {
      setWithLangSwitch(true);
    }
  }, [query]);

  useEffect(() => {
    if (!tabletView) {
      setOpenSidebar(false);
    } else {
      setOpenSidebar(true);
    }
  }, [tabletView]);

  useEffect(() => {
    if (!account) {
      setShowDisconnectModal(false);
    }
    if (account) {
      setUser(false);
    }
  }, [account]);

  return (
    <BrowserRouter>
      <AuthorizeModalProvider>
        <AirdropModalProvider>
          <DialogModalProvider>
            <Sidebar
              accountModalHandler={() => setUser(true)}
              disconnectHandler={() => setShowDisconnectModal(true)}
              collapsed={openSidebar}
              setCollapsed={setOpenSidebar}
            />
            <PageContainer expanded={sidebarShrunk}>
              {!tabletView && <Widgetbar user={user} setUser={setUser} />}
              <Container>
                <Routes />
              </Container>
              {tabletView && showDisconnectModal && (
                <DisconnectModal
                  onClose={() => setShowDisconnectModal(false)}
                />
              )}
              {tabletView && user && (
                <UserModal
                  setShowDisconnectModal={setShowDisconnectModal}
                  onClose={() => setUser(false)}
                />
              )}
              {/*{tabletView && (*/}
              {/*  <Navbar*/}
              {/*    hide={!openSidebar}*/}
              {/*    setUser={() => setUser(true)}*/}
              {/*    user={user}*/}
              {/*  />*/}
              {/*)}*/}
              <Footer />
            </PageContainer>
            <CookiesPrivacyPolicy />
          </DialogModalProvider>
        </AirdropModalProvider>
      </AuthorizeModalProvider>
    </BrowserRouter>
  );
}

export default App;
