import React, { useCallback, useEffect, useRef, useMemo } from "react";
import canvasConfetti from "canvas-confetti";
import BigNumber from "bignumber.js";
import { State } from "store";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store";
import { setShowConfetti } from "store/airdrop";

import "./styles.scss";

const confettiTimeout = 6_000;
const leftSide = [0.1, 0.3];
const rightSide = [0.7, 0.9];

function randomInRange(min: number, max: number): number {
  return Math.random() * (max - min) + min;
}

const ConfettiCanvas = () => {
  const dispatch = useAppDispatch();
  const { showConfetti, rewards } = useSelector(
    (state: State) => state.airdrop,
  );
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const confetti = canvasConfetti.create(canvasRef.current, {
    resize: true,
    useWorker: false,
  });

  const availableRewardSum = useMemo(() => {
    return rewards.reduce(
      (acc, reward) =>
        reward.isClaimed || reward.disabled ? acc : acc.plus(reward.amount),
      new BigNumber(0),
    );
  }, [rewards]);

  const addConfetti = useCallback(
    (min: number, max: number) => {
      confetti({
        particleCount: 100,
        angle: 90,
        spread: 360,
        startVelocity: 25,
        origin: {
          x: randomInRange(min, max),
          y: Math.random() - 0.4,
        },
      });
    },
    [confetti],
  );

  const clearConfetti = useCallback(() => {
    dispatch(setShowConfetti(false));
  }, [dispatch]);

  useEffect(() => {
    if (!!confetti && showConfetti && availableRewardSum.gt(0)) {
      let isIntervalsCleared = false;
      addConfetti(leftSide[0], leftSide[1]);
      addConfetti(rightSide[0], rightSide[1]);
      const timer1 = setInterval(() => {
        addConfetti(leftSide[0], leftSide[1]);
      }, 1000);
      const timer2 = setInterval(() => {
        addConfetti(rightSide[0], rightSide[1]);
      }, 1500);

      setTimeout(() => {
        if (!isIntervalsCleared) {
          clearInterval(timer1);
          clearInterval(timer2);
          isIntervalsCleared = true;
        }
      }, confettiTimeout);

      return () => {
        if (!isIntervalsCleared) {
          clearInterval(timer1);
          clearInterval(timer2);
          isIntervalsCleared = true;
        }
      };
    }
  }, [addConfetti, availableRewardSum, confetti, showConfetti]);

  useEffect(() => {
    window.addEventListener("blur", clearConfetti);

    return () => {
      window.removeEventListener("blur", clearConfetti);
      confetti.reset();
    };
  }, [clearConfetti, confetti]);

  return <canvas ref={canvasRef} className="confetti-canvas"></canvas>;
};

export default ConfettiCanvas;
