import { DashboardState } from "./types";

const reducers = {
  setAssets(state: DashboardState, action: { payload: any }) {
    state.assets = action.payload;
  },

  setActiveAssets(state: DashboardState, action: { payload: any }) {
    state.activeAssets = action.payload;
  },

  setTokenPercentages(state: DashboardState, action: { payload: any }) {
    state.tokenPercentages = action.payload;
  },
};

export default reducers;
