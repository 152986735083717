import React, { useRef } from "react";
import { createPortal } from "react-dom";
import { useAirdrop } from "./AirdropModalProvider";
import type { StageId } from "./AirdropModalProvider";
import {
  ConfettiCanvas,
  PrimaryContent,
  DetailsContent,
  SuccessContent,
} from "./components";
import { useClickOutside } from "shared/hooks";
import { useTranslation } from "react-i18next";

import Styled from "./styled";

type Props = {
  stageId: StageId;
};

enum StageIds {
  Primary = "primary",
  Details = "details",
  Success = "success",
}

const stages = {
  [StageIds.Primary]: {
    title: "AIRDROP_MODAL-title_tokens",
    content: <PrimaryContent />,
    backstageId: null,
    showConfetti: false,
  },
  [StageIds.Details]: {
    title: "AIRDROP_MODAL-title_tokens",
    content: <DetailsContent />,
    backstageId: StageIds.Primary,
    showConfetti: true,
  },
  [StageIds.Success]: {
    title: "AIRDROP_MODAL-title_claimed",
    content: <SuccessContent />,
    backstageId: null,
    showConfetti: false,
  },
};

const AirdropModal = ({ stageId }: Props) => {
  const { showAirdrop, hideAirdrop } = useAirdrop();
  const { title, content, backstageId } = stages[stageId];
  const AirdropModalRef = useRef(null);

  useClickOutside(AirdropModalRef, hideAirdrop);

  const { t } = useTranslation();

  return createPortal(
    <Styled.Wrapper>
      <ConfettiCanvas />
      <Styled.Modal ref={AirdropModalRef}>
        <Styled.Top>
          <Styled.TopInner>
            {!!backstageId && (
              <Styled.BackLink
                onClick={() => {
                  showAirdrop(backstageId, stages[backstageId].showConfetti);
                }}
              >
                <Styled.ArrowBackIcon />
                <Styled.BackText>{t("BUTTONS-back")}</Styled.BackText>
              </Styled.BackLink>
            )}
            <Styled.Title>{t(`${title}`)}</Styled.Title>
          </Styled.TopInner>
          <Styled.CloseIcon onClick={hideAirdrop} />
        </Styled.Top>
        {content}
      </Styled.Modal>
    </Styled.Wrapper>,
    document.body,
  );
};

export default AirdropModal;
