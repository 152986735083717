import React from "react";

const ETHIcon = (props) => {
  return (
    <svg
      {...props}
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="14" cy="14" r="13.5" fill="white" stroke="#577EE2" />
      <path d="M7.46655 14L13.9999 3.73333V11.2L7.46655 14Z" fill="#92ABEF" />
      <path d="M14 11.2V3.73333L20.5333 14L14 11.2Z" fill="#577EE2" />
      <path d="M7.46655 14L13.9999 11.2V17.7333L7.46655 14Z" fill="#577EE2" />
      <path d="M20.5332 14L13.9999 11.2V17.7333L20.5332 14Z" fill="#335CBC" />
      <path
        d="M13.9999 24.2667L7.46655 15.4L13.9999 19.1333V24.2667Z"
        fill="#92ABEF"
      />
      <path d="M20.5333 15.4L14 24.2667V19.1333L20.5333 15.4Z" fill="#577EE2" />
    </svg>
  );
};

export default ETHIcon;
