import React from "react";
import { useMemo, useState } from "react";
import {
  AlphaRouter,
  CurrencyAmount,
  SwapOptionsSwapRouter02,
  SwapType,
  WETH9,
  WMATIC_POLYGON,
} from "@uniswap/smart-order-router";
import axios from "axios";
import { Percent, QUOTER_ADDRESSES, Token, TradeType } from "@uniswap/sdk-core";
import { useWeb3React } from "@web3-react/core";
import { useMakeMulticall } from "./useMakeMultiCall";
import bn from "bignumber.js";
import { useAuth } from "./useAuth";
import { getContract } from "utils/getContract";
import {
  ERC20,
  PancakeFactoryV2,
  PancakeRouter,
  RoobeeCallForwarder,
  UniswapV3Factory,
  UniswapV3Pool,
  UniswapV3Quoter,
} from "shared/constants/abis/types";
import {
  ERC20Abi,
  MAX_UINT,
  PANCAKE_FACTORY,
  PANCAKE_ROUTER,
  PancakeFactoryV2Abi,
  PancakeRouterAbi,
  ROOBEE_CALL_FORWARDER,
  ROOBEE_MULTICALL,
  RoobeeCallForwarderAbi,
  UNISWAP_FACTORY_V3,
  UniswapV3FactoryAbi,
  UniswapV3PoolAbi,
  UniswapV3QuoterAbi,
  WBNB,
  ZERO_ADDRESS,
} from "shared/constants";
import { ethersToBN } from "utils/ethersToBN";
import { useAppDispatch } from "store";
import { getCartTokens } from "store/cart/thunks";
import MarketplaceDialogSuccess from "../../pages/Cart/components/MarketplaceDialogSuccess";
import MarketplaceDialogFailed from "../../pages/Cart/components/MarketplaceDialogFailed";
import { useDialogModal } from "../../components";
import { ITokenDisplayable } from "shared/types";
import { ethers } from "ethers";
import SUPPORTED_CHAIN_IDS from "shared/constants/supportedChainIds";
import { UNISWAP_V3_FEE_AMOUNTS } from "pages/Cart/constants";

export type TTokenToBuyWithMulticall = {
  token: string;
  value: number;
  decimals: number;
  source: string;
};

const useBuyTokensWithMulticall = () => {
  const { account, chainId, library } = useWeb3React();
  const { makeMulticall } = useMakeMulticall();
  const { authToken } = useAuth();
  const dispatch = useAppDispatch();
  const { showModal: showDialogModal } = useDialogModal();

  const [isLoading, setIsLoading] = useState(false);

  const DialogMarketplaceSuccess = useMemo(() => {
    return <MarketplaceDialogSuccess />;
  }, []);

  const DialogMarketplaceFailed = useMemo(() => {
    return <MarketplaceDialogFailed />;
  }, []);

  const buyTokens = async (
    payToken: ITokenDisplayable | null,
    tokensToBuy: TTokenToBuyWithMulticall[],
  ) => {
    setIsLoading(true);

    if (!account || !chainId || !payToken || tokensToBuy.length === 0) {
      return;
    }

    if (!ROOBEE_CALL_FORWARDER[chainId]) {
      return;
    }

    try {
      const addresses: string[] = [];
      const datas: string[] = [];
      const values: string[] = [];
      let valuesSum = new bn(0);
      let payTokenTotal = new bn(0);
      const payTokenAllowances: { [contract: string]: bn } = {};

      const signer = await library.getSigner();

      const router = new AlphaRouter({
        chainId: chainId ?? 0,
        provider: signer.provider,
      });

      const now = Math.floor(Date.now() / 1000);
      const deadline = now + 60 * 60; // NOTE(Nikita): now in seconds + number of seconds
      const slippagePercents = 0.95; // amount minus slippage in percents

      const options: SwapOptionsSwapRouter02 = {
        recipient: account,
        slippageTolerance: new Percent(50, 10_000),
        deadline: deadline,
        type: SwapType.SWAP_ROUTER_02,
      };

      const roobeeCallForwarder = getContract<RoobeeCallForwarder>(
        RoobeeCallForwarderAbi,
        ROOBEE_CALL_FORWARDER[chainId],
        library.getSigner(),
      );
      const payTokenContract = getContract<ERC20>(
        ERC20Abi,
        payToken.address,
        library.getSigner(),
      );

      let decimalsPlaces = 18;
      let decimals = new bn(10).pow(decimalsPlaces);

      if (payToken.isNative) {
        decimals = new bn(10).pow(18);
      } else {
        decimalsPlaces = await payTokenContract.decimals();
        decimals = new bn(10).pow(decimalsPlaces);
      }

      for (const tokenBuy of tokensToBuy) {
        switch (tokenBuy.source) {
          case "uni_v3":
            {
              const tokenBuyDecimalsRaw = new bn(10).pow(tokenBuy.decimals);
              const rawAmountOut = new bn(tokenBuy.value).times(
                tokenBuyDecimalsRaw,
              );

              const uniswapV3Factory = getContract<UniswapV3Factory>(
                UniswapV3FactoryAbi,
                UNISWAP_FACTORY_V3[chainId],
                library.getSigner(),
              );

              let mostProfitablePoolIndex;
              let maxCatchedLiquidity = ethers.BigNumber.from("0");
              const fetchPoolAddressRequests: Promise<string>[] = [];
              for (const feeAmount of UNISWAP_V3_FEE_AMOUNTS) {
                const addressRequest = uniswapV3Factory.getPool(
                  payToken.address,
                  tokenBuy.token,
                  feeAmount,
                );

                fetchPoolAddressRequests.push(addressRequest);
              }

              const pools = await Promise.all(fetchPoolAddressRequests);
              for (
                let poolAddressIndex = 0;
                poolAddressIndex < pools.length;
                poolAddressIndex++
              ) {
                const poolAddress = pools[poolAddressIndex];
                if (poolAddress === ZERO_ADDRESS) {
                  continue;
                }

                const poolContract = getContract<UniswapV3Pool>(
                  UniswapV3PoolAbi,
                  poolAddress,
                  library.getSigner(),
                );

                const liquidityFromPool = await poolContract.liquidity();
                if (maxCatchedLiquidity.lt(liquidityFromPool)) {
                  maxCatchedLiquidity = liquidityFromPool;
                  mostProfitablePoolIndex = poolAddressIndex;
                }
              }

              if (isNaN(mostProfitablePoolIndex)) {
                return;
              }

              const matchingFee =
                UNISWAP_V3_FEE_AMOUNTS[mostProfitablePoolIndex];

              const uniswapV3Quoter = getContract<UniswapV3Quoter>(
                UniswapV3QuoterAbi,
                QUOTER_ADDRESSES[chainId],
                library.getSigner(),
              );

              const payTokenQuote =
                await uniswapV3Quoter.callStatic.quoteExactOutputSingle(
                  payToken.address,
                  tokenBuy.token,
                  matchingFee,
                  rawAmountOut.toFixed(),
                  0,
                );

              const route = await router.route(
                CurrencyAmount.fromRawAmount(
                  payToken.isNative
                    ? chainId === SUPPORTED_CHAIN_IDS.POLYGON
                      ? WMATIC_POLYGON
                      : WETH9[chainId]
                    : new Token(chainId, payToken.address, decimalsPlaces),
                  payTokenQuote.toString(),
                ),
                new Token(chainId ?? 0, tokenBuy.token, tokenBuy.decimals),
                TradeType.EXACT_INPUT,
                options,
              );

              if (route && route.methodParameters) {
                addresses.push(route.methodParameters.to);
                datas.push(route.methodParameters.calldata);

                if (payToken.isNative) {
                  values.push(payTokenQuote.toString());
                  valuesSum = valuesSum.plus(payTokenQuote.toString());
                } else {
                  values.push("0");

                  payTokenTotal = payTokenTotal.plus(payTokenQuote.toString());
                  if (
                    !payTokenAllowances[route.methodParameters.to.toLowerCase()]
                  ) {
                    payTokenAllowances[
                      route.methodParameters.to.toLowerCase()
                    ] = new bn(payTokenQuote.toString());
                  } else {
                    payTokenAllowances[
                      route.methodParameters.to.toLowerCase()
                    ] = payTokenAllowances[
                      route.methodParameters.to.toLowerCase()
                    ].plus(payTokenQuote.toString());
                  }
                }
              }
            }
            break;
          case "uni_v2":
            {
              const pancakeRouter = getContract<PancakeRouter>(
                PancakeRouterAbi,
                PANCAKE_ROUTER,
                library.getSigner(),
              );
              const pancakeFactory = getContract<PancakeFactoryV2>(
                PancakeFactoryV2Abi,
                PANCAKE_FACTORY,
                library.getSigner(),
              );
              let path: string[] = [];

              if (payToken.isNative) {
                const poolAddress = await pancakeFactory.getPair(
                  WBNB,
                  tokenBuy.token,
                );

                if (poolAddress === ZERO_ADDRESS) {
                  console.log(`Path ${WBNB}-${tokenBuy.token} doesn't exist`);
                  continue;
                } else {
                  path = [WBNB, tokenBuy.token];
                }
              } else {
                const poolAddress = await pancakeFactory.getPair(
                  payToken.address,
                  tokenBuy.token,
                );

                if (poolAddress === ZERO_ADDRESS) {
                  const poolNativeToPay = await pancakeFactory.getPair(
                    payToken.address,
                    WBNB,
                  );
                  const poolNativeToBuy = await pancakeFactory.getPair(
                    WBNB,
                    tokenBuy.token,
                  );

                  if (
                    poolNativeToPay === ZERO_ADDRESS ||
                    poolNativeToBuy === ZERO_ADDRESS
                  ) {
                    console.log(
                      `Path ${payToken.address}-${WBNB}-${tokenBuy.token} doesn't exist`,
                    );
                    continue;
                  } else {
                    path = [payToken.address, WBNB, tokenBuy.token];
                  }
                } else {
                  path = [payToken.address, tokenBuy.token];
                }
              }

              const amountOutRaw = new bn(tokenBuy.value).times(decimals);
              const amountsIn = await pancakeRouter.getAmountsIn(
                amountOutRaw.toFixed(),
                path,
              );
              const amountIn = amountsIn[0];

              const amountsOut = await pancakeRouter.getAmountsOut(
                amountIn.toString(),
                path,
              );

              const minAmountOut = ethersToBN(
                amountsOut[amountsOut.length - 1],
              ).times(slippagePercents);

              let encodedData;

              if (payToken.isNative) {
                // @ts-ignore
                encodedData = pancakeRouter.interface.encodeFunctionData(
                  "swapExactETHForTokens",
                  [minAmountOut.toFixed(0), path, account, deadline],
                );

                addresses.push(PANCAKE_ROUTER);
                values.push(ethersToBN(amountIn).toFixed(0));
                valuesSum = valuesSum.plus(amountIn.toString());
              } else {
                // @ts-ignore
                encodedData = pancakeRouter.interface.encodeFunctionData(
                  "swapExactTokensForTokens",
                  [
                    ethersToBN(amountIn).toFixed(0),
                    minAmountOut.toFixed(0),
                    path,
                    account,
                    deadline,
                  ],
                );

                payTokenTotal = payTokenTotal.plus(
                  ethersToBN(amountIn).toFixed(0),
                );
                if (!payTokenAllowances[pancakeRouter.address.toLowerCase()]) {
                  payTokenAllowances[pancakeRouter.address.toLowerCase()] =
                    new bn(ethersToBN(amountIn).toFixed(0));
                } else {
                  payTokenAllowances[pancakeRouter.address.toLowerCase()] =
                    payTokenAllowances[
                      pancakeRouter.address.toLowerCase()
                    ].plus(ethersToBN(amountIn).toFixed(0));
                }

                addresses.push(PANCAKE_ROUTER);
                values.push("0");
              }

              datas.push(encodedData);
            }
            break;
          case "layer_zero":
            // layerZero
            break;
        }
      }

      // setIsLoading(false);
      // return;

      const contractAddresses: string[] = [];
      const allowancesPromises: Promise<ethers.BigNumber>[] = [];

      Object.keys(payTokenAllowances).forEach((contractAddress) => {
        const allowancePromise = payTokenContract.allowance(
          ROOBEE_MULTICALL[chainId],
          contractAddress,
        );

        contractAddresses.push(contractAddress);
        allowancesPromises.push(allowancePromise);
      });

      const allowancesArr = await Promise.all(allowancesPromises).catch(
        (err) => {
          console.log("Error fetching allowances", err);

          return [];
        },
      );

      for (
        let contractAddressIndex = 0;
        contractAddressIndex < contractAddresses.length;
        contractAddressIndex++
      ) {
        const contractAddress =
          contractAddresses[contractAddressIndex].toLowerCase();
        const allowance = allowancesArr[contractAddressIndex];
        const requiredAllowance = payTokenAllowances[contractAddress];
        if (ethersToBN(allowance).lt(requiredAllowance)) {
          // @ts-ignore
          const encodedApprove = payTokenContract.interface.encodeFunctionData(
            "approve",
            [contractAddress, MAX_UINT],
          );

          addresses.unshift(payTokenContract.address);
          datas.unshift(encodedApprove);
          values.unshift("0");
        }
      }

      let tx;

      if (!payToken.isNative) {
        const allowanceFromPayToForwarder = await payTokenContract.allowance(
          account,
          ROOBEE_CALL_FORWARDER[chainId],
        );
        if (ethersToBN(allowanceFromPayToForwarder).lt(payTokenTotal)) {
          tx = await payTokenContract
            .approve(ROOBEE_CALL_FORWARDER[chainId], MAX_UINT)
            .catch(() => {
              return null;
            });
        } else {
          const gas = await roobeeCallForwarder.estimateGas
            .payAndExecute(
              payToken.address,
              payTokenTotal.toFixed(),
              addresses,
              datas,
              values,
              {
                value: valuesSum.toFixed(),
              },
            )
            .catch(() => {
              return "720000";
            });

          tx = await roobeeCallForwarder
            .payAndExecute(
              payToken.address,
              payTokenTotal.toFixed(),
              addresses,
              datas,
              values,
              {
                value: valuesSum.toFixed(),
                gasLimit: gas,
              },
            )
            .then((res) => {
              axios
                .get(
                  `/invest/${account}:tokensSave?chainId=${chainId}&txHash=${res.hash}`,
                  {
                    headers: {
                      Authorization: `Bearer ${authToken}`,
                    },
                  },
                )
                .then(() => {
                  // @ts-ignore
                  dispatch(getCartTokens(account));
                  showDialogModal(DialogMarketplaceSuccess);
                })
                .catch(() => {
                  showDialogModal(DialogMarketplaceFailed);
                });

              return res;
            })
            .catch((err) => {
              console.log(err);

              return null;
            });
        }
      } else {
        tx = await makeMulticall(addresses, datas, values, valuesSum.toFixed())
          .then((res) => {
            axios
              .get(
                `/invest/${account}:tokensSave?chainId=${chainId}&txHash=${res.hash}`,
                {
                  headers: {
                    Authorization: `Bearer ${authToken}`,
                  },
                },
              )
              .then(() => {
                // @ts-ignore
                dispatch(getCartTokens(account));
                showDialogModal(DialogMarketplaceSuccess);
              })
              .catch(() => {
                showDialogModal(DialogMarketplaceFailed);
              });

            return res;
          })
          .catch(() => {
            return null;
          });
      }

      if (!tx) {
        setIsLoading(false);
        return;
      }
    } catch (e) {
      console.error(e);
    }

    setIsLoading(false);
  };

  return { buyTokens, isLoading };
};

export { useBuyTokensWithMulticall };
