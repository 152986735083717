import { useEffect, useMemo, useRef } from "react";

const useInterval = (
  callback: () => void,
  delay: number,
  leading = true,
  deps?: any[],
) => {
  const savedCallback = useRef<() => void>();
  const dependencies = useMemo(() => {
    if (Array.isArray(deps)) {
      return [delay, leading, ...deps];
    }

    return [delay, leading];
  }, [delay, leading, deps]);

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      const { current } = savedCallback;
      if (current) {
        current();
      }
    }

    if (delay !== null) {
      if (leading) {
        tick();
      }
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
    return undefined;
    // eslint-disable-next-line
  }, dependencies);
};

export { useInterval };
