import { useEffect, useRef, useState } from "react";
import { useResize } from "./useResize";

const maxMobileViewWidth = 600;

const useMobileView = () => {
  const [isMobileView, setIsMobileView] = useState(false);
  const prevIsMobileView = useRef(false);
  const { windowWidth } = useResize();

  useEffect(() => {
    const isMobileViewUpdated = windowWidth <= maxMobileViewWidth;

    if (isMobileViewUpdated !== prevIsMobileView.current) {
      setIsMobileView(isMobileViewUpdated);
      prevIsMobileView.current = isMobileViewUpdated;
    }
  }, [windowWidth]);

  return isMobileView;
};

export { useMobileView };
