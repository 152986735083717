import { useMemo } from "react";
import { useWeb3React } from "@web3-react/core";
import { getContract } from "utils/getContract";
import { ZERO_ADDRESS } from "shared/constants";

function useContract<T>(abi: any, address: string): T {
  const { account, library } = useWeb3React();

  return useMemo(
    () =>
      getContract<T>(
        abi,
        address ?? ZERO_ADDRESS,
        account ? library.getSigner(0) : library,
      ),
    [account, library, abi, address],
  );
}

export { useContract };
