import React from "react";

const LinkIcon = ({ ...props }) => (
  <svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 2H2.2C2.08954 2 2 2.08954 2 2.2V13.8C2 13.9105 2.08954 14 2.2 14H13.8C13.9105 14 14 13.9105 14 13.8V8"
      stroke="black"
      strokeLinecap="round"
    />
    <path
      d="M11.3359 1.33594H14.4693C14.5797 1.33594 14.6693 1.42548 14.6693 1.53594V4.66927"
      stroke="black"
      strokeLinecap="round"
    />
    <path d="M14.6667 1.33594L8 8.0026" stroke="black" strokeLinecap="round" />
  </svg>
);

export default LinkIcon;
