import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { BscConnector } from "@binance-chain/bsc-connector";
import { BitKeepConnector } from "bitkeep/connector";
import { OntoConnector } from "onto/connector";
import { MathWalletConnector } from "math-wallet/connector";
import { RPC_URLS } from "./rpcUrls";
import POLLING_INTERVAL from "./pollingInterval";

export const Injected = new InjectedConnector({
  supportedChainIds: [
    1, 3, 4, 5, 10, 42, 56, 97, 100, 137, 204, 324, 8453, 32520, 42161, 59144,
    81457, 421611, 534352, 168587773, 1313161554,
  ],
});

export const BitKeep = new BitKeepConnector({
  supportedChainIds: [
    1, 3, 4, 5, 10, 42, 56, 97, 100, 137, 204, 324, 8453, 32520, 42161, 59144,
    81457, 421611, 534352, 168587773, 1313161554,
  ],
});

export const Onto = new OntoConnector({
  supportedChainIds: [
    1, 3, 4, 5, 10, 42, 56, 97, 100, 137, 204, 324, 8453, 32520, 42161, 59144,
    81457, 421611, 534352, 168587773, 1313161554,
  ],
});

export const MathWallet = new MathWalletConnector({
  supportedChainIds: [
    1, 3, 4, 5, 10, 42, 56, 97, 100, 137, 204, 324, 8453, 32520, 42161, 59144,
    81457, 421611, 534352, 168587773, 1313161554,
  ],
});

export const WalletConnect = new WalletConnectConnector({
  rpc: { 1: RPC_URLS.ETH_MAINNET, 56: RPC_URLS.BSC_MAINNET },
  qrcode: true,
  // @ts-ignore
  pollingInterval: POLLING_INTERVAL,
});

export const BinanceWallet = new BscConnector({
  supportedChainIds: [1, 56, 97],
});
