import React from "react";

const ZkSyncEraIcon = (props) => {
  return (
    <svg
      {...props}
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="14" cy="14" r="14" fill="white" />
      <circle cx="14" cy="14" r="13.5" stroke="#1E69FF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.6986 13.7821L18.3005 8.39999V12.3406L12.9412 16.2859L18.3005 16.2904V19.162L23.6986 13.7821Z"
        fill="#1E69FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.66675 13.7798L10.0649 19.1597V15.251L15.4241 11.2738L10.0649 11.2692V8.39999L4.66675 13.7798Z"
        fill="#1E69FF"
      />
    </svg>
  );
};

export default ZkSyncEraIcon;
