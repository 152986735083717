import { useWeb3React } from "@web3-react/core";
import useNetworks from "./useNetworks";

const useCurrentNetwork = () => {
  const { chainId } = useWeb3React();
  const { networksByChainId } = useNetworks();
  return chainId ? networksByChainId[chainId] : null;
};

export default useCurrentNetwork;
