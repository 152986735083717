import { useState, useEffect } from "react";

function getScrollPercentage(element) {
  if (element === null) {
    return NaN;
  }
  const height = element.scrollHeight - element.clientHeight;
  return Math.round((element.scrollTop / height) * 100);
}

const useScrollPercentage = (node) => {
  const [scrollPercentage, setScrollPercentage] = useState(NaN);

  const reportScroll = (e) => {
    setScrollPercentage(getScrollPercentage(e.target));
  };

  useEffect(() => {
    if (node) {
      node.addEventListener("scroll", reportScroll, { passive: true });
      if (Number.isNaN(scrollPercentage)) {
        setScrollPercentage(getScrollPercentage(node));
      }
    }
    return () => {
      if (node) {
        node.removeEventListener("scroll", reportScroll);
      }
    };
  }, [node, scrollPercentage]);

  return Number.isNaN(scrollPercentage) ? 0 : scrollPercentage;
};

export { useScrollPercentage };
