import { useCallback, useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import axios from "axios";
import { whitelistedKey } from "shared/constants";

// TODO(Nikita): Remove
const useDcaWhitelistAuth = () => {
  const { account } = useWeb3React();

  const [isWhitelisted, setIsWhitelisted] = useState(false);

  const checkIfAccountInWhitelist = useCallback(async () => {
    if (account) {
      await axios.get(`/dca/checkWhitelist/${account}`).then((res) => {
        if (res.data.exists) {
          localStorage.setItem(whitelistedKey, "true");
        } else {
          localStorage.setItem(whitelistedKey, "false");
        }

        setIsWhitelisted(res.data.exists);
      });
    }
  }, [account, setIsWhitelisted]);

  useEffect(() => {
    checkIfAccountInWhitelist();
  }, [checkIfAccountInWhitelist]);

  useEffect(() => {
    const isAlreadyWhitelisted = localStorage.getItem(whitelistedKey);

    if (isAlreadyWhitelisted === "true") {
      setIsWhitelisted(true);
    }
  }, [setIsWhitelisted]);

  return { isWhitelisted, checkIfAccountInWhitelist };
};

export { useDcaWhitelistAuth };
