import { AbstractConnector } from "@web3-react/abstract-connector";
import {
  BinanceWallet,
  BitKeep,
  Injected,
  MathWallet,
  Onto,
  WalletConnect,
} from "./connectors";

export interface IWallet {
  name: string;
  image: string;
  slug: string;
  connector: AbstractConnector;
  supportedNetworkIds: number[];
}

export const WALLETS: IWallet[] = [
  {
    name: "Metamask",
    image: "metamask.svg",
    slug: "metamask",
    connector: Injected,
    supportedNetworkIds: [
      1, 3, 4, 5, 10, 42, 56, 97, 100, 137, 204, 324, 8453, 32520, 42161, 59144,
      81457, 421611, 534352, 1313161554,
    ],
  },
  {
    name: "Bitkeep",
    image: "bitkeep.svg",
    slug: "bitkeep",
    connector: BitKeep,
    supportedNetworkIds: [
      1, 3, 4, 5, 10, 42, 56, 97, 100, 137, 204, 324, 8453, 32520, 42161, 59144,
      81457, 421611, 534352, 1313161554,
    ],
  },
  {
    name: "Onto",
    image: "onto.svg",
    slug: "onto",
    connector: Onto,
    supportedNetworkIds: [
      1, 3, 4, 5, 10, 42, 56, 97, 100, 137, 204, 324, 8453, 32520, 42161, 59144,
      81457, 421611, 534352, 1313161554,
    ],
  },
  {
    name: "Binance Chain Wallet",
    image: "binance.svg",
    slug: "binance",
    connector: BinanceWallet,
    supportedNetworkIds: [1, 56, 97],
  },
  {
    name: "Wallet Connect",
    image: "wallet_connect.svg",
    slug: "walletconnect",
    connector: WalletConnect,
    supportedNetworkIds: [
      1, 3, 4, 5, 10, 42, 56, 97, 100, 137, 204, 324, 8453, 32520, 42161, 59144,
      81457, 421611, 534352, 1313161554,
    ],
  },
  {
    name: "Trust Wallet",
    image: "trust_wallet.svg",
    slug: "trustwallet",
    connector: Injected,
    supportedNetworkIds: [
      1, 3, 4, 5, 10, 42, 56, 97, 100, 137, 204, 324, 8453, 32520, 42161, 59144,
      81457, 421611, 534352, 1313161554,
    ],
  },
  {
    name: "SafePal",
    image: "safepal.svg",
    slug: "safepal",
    connector: Injected,
    supportedNetworkIds: [
      1, 3, 4, 5, 10, 42, 56, 97, 100, 137, 204, 324, 8453, 32520, 42161, 59144,
      81457, 421611, 534352, 1313161554,
    ],
  },
  {
    name: "Math",
    image: "math_wallet.png",
    slug: "mathwallet",
    connector: MathWallet,
    supportedNetworkIds: [
      1, 3, 4, 5, 10, 42, 56, 97, 100, 137, 204, 324, 8453, 32520, 42161, 59144,
      81457, 421611, 534352, 1313161554,
    ],
  },
];

export const WALLETS_OBJ = WALLETS.reduce(
  (all, cur) => ({ ...all, [cur.slug]: cur }),
  {},
) as Record<string, IWallet>;
