import React from "react";
import ReactDOM from "react-dom";
import "antd/dist/antd.css";
import "./styles/index.scss";
import App from "./app";
import reportWebVitals from "./reportWebVitals";
import { Web3ReactProvider } from "@web3-react/core";
import BigNumber from "bignumber.js";
import { Router } from "react-router-dom";
import { ethers } from "ethers";
import history from "./shared/history";
import ErrorBoundary from "app/components/ErrorBoundary";
import { POLLING_INTERVAL } from "./shared/constants";
import { Provider } from "react-redux";
import store from "./store";
import { ToastContainer } from "react-toastify";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import axios from "axios";
import "react-toastify/dist/ReactToastify.min.css";

import "./styles/global/toast.scss";
import { ThemeProvider } from "styled-components";
import theme from "shared/styles/Theme";

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

BigNumber.config({ ROUNDING_MODE: BigNumber.ROUND_DOWN });
BigNumber.set({ ROUNDING_MODE: BigNumber.ROUND_DOWN });

function getLibrary(provider): ethers.providers.Web3Provider {
  const library = new ethers.providers.Web3Provider(provider);
  library.pollingInterval = POLLING_INTERVAL;

  return library;
}

ReactDOM.render(
  <Web3ReactProvider getLibrary={getLibrary}>
    <ToastContainer
      theme="colored"
      position="top-right"
      autoClose={5000}
      hideProgressBar
      icon={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      pauseOnHover
    />
    <Provider store={store}>
      <Router history={history}>
        <ErrorBoundary>
          <I18nextProvider i18n={i18n}>
            <ThemeProvider theme={theme}>
              <App />
            </ThemeProvider>
          </I18nextProvider>
        </ErrorBoundary>
      </Router>
    </Provider>
  </Web3ReactProvider>,
  document.getElementById("root"),
);

reportWebVitals();

// const loadBitQueryScript = (callback: (status: string) => void) => {
//   const existingScript = document.getElementById("bit-query-widget");
//   if (!existingScript) {
//     const script = document.createElement("script");
//     script.src = "https://cdn.jsdelivr.net/gh/bitquery/widgets@v1.3.9/dist/widgets.js";
//     script.id = "bit-query-widget";
//     document.body.appendChild(script);
//     script.onload = () => {
//       if (callback) callback("success");
//     };
//     script.onerror = () => {
//       if (callback) callback("error");
//     };
//   }
//   if (existingScript && callback) callback("success");
// };

// loadBitQueryScript(async (status) => {
//   if (status !== "error" && (window as any).widgets && (window as any).widgets.init) {
//     try {
//       await (window as any).widgets.init("https://graphql.bitquery.io", process.env.REACT_APP_BITQUERY_API_KEY);
//     } catch (e) {
//       console.error(e);
//       console.warn("BitQuery script could not be initialized, the dashboard page will not work correctly");
//     }
//   } else console.error("BitQuery widgets were not loaded");
//   // If you want your app to work offline and load faster, you can change
//   // unregister() to register() below. Note this comes with some pitfalls.
//   // Learn more about service workers: http://bit.ly/CRA-PWA
// });
