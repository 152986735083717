import styled, { css } from "styled-components";

type DetailsContainerTypes = {
  $disabled?: boolean;
};

type DetailsRewardTypes = {
  isClaimed?: boolean;
};

const Wrapper = styled.div`
  padding: 20px;
`;

const Description = styled.span`
  font-weight: 300;
  font-size: 12px;
  line-height: 19px;
  color: #000000;
`;

const RoundWrapper = styled.div`
  position: relative;
`;

const ClaimedLabel = styled.span`
  position: absolute;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  opacity: 1;
  left: 80px;
  top: 50%;
  transform: translateY(-50%);
  @media (max-width: 600px) {
    transform: none;
    top: 12px;
  }
`;

const RewardContainer = styled.div<DetailsContainerTypes>`
  margin-top: 20px;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #c2c2c2;
  border-radius: 10px;

  ${(props) =>
    props.$disabled &&
    css`
      opacity: 0.5;
    `};

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Round = styled.span<DetailsRewardTypes>`
  font-size: 14px;
  line-height: 155%;
  color: #000000;
  ${(props) =>
    props.isClaimed &&
    css`
      color: #858585;
    `}
`;

const Reward = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 600px) {
    margin-top: 20px;
  }
`;

const RewardAmount = styled.span<DetailsRewardTypes>`
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  color: #000000;

  ${(props) =>
    props.isClaimed &&
    css`
      color: #858585;
    `}
`;

const RewardToken = styled.span<DetailsRewardTypes>`
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  color: #000000;
  margin-left: 14px;

  ${(props) =>
    props.isClaimed &&
    css`
      color: #858585;
    `}
`;

const Button = styled.button`
  background: linear-gradient(90deg, #b6e82f 0%, #50e0ed 100%);
  border-radius: 10px;
  padding: 10px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 14px;
  line-height: 145%;
  margin: 28px auto 0;
  max-height: 32px;
  color: #000000;

  &:disabled {
    background: transparent;
    border: 1px solid #858585;
    cursor: not-allowed;
    color: #858585;
  }
  @media (max-width: 600px) {
    margin-top: 20px;
  }
`;

export default {
  Wrapper,
  Description,
  RoundWrapper,
  ClaimedLabel,
  RewardContainer,
  Round,
  Reward,
  RewardAmount,
  RewardToken,
  Button,
};
