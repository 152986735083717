import React from "react";

const ArrowBottomIcon = (props) => (
  <svg
    {...props}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.5575 6.43506L9 9.87756L12.4425 6.43506L13.5 7.50006L9 12.0001L4.5 7.50006L5.5575 6.43506Z"
      fill="white"
    />
  </svg>
);

export default ArrowBottomIcon;
