import React from "react";
import Slider, { Range } from "rc-slider";

import "./styles.scss";

type Props = {
  min: number;
  max: number;
  onChange: (value: number[]) => void;
  value: number[];
  onBlur?: (...args) => void;
  onAfterChange?: (...args) => void;
  className?: string;
  step?: number;
  disabled?: boolean;
};

const RangeSlider = ({
  min,
  max,
  onChange,
  value,
  className,
  step = 1,
  onBlur,
  onAfterChange,
  disabled,
}: Props) => {
  return (
    <>
      {value.length === 1 ? (
        // @ts-ignore
        <Slider
          className={className}
          min={min}
          max={max}
          step={step}
          onChange={(val) => onChange([val])}
          value={value[0]}
          onBlur={onBlur}
          onAfterChange={onAfterChange}
          disabled={disabled}
        />
      ) : (
        // @ts-ignore
        <Range
          className={className}
          min={min}
          max={max}
          step={step}
          onChange={onChange}
          value={value}
          onBlur={onBlur}
          onAfterChange={onAfterChange}
          disabled={disabled}
        />
      )}
    </>
  );
};

export default RangeSlider;
