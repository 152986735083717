import React from "react";

const DiscordIcon = (props?: any): JSX.Element => {
  return (
    <svg
      {...props}
      width="20"
      height="17"
      viewBox="0 0 20 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6200_80383)">
        <path
          d="M16.9221 2.04869C15.6554 1.46515 14.284 1.04162 12.8554 0.796918C12.8429 0.796522 12.8304 0.798845 12.8189 0.803724C12.8073 0.808602 12.797 0.815917 12.7888 0.825154C12.6173 1.13574 12.4173 1.54045 12.284 1.85104C10.7688 1.62515 9.22777 1.62515 7.71253 1.85104C7.5792 1.53104 7.3792 1.13574 7.19825 0.825154C7.18872 0.80633 7.16015 0.796918 7.13158 0.796918C5.70301 1.04162 4.3411 1.46515 3.06491 2.04869C3.05539 2.04869 3.04587 2.0581 3.03634 2.06751C0.44587 5.8981 -0.268415 9.62515 0.0839659 13.3145C0.0839659 13.3333 0.0934897 13.3522 0.112537 13.3616C1.82682 14.6039 3.47444 15.3569 5.10301 15.8557C5.13158 15.8651 5.16015 15.8557 5.16968 15.8369C5.55063 15.3192 5.89349 14.7733 6.18872 14.1992C6.20777 14.1616 6.18872 14.1239 6.15063 14.1145C5.60777 13.9075 5.09349 13.6628 4.58872 13.3804C4.55063 13.3616 4.55063 13.3051 4.5792 13.2769C4.68396 13.2016 4.78872 13.1169 4.89349 13.0416C4.91253 13.0228 4.9411 13.0228 4.96015 13.0322C8.23634 14.5098 11.7697 14.5098 15.0078 13.0322C15.0269 13.0228 15.0554 13.0228 15.0745 13.0416C15.1792 13.1263 15.284 13.2016 15.3888 13.2863C15.4269 13.3145 15.4269 13.371 15.3792 13.3898C14.884 13.6816 14.3602 13.9169 13.8173 14.1239C13.7792 14.1333 13.7697 14.1804 13.7792 14.2086C14.084 14.7828 14.4269 15.3286 14.7983 15.8463C14.8269 15.8557 14.8554 15.8651 14.884 15.8557C16.5221 15.3569 18.1697 14.6039 19.884 13.3616C19.903 13.3522 19.9126 13.3333 19.9126 13.3145C20.3316 9.05103 19.2173 5.35222 16.9602 2.06751C16.9507 2.0581 16.9411 2.04869 16.9221 2.04869ZM6.68396 11.0651C5.70301 11.0651 4.88396 10.171 4.88396 9.06986C4.88396 7.96869 5.68396 7.07457 6.68396 7.07457C7.69349 7.07457 8.49349 7.9781 8.48396 9.06986C8.48396 10.171 7.68396 11.0651 6.68396 11.0651ZM13.3221 11.0651C12.3411 11.0651 11.5221 10.171 11.5221 9.06986C11.5221 7.96869 12.3221 7.07457 13.3221 7.07457C14.3316 7.07457 15.1316 7.9781 15.1221 9.06986C15.1221 10.171 14.3316 11.0651 13.3221 11.0651Z"
          fill="#577EE2"
        />
      </g>
      <defs>
        <clipPath id="clip0_6200_80383">
          <rect
            width="20"
            height="16"
            fill="white"
            transform="translate(0 0.796875)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DiscordIcon;
