import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useClickOutside, useToast } from "shared/hooks";
import { Network, NETWORKS } from "shared/constants";
import { useWeb3React } from "@web3-react/core";
import { setupNetwork } from "utils/setupNetwork";
import { FormattedNetwork } from "./types";

import { useTranslation } from "react-i18next";
import { AvailableSetup } from "shared/types";
import isMobileDevice from "utils/isMobileDevice";

import Styled from "./styled";
import SUPPORTED_CHAIN_IDS from "shared/constants/supportedChainIds";

type Props = {
  disabled?: boolean;
  noChange?: boolean;
  className?: string;
};

const getNetworkColor = (chainId: number) => {
  switch (chainId) {
    case 1:
      return "#577EE2";
    case 10:
      return "#FF0420";
    case 56:
      return "#EBB50B";
    case 100:
      return "#3E6957";
    case 137:
      return "#8049DB";
    case 204:
      return "#EBB50B";
    case 324:
      return "#1E69FF";
    case 8453:
      return "#3773F5";
    case 32520:
      return "#6200DC";
    case 534352:
      return "#62E6D4";
    case 42161:
      return "#96BEDC";
    case 43114:
      return "#E84142";
    case 59144:
      return "#61DFFF";
    case 1313161554:
      return "#6ED34A";
    case SUPPORTED_CHAIN_IDS.BLAST:
      return "#6ED34A";
    default:
      return "#627EEA";
  }
};

const getNetworkDisplayName = (chainId: number) => {
  switch (chainId) {
    case 1:
      return "NETWORKS-eth";
    case 56:
      return "NETWORKS-bsc";
    case 100:
      return "NETWORKS-gnosis";
    case 137:
      return "NETWORKS-polygon";
    case 204:
      return "NETWORKS-opbnb";
    case 324:
      return "NETWORKS-zksyncera";
    case 10:
      return "NETWORKS-optimism";
    case 8453:
      return "NETWORKS-base";
    case 32520:
      return "NETWORKS-bitgert";
    case 42161:
      return "NETWORKS-arbitrum";
    case 43114:
      return "NETWORKS-avalanche";
    case 59144:
      return "NETWORKS-linea";
    case 534352:
      return "NETWORKS-scroll";
    case 1313161554:
      return "NETWORKS-aurora";
    case SUPPORTED_CHAIN_IDS.BLAST:
      return "NETWORKS-blast";
    default:
      return "NETWORKS-undefined";
  }
};

const UndefinedNetwork: FormattedNetwork = {
  name: "",
  displayName: "NETWORKS-undefined",
  color: "#627EEA",
  chainId: 0,
  icon: "",
};

const NetworkSelect = ({
  disabled = false,
  noChange = false,
  className,
}: Props) => {
  const { chainId, connector } = useWeb3React();
  const [showSelect, setShowSelect] = useState<boolean>(false);
  const [selectedNetwork, setSelectedNetwork] =
    useState<FormattedNetwork>(UndefinedNetwork);
  const { toastError } = useToast();
  const [prevChainId, setPrevChainId] = useState<number | undefined | null>(
    null,
  );
  const [networkChanged, setNetworkChanged] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const { t } = useTranslation();

  const hideOptions = useCallback(() => {
    setShowSelect(false);
  }, [setShowSelect]);

  const WrapperRef = useRef(null);
  useClickOutside(WrapperRef, hideOptions);

  const formattedNetworks = useMemo(() => {
    return NETWORKS.map((network) => ({
      ...network,
      color: getNetworkColor(network.chainId),
      displayName: getNetworkDisplayName(network.chainId),
    }));
  }, []);

  const filteredNetworks = formattedNetworks.filter((network) =>
    t(network.displayName).toLowerCase().includes(searchQuery.toLowerCase()),
  );

  const onShowNotSupportedNetwork = useCallback(() => {
    toastError(t("COMMON-no_network_wallet"));
  }, [t, toastError]);

  const onChangeNetwork = (network: Network) => {
    if (noChange || disabled) return;

    if (connector) {
      if (!connector.supportedChainIds?.includes(network.chainId)) {
        onShowNotSupportedNetwork();
      } else {
        setupNetwork(network.configName as AvailableSetup)
          .then((res: boolean) => res && hideOptions())
          .catch((e) => {
            if (e.code === -32002 || e.code === 4902) {
              toastError(t("COMMON-warning"), t("WARNING-32002"));
            }
          });
      }
    }
  };

  useEffect(() => {
    const newNetwork = formattedNetworks.find(
      (network) => network.chainId === chainId,
    );

    if (newNetwork) {
      setSelectedNetwork(newNetwork);
    } else {
      setSelectedNetwork(UndefinedNetwork);
    }

    // if it's mobile, there was chainId, and there's no current chainId
    if (isMobileDevice() !== false && networkChanged) {
      // setTimeout(() => {
      //   window.location.reload();
      // }, 1000);
    }
  }, [chainId, formattedNetworks, networkChanged]);

  useEffect(() => {
    if (!prevChainId && !!chainId) {
      setNetworkChanged(true);
    }
    setPrevChainId(chainId);
  }, [chainId, prevChainId]);

  return (
    <Styled.Wrapper className={className} $active={showSelect} ref={WrapperRef}>
      <Styled.SelectedOption
        onClick={() => {
          setShowSelect((prevState) => !prevState);
        }}
        disabled={disabled && !noChange}
        background={selectedNetwork.color}
      >
        <Styled.SelectedNameIcon>
          {!!selectedNetwork.name && (
            <Styled.SelectedNetworkIcon
              chainId={selectedNetwork.chainId}
              slug={selectedNetwork.chainId}
            />
          )}
          <Styled.SelectedOptionName>
            {t(selectedNetwork.displayName)}
          </Styled.SelectedOptionName>
        </Styled.SelectedNameIcon>
        {(!disabled || noChange) && <Styled.ArrowBottomIcon />}
      </Styled.SelectedOption>
      {((showSelect && !disabled) || (showSelect && noChange)) && (
        <Styled.Options>
          <Styled.Search>
            <Styled.SearchIcon />
            <Styled.SearchInput
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <Styled.SearchClear
              hide={searchQuery.length <= 0}
              onClick={() => setSearchQuery("")}
            />
          </Styled.Search>
          {filteredNetworks.length > 0 ? (
            <Styled.OptionsBox>
              {filteredNetworks.map((network) => (
                <Styled.Option
                  key={network.chainId}
                  active={network.chainId === selectedNetwork?.chainId}
                  onClick={() =>
                    network.configName &&
                    network.chainId !== chainId &&
                    onChangeNetwork(network)
                  }
                >
                  <Styled.NetworkIcon chainId={network.chainId} />
                  <Styled.OptionName>
                    {t(network.displayName)}
                  </Styled.OptionName>
                </Styled.Option>
              ))}
            </Styled.OptionsBox>
          ) : (
            <Styled.EmptyOptions>Nothing found</Styled.EmptyOptions>
          )}
        </Styled.Options>
      )}
    </Styled.Wrapper>
  );
};

export default NetworkSelect;
