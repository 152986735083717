import React from "react";
import "./styles.scss";

export default function Toggler({
  checked,
  onChange,
  className,
}: {
  checked: boolean;
  onChange: any;
  className?: string;
}) {
  return (
    <button
      className={
        `${className} ctoggler ctoggler--react` + (checked ? " active" : "")
      }
      onClick={onChange}
    />
  );
}
