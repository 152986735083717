import React from "react";

export const TooltipIcon = ({ ...props }) => (
  <svg
    {...props}
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="7.5" cy="7.5" r="7" stroke="#ADADAD" />
    <path
      d="M6.69539 9.29855V7.36004C7.33604 7.24418 7.89999 7.00354 8.38724 6.63812C8.8745 6.2727 9.11812 5.83152 9.11812 5.31459C9.11812 4.81548 8.92864 4.42332 8.54966 4.13812C8.17971 3.844 7.70148 3.69694 7.11497 3.69694C6.32995 3.69694 5.54944 3.95986 4.77344 4.48571V3.48304C5.4592 2.98393 6.29385 2.73438 7.27738 2.73438C8.08948 2.73438 8.78427 2.9661 9.36175 3.42956C9.93924 3.89302 10.228 4.50354 10.228 5.26111C10.228 5.90282 9.98436 6.45095 9.4971 6.9055C9.01887 7.35113 8.43236 7.68535 7.73757 7.90817V9.29855H6.69539ZM6.70892 12.0659C6.56455 11.9233 6.49236 11.7495 6.49236 11.5445C6.49236 11.3395 6.56455 11.1657 6.70892 11.0231C6.86232 10.8716 7.04278 10.7959 7.25032 10.7959C7.45785 10.7959 7.6338 10.8716 7.77817 11.0231C7.92255 11.1657 7.99473 11.3395 7.99473 11.5445C7.99473 11.7495 7.92255 11.9233 7.77817 12.0659C7.6338 12.2085 7.45785 12.2798 7.25032 12.2798C7.04278 12.2798 6.86232 12.2085 6.70892 12.0659Z"
      fill="#ADADAD"
    />
  </svg>
);
