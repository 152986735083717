import { TimeInterims } from "./types";
import moment from "moment";

const getTimeInterimTimestamp = (timeInterim: TimeInterims): number => {
  switch (timeInterim) {
    case "last_year":
      return moment().subtract(1, "year").unix();
    case "last_6_month":
      return moment().subtract(6, "months").unix();
    case "last_3_month":
      return moment().subtract(3, "months").unix();
    case "last_month":
      return moment().subtract(1, "month").unix();
    case "last_week":
      return moment().subtract(1, "week").unix();
    default:
      return moment().subtract(1, "day").unix();
  }
};

export { getTimeInterimTimestamp };
