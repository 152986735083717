import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Styled from "./styled";
import { MetamaskIcon } from "@pancakeswap-libs/uikit";
// import { AddressIcon } from "pages/Save/_resources/AddressIcon";
import WarningIcon from "components/BetaModal/_resources/WarningIcon";
import { useResize } from "shared/hooks/useResize";

interface Props {
  onClose: () => void;
}

const NoPluginModal = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const { windowWidth } = useResize();

  const mobileView = useMemo(() => {
    return windowWidth <= 600;
  }, [windowWidth]);
  return (
    <Styled.Wrapper
      onClose={onClose}
      pretitleContent={
        <Styled.Top>
          <WarningIcon />
          <Styled.TopTitle>{t("COMMON-warning")}</Styled.TopTitle>
        </Styled.Top>
      }
    >
      <Styled.Content>
        <Styled.Title>{t("No_Plugin_Modal-title")}</Styled.Title>
        <Styled.SubTitle>{t("No_Plugin_Modal-subtitle")}</Styled.SubTitle>
        {/*{mobileView ? null : <Styled.HR /> }*/}
      </Styled.Content>
      <Styled.Inner background={mobileView}>
        <Styled.MetaMask href="https://metamask.io/" target="_blank">
          <MetamaskIcon />
          <Styled.MetamaskText>Metamask</Styled.MetamaskText>
        </Styled.MetaMask>
        <Styled.Text>{t("No_Plugin_Modal-text1")}</Styled.Text>
        {/*{!mobileView ? <Styled.Text>{t('No_Plugin_Modal-text2')}</Styled.Text> : null }*/}
      </Styled.Inner>
      {/*{!mobileView ?*/}
      {/*  <Styled.LinksBackground>*/}
      {/*    <Styled.Links>*/}
      {/*      <Styled.Link href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn"*/}
      {/*                   target="_blank">*/}
      {/*        <Styled.Img src="/svg/browsers/chrome.svg" alt=""/>*/}
      {/*        Chrome*/}
      {/*      </Styled.Link>*/}
      {/*      <Styled.Link href="https://addons.mozilla.org/en-US/firefox/addon/ether-metamask/" target="_blank">*/}
      {/*        <Styled.Img src="/svg/browsers/firefox.svg" alt=""/>*/}
      {/*        Firefox*/}
      {/*      </Styled.Link>*/}
      {/*      <Styled.Link href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn"*/}
      {/*                   target="_blank">*/}
      {/*        <Styled.Img src="/svg/browsers/brave.svg" alt=""/>*/}
      {/*        Brave*/}
      {/*      </Styled.Link>*/}
      {/*      <Styled.Link*/}
      {/*        href="https://microsoftedge.microsoft.com/addons/detail/metamask/ejbalbakoplchlghecdalmeeeajnimhm?hl=en-US"*/}
      {/*        target="_blank">*/}
      {/*        <Styled.Img src="/svg/browsers/edge.svg" alt=""/>*/}
      {/*        Edge*/}
      {/*      </Styled.Link>*/}
      {/*    </Styled.Links>*/}
      {/*  </Styled.LinksBackground> : null*/}
      {/*}*/}
      <Styled.HowTo href="https://roobee.finance/howtoinstall" target="_blank">
        {/*<Styled.HowToIcon />*/}
        <Styled.HowToText>
          How to install crypto-wallet and start to use?
        </Styled.HowToText>
      </Styled.HowTo>
      {/*{mobileView ?*/}
      {/*  <Styled.HowTo href="https://roobee.finance/howtoinstall" target="_blank">*/}
      {/*    <Styled.HowToIcon />*/}
      {/*    <Styled.HowToText>How to install crypto-wallet and start to use?</Styled.HowToText>*/}
      {/*  </Styled.HowTo>*/}
      {/*  :*/}
      {/*  <Styled.LearnMore>*/}
      {/*    <Styled.Button href="https://roobee.gitbook.io/roobee-eng/get-started" target="_blank">*/}
      {/*      {t('ACTIONS-learn_more')}*/}
      {/*      <AddressIcon />*/}
      {/*    </Styled.Button>*/}
      {/*  </Styled.LearnMore>*/}
      {/*}*/}
    </Styled.Wrapper>
  );
};

export default NoPluginModal;
