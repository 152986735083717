import React from "react";

const ArrowBackIcon = (props) => (
  <svg
    {...props}
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.5 3.5L1.35355 7.64645C1.15829 7.84171 1.15829 8.15829 1.35355 8.35355L5.5 12.5"
      stroke="#C2C2C2"
      strokeLinecap="round"
    />
  </svg>
);

export default ArrowBackIcon;
