import styled from "styled-components";
import { CustomScrollbar } from "shared/styles/Mixins";

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10001;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #697b8c;
  opacity: 0.6;
`;

const Card = styled.div`
  position: relative;
  z-index: 1;
  margin-bottom: 100px;
  background: ${(props) => props.theme.Colors.BackgroundWhite};
  max-width: 600px;
  min-width: 500px;
  max-height: 80vh;
  overflow-y: auto;
  background: #ffffff;
  box-shadow: 0 2px 6px rgba(0, 111, 128, 0.08);
  border-radius: 16px;
  ${CustomScrollbar};
  @media (max-width: 600px) {
    margin-bottom: 0;
    min-width: 300px;
  }
`;

const CardTitle = styled.div``;

const CardTop = styled.div``;

const CardBottom = styled.div``;

const CardContent = styled.div``;

const Close = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;
  }
`;

export default {
  Wrapper,
  Background,
  Card,
  CardTitle,
  CardTop,
  CardContent,
  CardBottom,
  Close,
};
