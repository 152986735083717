import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  RoobeeBscAbi,
  DaoStakingAbi,
  DAO_STAKING,
  BROOBEE_BSC,
  AirdropAbi,
  AIRDROP,
} from "shared/constants";
import BigNumber from "bignumber.js";
import type { AddressRewardResponse, RoundReward } from "./types";
import { getContract } from "utils/getContract";
import { Airdrop, DaoStaking, RoobeeBsc } from "shared/constants/abis/types";
import { ethersToBN } from "utils/ethersToBN";

const fetchGeneralBalanceInfo = createAsyncThunk<any, any, any>(
  "airdrop/fetchGeneralBalanceInfo",
  async ({ account, library }) => {
    const result = {
      balance: new BigNumber(0),
      stakedBalance: new BigNumber(0),
      stakingApy: 0,
    };

    if (!!account && !!library) {
      const roobee = getContract<RoobeeBsc>(
        RoobeeBscAbi,
        BROOBEE_BSC,
        library.getSigner(),
      );
      const daoStaking = getContract<DaoStaking>(
        DaoStakingAbi,
        DAO_STAKING,
        library.getSigner(),
      );
      const roobeeBalance = await roobee.balanceOf(account);
      const roobeeStaked = await daoStaking.balanceOf(account);
      // console.log("BALANCES ROOBEE AND STAKED:", daoStaking);

      result.balance = ethersToBN(roobeeBalance);
      result.stakedBalance = ethersToBN(roobeeStaked);
    }

    return result;
  },
);

const fetchRewards = createAsyncThunk<any, any, any>(
  "airdrop/fetchRewards",
  async ({ account, chainId, library }): Promise<RoundReward[]> => {
    if (!account) return [];

    const addressRewards = await axios
      .get(`/airdrop/${account}:info`)
      .then((res: any) => res.data.rounds)
      .catch(() => {
        return [];
      });

    let rewards =
      addressRewards?.map((reward: AddressRewardResponse) => {
        return {
          id: reward.roundId,
          amount: new BigNumber(reward.amount),
          isClaimed: reward.claimed,
        };
      }) || [];

    if (rewards.length === 0) {
      rewards.push({ id: 1, amount: new BigNumber(0), isClaimed: false });
      rewards.push({
        id: 2,
        amount: new BigNumber(0),
        isClaimed: false,
        disabled: true,
      });
    } else if (rewards.length === 1) {
      rewards.push({
        id: 2,
        amount: new BigNumber(0),
        isClaimed: false,
        disabled: true,
      });
    }

    if (chainId === 56 && !!library) {
      const AirdropContract = getContract<Airdrop>(
        AirdropAbi,
        AIRDROP,
        library.getSigner(),
      );

      for (let roundIndex = 0; roundIndex < rewards.length; roundIndex++) {
        const roundId = rewards[roundIndex].id;
        const roundIsClaimed = await AirdropContract.isWithdrawn(
          roundId,
          account,
        );
        rewards[roundIndex].isClaimed = roundIsClaimed;
      }
    } else {
      rewards = rewards.map((reward) => ({
        ...reward,
        amount: new BigNumber(0),
        isClaimed: false,
        disabled: true,
      }));
    }

    return rewards;
  },
);

export { fetchGeneralBalanceInfo, fetchRewards };
