import styled from "styled-components";
import { default as NotStyledGoBackIcon } from "./_resources/ArrowIcon";

const Link = styled.button`
  display: flex;
  align-items: center;
  margin-bottom: 22px;
  max-width: max-content;
`;

const Icon = styled(NotStyledGoBackIcon)``;

const Text = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 155%;
  letter-spacing: -0.01em;
  color: #8596a6;
  margin-left: 18px;
`;

export default {
  Link,
  Icon,
  Text,
};
