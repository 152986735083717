import React from "react";

const DropdownIcon = (props) => {
  return (
    <svg
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 6L7.14645 10.1464C7.34171 10.3417 7.65829 10.3417 7.85355 10.1464L12 6"
        stroke="black"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default DropdownIcon;
