export default function limitNumberString(strOrNum: string | number): string {
  if (typeof strOrNum === "string") {
    if (strOrNum.length === 1 && strOrNum[0] === "0") {
      return strOrNum;
    }
  }

  const maxLength = 10;
  const str = String(strOrNum).replace(/[^0-9.,]/g, "");
  const dotStr = str.replace(/,/g, ".").replace(/\.(?=.*\.)/g, "");
  const zeroRegex = /^[0]+(?!\.)/;
  const noLeadingZeroStr = dotStr.replace(zeroRegex, "");
  const truncatedStr = noLeadingZeroStr.slice(0, maxLength);

  if (truncatedStr === "") {
    return "0";
  }

  return truncatedStr;
}
