import styled from "styled-components";
import Colors from "shared/styles/Colors";

const Card = styled.div``;

const CardTop = styled.div`
  border-bottom: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: ${Colors.Gray0};

  svg {
    margin-right: 7px;
  }
`;

const CardContent = styled.div`
  max-width: unset;
  padding: 0 20px 25px;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: ${Colors.Gray0};
`;

export default {
  Card,
  CardTop,
  CardContent,
};
