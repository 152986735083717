import { useCallback, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { NoEthereumProviderError } from "@web3-react/injected-connector";
import { NoBscProviderError } from "@binance-chain/bsc-connector";
import {
  connectorKey as connectorKeyStorage,
  IWallet,
  Network,
  termsAccepted,
  WALLETS,
  welcomeScreenUnlockedKey,
} from "shared/constants";
import { useAuthorizeModal } from "shared/hooks";
import { setupNetwork } from "../../utils/setupNetwork";
import { useToast } from "./useToast";
import eventBus from "../../utils/eventBus";

const useConnect = () => {
  const { activate, deactivate } = useWeb3React();
  const { hideModal } = useAuthorizeModal();

  const { toastError } = useToast();
  const [connectorKey, setConnectorKey] = useState<null | string>(
    localStorage.getItem(connectorKeyStorage),
  );

  const connect = useCallback(
    async (wallet: IWallet, selectedNetwork: Network) => {
      await activate(wallet.connector, (err) => {
        if (
          err instanceof NoEthereumProviderError ||
          err instanceof NoBscProviderError
        ) {
          setConnectorKey(null);
          eventBus.dispatch("disconnected-from-wallet");
          localStorage.removeItem(connectorKeyStorage);
          localStorage.removeItem(welcomeScreenUnlockedKey);
          toastError(
            "Please install browser plugin or wallet app and refresh this page",
          );
        }
      });

      const currentChainId: number = parseInt(
        (await wallet.connector.getChainId()).toString(),
        16,
      );
      if (
        currentChainId !== selectedNetwork.chainId &&
        selectedNetwork.configName
      ) {
        await setupNetwork(selectedNetwork.configName);
      }
      localStorage.setItem(connectorKeyStorage, wallet.name);
      localStorage.setItem(welcomeScreenUnlockedKey, "true");
      localStorage.setItem(termsAccepted, "true");
      eventBus.dispatch("connected-to-wallet", wallet.name);
    },
    [activate, toastError],
  );

  const disconnect = useCallback(async () => {
    deactivate();

    if (window.localStorage.getItem("walletconnect")) {
      const walletConnect = WALLETS.find(
        (wallet) => wallet.name === "Wallet Connect",
      );

      if (walletConnect) {
        const { connector } = walletConnect;
        // @ts-ignore
        connector.walletconnect.close();
        // @ts-ignore
        connector.walletconnect.walletConnectProvider = null;
      }
    }
    setConnectorKey(null);
    eventBus.dispatch("disconnected-from-wallet");
    window.localStorage.removeItem(connectorKeyStorage);
    hideModal();
  }, [deactivate, hideModal]);

  return { connect, disconnect, connectorKey };
};

export { useConnect };
