import React from "react";

const OptimisticIcon = (props) => {
  return (
    <svg
      {...props}
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.5 14C27.5 21.4558 21.4558 27.5 14 27.5C6.54416 27.5 0.5 21.4558 0.5 14C0.5 6.54416 6.54416 0.5 14 0.5C21.4558 0.5 27.5 6.54416 27.5 14Z"
        fill="white"
        stroke="#FF0420"
      />
      <path
        d="M9.91511 17.7112C9.1867 17.7423 8.4679 17.5358 7.86698 17.123C7.60738 16.9247 7.39999 16.6661 7.26275 16.3697C7.1255 16.0733 7.06254 15.7479 7.07931 15.4216C7.08251 15.2315 7.10008 15.0419 7.13187 14.8545C7.22291 14.3496 7.35243 13.7439 7.52043 13.0375C7.99658 11.1119 9.22537 10.1491 11.2068 10.1491C11.7034 10.141 12.1965 10.2339 12.6561 10.4222C13.0641 10.5821 13.4151 10.8601 13.6644 11.2205C13.9157 11.5918 14.0441 12.0327 14.0316 12.4809C14.028 12.6675 14.0104 12.8535 13.979 13.0375C13.8739 13.6607 13.7479 14.2664 13.601 14.8545C13.3562 15.8137 12.9326 16.5314 12.3302 17.0075C11.7285 17.4766 10.9234 17.7112 9.91511 17.7112ZM10.0622 16.1987C10.426 16.2088 10.7809 16.0855 11.0601 15.8522C11.3687 15.5723 11.5787 15.2002 11.6587 14.7914C11.8198 14.1335 11.9422 13.5593 12.0259 13.0689C12.0541 12.92 12.0681 12.7688 12.0679 12.6173C12.0679 11.98 11.7353 11.6614 11.0702 11.6616C10.7035 11.6528 10.3458 11.7757 10.062 12.0081C9.7591 12.291 9.55324 12.6622 9.47377 13.0689C9.34769 13.538 9.22169 14.1122 9.09577 14.7914C9.06727 14.9367 9.0532 15.0844 9.05377 15.2325C9.05377 15.8767 9.38992 16.1987 10.0622 16.1987Z"
        fill="#FF0420"
      />
      <path
        d="M14.5154 17.6099C14.4821 17.6118 14.4487 17.6062 14.4178 17.5935C14.3869 17.5808 14.3592 17.5613 14.3369 17.5364C14.3019 17.48 14.2906 17.412 14.3054 17.3473L15.7548 10.5204C15.7676 10.445 15.8086 10.3774 15.8695 10.3313C15.9258 10.2848 15.9962 10.2589 16.0692 10.2578H18.863C19.5208 10.2255 20.1728 10.394 20.7326 10.7409C20.9627 10.8917 21.1496 11.0998 21.2749 11.3448C21.4001 11.5897 21.4594 11.8631 21.4468 12.1379C21.4458 12.3218 21.4246 12.5049 21.3837 12.6842C21.2679 13.3918 20.889 14.0296 20.3229 14.4697C19.7978 14.8549 19.0766 15.0474 18.1593 15.0474H16.7417L16.2585 17.3475C16.2457 17.4229 16.2047 17.4905 16.1438 17.5367C16.0876 17.5831 16.0172 17.609 15.9443 17.6101L14.5154 17.6099ZM18.2335 13.5976C18.5087 13.6037 18.7782 13.5188 19.0002 13.3562C19.2287 13.1884 19.3861 12.9411 19.4414 12.6631C19.4609 12.5591 19.4714 12.4537 19.4728 12.3479C19.4788 12.2625 19.4659 12.1768 19.435 12.0969C19.4042 12.0171 19.3561 11.9449 19.2942 11.8857C19.119 11.7563 18.902 11.6964 18.6851 11.7177H17.4249L17.0258 13.5976H18.2335Z"
        fill="#FF0420"
      />
    </svg>
  );
};

export default OptimisticIcon;
