import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { getVenusData } from "./thunks";
import { VenusState } from "./types";

const extraReducers: any = (builder: ActionReducerMapBuilder<VenusState>) => {
  // builder.addCase(getWalletBalances.fulfilled, (state, action) => {
  //   state.assets = action.payload;
  // })

  builder.addCase(getVenusData.fulfilled, (state, action) => {
    state.assets = action.payload;
  });
};

export default extraReducers;
