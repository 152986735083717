import React from "react";

const Warning = ({ ...props }) => (
  <svg
    {...props}
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.084 13.7497H11.9173V15.583H10.084V13.7497ZM10.084 6.41634H11.9173V11.9163H10.084V6.41634ZM11.0007 1.83301C5.93148 1.83301 1.83398 5.95801 1.83398 10.9997C1.83398 13.4308 2.79976 15.7624 4.51884 17.4815C5.37004 18.3327 6.38057 19.0079 7.49272 19.4686C8.60487 19.9292 9.79687 20.1663 11.0007 20.1663C13.4318 20.1663 15.7634 19.2006 17.4825 17.4815C19.2015 15.7624 20.1673 13.4308 20.1673 10.9997C20.1673 9.79589 19.9302 8.60389 19.4695 7.49174C19.0089 6.37959 18.3337 5.36907 17.4825 4.51786C16.6313 3.66666 15.6207 2.99145 14.5086 2.53078C13.3964 2.07011 12.2044 1.83301 11.0007 1.83301ZM11.0007 18.333C9.05573 18.333 7.19047 17.5604 5.8152 16.1851C4.43993 14.8099 3.66732 12.9446 3.66732 10.9997C3.66732 9.05475 4.43993 7.18949 5.8152 5.81422C7.19047 4.43896 9.05573 3.66634 11.0007 3.66634C12.9456 3.66634 14.8108 4.43896 16.1861 5.81422C17.5614 7.18949 18.334 9.05475 18.334 10.9997C18.334 12.9446 17.5614 14.8099 16.1861 16.1851C14.8108 17.5604 12.9456 18.333 11.0007 18.333Z"
      fill="#FF513D"
    ></path>
  </svg>
);

export default Warning;
