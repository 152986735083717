import React, { ComponentPropsWithoutRef, FC } from "react";
import { Asset, Color } from "./types";

import {
  ArrowBottom,
  ReminderIcon as Reminder,
  Calendar,
  CloseCross,
  ArrowLeft,
  ArrowRight,
  CircledEsclamation,
  Note,
  OptionPlay,
  Edit,
  Discord,
  Success,
  Warning,
} from "./styled";

interface Props extends ComponentPropsWithoutRef<"svg"> {
  asset: Asset;
  color: Color;
  clickable?: boolean;
}

const Icon: FC<Props> = ({ asset, ...props }: Props) => {
  switch (asset) {
    case "util-arrow-bottom":
      return <ArrowBottom {...props} />;
    case "reminder":
      return <Reminder {...props} />;
    case "calendar":
      return <Calendar {...props} />;
    case "util-close-cross":
      return <CloseCross {...props} />;
    case "util-arrow-left":
      return <ArrowLeft {...props} />;
    case "util-arrow-right":
      return <ArrowRight {...props} />;
    case "util-reminder-circled-esclamation":
      return <CircledEsclamation {...props} />;
    case "note":
      return <Note {...props} />;
    case "option-play":
      return <OptionPlay {...props} />;
    case "edit":
      return <Edit {...props} />;
    case "org-discord":
      return <Discord {...props} />;
    case "success":
      return <Success {...props} />;
    case "warning":
      return <Warning {...props} />;
    default:
      return <></>;
  }
};

export default Icon;
