import React, { FC, useCallback, useMemo, useRef, useState } from "react";
import { useAuthorizeModal, useClickOutside, useResize } from "shared/hooks";
import { matchPath, useLocation } from "react-router-dom";
import { Spin } from "hamburger-react";
import { SIDEBAR_NAV } from "./consts";
import {
  SidebarNavItemCommonType,
  SidebarNavItemExpandableType,
  SidebarTypes,
} from "./types";
import { useWeb3React } from "@web3-react/core";

import Styled from "./styled";

const Sidebar: FC<SidebarTypes> = ({
  collapsed,
  setCollapsed,
  disconnectHandler,
  accountModalHandler,
}) => {
  const { pathname } = useLocation();
  const { account } = useWeb3React();
  const [innerCollapsed, setInnerCollapsed] = useState<boolean>(true);
  const [expandedNavItems, setExpandedItems] = useState<
    Record<string, boolean>
  >({});
  const { windowWidth } = useResize();
  const NavRef = useRef(null);

  const tabletView = useMemo(() => {
    return windowWidth <= 1350;
  }, [windowWidth]);

  const { showModal } = useAuthorizeModal();

  const onToggleCollapse = useCallback(() => {
    setCollapsed(!collapsed);
  }, [collapsed, setCollapsed]);

  const activeExpandedNavItemTitle = useMemo<string>(() => {
    let activeExpandedNavItem = "";

    SIDEBAR_NAV.forEach((navItem) => {
      if ((navItem as SidebarNavItemExpandableType).children) {
        (navItem as SidebarNavItemExpandableType).children.forEach(
          (childItem) => {
            if (
              !(
                childItem.path.startsWith("http://") ||
                childItem.path.startsWith("https://")
              ) &&
              matchPath(`${childItem.path}/*`, { path: pathname, exact: true })
            ) {
              activeExpandedNavItem = navItem.title;
            }
          },
        );
      }
    });

    return activeExpandedNavItem;
  }, [pathname]);

  useClickOutside(NavRef, () => {
    if (tabletView) {
      setCollapsed(true);
    }
  });

  const showAuthModal = () => {
    showModal();
  };

  return (
    <Styled.Wrapper ref={NavRef} collapsed={collapsed}>
      <Styled.Header collapsed={!collapsed}>
        {tabletView &&
          collapsed &&
          (!account ? <Styled.UnlockWalletButton /> : <Styled.NetworkSelect />)}
        {!tabletView && !collapsed ? (
          <Styled.Logo to="/">
            <Styled.LogoIcon />
          </Styled.Logo>
        ) : (
          ""
        )}
        <Styled.Hamburger>
          <Spin
            toggled={!collapsed}
            toggle={onToggleCollapse}
            size={20}
            color="#8596A6"
          />
        </Styled.Hamburger>
      </Styled.Header>
      <Styled.Body>
        <Styled.Nav>
          <Styled.NavList>
            {tabletView ? (
              <Styled.Logo to="/">
                <Styled.LogoIcon />
              </Styled.Logo>
            ) : (
              ""
            )}
            <Styled.NavListItem
              onClick={account ? accountModalHandler : showAuthModal}
            >
              <Styled.NavListItemButton>
                <Styled.NavListItemIcon src="/svg/sidebar/Account.svg" />
                <Styled.NavListItemLabel>Account</Styled.NavListItemLabel>
              </Styled.NavListItemButton>
            </Styled.NavListItem>
            {SIDEBAR_NAV.map((item) => (
              <Styled.NavListItem
                key={`${item.title}${
                  (item as SidebarNavItemCommonType).path
                    ? `-${(item as SidebarNavItemCommonType).path}`
                    : ""
                }`}
              >
                <Styled.NavListItemContent
                  onClick={() => tabletView && setCollapsed((prev) => !prev)}
                  {...((item as SidebarNavItemCommonType).path
                    ? (item as SidebarNavItemCommonType).path.startsWith(
                        "https://",
                      ) ||
                      (item as SidebarNavItemCommonType).path.startsWith(
                        "http://",
                      )
                      ? {
                          as: "a",
                          href: (item as SidebarNavItemCommonType).path,
                          target: "_blank",
                        }
                      : {
                          to: (item as SidebarNavItemCommonType).path,
                          exact: true,
                        }
                    : {
                        as: "button",
                        active: activeExpandedNavItemTitle === item.title,
                        onClick: () => {
                          setInnerCollapsed(!innerCollapsed);
                          setExpandedItems({
                            ...expandedNavItems,
                            [item.title]:
                              expandedNavItems[item.title] === undefined ||
                              innerCollapsed
                                ? true
                                : !expandedNavItems[item.title],
                          });
                        },
                      })}
                >
                  <Styled.NavListItemIcon
                    src={`/svg/sidebar/${item.title}.svg`}
                  />
                  <Styled.NavListItemLabel>
                    {item.title}
                  </Styled.NavListItemLabel>
                  <Styled.NavListItemUpdate>
                    {item.update}
                  </Styled.NavListItemUpdate>
                </Styled.NavListItemContent>
                {(item as SidebarNavItemExpandableType).children && (
                  <Styled.NavListItemDropdown
                    hidden={!expandedNavItems[item.title]}
                  >
                    {(item as SidebarNavItemExpandableType).children.map(
                      (child) => (
                        <Styled.NavListItemDropdownItem
                          key={`${child.title}${
                            (child as SidebarNavItemCommonType).path
                              ? `-${(child as SidebarNavItemCommonType).path}`
                              : ""
                          }`}
                        >
                          <Styled.NavListItemContent
                            {...((
                              child as SidebarNavItemCommonType
                            ).path.startsWith("http://") ||
                            (child as SidebarNavItemCommonType).path.startsWith(
                              "https://",
                            )
                              ? {
                                  as: "a",
                                  href: (child as SidebarNavItemCommonType)
                                    .path,
                                  target: "_blank",
                                }
                              : {
                                  to: child.path,
                                })}
                            onClick={() =>
                              tabletView && setCollapsed(!collapsed)
                            }
                          >
                            <Styled.NavListItemLabel>
                              {child.title}
                            </Styled.NavListItemLabel>
                          </Styled.NavListItemContent>
                        </Styled.NavListItemDropdownItem>
                      ),
                    )}
                  </Styled.NavListItemDropdown>
                )}
              </Styled.NavListItem>
            ))}
            <Styled.NavListItem onClick={disconnectHandler}>
              <Styled.NavListItemButton>
                <Styled.NavListItemIcon src="/svg/sidebar/Disconnect.svg" />
                <Styled.NavListItemLabel>
                  Disconnect wallet
                </Styled.NavListItemLabel>
              </Styled.NavListItemButton>
            </Styled.NavListItem>
          </Styled.NavList>
        </Styled.Nav>
        <Styled.Collapser onClick={onToggleCollapse}>
          <Styled.CollapserIcon />
        </Styled.Collapser>
      </Styled.Body>
    </Styled.Wrapper>
  );
};

export default Sidebar;
