export const SCAN_API_URLS = {
  // network name: url/api
  ETH: "https://api.etherscan.io/api",
  BSC: "https://api.bscscan.com/api",
  OPTIMISM: "https://api-optimistic.etherscan.io/api",
  ARBITRUM: "https://api.arbiscan.io/api",
  "ARBITRUM-testnet": "https://api-testnet.arbiscan.io/api",
  POLYGON: "https://api.polygonscan.com/api",
  "POLYGON-testnet": "https://api-testnet.polygonscan.com/api",
  AVALANCHE: "https://api.snowtrace.io/api",
  "AVALANCHE-testnet": "https://api-testnet.snowtrace.io/api",
};
