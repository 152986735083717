import React, { useMemo } from "react";
import { State } from "store";
import { useSelector } from "react-redux";
import classNames from "classnames";
import BigNumber from "bignumber.js";
import { DEFAULT_DECIMALS } from "shared/constants";
import { displayBigNumber } from "../../displayBigNumber";
import { useAirdrop } from "../../AirdropModalProvider";
import { useTranslation } from "react-i18next";
import Parser from "html-react-parser";

import Styled from "./styled";

const PrimaryContent = () => {
  const { showAirdrop } = useAirdrop();
  const { roobeeBalance, stakedRoobee, stakingApy, rewards } = useSelector(
    (state: State) => state.airdrop,
  );

  const availableRewardSum = useMemo(() => {
    return rewards.reduce(
      (acc, reward) =>
        reward.isClaimed || reward.disabled ? acc : acc.plus(reward.amount),
      new BigNumber(0),
    );
  }, [rewards]);

  const { t } = useTranslation();

  return (
    <Styled.Wrapper>
      <Styled.BalanceInfo>
        <Styled.LabeledAmount>
          <Styled.Label>{t("COMMON-balance")}</Styled.Label>
          <Styled.Amount>
            {displayBigNumber(roobeeBalance, DEFAULT_DECIMALS)}
          </Styled.Amount>
        </Styled.LabeledAmount>
        <Styled.Box>
          <Styled.LabeledAmount>
            <Styled.Label>{t("COMMON-your_staked")}</Styled.Label>
            <Styled.Amount>
              {displayBigNumber(stakedRoobee, DEFAULT_DECIMALS)}
            </Styled.Amount>
          </Styled.LabeledAmount>
          <Styled.LabeledAmount>
            <Styled.Label>{t("COMMON-staking_apy")}</Styled.Label>
            <Styled.Amount>{stakingApy}</Styled.Amount>
          </Styled.LabeledAmount>
        </Styled.Box>
      </Styled.BalanceInfo>
      <Styled.AttractiveLabel>
        {t("AIRDROP_MODAL-attractive")}
      </Styled.AttractiveLabel>
      <Styled.AddressContainer>
        <Styled.AddressText>
          {t("AIRDROP_MODAL-check_address")}
        </Styled.AddressText>
        <Styled.Button
          onClick={() => {
            showAirdrop("details", true);
          }}
          className={classNames({ "pending-btn": availableRewardSum.gt(0) })}
        >
          {t("BUTTONS-check")}
        </Styled.Button>
      </Styled.AddressContainer>
      <Styled.DaoText>{Parser(t("AIRDROP_MODAL-dao"))}</Styled.DaoText>
    </Styled.Wrapper>
  );
};

export default PrimaryContent;
