import React from "react";

const AvalancheIcon = (props) => {
  return (
    <svg
      {...props}
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="14" cy="14" r="13.5" stroke="#FF9393" />
      <path
        d="M10.3596 19.6H6.96798C6.35611 19.6 5.97074 18.9411 6.27072 18.4078L13.3155 5.8838C13.6186 5.34496 14.3922 5.33889 14.7037 5.87291L16.4104 8.79855C16.9416 9.70929 16.9558 10.8321 16.4477 11.7559L12.9883 18.0458C12.461 19.0044 11.4537 19.6 10.3596 19.6Z"
        fill="#E84142"
      />
      <path
        d="M19.2745 14.1303L21.8016 18.5527C22.0683 19.0194 21.7313 19.6 21.1939 19.6H16.1397C15.6022 19.6 15.2652 19.0194 15.5319 18.5527L18.059 14.1303C18.3277 13.66 19.0058 13.66 19.2745 14.1303Z"
        fill="#E84142"
      />
      <path
        d="M10.3596 19.6H6.96798C6.35611 19.6 5.97074 18.9411 6.27072 18.4078L13.3155 5.8838C13.6186 5.34496 14.3922 5.33889 14.7037 5.87291L16.4104 8.79855C16.9416 9.70929 16.9558 10.8321 16.4477 11.7559L12.9883 18.0458C12.461 19.0044 11.4537 19.6 10.3596 19.6Z"
        stroke="#E84142"
      />
      <path
        d="M19.2745 14.1303L21.8016 18.5527C22.0683 19.0194 21.7313 19.6 21.1939 19.6H16.1397C15.6022 19.6 15.2652 19.0194 15.5319 18.5527L18.059 14.1303C18.3277 13.66 19.0058 13.66 19.2745 14.1303Z"
        stroke="#E84142"
      />
    </svg>
  );
};

export default AvalancheIcon;
