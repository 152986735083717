import styled, { css } from "styled-components";

type PageContainerTypes = {
  expanded?: boolean;
};

const PageContainer = styled.div<PageContainerTypes>`
  padding-left: 238px;
  padding-right: 288px;
  padding-top: 36px;
  width: 100%;
  min-height: 100vh;

  ${(props) =>
    props.expanded &&
    css`
      @media (min-width: 1101px) {
        padding-left: 128px;
      }
    `};

  @media (max-width: 1350px) {
    padding-left: 40px;
    padding-top: 94px;
    padding-right: 40px;
  }

  @media (max-width: 870px) {
    padding-left: 30px;
    padding-right: 30px;
  }

  @media (max-width: 600px) {
    padding-left: 0;
    padding-top: 60px;
    padding-right: 0;
  }
`;

export default PageContainer;
