import React from "react";

const SearchIcon = (props) => {
  return (
    <svg
      {...props}
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.92969 0C6.89323 0 7.79167 0.247396 8.625 0.742188C9.45833 1.21094 10.1094 1.86198 10.5781 2.69531C11.0729 3.52865 11.3203 4.4401 11.3203 5.42969C11.3203 6.75781 10.8906 7.92969 10.0312 8.94531L10.2656 9.17969H10.9297L15.0703 13.3203L13.8203 14.5703L9.67969 10.4297V9.76562L9.44531 9.53125C8.42969 10.3906 7.25781 10.8203 5.92969 10.8203C4.9401 10.8203 4.02865 10.5859 3.19531 10.1172C2.36198 9.6224 1.69792 8.95833 1.20312 8.125C0.734375 7.29167 0.5 6.39323 0.5 5.42969C0.5 4.4401 0.734375 3.52865 1.20312 2.69531C1.69792 1.86198 2.36198 1.21094 3.19531 0.742188C4.02865 0.247396 4.9401 0 5.92969 0ZM5.92969 1.67969C4.88802 1.67969 4.0026 2.04427 3.27344 2.77344C2.54427 3.5026 2.17969 4.38802 2.17969 5.42969C2.17969 6.47135 2.54427 7.35677 3.27344 8.08594C4.0026 8.8151 4.88802 9.17969 5.92969 9.17969C6.97135 9.17969 7.85677 8.8151 8.58594 8.08594C9.3151 7.35677 9.67969 6.47135 9.67969 5.42969C9.67969 4.38802 9.3151 3.5026 8.58594 2.77344C7.85677 2.04427 6.97135 1.67969 5.92969 1.67969Z"
        fill="#8596A6"
      />
    </svg>
  );
};

export default SearchIcon;
