import React from "react";
import CloseIcon from "./_resources/CloseIcon";
import Styled from "./styled";

export type ModalProps = {
  onClose?: any;
  children?: any;
  title?: string;
  className?: string;
  pretitleContent?: any;
  bottomContent?: any;
  showClose?: boolean;
};

const Modal = ({
  onClose,
  children,
  title,
  className,
  pretitleContent,
  bottomContent,
  showClose = true,
}: ModalProps) => {
  return (
    <Styled.Wrapper className={className}>
      <Styled.Background onClick={onClose} />
      <Styled.Card>
        {showClose && (
          <Styled.Close onClick={onClose}>
            <CloseIcon />
          </Styled.Close>
        )}
        <Styled.CardTop>
          {pretitleContent}
          <Styled.CardTitle>{title}</Styled.CardTitle>
        </Styled.CardTop>
        <Styled.CardContent>{children}</Styled.CardContent>
        <Styled.CardBottom>{bottomContent}</Styled.CardBottom>
      </Styled.Card>
    </Styled.Wrapper>
  );
};

export default Modal;
