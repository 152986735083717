import styled, { css } from "styled-components";
import NetworkIcon from "../../../NetworkIcon";
import { default as NotStyledCloseIcon } from "../../_resources/CloseIcon";
import { default as NotStyledWalletIcon } from "../../_resources/WalletIcon";
import { default as NotStyledUncheckedIcon } from "../../_resources/UncheckedIcon";
import { default as NotStyledCheckedIcon } from "../../_resources/CheckedIcon";
import { CustomScrollbar } from "shared/styles/Mixins";

type AuthCardTypes = {
  $active?: boolean;
  $disable?: boolean;
};

type TitleTextTypes = {
  mLeft?: boolean;
};

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 65, 0.2);
  z-index: 1001;
  @media (max-width: 800px) {
    height: unset;
    bottom: 0;
  }
`;
const Modal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  min-width: fit-content;
  height: fit-content;
  width: 100%;
  max-width: 500px;
  max-height: 100%;
  background: ${(props) => props.theme.Colors.BackgroundWhite};
  border-radius: 10px;
  pointer-events: auto;
  z-index: 1002;
  overflow: auto;

  @media (max-width: 600px) {
    max-width: 300px;
  }
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 155%;
  letter-spacing: -0.01em;
  text-transform: capitalize;
  padding: 20px;
  border-bottom: 1px solid #c2c2c2;
`;

const Content = styled.div`
  padding: 20px 18px 30px 20px;
  @media (max-width: 600px) {
    padding: 20px 10px;
  }
`;

const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &:nth-child(2) {
    margin-top: 20px;
  }

  &:last-child {
    margin-top: 16px;
  }

  @media (max-width: 600px) {
    margin-bottom: 16px;
    &:first-child {
      padding: 0 10px 0 0;
    }
    &:nth-child(2) {
      margin-top: 0;
    }
    &:last-child {
      margin-top: 24px;
      margin-bottom: 0;
    }
  }
`;

const StepTitle = styled.div`
  display: flex;
  align-items: center;
`;

const TitleText = styled.div<TitleTextTypes>`
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  @media (max-width: 600px) {
    ${(props) =>
      props.mLeft &&
      css`
        margin-left: 11px;
      `}
  }
`;

const Link = styled.a`
  color: #a7dd12;
  @media (max-width: 600px) {
    margin-top: 5px;
    display: inline-block;
  }
`;

const AcceptCheckbox = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
`;

const AcceptCheckboxText = styled.div`
  margin-left: 12px;
  user-select: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
`;

const AuthCardGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  grid-column-gap: 3px;
  grid-row-gap: 6px;
  margin-top: 16px;
  max-width: 462px;

  @media (max-width: 600px) {
    grid-row-gap: 16px;
    margin-top: 12px;
  }
`;

const AuthCard = styled.div<AuthCardTypes>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 90px;
  height: 90px;
  align-items: center;
  cursor: pointer;
  border: 1px solid #f5f5f5;
  border-radius: 10px;
  padding: 15px 6px 0;
  transition:
    border,
    box-shadow,
    filter 0.3s ease;

  ${(props) =>
    props.$active &&
    css`
      border: solid 1px transparent;
      background-image: linear-gradient(
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0)
        ),
        linear-gradient(101deg, #b6e82f, #50e0ed);
      background-origin: border-box;
      background-clip: content-box, border-box;
      box-shadow: 2px 1000px 1px #ffffff inset;
    `};
  &:hover {
    border: solid 1px transparent;
    background-image: linear-gradient(
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0)
      ),
      linear-gradient(101deg, #b6e82f, #50e0ed);
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px #ffffff inset;
    filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.25));
  }

  ${(props) =>
    props.$disable &&
    css`
      filter: grayscale(1);
      opacity: 0.5;
      &:hover {
        filter: grayscale(1);
        background-image: none;
        box-shadow: none;
        background-clip: initial;
        background-origin: initial;
      }
      cursor: initial;
    `};

  @media (max-width: 600px) {
    width: 85px;
    height: 85px;
  }
`;

const AuthNetworkImage = styled(NetworkIcon)`
  width: 30px;
  height: 30px;
  margin-bottom: 6px;
`;

const AuthCardImage = styled.img`
  width: 30px;
  height: 30px;
  margin-bottom: 6px;
`;
const AuthCardName = styled.div`
  font-size: 12px;
  line-height: 17px;
  text-align: center;
`;

const CloseIcon = styled(NotStyledCloseIcon)`
  cursor: pointer;
  display: block;
  margin-left: auto;
  @media (max-width: 600px) {
    width: 12px;
    height: 12px;
  }
`;
const CheckedIcon = styled(NotStyledCheckedIcon)`
  cursor: pointer;
  opacity: 0.5;
`;
const UncheckedIcon = styled(NotStyledUncheckedIcon)`
  cursor: pointer;
`;

const WalletIcon = styled(NotStyledWalletIcon)`
  margin-right: 16px;
`;

const Br = styled.br`
  display: none;
  @media (max-width: 600px) {
    display: initial;
  }
`;

const Button = styled.button`
  color: #5c5c5c;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
  position: relative;
  display: flex;
  flex-direction: column;
  grid-row-gap: 14px;
  width: 90px;
  height: 90px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid #f5f5f5;
  border-radius: 10px;
  padding: 0 6px;
  transition:
    border,
    box-shadow,
    filter 0.3s ease;
  &:hover {
    border: solid 1px transparent;
    background-image: linear-gradient(
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0)
      ),
      linear-gradient(101deg, #b6e82f, #50e0ed);
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px #ffffff inset;
    filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.25));
  }

  @media (max-width: 600px) {
    width: 85px;
    height: 85px;
  }
`;

const ScrollContent = styled.div`
  ${CustomScrollbar};
  overflow-y: auto;
  max-height: 460px;
  margin-top: 15px;
  padding-right: 8px;

  @media (max-width: 600px) {
    padding-right: 0;
  }
`;

const Dots = styled.div`
  color: #5c5c5c;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 145%;
`;

export default {
  Overlay,
  Modal,
  Header,
  WalletIcon,
  Step,
  Content,
  StepTitle,
  TitleText,
  Link,
  AcceptCheckbox,
  AcceptCheckboxText,
  AuthCardGrid,
  AuthCard,
  AuthNetworkImage,
  AuthCardImage,
  AuthCardName,
  CloseIcon,
  CheckedIcon,
  UncheckedIcon,
  Br,
  Button,
  ScrollContent,
  Dots,
};
