import React, { FC } from "react";
import { useWeb3React } from "@web3-react/core";

import Styled from "./styled";

type WalletTypes = {
  userModalHandler: (boolean) => void;
};

const Wallet: FC<WalletTypes> = ({ userModalHandler }) => {
  const { account } = useWeb3React();

  return (
    <Styled.Wrapper $active={!!account}>
      <Styled.Top>
        <Styled.Account>
          <Styled.AccountText>Your account</Styled.AccountText>
          <Styled.AccountIcon />
        </Styled.Account>
        <Styled.Balance>
          <Styled.ApproximateBalance userModalHandler={userModalHandler} />
        </Styled.Balance>
      </Styled.Top>
      <Styled.Middle>
        <Styled.WalletAddress userModalHandler={userModalHandler} />
      </Styled.Middle>
    </Styled.Wrapper>
  );
};

export default Wallet;
