import React from "react";

const CoverIcon = ({ ...props }) => (
  <svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.0013 5.4974C15.0013 5.60277 14.967 5.76519 14.8775 5.98234C14.7898 6.1951 14.6569 6.44173 14.4801 6.70967C14.1266 7.24547 13.6098 7.84836 12.9667 8.41372C11.676 9.54838 9.91783 10.4974 8.0013 10.4974C6.08477 10.4974 4.32661 9.54838 3.03594 8.41372C2.39285 7.84836 1.87597 7.24547 1.5225 6.70967C1.34573 6.44173 1.21284 6.1951 1.12513 5.98234C1.03562 5.76519 1.0013 5.60277 1.0013 5.4974C1.0013 5.39202 1.03562 5.2296 1.12513 5.01245C1.21284 4.79969 1.34573 4.55307 1.5225 4.28512C1.87597 3.74932 2.39285 3.14643 3.03594 2.58108C4.32661 1.44641 6.08477 0.497396 8.0013 0.497396C9.91783 0.497396 11.676 1.44641 12.9667 2.58108C13.6098 3.14643 14.1266 3.74932 14.4801 4.28512C14.6569 4.55307 14.7898 4.79969 14.8775 5.01245C14.967 5.2296 15.0013 5.39202 15.0013 5.4974Z"
      stroke="black"
      strokeWidth="0.666667"
    />
    <circle
      cx="8.0026"
      cy="5.49479"
      r="2.33333"
      stroke="black"
      strokeWidth="0.666667"
    />
  </svg>
);

export default CoverIcon;
