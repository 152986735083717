import React from "react";

const LogoutIcon = ({ ...props }) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17 16.5V21C17 21.5523 16.5523 22 16 22H3C2.44772 22 2 21.5523 2 21V3C2 2.44772 2.44772 2 3 2H16C16.5523 2 17 2.44772 17 3V7"
      stroke="black"
      strokeLinecap="round"
    />
    <path d="M9 11.5H21" stroke="black" strokeLinecap="round" />
    <path
      d="M18.6133 9L21.4322 11.1005C21.7007 11.3005 21.7059 11.6995 21.4427 11.8995L18.6785 14"
      stroke="black"
      strokeLinecap="round"
    />
  </svg>
);

export default LogoutIcon;
