import React from "react";

const HiddenIcon = ({ ...props }) => (
  <svg
    {...props}
    width="34"
    height="8"
    viewBox="0 0 34 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="4" cy="4" r="4" fill="#C2C2C2" />
    <circle cx="17" cy="4" r="4" fill="#C2C2C2" />
    <circle cx="30" cy="4" r="4" fill="#C2C2C2" />
  </svg>
);

export default HiddenIcon;
