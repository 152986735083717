import React from "react";

const MediumIcon = (props?: any): JSX.Element => {
  return (
    <svg
      {...props}
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 0.203125V20.2031H20V0.203125H0ZM16.6158 4.94229L15.5417 5.96979C15.4964 6.00441 15.4614 6.05069 15.4404 6.10369C15.4194 6.15668 15.4132 6.21439 15.4225 6.27063V13.8273C15.4132 13.8835 15.4194 13.9412 15.4404 13.9942C15.4614 14.0472 15.4964 14.0935 15.5417 14.1281L16.5892 15.1565V15.3815H11.32V15.1565L12.4058 14.1031C12.5125 13.9965 12.5125 13.9648 12.5125 13.8031V7.69479L9.49583 15.3581H9.0875L5.575 7.69479V12.8306C5.56055 12.9371 5.57052 13.0455 5.60416 13.1476C5.6378 13.2497 5.69423 13.3428 5.76917 13.4198L7.18083 15.1323V15.3573H3.17917V15.1323L4.59167 13.4198C4.666 13.3427 4.72117 13.2492 4.75272 13.1469C4.78428 13.0445 4.79134 12.9362 4.77333 12.8306V6.89146C4.78158 6.81024 4.77057 6.72822 4.74117 6.65206C4.71178 6.5759 4.66484 6.50774 4.60417 6.45312L3.34917 4.94229V4.71646H7.24417L10.255 11.319L12.9017 4.71562H16.615L16.6158 4.94229Z"
        fill="#5C5C5C"
      />
    </svg>
  );
};

export default MediumIcon;
