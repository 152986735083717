import React from "react";

const OptimisticIcon = (props) => {
  return (
    <svg
      {...props}
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="14" cy="14" r="14" fill="white" />
      <path
        d="M15.0044 10.8316V9.05686C15.0044 8.68537 14.809 8.34179 14.4901 8.15068L9.87763 5.39885C9.54801 5.20237 9.13679 5.20022 8.80503 5.39348L4.02288 8.18289C3.6997 8.37186 3.5 8.71865 3.5 9.09444V14.4231C3.5 14.7999 3.70078 15.1489 4.02825 15.3368L8.72665 18.0457C9.05197 18.2336 9.45353 18.2336 9.77992 18.0467L18.248 13.1766C18.5765 12.9876 18.9813 12.9897 19.3077 13.1808L21.9404 14.7259C22.2635 14.9148 22.4611 15.2616 22.4611 15.6353V18.5642C22.4611 18.939 22.2625 19.2858 21.9382 19.4747L19.1971 21.0756C18.8643 21.2699 18.452 21.2678 18.1213 21.0681L15.5767 19.537C15.26 19.3459 15.0656 19.0034 15.0656 18.633V16.7637L12.9805 17.9233V19.8387C12.9805 20.2166 13.1824 20.5656 13.5109 20.7535L18.132 23.4054C18.4574 23.5923 18.8568 23.5923 19.181 23.4054L23.9696 20.6622C24.2981 20.4743 24.5 20.1254 24.5 19.7464V14.538C24.5 14.1643 24.3024 13.8197 23.9814 13.6296L19.3045 10.8682C18.9792 10.676 18.5765 10.6727 18.248 10.8585L9.80784 15.6471C9.48359 15.8317 9.08526 15.8307 8.76101 15.6439L6.16486 14.1514C5.83524 13.9614 5.63339 13.6092 5.63554 13.2292L5.65701 10.1348C5.65916 9.7569 5.86423 9.40903 6.19492 9.22328L8.76315 7.77704C9.08633 7.59559 9.48144 7.59666 9.80354 7.78026L12.419 9.2716C12.7561 9.46378 12.9612 9.82669 12.9516 10.2143L12.9065 12.0191L15.0044 10.8316Z"
        fill="#8049DB"
      />
      <path
        d="M27.5 14C27.5 21.4558 21.4558 27.5 14 27.5C6.54416 27.5 0.5 21.4558 0.5 14C0.5 6.54416 6.54416 0.5 14 0.5C21.4558 0.5 27.5 6.54416 27.5 14Z"
        stroke="#8049DB"
      />
    </svg>
  );
};

export default OptimisticIcon;
