import React, { useMemo } from "react";
import { useWeb3React } from "@web3-react/core";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { State } from "store";
import { useResize } from "shared/hooks";
import { DEFAULT_DECIMALS } from "shared/constants";
import { useAirdrop } from "../../AirdropModalProvider";

import { displayBigNumber } from "../../displayBigNumber";
import Styled from "./styled";

const SuccessContent = () => {
  const { account } = useWeb3React();
  const { hideAirdrop } = useAirdrop();
  const { lastClaimedRewardAmount } = useSelector(
    (state: State) => state.airdrop,
  );

  const { t } = useTranslation();

  const { windowWidth } = useResize();

  const mobileView = useMemo(() => {
    return windowWidth <= 600;
  }, [windowWidth]);

  return (
    <Styled.Wrapper>
      <Styled.SuccessMessage>
        <Styled.SuccessIcon
          src="/images/airdrop/success_icon.svg"
          alt="success"
        />
        <Styled.Message>
          {displayBigNumber(lastClaimedRewardAmount, DEFAULT_DECIMALS)}{" "}
          {t("AIRDROP_MODAL-broobee_rewarded")}
        </Styled.Message>
      </Styled.SuccessMessage>
      <Styled.Recipient>
        <Styled.Label>{t("AIRDROP_MODAL-recipient")}</Styled.Label>
        <Styled.Address className="address">
          {!mobileView
            ? account
            : `${account?.substring(0, 7)}...${account?.substring(30 - 4)}`}
        </Styled.Address>
      </Styled.Recipient>
      <Styled.Button onClick={hideAirdrop}>{t("BUTTONS-ok")}</Styled.Button>
    </Styled.Wrapper>
  );
};

export default SuccessContent;
