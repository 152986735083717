import styled, { css } from "styled-components";
import { default as NotStyledArrowBottomIcon } from "./_resources/ArrowBottomIcon";
import { default as NotStyledNetworkIcon } from "components/NetworkIcon";
import { default as NotStyledSearchIcon } from "./_resources/SearchIcon";
import { CustomNetworkScrollbar } from "shared/styles/Mixins";
import ClearIcon from "./_resources/ClearIcon";

type SelectedOptionTypes = {
  disabled?: boolean;
  background?: string;
};

type OptionTypes = {
  active?: boolean;
};

type SelectedNetworkIconTypes = {
  slug?: 1 | 56 | 10 | 137 | 43114 | number;
};

type NetworkTypes = {
  $active?: boolean;
};

type SearchClearTypes = {
  hide?: boolean;
};

const Wrapper = styled.div<NetworkTypes>`
  position: relative;
  margin-bottom: 20px;
  background: ${(props) => props.theme.Colors.BackgroundWhite};
  border-radius: 10px;
  ${(props) =>
    props.$active &&
    css`
      border-radius: 10px 10px 0 0;
    `};
`;
const SelectedOption = styled.div<SelectedOptionTypes>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 9px 16px;
  border-radius: 10px;
  cursor: pointer;
  background: ${(props) => props.background};
  ${(props) =>
    props.disabled &&
    css`
      cursor: initial;
    `};
`;
const SelectedOptionName = styled.span`
  margin-left: 11px;
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  color: ${(props) => props.theme.Colors.White};
`;

const ArrowBottomIcon = styled(NotStyledArrowBottomIcon)`
  width: 16px;
  height: 16px;
`;
const Options = styled.div`
  position: absolute;
  width: 100%;
  padding: 16px 16px 25px;
  top: 100%;
  left: 0;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 0 0 10px 10px;
  box-shadow:
    0 20px 20px rgba(105, 123, 140, 0.1),
    0 10px 10px rgba(105, 123, 140, 0.05);
  z-index: 11;

  @media (max-width: 600px) {
    padding-bottom: 20px;
  }
`;

const OptionsBox = styled.div`
  height: 440px;
  overflow-y: auto;
  ${CustomNetworkScrollbar};
  @media (max-width: 600px) {
    padding-left: 4px;
    height: 170px;
  }
`;

const OptionName = styled.span`
  margin-left: 10px;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
  color: ${(props) => props.theme.Colors.Gray6};
  white-space: nowrap;
`;

const Option = styled.div<OptionTypes>`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 14px;

  &:last-of-type {
    margin-bottom: 0;
  }

  ${(props) =>
    props.active &&
    css`
      cursor: initial;

      ${OptionName} {
        font-weight: 500;
      }
    `};

  &:hover {
    ${OptionName} {
      font-weight: 400;
    }
  }
`;

const NetworkIcon = styled(NotStyledNetworkIcon)`
  width: 30px;
  height: 30px;
`;

const SelectedNetworkIcon = styled(
  NotStyledNetworkIcon,
)<SelectedNetworkIconTypes>`
  width: 28px;
  height: 28px;
  ${(props) => {
    switch (props.slug) {
      case 43114:
        return css`
          fill: #ffffff;
        `;
      default:
        return "";
    }
  }}
`;

const SelectedNameIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const OptionsText = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 155%;
  padding: 0 16px;
  margin-bottom: 12px;
  color: ${(props) => props.theme.Colors.Gray6};
`;

const Search = styled.div`
  border-radius: 10px;
  margin-bottom: 14px;
  border: 1px solid #c2c2c2;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-column-gap: 12px;
  width: 100%;
  padding: 0 12px;
`;

const SearchIcon = styled(NotStyledSearchIcon)`
  width: 20px;
  min-width: 20px;
  height: 20px;
`;

const SearchInput = styled.input`
  padding: 8px 0;
  max-width: 138px;
  font-size: 14px;
  line-height: 155%;
  color: #5c5c5c;
  border: none;

  &::placeholder {
    color: #c2c2c2;
  }

  @media (max-width: 600px) {
    max-width: 75px;
  }
`;

const SearchClear = styled(ClearIcon)<SearchClearTypes>`
  width: 12px;
  height: 12px;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s ease;

  ${(props) =>
    props.hide &&
    css`
      opacity: 0;
    `}
`;

const EmptyOptions = styled.div`
  color: #5c5c5c;
  font-size: 12px;
  font-weight: 300;
  line-height: 150%;
  text-align: center;
  height: 175px;
`;

export default {
  Wrapper,
  SelectedOption,
  SelectedOptionName,
  ArrowBottomIcon,
  Options,
  OptionsBox,
  EmptyOptions,
  OptionsText,
  Option,
  OptionName,
  NetworkIcon,
  SelectedNetworkIcon,
  SelectedNameIcon,
  Search,
  SearchIcon,
  SearchInput,
  SearchClear,
};
