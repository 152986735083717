import { useEffect, useState, useRef } from "react";
import bn from "bignumber.js";
import axios from "axios";
import { UNISWAP_V3_SUBGRAPH_ENDPOINT } from "shared/constants";
import { useWeb3React } from "@web3-react/core";

const useUniswapTokenPriceInUSD = (address: string | undefined) => {
  const [price, setPrice] = useState<bn>(new bn(0));
  const { chainId } = useWeb3React();
  const mountedRef = useRef(true);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (!!address && mountedRef.current) {
      const query = `{
                token(id: "${address.toLowerCase()}") {
                  lastPriceUSD
                }
            }`;

      axios
        .post(UNISWAP_V3_SUBGRAPH_ENDPOINT[chainId ?? 1], { query })
        .then((res) => {
          const price_ = res.data.data?.token?.lastPriceUSD;

          if (price_) {
            setPrice(new bn(price_));
          } else {
            return new bn(0);
          }
        });
    }
    // eslint-disable-next-line
  }, [address, chainId, mountedRef.current]);

  return price;
};

export { useUniswapTokenPriceInUSD };
