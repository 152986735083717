import React from "react";

const BSCIcon = (props) => {
  return (
    <svg
      {...props}
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_7401_100655)">
        <path
          d="M27.5 14C27.5 21.4558 21.4558 27.5 14 27.5C6.54416 27.5 0.5 21.4558 0.5 14C0.5 6.54416 6.54416 0.5 14 0.5C21.4558 0.5 27.5 6.54416 27.5 14Z"
          fill="white"
          stroke="#EBB50B"
        />
        <path
          d="M8.20337 12.3366L9.87333 14.0059L8.20337 15.6752L6.53345 14.0059L8.20337 12.3366Z"
          fill="#EBB50B"
        />
        <path
          d="M14.0002 12.3366L15.6702 14.0059L14.0002 15.6752L12.3303 14.0059L14.0002 12.3366Z"
          fill="#EBB50B"
        />
        <path
          d="M19.7972 12.3366L21.4671 14.0059L19.7972 15.6752L18.1272 14.0059L19.7972 12.3366Z"
          fill="#EBB50B"
        />
        <path
          d="M14.0149 6.53333L18.586 11.1027L16.9161 12.7721L12.345 8.20265L14.0149 6.53333Z"
          fill="#EBB50B"
        />
        <path
          d="M14.0213 6.53333L9.4502 11.1027L11.1201 12.7721L15.6913 8.20265L14.0213 6.53333Z"
          fill="#EBB50B"
        />
        <path
          d="M14.0149 21.4669L18.586 16.8975L16.9161 15.2282L12.345 19.7976L14.0149 21.4669Z"
          fill="#EBB50B"
        />
        <path
          d="M14.0213 21.4669L9.4502 16.8975L11.1201 15.2282L15.6913 19.7976L14.0213 21.4669Z"
          fill="#EBB50B"
        />
      </g>
      <defs>
        <clipPath id="clip0_7401_100655">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BSCIcon;
