import React from "react";

const AuroraIcon = (props) => (
  <svg
    {...props}
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="14" cy="14" r="13.5" fill="white" stroke="#6ED34A" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.0746 6.066H13.875C13.2324 6.066 12.6451 6.43003 12.3593 7.00565L7.17602 17.4435C7.05987 17.6774 6.99942 17.9351 6.99942 18.1962V18.375C6.99942 19.3097 7.75711 20.0673 8.69176 20.0673H19.3084C20.2431 20.0673 21.0008 19.3097 21.0008 18.375V18.2022C21.0008 17.9389 20.9394 17.6793 20.8214 17.444L15.5876 7.00012C15.3007 6.42754 14.7151 6.066 14.0746 6.066ZM13.875 4.66667C12.701 4.66667 11.6282 5.33171 11.106 6.38328L5.92272 16.8212C5.71052 17.2485 5.6001 17.7191 5.6001 18.1962V18.375C5.6001 20.0825 6.98428 21.4667 8.69176 21.4667H19.3084C21.0159 21.4667 22.4001 20.0825 22.4001 18.375V18.2022C22.4001 17.7213 22.2879 17.247 22.0724 16.817L16.8386 6.37319C16.3144 5.32716 15.2446 4.66667 14.0746 4.66667H13.875Z"
      fill="#6ED34A"
    />
  </svg>
);

export default AuroraIcon;
