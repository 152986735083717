import React from "react";
import type { FC } from "react";
import { PageCardStyled } from "./styled";

interface Props {
  children: React.ReactNode;
}

const PageCard: FC<Props> = ({ children }: Props) => {
  return <PageCardStyled>{children}</PageCardStyled>;
};

export default PageCard;
