import styled, { css } from "styled-components";
import Modal from "components/Modal";
import { default as NotStyledApproximateBalance } from "../../ApproximateBalance";
import { default as NotStyledWalletAddress } from "../../WalletAddress";
import { default as NotStyledAccountIcon } from "app/components/Widgetbar/_resources/AccountIcon";
import ModalStyled from "components/Modal/styled";
import WalletAddressStyled from "../../WalletAddress/styled";
import ApproximateBalanceStyled from "../../ApproximateBalance/styled";
import { default as NotStyledButton } from "shared/styles/elements/Button";
import { default as NotStyledLink } from "shared/styles/elements/Link";
import { default as NotStyledTransactionIcon } from "./_resources/TransactionIcon";
import { default as NotStyledLinkIcon } from "./_resources/LinkIcon";
import { default as NotStyledDisconnectIcon } from "./_resources/DisconnectIcon";
import { CustomScrollbar } from "shared/styles/Mixins";

type LinkTypes = {
  deactive?: boolean;
};

const Wrapper = styled(Modal)`
  ${ModalStyled.Card} {
    width: 530px;
    max-height: 100%;
    height: 820px;
    overflow: hidden;
    margin-bottom: 0;
  }

  ${ModalStyled.CardContent} {
    overflow: auto;
    max-height: calc(100% - 65px);
    ${CustomScrollbar};
  }

  ${ModalStyled.CardTop} {
    padding: 0;
  }

  ${ModalStyled.Close} {
    width: 12px;
    height: 12px;
    top: 24px;
    right: 24px;

    path {
      fill: #c2c2c2;
    }
  }

  @media (max-width: 600px) {
    ${ModalStyled.Card} {
      width: 300px;
      height: 665px;
    }
  }
`;

const ApproximateBalance = styled(NotStyledApproximateBalance)`
  ${ApproximateBalanceStyled.UserIcon} {
    width: 60px;
    height: 60px;
    margin-right: 30px;
    @media (max-width: 600px) {
      width: 40px;
      height: 40px;
      margin-right: 20px;
    }
  }

  ${ApproximateBalanceStyled.Text} {
    font-size: 16px;
  }

  ${ApproximateBalanceStyled.HideButton} {
    margin-left: 5px;
  }

  @media (max-width: 600px) {
    padding: 0 10px;
  }
`;

const WalletAddress = styled(NotStyledWalletAddress)`
  margin-top: 20px;
  padding: 11px 20px;

  ${WalletAddressStyled.WalletIcon} {
    display: none;
  }

  ${WalletAddressStyled.WalletAddress} {
    margin-left: 0;
    max-width: 100%;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
  }

  ${WalletAddressStyled.CopyIcon} {
    width: 16px;
    height: 16px;

    path {
      stroke: #000000;
    }

    rect {
      stroke: #000000;
    }
  }

  @media (max-width: 600px) {
    padding: 11px 12px;
    margin: 20px 10px 0;
  }
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 155%;
  letter-spacing: -0.01em;
  margin-left: 15px;
`;

const Content = styled.div`
  padding: 20px 20px 42px;
  @media (max-width: 600px) {
    padding: 20px 10px;
    height: 600px;
    overflow-y: scroll;
    ${CustomScrollbar};
  }
`;

const Top = styled.div`
  padding: 20px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #c2c2c2;
`;

const AccountIcon = styled(NotStyledAccountIcon)`
  width: 24px;
  height: 24px;
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;

  @media (max-width: 600px) {
    flex-direction: column;
    margin-top: 20px;
    align-items: flex-start;
    padding: 0 10px;
  }
`;

const Link = styled(NotStyledLink)<LinkTypes>`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  max-width: max-content;
  margin-right: 8px;

  &:hover {
    color: ${(props) => props.theme.Colors.Black};
    filter: drop-shadow(2px 2px 4px rgba(24, 25, 27, 0.1));
    border: 1px solid transparent;
    border-image: linear-gradient(
      90deg,
      rgba(182, 232, 47, 0.25) 0%,
      rgba(80, 224, 237, 0.25) 100%
    );
    position: relative;
    border-radius: 10px;
    background-image: linear-gradient(
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.5)
      ),
      linear-gradient(101deg, #b6e82f, #50e0ed);
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px #ffffff inset;
  }

  @media (max-width: 600px) {
    max-width: 100%;
    width: 100%;
    margin-right: 0;
  }

  ${(props) =>
    props.deactive &&
    css`
      pointer-events: none;
      opacity: 0.3;
    `};
`;

const TransactionIcon = styled(NotStyledTransactionIcon)``;

const LinkIcon = styled(NotStyledLinkIcon)``;

const DisconnectIcon = styled(NotStyledDisconnectIcon)``;

const Button = styled(NotStyledButton)`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  max-width: max-content;
  @media (max-width: 600px) {
    margin-left: 12px;
  }
`;

const Text = styled.div`
  font-size: 14px;
  line-height: 145%;
  margin-left: 10px;
`;

const Wallet = styled.div`
  margin-top: 30px;
  @media (max-width: 600px) {
    margin-top: 24px;
  }
`;

const WalletTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 16px;
  @media (max-width: 600px) {
    margin-left: 10px;
    margin-bottom: 12px;
  }
`;

const Box = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 600px) {
    margin-top: 20px;
    justify-content: center;
    width: 100%;
  }
`;

export default {
  Wrapper,
  Title,
  Top,
  AccountIcon,
  Box,
  Content,
  WalletAddress,
  ApproximateBalance,
  Buttons,
  Link,
  TransactionIcon,
  LinkIcon,
  DisconnectIcon,
  Text,
  Button,
  Wallet,
  WalletTitle,
};
