import styled from "styled-components";

const Wrapper = styled.div`
  overflow: hidden;
  transition: height 0.2s ease;
`;
const Content = styled.div``;

export default {
  Wrapper,
  Content,
};
