import React from "react";

const UncoverIcon = ({ ...props }) => (
  <svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 8C1.66039 8.56733 2.49562 9.34924 3.61905 10.1023M23 8C21.254 9.5 18.2857 12.5 12 12.5C8.09168 12.5 5.46592 11.3402 3.61905 10.1023M1.5 13.5L3.61905 10.1023"
      stroke="black"
      strokeLinecap="round"
    />
    <path d="M7 16.5L8.5 12.5" stroke="black" strokeLinecap="round" />
    <path
      d="M17 16.5L15.5 12.5M22.5 13.5L20.5 10"
      stroke="black"
      strokeLinecap="round"
    />
  </svg>
);

export default UncoverIcon;
