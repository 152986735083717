import React from "react";

const TelegramIcon = (props?: any): JSX.Element => {
  return (
    <svg
      {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 10C20 15.5225 15.5225 20 10 20C4.4775 20 0 15.5225 0 10C0 4.4775 4.4775 0 10 0C15.5225 0 20 4.4775 20 10ZM10.3583 7.3825C9.38583 7.78667 7.44167 8.62417 4.52667 9.89417C4.05333 10.0825 3.805 10.2667 3.7825 10.4467C3.74417 10.7517 4.12583 10.8717 4.64417 11.0342C4.715 11.0567 4.78833 11.0792 4.86333 11.1042C5.37417 11.27 6.06083 11.4642 6.4175 11.4717C6.74167 11.4783 7.10333 11.345 7.5025 11.0717C10.2258 9.2325 11.6317 8.30333 11.72 8.28333C11.7825 8.26917 11.8692 8.25083 11.9275 8.30333C11.9858 8.355 11.98 8.45333 11.9742 8.48C11.9358 8.64083 10.4408 10.0317 9.66583 10.7517C9.42417 10.9758 9.25333 11.135 9.21833 11.1717C9.14 11.2525 9.06 11.33 8.98333 11.4042C8.50833 11.8608 8.15333 12.2042 9.00333 12.7642C9.41167 13.0333 9.73833 13.2558 10.0642 13.4775C10.42 13.72 10.775 13.9617 11.235 14.2633C11.3517 14.34 11.4633 14.4192 11.5725 14.4967C11.9867 14.7925 12.3592 15.0575 12.8192 15.0158C13.0858 14.9908 13.3625 14.74 13.5025 13.9908C13.8333 12.2192 14.485 8.3825 14.6358 6.80083C14.645 6.66951 14.6394 6.53757 14.6192 6.4075C14.607 6.30244 14.5559 6.20578 14.4758 6.13667C14.3567 6.03917 14.1717 6.01833 14.0883 6.02C13.7125 6.02667 13.1358 6.2275 10.3583 7.3825Z"
        fill="#2EADB9"
      />
    </svg>
  );
};

export default TelegramIcon;
