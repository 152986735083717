import SUPPORTED_CHAIN_IDS from "shared/constants/supportedChainIds";
import {
  TimeInterims,
  TimeInterim,
  Chains,
  Chain,
  Holders,
  Holder,
} from "./types";

const TIME_INTERIMS: Record<TimeInterims, TimeInterim> = {
  last_year: {
    label: "TIME_INTERVALS-last_year",
    value: "last_year",
  },
  last_6_month: {
    label: "TIME_INTERVALS-last_6_months",
    value: "last_6_month",
  },
  last_3_month: {
    label: "TIME_INTERVALS-last_3_months",
    value: "last_3_month",
  },
  last_month: {
    label: "TIME_INTERVALS-last_month",
    value: "last_month",
  },
  last_week: {
    label: "TIME_INTERVALS-last_week",
    value: "last_week",
  },
  current: {
    label: "TIME_INTERVALS-current",
    value: "current",
  },
};

const CHAIN_LIST: Record<Chains, Chain> = {
  ETH: {
    chainId: 1,
    name: "Ethereum",
    slug: "ETH",
  },
  BSC: {
    chainId: 56,
    name: "BNB Chain",
    slug: "BSC",
  },
  ARBITRUM: {
    chainId: 42161,
    name: "Arbitrum",
    slug: "ARBITRUM",
  },
  OPTIMISM: {
    chainId: 10,
    name: "Optimism",
    slug: "OPTIMISM",
  },
  POLYGON: {
    chainId: 137,
    name: "Polygon",
    slug: "POLYGON",
  },
  ZKSYNCERA: {
    chainId: 324,
    name: "ZkSync Era",
    slug: "ZKSYNCERA",
  },
  AVALANCHE: {
    chainId: 43114,
    name: "Avalanche",
    slug: "AVALANCHE",
  },
  AURORA: {
    chainId: 1313161554,
    name: "Aurora",
    slug: "AURORA",
  },
  BITGERT: {
    chainId: 32520,
    name: "Bitgert",
    slug: "BITGERT",
  },
  GNOSIS: {
    chainId: 100,
    name: "Gnosis",
    slug: "GNOSIS",
  },
  LINEA: {
    chainId: SUPPORTED_CHAIN_IDS.LINEA,
    name: "Linea",
    slug: "LINEA",
  },
  OPBNB: {
    chainId: SUPPORTED_CHAIN_IDS.OP_BNB,
    name: "opBNB",
    slug: "OPBNB",
  },
  SCROLL: {
    chainId: SUPPORTED_CHAIN_IDS.SCROLL,
    name: "Scroll",
    slug: "SCROLL",
  },
  BASE: {
    chainId: SUPPORTED_CHAIN_IDS.BASE,
    name: "Base",
    slug: "BASE",
  },
  BLAST: {
    chainId: SUPPORTED_CHAIN_IDS.BLAST,
    name: "Blast",
    slug: "BLAST",
  },
};

const CHAIN_LIST_ARRAY = Object.values(CHAIN_LIST);

const CHAIN_LIST_ID: Record<number, Chain> = {
  ...CHAIN_LIST_ARRAY.reduce((a, v) => ({ ...a, [v.chainId]: v }), {}),
};

const HOLDER_LIST: Record<Holders, Holder> = {
  wallet: {
    name: "Wallet",
    slug: "wallet",
  },
  venus: {
    name: "Venus",
    slug: "venus",
    link: "https://venus.io",
  },
  pancakeswap: {
    name: "Pancakeswap",
    slug: "pancakeswap",
    link: "https://pancakeswap.finance/",
  },
  ellipsis: {
    name: "Ellipsis",
    slug: "ellipsis",
    link: "https://ellipsis.finance/",
  },
};

export {
  TIME_INTERIMS,
  CHAIN_LIST,
  CHAIN_LIST_ID,
  CHAIN_LIST_ARRAY,
  HOLDER_LIST,
};
