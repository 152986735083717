import React, { FC, useMemo } from "react";
import Styled from "./styled";
import WalletInfo from "../../../../components/WalletInfo";
import { ROUTE_PATHS } from "shared/constants";
import { useCurrentNetwork } from "shared/hooks";
import { useWeb3React } from "@web3-react/core";

type UserModalTypes = {
  onClose: () => void;
  setShowDisconnectModal?: (boolean) => void;
};

const UserModal: FC<UserModalTypes> = ({ onClose, setShowDisconnectModal }) => {
  const openDisconnectModal = () => {
    if (setShowDisconnectModal) {
      setShowDisconnectModal(true);
    }
  };

  const { account } = useWeb3React();
  const currentNetwork = useCurrentNetwork();

  const scanData = useMemo(() => {
    if (currentNetwork?.blockExplorerUrls?.[0])
      return {
        link: `${currentNetwork.blockExplorerUrls[0]}address/${account}`,
        name: currentNetwork.blockExplorerName,
      };
    return undefined;
  }, [currentNetwork, account]);

  return (
    <Styled.Wrapper
      onClose={onClose}
      pretitleContent={
        <Styled.Top>
          <Styled.AccountIcon />
          <Styled.Title>Your Account</Styled.Title>
        </Styled.Top>
      }
    >
      <Styled.Content>
        <Styled.ApproximateBalance balanceText />
        <Styled.WalletAddress showFullAddress />
        <Styled.Buttons onClick={onClose}>
          <Styled.Link deactive={true} to={ROUTE_PATHS.TRANSACTIONS}>
            <Styled.TransactionIcon />
            <Styled.Text>History</Styled.Text>
          </Styled.Link>
          <Styled.Box>
            <Styled.Link as={"a"} href={scanData?.link} target={"_blank"}>
              <Styled.LinkIcon />
              <Styled.Text>{scanData?.name}</Styled.Text>
            </Styled.Link>
            <Styled.Button onClick={openDisconnectModal}>
              <Styled.DisconnectIcon />
              <Styled.Text>Disconnect</Styled.Text>
            </Styled.Button>
          </Styled.Box>
        </Styled.Buttons>
        <Styled.Wallet>
          <Styled.WalletTitle>Wallet Info</Styled.WalletTitle>
          <WalletInfo />
        </Styled.Wallet>
      </Styled.Content>
    </Styled.Wrapper>
  );
};

export default UserModal;
