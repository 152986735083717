import styled, { css } from "styled-components";
import { ButtonProps } from "./types";

const Wrapper = styled.button<ButtonProps>`
  ${(props) =>
    props.color === "normal" &&
    css`
      color: ${(props_) => props_.theme.Colors.Black};
      border: solid 1px transparent;
      background-image: linear-gradient(
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0)
        ),
        linear-gradient(101deg, #b6e82f, #50e0ed);
      background-origin: border-box;
      background-clip: content-box, border-box;
      box-shadow: 2px 1000px 1px #ffffff inset;
      border-radius: 10px;
      transition:
        color,
        border,
        box-shadow,
        filter 0.3s ease;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      &:hover {
        color: ${(props_) => props_.theme.Colors.Black};
        filter: drop-shadow(2px 2px 6px rgba(0, 0, 0, 0.35));
        border: 1px solid transparent;
        border-image: linear-gradient(
          90deg,
          rgba(182, 232, 47, 0.25) 0%,
          rgba(80, 224, 237, 0.25) 100%
        );
        position: relative;
        border-radius: 10px;
        background-image: linear-gradient(
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0)
          ),
          linear-gradient(101deg, #b6e82f, #50e0ed);
        background-origin: border-box;
        background-clip: content-box, border-box;
        box-shadow: 2px 1000px 1px #f8f8f8 inset;
      }

      ${(props_) =>
        props_.disabled &&
        css`
          background-image: none !important;
          box-shadow: none !important;
          filter: none !important;
          cursor: default;
          border: 1px solid ${props_.theme.Colors.MG24} !important;
          color: ${props_.theme.Colors.MG24} !important;
        `};
    `};
  ${(props) =>
    props.color === "gradient" &&
    css`
      color: ${(props_) => props_.theme.Colors.Black};
      border-radius: 10px;
      z-index: 1;
      position: relative;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;

      &:before {
        position: absolute;
        content: "";
        top: 0;
        border-radius: 10px;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, #b6e82f 0%, #50e0ed 100%);
        transition: opacity 0.3s ease;
        opacity: 1;
      }

      &:hover {
        &:before {
          opacity: 0.5;
        }
      }
      ${(props_) =>
        props_.disabled &&
        css`
          background-image: none !important;
          box-shadow: none !important;
          filter: none !important;
          cursor: default;
          border: 1px solid ${props_.theme.Colors.MG24} !important;
          color: ${props_.theme.Colors.MG24} !important;
          &:before {
            display: none;
          }
        `};
    `}
  ${(props) => props.disable && css``}
  ${(props) => {
    switch (props.size) {
      case "big":
        return css`
          padding: 13px 20px;
        `;
      case "default":
        return css`
          padding: 10px 16px;
        `;
      case "small":
        return css`
          padding: 5px 20px;
        `;
      case "modal":
        return css`
          min-width: 150px;
          min-height: 40px;
          margin: 0 auto;
          color: #000;
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 145%;
          text-transform: capitalize;
        `;
    }
  }}
`;
export default {
  Wrapper,
};
