import React from "react";

const UserIcon = ({ ...props }) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    width="74px"
    height="74px"
    viewBox="0 0 74 74"
    enableBackground="new 0 0 74 74"
    xmlSpace="preserve"
  >
    <image
      id="image0"
      width="74"
      height="74"
      x="0"
      y="0"
      href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEoAAABKCAMAAAArDjJDAAAABGdBTUEAALGPC/xhBQAAACBjSFJN AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABMlBMVEUAAAAQn3AUrnkVr3oT r3sUr3oVr3oUr3oUr3sUr3kUr3oWrncUr3sTr3oTrnkQrXsQp3gUr3sTr3oVr3wUr3vvGBCNX0Dv EBD1GA31GAv3GQj2Fwz1GQ33GA31GAz2GA31Fwz0Gwv3GAz2GQu+PyirSTEUsnkVr3sTr3oVr3sU q3wQr4AVr3UVr3sVrnsUr3wZtXsYr3h9akf4GQv3GAz2GQ33GBD1Gg3/GQj2Fwv2GQwponApoHAQ r3AponL3GAz3GQ33GA33GAv/EBD4Fwz2GQz3GAwUr3wUr3wUr3s1wWxY011GymRGymVBx2dv31OF ZESbcDqyfDCLiEKKiUP2GQ2+Pyi9Pim+PijORyDUSh7lMRXLNSJMimChUTZNiV8/k2Y/kmZNil9M iWDoIhT///8jVpw7AAAASXRSTlMAED9vn6/fz7+Pfy/P318fIO+fb48g7xCfnx+P719/388wP2+/ vz/Pr59AEDDfX+8fIO9vv98g7x9vj6+vEK9/v5+fEI/PQECvpB3GWwAAAAFiS0dEZbXdiJ8AAAAH dElNRQfnAg8SGDkniqXjAAACdUlEQVRYw7XYeVvTMBwH8HRdu2x2QhnigaDIoXMeyBwFEYcnhimO aREE58X7fw32SNquadM0Cd//+iT97Jc+TZoMAEa0il41TBN5MWuwrleAWCoNiKhA/UpZR2tYKCfN q1MloOm6jViZ4cW0GVQYPqxlF0sI2Y3isUEeKHhmUypKwoXpLGmWH/KTP0jNCDocDHA+0Td/Jm0H waWh5Ui1sPvhF5wBTQ1I22F4XdNYUikq2zKQCIUMWmogMQrVqbcAiVIo9U5M2+KUPfmuNpE4hWD2 gxKhkkO8NjdM5GiE85WmvpG2o+Qdc9cj6oabmWOa+p7d82ZUlCtLubfYRZWh5tlFlaFuh09rQQHl LgbUHRXUXV9aclVQ7j2PWlZD+SNcOQlz+gPnjLSe/6RyTtrOSO9TfPuqR1FzYuRyZETNLg2sqaIq 8UIlS+nx90qWqgKoijKApYpqAlMdhVRR6FIoek5wTZzjS6FMVVRTHXUfPFBFQdBWRdXj6SxL6fEi I0s9BB1VlLeKwnSv8S+c3zT1h7SN0xRM7GIiaog4MkxT/m5mTQ31yP8QWiqox8l9mhz1JKA6TxVQ 68mTjRTVxvurZ/LUOtn2tWWpdrQX7WwMpbIRFQVA1+P/Uj/ISlT6P5fs03Cey1C9idPEpiNOOVuT h5yuOLWdPnq9EKUWAZUdMeplxim1Y4lQ1i5gWGWobMmzdoKta7RAXpxQuSBt46DrqxzJS3/ilwvX 9nnASNfhp5xtwMxmj5fa2wJF6Ts8VFFJuLDXxdSbtzxSjOVRzrvisSWwhV4e9b7PW1GUpeUVmlrt fyjr4Oy3ZqEV/sVqfqy29ndZnf8DvmQl5C21ru4AAAAldEVYdGRhdGU6Y3JlYXRlADIwMjMtMDIt MTVUMTc6MjQ6NTcrMDE6MDA4r3IMAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIzLTAyLTE1VDE3OjI0 OjU3KzAxOjAwSfLKsAAAAABJRU5ErkJggg=="
    />
  </svg>
);

export default UserIcon;
