import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  MAINNET_ABI,
  VENUS_ABI,
  VENUS_BNB_ABI,
  VENUS_DISTRIBUTOR_PROXY_ABI,
} from "./abis";
import { VenusState } from "./types";
import { MAX_UINT, MinAbi, DEFAULT_DECIMALS } from "shared/constants";
import { setApproving } from "./index";
import { State } from "../index";
import { getContract } from "utils/getContract";
import { ethers } from "ethers";
import { ethersToBN } from "utils/ethersToBN";
import axios from "axios";

const getApprovedAssets = async (assetsString: string, account: string) => {
  const query = new window.widgets.query(`
        query {
          ethereum(network: bsc) {
            smartContractCalls(
              caller: {is: "${account}"}
              smartContractMethod: {is: "approve"}
              smartContractAddress: {in: [${assetsString}]}
            ) {
              smartContractMethod {
                name
              }
              arguments {
                argument
                value
              }
              smartContract {
                address {
                  address
                }
              }
            }
          }
        }`);

  return new Promise((resolve) => {
    query.request({}, (res: any) => {
      resolve(res);
    });
  });
};

const collectXVS: any = createAsyncThunk<any, any>(
  "venus/collectXVS",
  async ({ account, library }) => {
    if (account) {
      // Venus Distribution (Unitroller) address
      const contract = getContract(
        VENUS_DISTRIBUTOR_PROXY_ABI,
        "0xfd36e2c2a6789db23113685031d7f16329158384",
        library.getSigner(),
      );
      try {
        const gas = await contract.estimateGas["claimVenus(address)"](account)
          .then((res) => res)
          .catch(() => "720000");

        contract["claimVenus(address)"](account, { gasLimit: gas });
      } catch (e) {
        console.error(e);
      }
    }
  },
);

const approve: any = createAsyncThunk<any, any, any>(
  "venus/approve",
  // eslint-disable-next-line
  async ({ account, library, tokenSymbol }, { getState, dispatch }) => {
    try {
      const state: VenusState = (getState() as State).venus;
      const token = state.assets.find((t: any) => t.symbol === tokenSymbol);
      const contract = getContract(
        MAINNET_ABI,
        token.addressMainnet,
        library.getSigner(),
      );
      dispatch(
        setApproving({
          status: true,
          tokenSymbol: tokenSymbol,
        }),
      );
      await contract.approve(token.venusContract, MAX_UINT);
    } catch (e) {
      console.error(e);
      dispatch(
        setApproving({
          status: false,
          tokenSymbol: tokenSymbol,
        }),
      );
    }
  },
);

const getSaveWithdrawContractToken = ({
  getState,
  library,
  tokenSymbol,
}: any) => {
  const state: VenusState = (getState() as State).venus;
  let contract;
  const token = state.assets.find((t) => t.symbol === tokenSymbol);
  if (token.symbol === "BNB") {
    contract = getContract(
      VENUS_BNB_ABI,
      token.venusContract,
      library.getSigner(),
    );
  } else {
    contract = getContract(VENUS_ABI, token.venusContract, library.getSigner());
  }
  return { contract, token };
};

const save: any = createAsyncThunk<any, any, any>(
  "venus/save",
  // eslint-disable-next-line
  async ({ account, library, tokenSymbol, amount }, { getState }) => {
    const { contract, token } = getSaveWithdrawContractToken({
      getState,
      library,
      tokenSymbol,
    });
    console.log(contract);
    try {
      if (token.symbol === "BNB") {
        const gas = await contract.estimateGas.mint({
          value: ethers.utils.parseEther(amount.toString()).toString(),
        });

        await contract.mint({
          value: ethers.utils.parseEther(amount.toString()).toString(),
          gasLimit: gas,
        });
      } else {
        const gas = contract.estimateGas.mint(
          ethers.utils.parseEther(amount.toString()).toString(),
        );

        await contract.mint(
          ethers.utils.parseEther(amount.toString()).toString(),
          { gasLimit: gas },
        );
      }
    } catch (e) {
      console.error(e);
    }
  },
);

const withdraw: any = createAsyncThunk<any, any, any>(
  "venus/withdraw",
  // eslint-disable-next-line
  async ({ account, library, tokenSymbol, amount }, { getState }) => {
    const { contract } = getSaveWithdrawContractToken({
      getState,
      library,
      tokenSymbol,
    });
    console.log(contract);
    try {
      await contract["redeemUnderlying(uint256)"](
        ethers.utils.parseEther(amount.toString()).toString(),
      );
    } catch (e) {
      console.error(e);
    }
  },
);

// const getWalletBalances: any = createAsyncThunk<any, any, any>(
//   "venus/getWalletBalances",
//   async ({account, library}, { getState }) => {
//     const state: VenusState = (getState() as State).venus
//     const assets = JSON.parse(JSON.stringify(state.assets)); // copying assets
//     for (let i = 0; i < assets.length; i++) {
//       if (assets[i].symbol !== 'BNB') {
//         try {
//           await balanceOf(account, assets[i].addressMainnet, { provider: library._provider }, (balance: any) => {
//             assets[i].walletBalance = balance;
//           })
//         } catch (e) {
//           console.error(e);
//         }
//       } else {
//         try {
//           await getETHBalance(account, (balance: any) => {
//             assets[i].walletBalance = balance;
//           })
//         } catch (e) {
//           console.error(e);
//         }
//       }
//     }
//     return assets
//   }
// )

// const checkVenusAllowance = async (library: any, accountAddress: string, tokenAddress: string, mainnetTokenAddress: string) => {
//   const contract = getContract(MAINNET_ABI, mainnetTokenAddress, library.getSigner());
//   const allowance = await contract.allowance(accountAddress, tokenAddress);
//   return allowance !== "0";
// };

const getVenusData: any = createAsyncThunk<any, any, any>(
  "venus/getVenusData",
  async ({ account, library }, { getState }) => {
    const state: VenusState = (getState() as State).venus;
    const assets = JSON.parse(JSON.stringify(state.assets)); // copying assets
    for (let i = 0; i < assets.length; ++i) {
      if (assets[i].symbol !== "BNB") {
        const contract = getContract(
          MinAbi,
          assets[i].addressMainnet,
          library.getSigner(),
        );

        let balance = await contract.balanceOf(account);
        const decimals = await contract.decimals();

        balance = ethersToBN(balance).div(10 ** decimals);

        assets[i].walletBalance = parseFloat(balance.toFixed());
      } else {
        const balance = await library.getBalance(account);
        assets[i].walletBalance = ethersToBN(balance)
          .div(10 ** DEFAULT_DECIMALS)
          .toNumber();
      }
    }

    // const state: VenusState = (getState() as State).venus
    let gotVTokens = false;
    // getting vtokens
    let vtokens: any;
    await axios
      .get("https://api.venus.io/api/vtoken")
      .then((res) => {
        gotVTokens = true;
        vtokens = res.data.data;
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => {});

    if (gotVTokens) {
      const assetsString = assets
        .map((a) => `"${a.addressMainnet}"`)
        .join(", ");

      const approvedAssets: any = (
        (await getApprovedAssets(assetsString, account)) as any
      )?.ethereum?.smartContractCalls;

      const mantissa = 28;
      for (let i = 0; i < assets.length; i++) {
        const contract = getContract(
          MinAbi,
          assets[i].venusContract,
          library.getSigner(),
        );

        let balance = await contract.balanceOf(account);
        const decimals = await contract.decimals();
        balance = parseFloat(
          ethersToBN(balance)
            .div(10 ** decimals)
            .toFixed(),
        );

        const vToken = vtokens.markets.find(
          (a: any) => a.address === assets[i].venusContract,
        );
        const exchangeRate = vToken.exchangeRate / 10 ** mantissa;
        assets[i].supplyApy = parseFloat(vToken.supplyApy);
        assets[i].supplyVenusApy = parseFloat(vToken.supplyVenusApy);
        assets[i].venusBalance = balance * exchangeRate;
        assets[i].venusBalanceUSD = balance * exchangeRate * vToken.tokenPrice;
        assets[i].walletBalanceUSD = balance * exchangeRate;
        assets[i].tokenPrice = vToken.tokenPrice;
        assets[i].venusExchangeRate = exchangeRate;
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        if (assets[i].symbol === "BNB") assets[i].approved = true;
        else {
          assets[i].approved =
            approvedAssets?.findIndex(
              (a) =>
                a?.smartContract?.address?.address?.toLowerCase() ===
                assets[i].addressMainnet?.toLowerCase(),
            ) !== -1 || false;
        }
        // assets[i].approved = assets[i].symbol === 'BNB' ? true : await checkVenusAllowance(library, account, assets[i].venusContract, assets[i].addressMainnet);
      }
    }
    return assets;
  },
);

export {
  collectXVS,
  approve,
  save,
  withdraw,
  // getWalletBalances,
  getVenusData,
};
