import React, { useEffect, useMemo, useState } from "react";
import Styled from "./styled";
import { CartIcon } from "./_resources/CartIcon";
import { useSelector } from "react-redux";
import { State, useAppDispatch } from "store";
import { getCartTokens } from "store/cart/thunks";
import { useWeb3React } from "@web3-react/core";
import { useDeleteInvestToken, useResize } from "shared/hooks";
import useEndOfScroll from "utils/useEndOfScroll";

type Props = {
  disabled?: boolean;
};

const Cart = ({ disabled = false }: Props) => {
  const { account, chainId } = useWeb3React();
  const dispatch = useAppDispatch();
  const items = useSelector((state: State) => state.cart.items);
  const { handleRemoveItem } = useDeleteInvestToken();
  const { windowWidth } = useResize();
  const [showItems, setShowItems] = useState(false);

  const mobileView = useMemo(() => {
    return windowWidth <= 600;
  }, [windowWidth]);

  const suitableItems = useMemo(() => {
    return items.filter((item) => item.chainId === chainId);
  }, [items, chainId]);

  useEffect(() => {
    if (account) {
      dispatch(getCartTokens(account));
    }
  }, [account, dispatch]);

  const isEndOfScroll = useEndOfScroll();

  // useEffect(() => {
  //   const resetCartTokens = () => {
  //     items.forEach((item) => {
  //       handleRemoveItem(item.id!);
  //     });
  //   };
  //
  //   resetCartTokens();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [chainId]);

  const openCartHandler = () => {
    if (!showItems) {
      setShowItems(true);
    } else return;
  };

  return items.length > 0 && !isEndOfScroll ? (
    <Styled.Wrapper onClick={openCartHandler} $disabled={disabled}>
      <Styled.Top>
        <Styled.Tag>Cart</Styled.Tag>
        <CartIcon />
      </Styled.Top>
      {mobileView && <Styled.ArrowIcon rotate={showItems} />}
      {mobileView && showItems && (
        <Styled.CartAmount>
          Cart (
          <Styled.CartAmountNumber>
            {items.length + " "}
          </Styled.CartAmountNumber>
          Tokens)
        </Styled.CartAmount>
      )}
      {!mobileView &&
        suitableItems.map((item) => (
          <Styled.Content
            key={(item?.id ?? 0) + item.chainId + item.tokenAddress}
          >
            <Styled.TokenBox>
              <Styled.CloseIcon onClick={() => handleRemoveItem(item.id!)} />
              <Styled.TokenName>{item.symbol}</Styled.TokenName>
            </Styled.TokenBox>
            <Styled.TokenIcon
              src={`/svg/invest/logos/${item.symbol}.svg`}
              alt={item.symbol}
              onError={(e) => {
                e.currentTarget.src = `https://storage.roobee.io/files/tokens/logos/${item.symbol}.png`;
                e.currentTarget.onerror = () => {
                  e.currentTarget.src = "/svg/global/question-coin.svg";
                  e.currentTarget.onerror = null;
                };
              }}
            />
          </Styled.Content>
        ))}
      {showItems && mobileView && (
        <Styled.Box>
          {suitableItems.map((item) => (
            <Styled.Content
              key={(item?.id ?? 0) + item.chainId + item.tokenAddress}
            >
              <Styled.TokenBox>
                <Styled.CloseIcon onClick={() => handleRemoveItem(item.id!)} />
                <Styled.TokenName>{item.symbol}</Styled.TokenName>
              </Styled.TokenBox>
              <Styled.TokenIcon
                src={`/svg/invest/logos/${item.symbol}.svg`}
                alt={item.symbol}
                onError={(e) => {
                  e.currentTarget.src = `https://storage.roobee.io/files/tokens/logos/${item.symbol}.png`;
                  e.currentTarget.onerror = () => {
                    e.currentTarget.src = "/svg/global/question-coin.svg";
                    e.currentTarget.onerror = null;
                  };
                }}
              />
            </Styled.Content>
          ))}
        </Styled.Box>
      )}
      {suitableItems.length > 0 &&
        (mobileView ? (
          showItems ? (
            <Styled.CartButtons>
              <Styled.HideBtn onClick={() => setShowItems(false)}>
                Hide
              </Styled.HideBtn>
              <Styled.InfoLink to={"/cart"}>
                <Styled.BuyIcon />
                Checkout
              </Styled.InfoLink>
            </Styled.CartButtons>
          ) : (
            <Styled.TokensAmountWrapper>
              <CartIcon />
              <Styled.TokensAmount>
                {items.length + " Tokens"}
              </Styled.TokensAmount>
            </Styled.TokensAmountWrapper>
          )
        ) : (
          <Styled.InfoLink to={"/cart"}>
            <Styled.InfoButton>
              <Styled.BuyIcon />
              Checkout
            </Styled.InfoButton>
          </Styled.InfoLink>
        ))}
    </Styled.Wrapper>
  ) : null;
};

export default Cart;
