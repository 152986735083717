import React from "react";

const NotificationIcon = ({ ...props }) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 12V18.1858C22 18.7381 21.5523 19.1858 21 19.1858H3C2.44772 19.1858 2 18.7381 2 18.1858V12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12V18.1858C23 19.2904 22.1046 20.1858 21 20.1858H17V20.4182V21C17 22.1046 16.1046 23 15 23H9C7.89543 23 7 22.1046 7 21V20.4182V20.1858H3C1.89543 20.1858 1 19.2904 1 18.1858V12ZM8 21V20.4182H16V21C16 21.5523 15.5523 22 15 22H9C8.44771 22 8 21.5523 8 21Z"
      fill="black"
    />
  </svg>
);

export default NotificationIcon;
