import i18next from "i18next";
import { initReactI18next } from "react-i18next";
// import LanguageDetector from 'i18next-browser-languagedetector'

export type SupportedLanguageType = {
  code: string;
  filename: string;
  textToShow: string;
};

export const supportedLanguages = {
  "en-US": {
    code: "en-US",
    filename: "en",
    textToShow: "Eng",
  } as SupportedLanguageType,
  "ru-RU": {
    code: "ru-RU",
    filename: "ru",
    textToShow: "Rus",
  } as SupportedLanguageType,
  "zh-CHS": {
    code: "zh-CHS",
    filename: "zh",
    textToShow: "Chinese",
  } as SupportedLanguageType,
};

const resources = Object.fromEntries(
  Object.values(supportedLanguages).map((l) => [
    l.code,
    { translation: require(`./locales/${l.filename}.json`) },
  ]),
);

i18next
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    debug: process.env.NODE_ENV === "development",
    fallbackLng: "en-US",
    interpolation: { escapeValue: false },
  })
  .then();

export default i18next;
