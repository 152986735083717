import { createSlice } from "@reduxjs/toolkit";
import { reducers } from "./reducers";
import extraReducers from "./extraReducers";
import { InvestState } from "./types";

const initialState: InvestState = {
  tokens: [],
  prices: {},
  previousPrices: {},
};

export const investSlice = createSlice({
  name: "invest",
  initialState,
  reducers,
  extraReducers,
});

export default investSlice.reducer;

export const { setTokens, setPrices, setPreviousPrices } = investSlice.actions;
