import styled from "styled-components";
import Modal from "components/Modal";
import ModalStyled from "components/Modal/styled";
import { default as NotStyledNetworkIcon } from "components/NetworkIcon";

const Wrapper = styled(Modal)`
  ${ModalStyled.Card} {
    width: 540px;
    max-height: 100%;
    overflow: hidden;
    margin-bottom: 0;
  }

  ${ModalStyled.CardTop} {
    padding: 0;
  }

  ${ModalStyled.Close} {
    width: 12px;
    height: 12px;
    top: 24px;
    right: 24px;

    path {
      fill: #c2c2c2;
    }
  }

  @media (max-width: 600px) {
    ${ModalStyled.Card} {
      width: 300px;
      height: auto;
      min-height: auto;
    }
  }
`;

const Content = styled.div`
  padding: 20px 20px 50px;
`;

const Subtitle = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 120%;
  text-align: center;
  color: #000000;
  margin: 33px auto 10px;
  max-width: 460px;
  width: 100%;
`;

const Text = styled.div`
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #858585;
`;

const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  grid-column-gap: 22px;
  width: 100%;
  margin-top: 15px;
  @media (max-width: 600px) {
    flex-wrap: wrap;
    grid-row-gap: 20px;
    grid-column-gap: 40px;
  }
`;

const Network = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const NetworkName = styled.div`
  margin-top: 12px;
  font-size: 12px;
  line-height: 145%;
  color: #000000;
`;

const NetworkIcon = styled(NotStyledNetworkIcon)`
  width: 40px;
  height: 40px;
`;

export default {
  Wrapper,
  Content,
  Subtitle,
  Text,
  Box,
  Network,
  NetworkName,
  NetworkIcon,
};
