import { createAsyncThunk } from "@reduxjs/toolkit";
// import {DashboardState} from "./types";
import { setActiveAssets, setAssets } from "./index";
import axios from "axios";
import BigNumber from "bignumber.js";
import { deepEqualObjects } from "utils/deepEqualObjects";
import {
  ERC20Abi,
  ROOBEE_ADDRESSES,
  SCAN_API_URLS,
  SCAN_API_KEYS,
  networkSetupConfigs,
} from "shared/constants";
import { ethersToBN } from "utils/ethersToBN";
import { getContract } from "utils/getContract";

declare global {
  interface Window {
    widgets: any;
  }
}

const testnets = [80001, 421611];

const getScanTransactions = async (
  transactions,
  account,
  page,
  network,
  chainId,
) => {
  const isTestnet = testnets.includes(chainId);

  const fetchedTransactions = (
    await axios.get(
      `${
        SCAN_API_URLS[`${network}${isTestnet ? "-testnet" : ""}`]
      }?module=account&action=tokentx&address=${account}&page=${page}&offset=10000&sort=asc&apikey=${
        SCAN_API_KEYS[network]
      }`,
    )
  )?.data?.result;

  if (fetchedTransactions?.length) {
    transactions.push(fetchedTransactions);

    if (fetchedTransactions.length >= 10000) {
      transactions.push(
        ...(
          (await getScanTransactions(
            transactions,
            account,
            page + 1,
            network,
            chainId,
          )) as any
        )?.fetchedTransactions,
      );
    }
  }

  return {
    transactions: transactions?.filter((t) => !!t)?.flat() || [],
    fetchedTransactions: fetchedTransactions || [],
  };
};

const fetchAssets: any = createAsyncThunk<any, any, any>(
  "dashboard/fetchAssets",
  async ({ account, network, library, chainId }, { dispatch }) => {
    if (account && library) {
      const networkNativeToken =
        networkSetupConfigs[network?.toLowerCase()]?.nativeCurrency;
      // calculating balances through transactions
      const { transactions } = await getScanTransactions(
          [],
          account,
          1,
          network,
          chainId,
        ), // network: string = ETH | BSC | OPTIMISM
        assetBalances = {
          "-": {
            value: ethersToBN(await library?.getBalance(account))
              .div(10 ** 18)
              .toNumber(),
            currency: {
              symbol: networkNativeToken?.symbol?.toUpperCase(),
              name: networkNativeToken?.name,
              address: "-",
            },
          },
        };

      if (transactions?.length) {
        transactions.forEach((t) => {
          if (t) {
            const transactionOut =
              account?.toLowerCase() === t.from?.toLowerCase();
            // const gas = new BigNumber(t.gasUsed || 0).multipliedBy(t.gasPrice || 0).div(10 ** 18).toNumber()
            const transactionValue =
              (transactionOut ? -1 : 1) *
              new BigNumber(t.value || 0)
                .div(10 ** (t.tokenDecimal ? parseInt(t.tokenDecimal) : 0))
                .toNumber();

            // if (transactionOut) {
            //   assetBalances['0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c'].value += gas
            // }

            if (t.contractAddress !== networkNativeToken?.scanAddress) {
              if (!assetBalances[t.contractAddress]) {
                assetBalances[t.contractAddress] = {
                  value: transactionValue,
                  currency: {
                    symbol: t.tokenSymbol,
                    name: t.tokenName,
                    address: t.contractAddress,
                  },
                };
              } else {
                assetBalances[t.contractAddress].value += transactionValue;
              }
            }
          }
        });
      }

      // getting roobee balance using contract
      if (!["OPTIMISM", "ARBITRUM", "POLYGON", "AVALANCHE"].includes(network)) {
        const roobeeContract = getContract(
          ERC20Abi,
          ROOBEE_ADDRESSES[network],
          account ? library.getSigner() : library,
        );
        const roobeeBalance = ethersToBN(
          await roobeeContract.balanceOf(account),
        )
          .div(10 ** 18)
          .toNumber();

        if (!assetBalances[ROOBEE_ADDRESSES[network]]) {
          assetBalances[ROOBEE_ADDRESSES[network]] = {
            value: roobeeBalance,
            currency: {
              symbol: "BROOBEE",
              name: "bROOBEE",
              address: ROOBEE_ADDRESSES[network],
            },
          };
        } else {
          assetBalances[ROOBEE_ADDRESSES[network]].value = roobeeBalance;
        }
      }

      const assets = assetBalances ? Object.values(assetBalances) : null,
        activeAssets =
          assets?.filter((b: { value: number }) => b.value > 1e-8) || null;

      // updating balances on backend
      if (activeAssets) {
        const balances: any = [];
        for (let i = 0; i < activeAssets?.length; ++i) {
          const { currency, value } = activeAssets[i];
          const formattedValue = new BigNumber(value).toFixed();
          balances.push({
            symbol: currency.symbol,
            value: formattedValue,
            address: currency.address,
          });
        }

        let localBalances = localStorage.getItem(
          `balances_${chainId}_${account}`,
        );

        try {
          if (localBalances) localBalances = JSON.parse(localBalances);
        } catch {
          localStorage.removeItem(`balances_${chainId}_${account}`);
        }

        if (!localBalances || !deepEqualObjects(localBalances, balances)) {
          localStorage.setItem(
            `balances_${chainId}_${account}`,
            JSON.stringify(balances),
          );
          axios
            .post("https://api.roobee.finance/api/updateBalances", {
              balances: balances,
              chainId,
              address: account,
            })
            .catch((e) => console.error(e));
        }
      }

      dispatch(setAssets(assets));
      dispatch(setActiveAssets(activeAssets));
    }
  },
);

export { fetchAssets, getScanTransactions };
