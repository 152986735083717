import React from "react";

const ArrowIcon = ({ ...props }) => (
  <svg
    {...props}
    width="6"
    height="14"
    viewBox="0 0 6 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_13656_15357)">
      <path
        d="M5.89394 2.89394L5 2L0 7L5 12L5.89394 11.1061L1.78788 7L5.89394 2.89394Z"
        fill="#8596A6"
      />
    </g>
    <defs>
      <clipPath id="clip0_13656_15357">
        <rect width="5.89394" height="14" rx="2.94697" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ArrowIcon;
