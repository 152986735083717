import { useState, useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import { ERC20Abi } from "shared/constants";
import { getContract } from "utils/getContract";
import { ethers } from "ethers";
import { ethersToBN } from "utils/ethersToBN";
import bn from "bignumber.js";
import { ERC20 } from "shared/constants/abis/types";

export const useBalanceOf = () => {
  const [balance, setBalance] = useState<string>("0");
  const [balanceRaw, setBalanceRaw] = useState<string>("0");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { account, library } = useWeb3React();

  const balanceOf = useCallback(
    async (contractAddress?: string) => {
      if (!account) {
        return;
      }

      setIsLoading(true);

      if (contractAddress) {
        const contract = getContract<ERC20>(ERC20Abi, contractAddress, library);
        const balance_ = ethersToBN(await contract.balanceOf(account));
        const decimals = await contract.decimals();

        const balanceFormatted = balance_
          .div(new bn(10).pow(decimals))
          .toFixed(decimals ?? 18);

        setBalance(balanceFormatted);
        setBalanceRaw(balance_.toFixed());
        setIsLoading(false);
      } else {
        if (library) {
          const balanceInWei = await library.getBalance(account);
          const balanceStr = ethers.utils.formatEther(balanceInWei);

          setBalance(balanceStr);
          setIsLoading(false);
        }
      }
    },
    [library, account, setIsLoading],
  );

  return { balance, balanceRaw, balanceOf, setBalance, isLoading };
};
