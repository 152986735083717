import { VenusState } from "./types";

const reducers = {
  setAssets(state: VenusState, action: { payload: any }) {
    state.assets = action.payload;
  },

  setApproving(state: VenusState, action: { payload: any }) {
    state.assets.find(
      (a) => a.symbol === action.payload.tokenSymbol,
    ).approving = action.payload.status;
  },
};

export default reducers;
