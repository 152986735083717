import { DcaPresetT, ITokenDisplayable } from "shared/types";
import SUPPORTED_CHAIN_IDS from "./supportedChainIds";

const DCA_POSITION_STATE_DISPLAY_VALUES = {
  creating: "Opening",
  inactive: "Paused",
  open: "Active",
  tx_submitted: "Active",
  await_exec: "Active",
  executing: "Active",
  finished: "Finished",
};

const DCA_TIME_PERIODS = [
  {
    id: 1,
    text: "Daily",
    value: {
      count: 1,
      units: "days",
    },
    active: false,
  },
  {
    id: 2,
    text: "Weekly",
    value: {
      count: 7,
      units: "days",
    },
    active: false,
  },
  {
    id: 3,
    text: "Bi-weekly",
    value: {
      count: 14,
      units: "days",
    },
    active: false,
  },
  {
    id: 4,
    text: "Monthly",
    value: {
      count: 31,
      units: "days",
    },
    active: false,
  },
  {
    id: 5,
    text: "One hour",
    value: {
      count: 1,
      units: "hours",
    },
    active: false,
  },
];

const DCA_BUY_TOKENS: Record<number, ITokenDisplayable[]> = {
  [SUPPORTED_CHAIN_IDS.POLYGON]: [
    {
      name: "Bitcoin",
      symbol: "BTC",
      decimals: 8,
      img: "https://storage.roobee.io/files/tokens/logos/WBTC.png",
      address: "0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6",
      disabled: false,
      isNative: false,
    },
    {
      name: "Matic",
      symbol: "MATIC",
      decimals: 18,
      img: "https://storage.roobee.io/files/tokens/logos/WMATIC.png",
      address: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
      disabled: false,
      isNative: false,
    },
    {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
      img: "https://storage.roobee.io/files/tokens/logos/WETH.png",
      address: "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
      disabled: false,
      isNative: false,
    },
  ],
  [SUPPORTED_CHAIN_IDS.BSC]: [
    {
      name: "Bitcoin",
      symbol: "BTCB",
      decimals: 8,
      img: "https://storage.roobee.io/files/tokens/logos/WBTC.png",
      address: "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
      disabled: false,
      isNative: false,
    },
    {
      name: "Bnb",
      symbol: "BNB",
      decimals: 18,
      img: "https://storage.roobee.io/files/tokens/logos/WBNB.png",
      address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      disabled: false,
      isNative: false,
    },
    {
      name: "Roobee",
      symbol: "ROOBEE",
      decimals: 18,
      img: "https://storage.roobee.io/files/tokens/logos/ROOBEE.png",
      address: "0xF77351D8f4eE853135961A936BB8d2e4fFa75f9D",
      disabled: false,
      isNative: false,
    },
  ],
  [SUPPORTED_CHAIN_IDS.OPTIMISM]: [
    {
      name: "Bitcoin",
      symbol: "BTC",
      decimals: 8,
      img: "https://storage.roobee.io/files/tokens/logos/WBTC.png",
      address: "0x68f180fcCe6836688e9084f035309E29Bf0A2095",
      disabled: false,
      isNative: false,
    },
    {
      name: "Optimism",
      symbol: "OP",
      decimals: 18,
      img: "https://storage.roobee.io/files/tokens/logos/OP.png",
      address: "0x4200000000000000000000000000000000000042",
      disabled: false,
      isNative: false,
    },
    {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
      img: "https://storage.roobee.io/files/tokens/logos/WETH.png",
      address: "0x4200000000000000000000000000000000000006",
      disabled: false,
      isNative: false,
    },
  ],
  [SUPPORTED_CHAIN_IDS.ARBITRUM]: [
    {
      name: "Bitcoin",
      symbol: "BTC",
      decimals: 8,
      img: "https://storage.roobee.io/files/tokens/logos/WBTC.png",
      address: "0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f",
      disabled: false,
      isNative: false,
    },
    {
      name: "Arbitrum",
      symbol: "ARB",
      decimals: 18,
      img: "https://storage.roobee.io/files/tokens/logos/ARB.png",
      address: "0x912CE59144191C1204E64559FE8253a0e49E6548",
      disabled: false,
      isNative: false,
    },
    {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
      img: "https://storage.roobee.io/files/tokens/logos/WETH.png",
      address: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
      disabled: false,
      isNative: false,
    },
  ],
  [SUPPORTED_CHAIN_IDS.BLAST]: [
    {
      name: "Wrapped Ether",
      symbol: "WETH",
      decimals: 18,
      img: "https://storage.roobee.io/files/tokens/logos/WETH.png",
      address: "0x4300000000000000000000000000000000000004",
      disabled: false,
      isNative: false,
    },
  ],
};

const DcaPresets: Record<number, DcaPresetT[]> = {
  [SUPPORTED_CHAIN_IDS.POLYGON]: [
    {
      token: DCA_BUY_TOKENS[SUPPORTED_CHAIN_IDS.POLYGON][0],
      timePeriod: DCA_TIME_PERIODS[1],
    },
    {
      token: DCA_BUY_TOKENS[SUPPORTED_CHAIN_IDS.POLYGON][1],
      timePeriod: DCA_TIME_PERIODS[2],
    },
    {
      token: DCA_BUY_TOKENS[SUPPORTED_CHAIN_IDS.POLYGON][2],
      timePeriod: DCA_TIME_PERIODS[3],
    },
  ],
  [SUPPORTED_CHAIN_IDS.BSC]: [
    {
      token: DCA_BUY_TOKENS[SUPPORTED_CHAIN_IDS.BSC][0],
      timePeriod: DCA_TIME_PERIODS[1],
    },
    {
      token: DCA_BUY_TOKENS[SUPPORTED_CHAIN_IDS.BSC][1],
      timePeriod: DCA_TIME_PERIODS[2],
    },
    {
      token: DCA_BUY_TOKENS[SUPPORTED_CHAIN_IDS.BSC][2],
      timePeriod: DCA_TIME_PERIODS[3],
    },
  ],
  [SUPPORTED_CHAIN_IDS.ARBITRUM]: [
    {
      token: DCA_BUY_TOKENS[SUPPORTED_CHAIN_IDS.ARBITRUM][0],
      timePeriod: DCA_TIME_PERIODS[1],
    },
    {
      token: DCA_BUY_TOKENS[SUPPORTED_CHAIN_IDS.ARBITRUM][1],
      timePeriod: DCA_TIME_PERIODS[2],
    },
    {
      token: DCA_BUY_TOKENS[SUPPORTED_CHAIN_IDS.ARBITRUM][2],
      timePeriod: DCA_TIME_PERIODS[3],
    },
  ],
  [SUPPORTED_CHAIN_IDS.OPTIMISM]: [
    {
      token: DCA_BUY_TOKENS[SUPPORTED_CHAIN_IDS.OPTIMISM][0],
      timePeriod: DCA_TIME_PERIODS[1],
    },
    {
      token: DCA_BUY_TOKENS[SUPPORTED_CHAIN_IDS.OPTIMISM][1],
      timePeriod: DCA_TIME_PERIODS[2],
    },
    {
      token: DCA_BUY_TOKENS[SUPPORTED_CHAIN_IDS.OPTIMISM][2],
      timePeriod: DCA_TIME_PERIODS[3],
    },
  ],
  [SUPPORTED_CHAIN_IDS.BLAST]: [
    {
      token: DCA_BUY_TOKENS[SUPPORTED_CHAIN_IDS.BLAST][0],
      timePeriod: DCA_TIME_PERIODS[1],
    },
  ],
};

export {
  DcaPresets,
  DCA_TIME_PERIODS,
  DCA_POSITION_STATE_DISPLAY_VALUES,
  DCA_BUY_TOKENS,
};
