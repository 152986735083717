import { createSlice } from "@reduxjs/toolkit";
import { CartState } from "./types";
import { extraReducers } from "./extraReducers";
import reducers from "./reducers";

const initialState: CartState = {
  items: [],
};

export const cartSlice = createSlice({
  name: "cart",
  reducers,
  initialState,
  extraReducers,
});

export default cartSlice.reducer;

export const { setItemAmount } = cartSlice.actions;
