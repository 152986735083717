import { createSlice } from "@reduxjs/toolkit";
import BigNumber from "bignumber.js";

import type { AirdropState } from "./types";
import reducers from "./reducers";
import extraReducers from "./extraReducers";

const initialState: AirdropState = {
  roobeeBalance: new BigNumber(0),
  stakedRoobee: new BigNumber(0),
  lastClaimedRewardAmount: new BigNumber(0),
  stakingApy: "0",
  showConfetti: false,
  rewards: [],
};

export const airdropSlice = createSlice({
  name: "airdrop",
  initialState,
  reducers,
  extraReducers,
});

export default airdropSlice.reducer;

export const {
  setRoobeeBalance,
  setStakedRoobee,
  setStakingApy,
  setRewards,
  setLastClaimedRewardAmount,
  setShowConfetti,
} = airdropSlice.actions;
