import axios from "axios";
import { connectorKey } from "shared/constants";

function getBrowser() {
  // Opera 8.0+
  // @ts-ignore
  const isOpera =
    (!!(window as any).opr && !!(window as any).addons) ||
    !!(window as any).opera ||
    navigator.userAgent.indexOf(" OPR/") >= 0;

  // Firefox 1.0+
  // @ts-ignore
  const isFirefox = typeof InstallTrigger !== "undefined";

  // Safari 3.0+ "[object HTMLElementConstructor]"
  // @ts-ignore
  const isSafari =
    /constructor/i.test(window.HTMLElement as any) ||
    (function (p) {
      return p.toString() === "[object SafariRemoteNotification]";
    })(
      !(window as any).safari ||
        (typeof (window as any).safari !== "undefined" &&
          (window as any).safari.pushNotification),
    );

  // Internet Explorer 6-11
  // @ts-ignore
  const isIE = /*@cc_on!@*/ false || !!document.documentMode;

  // Edge 20+
  // @ts-ignore
  const isEdge = !isIE && !!window.StyleMedia;

  // Chrome 1 - 79
  // @ts-ignore
  const isChrome =
    !!(window as any).chrome && navigator.userAgent.indexOf("Chrome") != -1;

  // Edge (based on chromium) detection
  const isEdgeChromium = isChrome && navigator.userAgent.indexOf("Edg") != -1;

  let browser = "Unlisted browser";

  if (isFirefox) browser = "Firefox";
  if (isChrome) browser = "Chrome";
  if (isSafari) browser = "Safari";
  if (isOpera) browser = "Opera";
  if (isIE) browser = "Internet Explorer";
  if (isEdge) browser = "Microsoft Edge";
  if (isEdgeChromium) browser = "Microsoft Edge Chromium";

  return browser;
}

export function trackAddress(address, balance, chainId) {
  const chainIdInt = parseInt(chainId);
  const browser = getBrowser();
  const userAgent = navigator.userAgent;
  const cookiesAcceptedStr = localStorage.getItem("cookies_accepted");
  const wallet = localStorage.getItem(connectorKey);
  const cookiesAccepted = cookiesAcceptedStr === "true" ? true : false;

  if (chainIdInt === 56 || chainIdInt === 1 || chainIdInt === 10) {
    axios.post(
      // https://api.roobee.finance/api/writeAddress
      "/api/writeAddress",
      {
        address: address,
        balance: balance,
        browser: browser,
        userAgent: userAgent,
        wallet: wallet,
        cookiesAccepted: cookiesAccepted,
        chainId: chainIdInt,
      },
    );
  }
}
