import React from "react";

const Discord = ({ ...props }) => (
  <svg
    {...props}
    width="33"
    height="24"
    viewBox="0 0 33 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.576 1.99455C25.5493 1.06479 23.355 0.389967 21.0692 6.89792e-05C21.0492 -0.00056176 21.0292 0.00313939 21.0108 0.0109123C20.9924 0.0186852 20.9759 0.030341 20.9625 0.0450575C20.6882 0.539928 20.3682 1.18476 20.1549 1.67963C17.7304 1.31972 15.2648 1.31972 12.8404 1.67963C12.6271 1.16976 12.3071 0.539928 12.0175 0.0450575C12.0023 0.0150654 11.9566 6.89792e-05 11.9108 6.89792e-05C9.62507 0.389967 7.44596 1.06479 5.404 1.99455C5.38876 1.99455 5.37353 2.00954 5.35829 2.02454C1.21341 8.12794 0.070524 14.0664 0.634349 19.9448C0.634349 19.9748 0.649587 20.0048 0.680064 20.0198C3.423 21.9993 6.05926 23.199 8.66505 23.9938C8.71076 24.0088 8.75648 23.9938 8.77171 23.9638C9.38126 23.139 9.92984 22.2692 10.4022 21.3545C10.4327 21.2945 10.4022 21.2345 10.3413 21.2195C9.47269 20.8896 8.64981 20.4997 7.84217 20.0498C7.78121 20.0198 7.78121 19.9299 7.82693 19.8849C7.99455 19.7649 8.16217 19.6299 8.3298 19.51C8.36027 19.48 8.40599 19.48 8.43647 19.495C13.6785 21.8493 19.332 21.8493 24.5131 19.495C24.5436 19.48 24.5893 19.48 24.6198 19.51C24.7874 19.6449 24.955 19.7649 25.1226 19.8999C25.1836 19.9448 25.1836 20.0348 25.1074 20.0648C24.315 20.5297 23.4769 20.9046 22.6083 21.2345C22.5473 21.2495 22.5321 21.3245 22.5473 21.3695C23.035 22.2842 23.5836 23.154 24.1779 23.9788C24.2236 23.9938 24.2693 24.0088 24.315 23.9938C26.936 23.199 29.5723 21.9993 32.3152 20.0198C32.3457 20.0048 32.3609 19.9748 32.3609 19.9448C33.0314 13.1516 31.2485 7.25817 27.637 2.02454C27.6218 2.00954 27.6065 1.99455 27.576 1.99455ZM11.1946 16.3608C9.62507 16.3608 8.31456 14.9362 8.31456 13.1816C8.31456 11.4271 9.59459 10.0024 11.1946 10.0024C12.8099 10.0024 14.09 11.4421 14.0747 13.1816C14.0747 14.9362 12.7947 16.3608 11.1946 16.3608ZM21.8159 16.3608C20.2463 16.3608 18.9358 14.9362 18.9358 13.1816C18.9358 11.4271 20.2158 10.0024 21.8159 10.0024C23.4312 10.0024 24.7112 11.4421 24.696 13.1816C24.696 14.9362 23.4312 16.3608 21.8159 16.3608Z"
      fill="#FDFDFD"
    />
  </svg>
);

export default Discord;
