import styled from "styled-components";
import { default as Modal } from "../../../../components/Modal";
import { LinkIcon as NotStyledLinkIcon } from "pages/Save/_resources/LinkIcon";
const Wrapper = styled(Modal)`
  .card__content {
    padding: 0;
    max-width: 100%;
  }
  .card__top h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 155%;
    color: #ff8c34;
  }

  @media (max-width: 600px) {
    .card__content {
      padding-bottom: 53px;
      max-width: 100%;
    }
  }
`;
const Title = styled.h1`
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  font-style: normal;
  text-align: center;
  color: #000000;
  margin: 0 0 6px;
  @media (max-width: 600px) {
    font-size: 14px;
    line-height: 155%;
    max-width: 170px;
    margin: 0 auto 5px;
  }
`;
const SubTitle = styled.h3`
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: ${(props) => props.theme.Colors.MG48};
  font-style: normal;
  max-width: 340px;
  margin: 0 auto;
  @media (max-width: 600px) {
    font-size: 14px;
    line-height: 155%;
    max-width: 245px;
    margin: 0 auto;
  }
`;
const MetaMask = styled.a`
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    top: -12px;
    left: 44%;
    z-index: 1;
    width: 60px;
    height: 60px;
    background: ${(props) => props.theme.Colors.BackgroundGray10};
    border-radius: 50px;
  }
  svg {
    width: 40px;
    height: 40px;
    z-index: 2;
    position: relative;
  }

  @media (max-width: 600px) {
    &:after {
      left: 40%;
    }
  }
`;

const MetamaskText = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 145%;
  text-align: center;
  color: ${(props) => props.theme.Colors.Black};
  margin: 10px auto 0;
`;

const Content = styled.div`
  padding: 30px 20px 0;
  margin-bottom: 15px;
`;
const Text = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 145%;
  text-align: center;
  max-width: 200px;
  margin: 0 auto;
  color: #5c5c5c;
  @media (max-width: 600px) {
    max-width: 220px;
    &:first-child {
      margin-bottom: 10px;
    }
  }
`;
const LinksBackground = styled.div`
  padding: 18px;
  background: #dcf9fb;
`;
const Link = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 145%;
  text-align: center;
  color: #5c5c5c;
  margin-right: 30px;
  transition: color 0.3s ease;
  &:last-child {
    margin-right: 0;
  }
  &:hover {
    color: #000000;
  }
  @media (max-width: 600px) {
    margin-right: 0;
  }
`;
const LearnMore = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 20px 40px;
  margin-top: 60px;
  @media (max-width: 600px) {
    margin-top: 35px;
  }
`;
const Links = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 600px) {
    display: grid;
    justify-content: center;
    grid-template-columns: auto auto;
    grid-gap: 20px;
  }
`;
const Button = styled.a`
  font-weight: 700;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 85%;
  text-align: right;
  color: #0dc4e0;
  svg {
    margin-left: 10px;
  }
`;
const Top = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 0 20px 20px;
  border-bottom: 1px solid #c2c2c2;
  svg {
    width: 22px;
    height: 22px;
    margin-right: 12px;
    path {
      fill: #ff8c34;
    }
  }
`;

const TopTitle = styled.h2`
  margin: 0;
  font-weight: 500;
  font-size: 18px;
  line-height: 155%;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  color: #ff8b02;
`;

const HR = styled.div`
  height: 1px;
  width: 240px;
  margin: 25px auto 30px;
  background: #d8e4eb;
`;

const Img = styled.img`
  width: 30px;
  object-fit: contain;
  height: 30px;
  margin-bottom: 10px;
`;

const Inner = styled.div<{ background?: boolean }>`
  margin-bottom: 20px;
  @media (max-width: 600px) {
    margin-bottom: 30px;
  }
`;

const HowTo = styled.a`
  background: #00a8c2;
  box-shadow:
    0 2px 4px rgba(28, 65, 84, 0.16),
    0 4px 8px rgba(28, 65, 84, 0.08);
  background: linear-gradient(90deg, #b6e82f 0%, #50e0ed 100%);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px 0 12px;
  max-width: 393px;
  margin: 0 auto 40px;
  @media (max-width: 600px) {
    max-width: 220px;
    padding: 12px 20px;
  }
`;

const HowToIcon = styled(NotStyledLinkIcon)`
  margin-bottom: 5px;
  path {
    fill: #ffffff;
  }
`;

const HowToText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  text-align: center;
  color: ${(props) => props.theme.Colors.Black};
`;

export default {
  Wrapper,
  Title,
  Top,
  HR,
  Inner,
  TopTitle,
  SubTitle,
  MetaMask,
  Img,
  HowTo,
  HowToIcon,
  HowToText,
  Content,
  Text,
  LinksBackground,
  Links,
  Link,
  LearnMore,
  Button,
  MetamaskText,
};
