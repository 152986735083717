import { useMemo } from "react";
import { useSelector } from "react-redux";
import { State } from "../../store";

const useGrowPercent = (contractAddress: string | undefined) => {
  const prices = useSelector((state: State) => state.invest.prices);
  const prevPrices = useSelector((state: State) => state.invest.previousPrices);

  const calculatedGrowPercent = useMemo(() => {
    if (contractAddress) {
      const currentPrice = prices[contractAddress.toLowerCase()]?.usd;
      const previousPrice = prevPrices[contractAddress.toLowerCase()]?.usd;

      if (!!currentPrice && !!previousPrice) {
        let isGrowing = true;

        // NOTE(Nikita): Getting growth of price relative to
        //               current price.
        const percent = (100 * previousPrice) / currentPrice - 100;

        if (percent < 0) {
          isGrowing = false;
        }
        return {
          percent: Math.abs(percent).toFixed(2),
          isGrowing,
        };
      }
    }
    return null;
  }, [prices, prevPrices, contractAddress]);

  return { calculatedGrowPercent };
};

export { useGrowPercent };
