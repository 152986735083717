import React from "react";
import { Button, Icon, useDialogModal } from "../../../../components";
import Styled from "./styled";
import { ROUTE_PATHS } from "../../../../shared/constants";
import { useHistory } from "react-router";
const MarketplaceDialogSuccess = (): JSX.Element => {
  const { hideModal } = useDialogModal();
  const history = useHistory();

  const redirectToInvest = () => {
    hideModal();
    history.push(ROUTE_PATHS.INVEST);
  };

  return (
    <>
      <Styled.Top>
        <Styled.Box>
          <Icon asset="success" color="PrimaryGreen" />
          <Styled.TopText>Success</Styled.TopText>
        </Styled.Box>
        <Icon
          asset="util-close-cross"
          color="MG24"
          clickable
          onClick={redirectToInvest}
        />
      </Styled.Top>
      <Styled.Content>
        <Styled.Text>Tokens purchased successfully!</Styled.Text>
        <Styled.SubText>
          View transaction information in the explorer
        </Styled.SubText>
        <Button color="gradient" size="modal" onClick={redirectToInvest}>
          Great!
        </Button>
      </Styled.Content>
    </>
  );
};

export default MarketplaceDialogSuccess;
