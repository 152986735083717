import React from "react";

type Props = {
  children: JSX.Element;
};

class ErrorBoundary extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error) {
    alert(error);
    console.error(error);
  }

  render() {
    return this.props.children;
  }
}
export default ErrorBoundary;
