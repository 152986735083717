import { connectorKey, networkSetupConfigs, WALLETS } from "shared/constants";
import type { AvailableSetup } from "shared/types";

type Window = {
  ethereum?;
  BinanceChain?;
  bitkeep?: {
    ethereum?;
  };
};

export const setupNetwork = async (
  configName: AvailableSetup,
): Promise<boolean> => {
  // @ts-ignore
  let provider;
  const selectedWallet = localStorage.getItem(connectorKey);

  switch (selectedWallet?.toLowerCase()) {
    // NOTE(Nikita): Metamask
    case WALLETS[0].name.toLowerCase():
      {
        provider = (window as Window).ethereum;
      }
      break;
    // NOTE(Nikita): Bitkeep
    case WALLETS[1].name.toLowerCase():
      {
        provider = (window as Window).bitkeep?.ethereum;
      }
      break;
    // NOTE(Nikita): BinanceChain wallet
    case WALLETS[2].name.toLowerCase():
      {
        provider = (window as Window).BinanceChain;
      }
      break;
    default:
      break;
  }

  if (provider) {
    const config = networkSetupConfigs[configName];

    if (!config) {
      return false;
    }

    try {
      if (provider) {
        // @ts-ignore
        await provider.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: config.chainId }],
        });

        return true;
        // @ts-ignore
      } else if (window.BinanceChain) {
        let networkId = "bsc-mainnet";
        if (config.chainId === "0x1") networkId = "eth-mainnet";
        // @ts-ignore
        provider.switchNetwork(networkId);
        return true;
      }

      return false;
    } catch (switchError: any) {
      console.log(switchError);
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        try {
          // @ts-ignore
          await provider.request({
            method: "wallet_addEthereumChain",
            params: [config],
          });
        } catch (addError) {
          return Promise.reject(addError);
        }
      }
      return Promise.reject(switchError);
    }
  }

  return true;
};
